import React, { memo } from 'react'
import { Checkbox, CheckboxProps, Dropdown, Radio, Table } from 'semantic-ui-react'
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen'
import { BANK_CODES, RISK_LEVELS, ROLES, FIELDS_INFO, CLYM_FEE_ACCEPT_BANKS } from '../../../Utils/constants'

interface PricingSectionProps {
  isRetail:boolean
  businessConduct: any
  riskLevel: string
  selectedBankOption: string
  pricingType: string
  pricingCardType: {
    id: string;
    text: string;
    value: string;
    ischecked: boolean;
  }[]
  pricingCardValue: any
  tieredQualDiscount: string
  tieredQualPerItem: string
  handleChangeTextInput: (e: any) => false | undefined
  updatePricingSectionStatus: () => void
  updateEarlyterminationFee: () => void
  tieredMidDiscount: string
  tieredNonDiscount: string
  tieredNonPerItem: string
  tieredMidPerItem: string
  tieredDebitDiscount: string
  tieredDebitPerItem: string
  amexQual: string
  amexPerItem: string
  amexMidQual: string
  amexMidPerItem: string
  amexNonQual: string
  amexNonPerItem: string
  errQualDiscount: string
  errQualPerItem: string
  errNonDiscount: string
  errNonPerItem: string
  Pricing_Type: {
    id: string;
    text: string;
    value: string;
  }[]
  discountRate: string
  perItem: string
  debitDiscountRate: string
  debitNetworkFees: string
  setDebitNetworkFees: React.Dispatch<React.SetStateAction<string>>
  intAmexDiscountRate: string
  intAmexperItem: string
  pinDebit: string
  pinDebitAuth: string
  acceptEbt: string
  ebt: string
  ebtAuth: string
  mc: string
  amexAuth: string
  avs: string
  batchFee: string
  annualFee: string
  monthlyFee: string
  chargeBackFee: string
  monthlyMinimum: string
  earlyTerminationFee: string
  accountReserve: string
  wirelessFee: string
  other: string
  billedOptions: {
    text: string;
    value: string;
  }[]
  billed: string
  handleBilledChange: (e: any, data: any) => void
  role: string | null
  merchantToken: string
  voiceAuth: string
  excessiveElectronicAuth: string
  irsReportingFee: string
  retreivalFee: string
  invalidTinFee: string
  achRejectFee: string
  collectionsFee: string
  pciValidation: string
  returnTransactionFee: string
  pciNonCompliance: string
  debitAccessFee: string
  industryCompliance: string
  amexOptubleSupportFee: string
  amexOptubleNetworkFee: string
  handleChangePricingType: (e: any, data: any) => void
  handlePricingCardType: (event: any, data: CheckboxProps) => void
  option_yesno: {
    text: string;
    value: string;
  }[]
  handleChangePinDebit: (e: any, data: any) => void
  handleAcceptEbt: (e: any, data: any) => void
  clymDataPrivacyCompliance: string
  setClymDataPrivacyCompliance: React.Dispatch<React.SetStateAction<string>>
  clymDataPrivacyComplianceFee: string,
  disableEarlyTermination:boolean,
  ticketSizeOptions:{
    text: string;
    value: string;
  }[],
  ticketSize:string,
  handleTicketSizeChange: (e: any, data: any) => void
  selectedSellTime: string,
  discountPercentageOptions:{
    text: string;
    value: string;
  }[],
  discountPercentage:string
  handleDiscountPercentChange: (e: any, data: any) => void
  serviceFeeOptions:{
    text: string;
    value: string;
  }[],
  serviceFee:string,
  handleServiceFeeChange: (e: any, data: any) => void,
  licenseTechFees:string
  config:any,
  pciError: boolean,
  pciErrorMessage: string,
  chargeBackError: boolean,
  chargeBackErrorMessage: string,
}
const PricingSection = (props: PricingSectionProps) => {
  const {
    isRetail,
    businessConduct,
    riskLevel,
    selectedBankOption,
    pricingType,
    pricingCardValue,
    tieredQualDiscount,
    handleChangeTextInput,
    updatePricingSectionStatus,
    updateEarlyterminationFee,
    tieredQualPerItem,
    tieredMidDiscount,
    tieredNonDiscount,
    tieredNonPerItem,
    tieredDebitDiscount,
    tieredDebitPerItem,
    amexQual,
    amexPerItem,
    amexMidQual,
    amexMidPerItem,
    amexNonQual,
    amexNonPerItem,
    errQualDiscount,
    errQualPerItem,
    errNonDiscount,
    errNonPerItem,
    discountRate,
    perItem,
    debitDiscountRate,
    debitNetworkFees,
    setDebitNetworkFees,
    intAmexDiscountRate,
    intAmexperItem,
    pinDebit,
    pinDebitAuth,
    acceptEbt,
    ebt,
    ebtAuth,
    mc,
    amexAuth,
    avs,
    batchFee,
    annualFee,
    monthlyFee,
    chargeBackFee,
    monthlyMinimum,
    earlyTerminationFee,
    accountReserve,
    wirelessFee,
    other,
    billedOptions,
    billed,
    handleBilledChange,
    role,
    merchantToken,
    voiceAuth,
    excessiveElectronicAuth,
    irsReportingFee,
    retreivalFee,
    invalidTinFee,
    achRejectFee,
    collectionsFee,
    pciValidation,
    returnTransactionFee,
    pciNonCompliance,
    debitAccessFee,
    industryCompliance,
    amexOptubleSupportFee,
    amexOptubleNetworkFee,
    Pricing_Type,
    handleChangePricingType,
    pricingCardType,
    handlePricingCardType,
    tieredMidPerItem,
    option_yesno,
    handleChangePinDebit,
    handleAcceptEbt,
    clymDataPrivacyCompliance,
    setClymDataPrivacyCompliance,
    clymDataPrivacyComplianceFee,
    disableEarlyTermination,
    selectedSellTime,
    ticketSizeOptions,
    ticketSize,
    handleTicketSizeChange,
    discountPercentageOptions,
    discountPercentage,
    handleDiscountPercentChange,
    serviceFeeOptions,
    serviceFee,
    handleServiceFeeChange,
    licenseTechFees,
    config,
    pciError,
    pciErrorMessage,
    chargeBackError,
    chargeBackErrorMessage,
  } = props

  const isMobile = useCheckMobileScreen();
  const clym_fee_accept_banks = Object.keys(CLYM_FEE_ACCEPT_BANKS)

  const renderPricingType = () => {
    return Pricing_Type.reduce((filteredList: any, item: any)=>{
      let showPricingType = true
      if((selectedBankOption === BANK_CODES.DART || selectedBankOption === BANK_CODES.CFSB)
        && (item.value === 'Tiered' || item.value === 'Err')) {
        showPricingType = false
      }else if(selectedSellTime === 'Cash Discount' && (selectedBankOption === BANK_CODES.CFSB_CD || selectedBankOption === BANK_CODES.ESQUIRE_CD) && (item.value === 'Tiered' || item.value === 'Err' || item.value === 'Interchange')){
        showPricingType = false
      }else {
        showPricingType = true
      }

      if(showPricingType){
        filteredList.push(
          <div className='three wide field' key={item.id}>
          <Radio
            label={item.value}
            name='pricingType'
            value={item.value}
            checked={pricingType === item.value}
            onChange={handleChangePricingType}
            disabled={role === ROLES.SALES_LIMITED || (selectedSellTime === 'Cash Discount' && (selectedBankOption === BANK_CODES.CFSB_CD || selectedBankOption === BANK_CODES.ESQUIRE_CD)) }
          />
        </div>
        )
      }
      return filteredList;
    }, []);
  }

  const renderCardType = () => {
    return pricingCardType.reduce((filteredList: any, item: any)=>{
      let showPricingCardType = true
      if(selectedBankOption === BANK_CODES.DART && riskLevel === RISK_LEVELS.HIGH_RISK && item.value === 'AMEX') showPricingCardType = false
      else showPricingCardType = true

      if(showPricingCardType){
        filteredList.push(
          <div className='three wide field' key={item.id}>
          <Checkbox
            label={item.value}
            name='pricingCardType'
            value={item.value}
            checked={item.ischecked}
            disabled={role === ROLES.SALES_LIMITED}
            onChange={(event, data: CheckboxProps) => {
              handlePricingCardType(event, data)
            }}
          />
        </div>
        )
      }
      return filteredList;
    }, []);
  }

  const renderAverageTicketSize = () => {
    return (
      <div className='inline fields'>
        <div className='four wide field'>
          <label>Average Ticket Size <span className="required-text"> *</span> </label>
        </div>
        <div className='eight wide field'>
          <Dropdown
            placeholder='choose Ticket Size'
            name='ticketSize'
            className={!ticketSize ? 'not-filled' : 'form-input'}
            fluid
            search
            selection
            options={ticketSizeOptions}
            value={ticketSize}
            disabled={role === ROLES.SALES_LIMITED}
            onBlur={updatePricingSectionStatus}
            onChange={handleTicketSizeChange}
          />
        </div>
      </div>
    )
  }
  const renderPinDebit = () => {
    return option_yesno.map((item: any, index: number) => {
      return (
        <Radio
          label={item.value}
          className='margin-right-10'
          name='pinDebit'
          value={item.value}
          checked={pinDebit === item.value}
          disabled={role === ROLES.SALES_LIMITED}
          onChange={handleChangePinDebit}
        />
      )
    })
  }

  const renderAcceptEbt = () => {
    return option_yesno.map((item: any, index: number) => {
      return (
        <Radio
          label={item.value}
          name='acceptEbt'
          className='margin-right-10'
          value={item.value}
          checked={acceptEbt === item.value}
          disabled={role === ROLES.SALES_LIMITED}
          onChange={handleAcceptEbt}
        />
      )
    })
  }

  const layHrLine = () => <hr style={{ width : '100%', backgroundColor : '#C1C1C1', border : '0.5px solid #C1C1C1', margin : '8px' }} />;

  const getIncludesArray = (array : any[], keys : any[]) => [...keys].some((key) => [...array].includes(key));

  return (
    <div className='form-fields pricing-section'>
      <div className='ui form'>
        <div className='fields vertical-grid'>
          <div 
          className='five wide field grey-outline margin-right-8 border-left-field margin-left-5'
          >
            <label>Business Conducted:</label>
            {businessConduct.join(", ")}
          </div>
          <div className='five wide field grey-outline  margin-right-8'>
            <label>Risk Level: </label>
            {riskLevel}
          </div>
          <div className='five wide field grey-outline'>
            <label>Bank Details:</label>
            {selectedBankOption}
          </div>
        </div>
          {/* {isMobile?layHrLine():''} */}
        {layHrLine()}
        
        <div className='inline fields'>
          <div className='four wide field'>
            <label>Pricing Type <span className="required-text"> *</span> </label>
          </div>
          {renderPricingType()}
        </div>
        <div className='inline fields'>
          <div className='four wide field'>
            <label>Card Type <span className="required-text"> *</span> </label>
          </div>
          {renderCardType()}
        </div>
        
        {(selectedSellTime === 'Cash Discount' && (selectedBankOption === BANK_CODES.CFSB_CD || selectedBankOption === BANK_CODES.ESQUIRE_CD)) ? renderAverageTicketSize() : ""}
        
        {selectedSellTime !== 'Cash Discount' && isMobile?layHrLine():''}

        {(selectedSellTime !== 'Cash Discount' && (pricingType === 'Tiered') && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover'))) && (
          <div className="grey-outline">
            <Table celled structured className="pricing-table qual">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='3'>Discount Fees - Visa/MC/Discover</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell ></Table.HeaderCell>
                  <Table.HeaderCell ><span className="required-text"> *</span> Percent % </Table.HeaderCell>
                  <Table.HeaderCell ><span className="required-text"> *</span> Per Item $</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Qual Discount </Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='tieredQualDiscount'
                      className={!tieredQualDiscount ? 'not-filled' : 'form-input'}
                      value={tieredQualDiscount}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='tieredQualPerItem'
                      className={!tieredQualPerItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={tieredQualPerItem}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Mid Discount</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='tieredMidDiscount'
                      className={!tieredMidDiscount ? 'not-filled' : 'form-input'}
                      value={tieredMidDiscount}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='tieredMidPerItem'
                      className={!tieredMidPerItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={tieredMidPerItem}
                      disabled={role === ROLES.SALES_LIMITED}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Non Discount</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='tieredNonDiscount'
                      className={!tieredNonDiscount ? 'not-filled' : 'form-input'}
                      value={tieredNonDiscount}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='tieredNonPerItem'
                      className={!tieredNonPerItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={tieredNonPerItem}
                      disabled={role === ROLES.SALES_LIMITED}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell> Debit</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='tieredDebitDiscount'
                      className={!tieredDebitDiscount ? 'not-filled' : 'form-input'}
                      value={tieredDebitDiscount}
                      disabled={role === ROLES.SALES_LIMITED}
                      autoComplete="new-password"
                      onBlur={updatePricingSectionStatus}
                      onChange={handleChangeTextInput}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='tieredDebitPerItem'
                      className={!tieredDebitPerItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={tieredDebitPerItem}
                      disabled={role === ROLES.SALES_LIMITED}
                      autoComplete="new-password"
                      onBlur={updatePricingSectionStatus}
                      onChange={handleChangeTextInput}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        )}
        {selectedSellTime !== 'Cash Discount' && pricingType === 'Tiered' && pricingCardValue.includes('AMEX') && (
          <div className="grey-outline">
            <Table celled structured className="pricing-table qual">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='3'>Discount Fees - Amex</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell ></Table.HeaderCell>
                  <Table.HeaderCell ><span className="required-text"> *</span>Percent %</Table.HeaderCell>
                  <Table.HeaderCell ><span className="required-text"> *</span>Per Item $</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Amex Qual</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='amexQual'
                      className={!amexQual ? 'not-filled' : 'form-input'}
                      value={amexQual}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='amexPerItem'
                      className={!amexPerItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={amexPerItem}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Amex Mid Qual</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='amexMidQual'
                      className={!amexMidQual ? 'not-filled' : 'form-input'}
                      value={amexMidQual}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='amexMidPerItem'
                      className={!amexMidPerItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={amexMidPerItem}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Amex Non Qual</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='amexNonQual'
                      className={!amexNonQual ? 'not-filled' : 'form-input'}
                      value={amexNonQual}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='amexNonPerItem'
                      className={!amexNonPerItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={amexNonPerItem}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        )}
        {(selectedSellTime !== 'Cash Discount' && (pricingType === 'Err') && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover'))) && (
          <div className="grey-outline">
            <Table celled structured className="pricing-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>Discount Fees</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell ></Table.HeaderCell>
                  <Table.HeaderCell ><span className="required-text"> *</span> Percent % </Table.HeaderCell>
                  <Table.HeaderCell ><span className="required-text"> *</span> Per Item $</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Qual Discount</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='errQualDiscount'
                      className={!errQualDiscount ? 'not-filled' : 'form-input'}
                      value={errQualDiscount}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='errQualPerItem'
                      className={!errQualPerItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={errQualPerItem}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Non Discount</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='errNonDiscount'
                      className={!errNonDiscount ? 'not-filled' : 'form-input'}
                      value={errNonDiscount}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='errNonPerItem'
                      className={!errNonPerItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={errNonPerItem}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
         )} 
        {(selectedSellTime !== 'Cash Discount' && (pricingType === 'Interchange' || pricingType === 'Flat Rate') && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover'))) && (
          <div className="grey-outline">
            <Table celled structured className="pricing-table qual">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='3'>Discount Fees - Visa/MC/Discover</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell ></Table.HeaderCell>
                  <Table.HeaderCell ><span className="required-text"> *</span> Percent % </Table.HeaderCell>
                  <Table.HeaderCell ><span className="required-text"> *</span> Per Item $</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Discount Rate</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='discountRate'
                      className={!discountRate ? 'not-filled' : 'form-input'}
                      value={discountRate}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='perItem'
                      className={!perItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={perItem}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                {(pricingType === 'Interchange') && <Table.Row>
                  <Table.Cell>Debit Discount Rate</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='debitDiscountRate'
                      className={!debitDiscountRate ? 'not-filled' : 'form-input'}
                      value={debitDiscountRate}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                </Table.Row>}
                {(pricingType === 'Interchange') && <Table.Row>
                  <Table.Cell>Pass-through debit network fees <span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>

                    <Checkbox
                      name=''
                      value=''
                      disabled={role === ROLES.SALES_LIMITED}
                      checked={debitNetworkFees === 'Yes' ? true : false}
                      onChange={(event, data: CheckboxProps) => {
                        setDebitNetworkFees(data.checked ? 'Yes' : 'No')
                      }}
                    />

                  </Table.Cell>
                </Table.Row>}
              </Table.Body>
            </Table>
          </div>
        )}
        {selectedSellTime !== 'Cash Discount' && (pricingType === 'Interchange' || pricingType === 'Flat Rate' || pricingType === 'Err') && pricingCardValue.includes('AMEX') && (
          <div className="grey-outline">
            <Table celled structured className="pricing-table qual">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>Discount Fees - Amex</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell ></Table.HeaderCell>
                  <Table.HeaderCell ><span className="required-text"> *</span> Percent % </Table.HeaderCell>
                  <Table.HeaderCell ><span className="required-text"> *</span> Per Item $</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Amex Discount Rate</Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='intAmexDiscountRate'
                      className={!intAmexDiscountRate ? 'not-filled' : 'form-input'}
                      value={intAmexDiscountRate}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='intAmexperItem'
                      className={!intAmexperItem ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={intAmexperItem}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        )}
        {selectedSellTime !== 'Cash Discount' && (
        <div className="grey-outline" style={{padding : 0}}>
          <Table celled structured className="pricing-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className='px-0' colSpan='4'>Authorization Fees</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {riskLevel === 'Low Risk' && (
                <Table.Row className='gdt-50-50'>
                  <Table.Cell>Pin Debit<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>{renderPinDebit()}</Table.Cell>
                  {pinDebit === "Yes" && (
                    <Table.Cell>Pin Debit Auth <span className="required-text"> *</span></Table.Cell>
                  )}
                  {pinDebit === "Yes" && (
                    <Table.Cell>
                      <span className='input-dollar'>$</span>
                      <input
                        type='text'
                        name='pinDebitAuth'
                        className={!pinDebitAuth ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                        value={pinDebitAuth}
                        autoComplete="new-password"
                        disabled={role === ROLES.SALES_LIMITED}
                        onChange={handleChangeTextInput}
                        onBlur={updatePricingSectionStatus}
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              )}
              {riskLevel === 'Low Risk' && (
                <Table.Row className='gdt-50-50'>
                  <Table.Cell>EBT<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>{renderAcceptEbt()} </Table.Cell>
                  <Table.Cell> </Table.Cell>
                  <Table.Cell> </Table.Cell>
                </Table.Row>
              )}
              {acceptEbt === "Yes" && (
                <Table.Row>
                  <Table.Cell>EBT#<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='ebt'
                      className={!ebt ? 'not-filled' : 'form-input'}
                      value={ebt}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                  <Table.Cell>EBT Auth <span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='ebtAuth'
                      className={!ebtAuth ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={ebtAuth}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
              {getIncludesArray(pricingCardValue,['Visa', 'MC', 'Discover', 'AMEX']) ? <Table.Row>
                {(pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover')) && (
                  <Table.Cell>Visa/Disc Auth<span className="required-text"> *</span></Table.Cell>
                )}
                {(pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover')) && (
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='mc'
                      className={!mc ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={mc}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                )}
                {pricingCardValue.includes('AMEX') && (
                  <Table.Cell>Amex Auth<span className="required-text"> *</span> </Table.Cell>
                )}
                {pricingCardValue.includes('AMEX') && (
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='amexAuth'
                      className={!amexAuth ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={amexAuth}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                )}

              </Table.Row> : <></>}
              <Table.Row>
                <Table.Cell>AVS<span className="required-text"> *</span></Table.Cell>
                <Table.Cell>
                  <span className='input-dollar'>$</span>
                  <input
                    type='text'
                    name='avs'
                    value={avs}
                    onChange={handleChangeTextInput}
                    onBlur={updatePricingSectionStatus}
                    disabled={role === ROLES.SALES_LIMITED}
                    autoComplete="new-password"
                    className={!avs ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                  />
                </Table.Cell>
                <Table.Cell>Batch Fee<span className="required-text"> *</span></Table.Cell>
                <Table.Cell>
                  <span className='input-dollar'>$</span>
                  <input
                    type='text'
                    name='batchFee'
                    value={batchFee}
                    onChange={handleChangeTextInput}
                    onBlur={updatePricingSectionStatus}
                    disabled={role === ROLES.SALES_LIMITED}
                    autoComplete="new-password"
                    className={!batchFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        )}
        {selectedSellTime !== 'Cash Discount' && isMobile?layHrLine():''}
        {selectedSellTime !== 'Cash Discount' && (
        <div className="grey-outline" style={{padding : 0}}>
          <Table celled structured className="pricing-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='4'>Misc Fees</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Annual Fee<span className="required-text"> *</span></Table.Cell>
                <Table.Cell>
                  <span className='input-dollar'>$</span>
                  <input
                    type='text'
                    className={!annualFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    name='annualFee'
                    value={annualFee}
                    autoComplete="new-password"
                    onChange={handleChangeTextInput}
                    disabled={role === ROLES.SALES_LIMITED}
                    onBlur={updatePricingSectionStatus}
                  />
                </Table.Cell>
                <Table.Cell>Monthly Fee<span className="required-text"> *</span></Table.Cell>
                <Table.Cell>
                  <span className='input-dollar'>$</span>
                  <input
                    type='text'
                    className={!monthlyFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    name='monthlyFee'
                    value={monthlyFee}
                    autoComplete="new-password"
                    onChange={handleChangeTextInput}
                    disabled={role === ROLES.SALES_LIMITED}
                    onBlur={updatePricingSectionStatus}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Chargeback Fee<span className="required-text"> *</span></Table.Cell>
                <Table.Cell>
                  <span className='input-dollar'>$</span>
                  <input
                    type='text'
                    name='chargeBackFee'
                    className={!chargeBackFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    value={chargeBackFee}
                    autoComplete="new-password"
                    onChange={handleChangeTextInput}
                    disabled={role === ROLES.SALES_LIMITED}
                    onBlur={updatePricingSectionStatus}
                  />
                   {chargeBackError ? <p className='section-error-text'>{chargeBackErrorMessage}</p> : null}
                </Table.Cell>
                <Table.Cell>Monthly Minimum<span className="required-text"> *</span></Table.Cell>
                <Table.Cell>
                  <span className='input-dollar'>$</span>
                  <input
                    type='text'
                    name='monthlyMinimum'
                    className={!monthlyMinimum ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    value={monthlyMinimum}
                    autoComplete="new-password"
                    onChange={handleChangeTextInput}
                    disabled={role === ROLES.SALES_LIMITED}
                    onBlur={updatePricingSectionStatus}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Early Termination Fee<span className="required-text"> *</span></Table.Cell>
                <Table.Cell>
                  <span className='input-dollar'>$</span>
                  <input
                    type='text'
                    name='earlyTerminationFee'
                    className={!earlyTerminationFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    value={earlyTerminationFee}
                    autoComplete="new-password"
                    onChange={handleChangeTextInput}
                    disabled={role === ROLES.SALES_LIMITED || !!disableEarlyTermination}
                    onBlur={()=>{updatePricingSectionStatus(); updateEarlyterminationFee() }} 
                  />
                </Table.Cell>
                {businessConduct.includes('E-Commerce') && riskLevel === RISK_LEVELS.HIGH_RISK && (

                  <Table.Cell>Account Reserve<span className="required-text"> *</span></Table.Cell>
                )}
                {businessConduct.includes('E-Commerce') && riskLevel === RISK_LEVELS.HIGH_RISK &&  (
                  <Table.Cell>
                    <input
                      type='text'
                      name='accountReserve'
                      className={!accountReserve ? 'not-filled' : 'form-input'}
                      value={accountReserve}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                    <span className='input-percent'>%</span>
                  </Table.Cell>
                )}
              </Table.Row>
              {riskLevel === "Low Risk" || riskLevel === "Low Risk" && <Table.Row>
                {riskLevel === "Low Risk" && (
                  <Table.Cell>Wireless Fee</Table.Cell>
                )}
                {riskLevel === "Low Risk" && (
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='wirelessFee'
                      className='form-input padding-left-30'
                      value={wirelessFee}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                    />
                  </Table.Cell>
                )}
              </Table.Row>}
              <Table.Row>
                <Table.Cell>Other </Table.Cell>
                <Table.Cell>
                  <span className='input-dollar'>$</span>
                  <input
                    type='text'
                    name='other'
                    className='form-input padding-left-30'
                    value={other}
                    autoComplete="new-password"
                    disabled={role === ROLES.SALES_LIMITED}
                    onChange={handleChangeTextInput}
                  />
                </Table.Cell>
                <Table.Cell>Billed </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    placeholder='choose one'
                    name='billed'
                    className='form-conditional'
                    fluid
                    search
                    selection
                    options={billedOptions}
                    value={billed}
                    disabled={role === ROLES.SALES_LIMITED}
                    onChange={handleBilledChange}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        )}
        {selectedSellTime !== 'Cash Discount' && selectedBankOption === BANK_CODES.CFSB && isMobile ? layHrLine() : ''}
        {(selectedSellTime === 'Cash Discount' && (selectedBankOption === BANK_CODES.CFSB_CD || selectedBankOption === BANK_CODES.ESQUIRE_CD)) && (
          <div className="grey-outline">
            <Table celled structured className="pricing-table qual">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='3'>Discount Fee</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell ></Table.HeaderCell>
                  {ticketSize ==='Above $20' &&<Table.HeaderCell ><span className="required-text"> *</span> Percent % </Table.HeaderCell>}
                  {ticketSize ==='Below $20' &&<Table.HeaderCell ><span className="required-text"> *</span> Flat $ </Table.HeaderCell>}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>                  
                    <Table.Cell>Cash Discount Rate</Table.Cell>
                  {ticketSize ==='Above $20' &&
                  <Dropdown
                    placeholder='choose one'
                    name='discountPercentage'
                    className={!discountPercentage ? 'not-filled' : 'form-input'}
                    fluid
                    search
                    selection
                    options={(ticketSize === 'Above $20' && isRetail) ? discountPercentageOptions :discountPercentageOptions.slice(1) }
                    value={discountPercentage}
                    disabled={role === ROLES.SALES_LIMITED}
                    onBlur={updatePricingSectionStatus}
                    onChange={handleDiscountPercentChange}
                  />
                  }
                  {ticketSize ==='Below $20' &&
                  <Table.Cell>
                    <Dropdown
                    placeholder='choose one'
                    name='serviceFee'
                    className={!serviceFee ? 'not-filled' : 'form-input'}
                    fluid
                    search
                    selection
                    options={serviceFeeOptions}
                    value={serviceFee}
                    disabled={role === ROLES.SALES_LIMITED}
                    onBlur={updatePricingSectionStatus}
                    onChange={handleServiceFeeChange}
                  />
                  </Table.Cell>
                  }
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        )}
        {selectedSellTime === 'Cash Discount' && (selectedBankOption === BANK_CODES.CFSB_CD || selectedBankOption === BANK_CODES.ESQUIRE_CD) && isMobile ? layHrLine() : ''}
        {(selectedSellTime === 'Cash Discount' && (selectedBankOption === BANK_CODES.CFSB_CD || selectedBankOption === BANK_CODES.ESQUIRE_CD)) && (
          <div className="grey-outline">
            <Table celled structured className="pricing-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>Other Fees</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              <Table.Row className='gdt-50-50'>
                  <Table.Cell>License & Technology Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                      <span className='input-dollar'>$</span>
                      <input
                        type='text'
                        name='licenseTechFees'
                        className={!licenseTechFees ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                        value={licenseTechFees}
                        autoComplete="new-password"
                        disabled={role === ROLES.SALES_LIMITED}
                        onChange={handleChangeTextInput}
                        onBlur={updatePricingSectionStatus}
                      />
                  </Table.Cell>
                </Table.Row>
                {selectedSellTime !== 'Cash Discount' && <Table.Row className='gdt-50-50'>
                  <Table.Cell>Pin Debit<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>{renderPinDebit()}</Table.Cell>
                  {pinDebit === "Yes" && (
                    <Table.Cell>Pin Debit Auth <span className="required-text"> *</span></Table.Cell>
                  )}
                  {pinDebit === "Yes" && (
                    <Table.Cell>
                      <span className='input-dollar'>$</span>
                      <input
                        type='text'
                        name='pinDebitAuth'
                        className={!pinDebitAuth ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                        value={pinDebitAuth}
                        autoComplete="new-password"
                        disabled={role === ROLES.SALES_LIMITED}
                        onChange={handleChangeTextInput}
                        onBlur={updatePricingSectionStatus}
                      />
                    </Table.Cell>
                  )}
                </Table.Row>}
                <Table.Row className='gdt-50-50'>
                  <Table.Cell>EBT<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>{renderAcceptEbt()} </Table.Cell>
                  <Table.Cell> </Table.Cell>
                  <Table.Cell> </Table.Cell>
                </Table.Row>
              {acceptEbt === "Yes" && (
                <Table.Row>
                  <Table.Cell>EBT#<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <input
                      type='text'
                      name='ebt'
                      className={!ebt ? 'not-filled' : 'form-input'}
                      value={ebt}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                  <Table.Cell>EBT Auth <span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='ebtAuth'
                      className={!ebtAuth ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={ebtAuth}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                )}
                <Table.Row className='gdt-50-50'>
                  <Table.Cell>Early Termination Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='earlyTerminationFee'
                      className={!earlyTerminationFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={earlyTerminationFee}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED || !!disableEarlyTermination}
                      onBlur={()=>{updatePricingSectionStatus(); updateEarlyterminationFee() }} 
                    />
                  </Table.Cell>
                  <Table.Cell>Chargeback Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='chargeBackFee'
                      className={!chargeBackFee || chargeBackError? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={chargeBackFee}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                    {chargeBackError ? <p className='section-error-text'>{chargeBackErrorMessage}</p> : null}
                  </Table.Cell>
                </Table.Row>
                <Table.Row className='gdt-50-50'>
                <Table.Cell>PCI Validation (Annual)<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='pciValidation'
                      value={pciValidation}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      className={!pciValidation || pciError ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      onBlur={updatePricingSectionStatus}
                    />
                    {pciError ? <p className='section-error-text'>{pciErrorMessage}</p> : null}
                  </Table.Cell>
                  <Table.Cell>PCI Non-Compliance(monthly)<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='pciNonCompliance'
                      value={pciNonCompliance || '19.95'}
                      autoComplete="new-password"
                      disabled={true}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                      className={'form-input padding-left-30'}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        )}
        {selectedSellTime !== 'Cash Discount' && (merchantToken !== '' || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || ((role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) && riskLevel === "Low Risk")) && (
          <div className="grey-outline" style={{padding : 0}}>
            <Table celled structured className="other-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>Other Fees</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Voice Auth<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='voiceAuth'
                      className={!voiceAuth ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={voiceAuth}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                      step='1'
                    />
                  </Table.Cell>
                  <Table.Cell>Excessive Electronic Auth<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='excessiveElectronicAuth'
                      className={!excessiveElectronicAuth ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={excessiveElectronicAuth}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>IRS Reporting Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='irsReportingFee'
                      className={!irsReportingFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={irsReportingFee}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                  <Table.Cell>Retrieval Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='retreivalFee'
                      className={!retreivalFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={retreivalFee}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Invalid Tin Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='invalidTinFee'
                      value={invalidTinFee}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                      className={!invalidTinFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    />
                  </Table.Cell>
                  <Table.Cell>NSF/ACH Reject Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='achRejectFee'
                      value={achRejectFee}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED || selectedBankOption === BANK_CODES.DART}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                      className={!achRejectFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Collection Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='collectionsFee'
                      className={!collectionsFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={collectionsFee}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                  <Table.Cell>{selectedBankOption === 'CFSB' ? 'PCI Validation(monthly)' : `PCI Validation(annual)`}<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='pciValidation'
                      value={pciValidation}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                      className={!pciValidation ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Return Transaction Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='returnTransactionFee'
                      value={returnTransactionFee}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                      className={!returnTransactionFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    />
                  </Table.Cell>
                  <Table.Cell>PCI Non-Compliance(monthly)<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='pciNonCompliance'
                      value={pciNonCompliance}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                      className={!pciNonCompliance ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  {riskLevel === 'Low Risk' && pinDebit === "Yes" && (
                    <Table.Cell>Debit Access Fee<span className="required-text"> *</span></Table.Cell>
                  )}
                  {riskLevel === 'Low Risk' && pinDebit === "Yes" && (
                    <Table.Cell>
                      <span className='input-dollar'>$</span>
                      <input
                        type='text'
                        name='debitAccessFee'
                        value={debitAccessFee}
                        autoComplete="new-password"
                        disabled={role === ROLES.SALES_LIMITED}
                        onChange={handleChangeTextInput}
                        onBlur={updatePricingSectionStatus}
                        className={!debitAccessFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      />
                    </Table.Cell>
                  )}
                  <Table.Cell>Industry Compliance<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='industryCompliance'
                      value={industryCompliance}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                      className={!industryCompliance ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                    />
                  </Table.Cell>
                </Table.Row>
                {pricingCardValue.includes('AMEX') && (
                  <Table.Row>
                    <Table.Cell>Amex OptBlue Support Fee<span className="required-text"> *</span> </Table.Cell>
                    <Table.Cell>
                      <span className='input-dollar'>$</span>
                      <input
                        type='text'
                        name='amexOptubleSupportFee'
                        value={amexOptubleSupportFee}
                        autoComplete="new-password"
                        onChange={handleChangeTextInput}
                        disabled={role === ROLES.SALES_LIMITED}
                        onBlur={updatePricingSectionStatus}
                        className={!amexOptubleSupportFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      />
                    </Table.Cell>
                    <Table.Cell>Amex OptBlue Network Fee<span className="required-text"> *</span></Table.Cell>
                    <Table.Cell>
                      <span className='input-dollar'>$</span>
                      <input
                        type='text'
                        name='amexOptubleNetworkFee'
                        value={amexOptubleNetworkFee}
                        disabled={role === ROLES.SALES_LIMITED}
                        autoComplete="new-password"
                        onChange={handleChangeTextInput}
                        onBlur={updatePricingSectionStatus}
                        className={!amexOptubleNetworkFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
                { riskLevel === RISK_LEVELS.HIGH_RISK &&clym_fee_accept_banks.indexOf(selectedBankOption)>-1 && 
                  <Table.Row>
                    <Table.Cell>Clym Data Privacy Compliance<span className='info1-icon' data-tooltip={FIELDS_INFO['Business Details'].clymDataPrivacyCompliance.helperText}></span><span className="required-text"> *</span></Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        label='Opt-In'
                        name=''
                        value=''
                        disabled={role === ROLES.SALES_LIMITED}
                        checked={clymDataPrivacyCompliance === 'Yes' ? true : false}
                        onChange={(event, data: CheckboxProps) => {
                          setClymDataPrivacyCompliance(data.checked ? 'Yes' : 'No')
                        }}
                      />
                    </Table.Cell>
                      <Table.Cell>
                        <span className='input-dollar'>$</span>
                        <input
                          type='text'
                          name='clymDataPrivacyComplianceFee'
                          value={clymDataPrivacyComplianceFee}
                          autoComplete="new-password"
                          onChange={handleChangeTextInput}
                          disabled={true}
                          className={!clymDataPrivacyComplianceFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                          onBlur={updatePricingSectionStatus}
                        />
                      </Table.Cell>
                  </Table.Row>
                }
              </Table.Body>
            </Table>
          </div>
        )}
        {selectedSellTime !== 'Cash Discount' && ((role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) && riskLevel === "High Risk") && (
          <div className="grey-outline">
            <Table celled structured className="other-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>Other Fees</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Voice Auth<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='voiceAuth'
                      className={!voiceAuth ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={voiceAuth}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                  <Table.Cell>Excessive Electronic Auth<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='excessiveElectronicAuth'
                      className={!excessiveElectronicAuth ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={excessiveElectronicAuth}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>IRS Reporting Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='irsReportingFee'
                      className={!irsReportingFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={irsReportingFee}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                  <Table.Cell>Retrieval Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='retreivalFee'
                      className={!irsReportingFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={retreivalFee}
                      autoComplete="new-password"
                      disabled={role === ROLES.SALES_LIMITED}
                      onChange={handleChangeTextInput}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Invalid Tin Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='invalidTinFee'
                      value={invalidTinFee}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      className={!invalidTinFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                  <Table.Cell>NSF/ACH Reject Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='achRejectFee'
                      value={achRejectFee}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED || selectedBankOption === BANK_CODES.DART}
                      className={!achRejectFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Collection Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='collectionsFee'
                      className={!collectionsFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      value={collectionsFee}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                  <Table.Cell>{selectedBankOption === 'CFSB' ? 'PCI Validation(monthly)' : `PCI Validation(annual)`}<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='pciValidation'
                      value={pciValidation}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      className={!pciValidation ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Return Transaction Fee<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='returnTransactionFee'
                      value={returnTransactionFee}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      className={!returnTransactionFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                  <Table.Cell>PCI Non-Compliance(monthly)<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='pciNonCompliance'
                      value={pciNonCompliance}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      className={!pciNonCompliance ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  {pinDebit === "Yes" && (
                    <Table.Cell>Debit Access Fee<span className="required-text"> *</span></Table.Cell>
                  )}
                  {pinDebit === "Yes" && (
                    <Table.Cell>
                      <span className='input-dollar'>$</span>
                      <input
                        type='text'
                        name='debitAccessFee'
                        value={debitAccessFee}
                        autoComplete="new-password"
                        onChange={handleChangeTextInput}
                        disabled={role === ROLES.SALES_LIMITED}
                        className={!debitAccessFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                        onBlur={updatePricingSectionStatus}
                      />
                    </Table.Cell>
                  )}
                  <Table.Cell>Industry Compliance<span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <span className='input-dollar'>$</span>
                    <input
                      type='text'
                      name='industryCompliance'
                      value={industryCompliance}
                      autoComplete="new-password"
                      onChange={handleChangeTextInput}
                      disabled={role === ROLES.SALES_LIMITED}
                      className={!industryCompliance ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                      onBlur={updatePricingSectionStatus}
                    />
                  </Table.Cell>
                </Table.Row>
                {pricingCardValue.includes('AMEX') && (
                  <Table.Row>
                    <Table.Cell>Amex OptBlue Support Fee<span className="required-text"> *</span> </Table.Cell>
                    <Table.Cell>
                      <span className='input-dollar'>$</span>
                      <input
                        type='text'
                        name='amexOptubleSupportFee'
                        value={amexOptubleSupportFee}
                        autoComplete="new-password"
                        onChange={handleChangeTextInput}
                        disabled={role === ROLES.SALES_LIMITED}
                        className={!amexOptubleSupportFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                        onBlur={updatePricingSectionStatus}
                      />
                    </Table.Cell>
                    <Table.Cell>Amex OptBlue Network Fee<span className="required-text"> *</span></Table.Cell>
                    <Table.Cell>
                      <span className='input-dollar'>$</span>
                      <input
                        type='text'
                        name='amexOptubleNetworkFee'
                        value={amexOptubleNetworkFee}
                        autoComplete="new-password"
                        onChange={handleChangeTextInput}
                        disabled={role === ROLES.SALES_LIMITED}
                        className={!amexOptubleNetworkFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                        onBlur={updatePricingSectionStatus}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
                 { riskLevel === RISK_LEVELS.HIGH_RISK &&clym_fee_accept_banks.indexOf(selectedBankOption)>-1 &&<Table.Row>
                  <Table.Cell>Clym Data Privacy Compliance<span className='info1-icon' data-tooltip={FIELDS_INFO['Business Details'].clymDataPrivacyCompliance.helperText}></span>
                  <span className="required-text"> *</span></Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      label='Opt-In'
                      name=''
                      value=''
                      disabled={role === ROLES.SALES_LIMITED}
                      checked={clymDataPrivacyCompliance === 'Yes' ? true : false}
                      onChange={(event, data: CheckboxProps) => {
                      setClymDataPrivacyCompliance(data.checked ? 'Yes' : 'No')
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                      <span className='input-dollar'>$</span>
                      <input
                        type='text'
                        name='clymDataPrivacyComplianceFee'
                        value={clymDataPrivacyComplianceFee}
                        autoComplete="new-password"
                        onChange={handleChangeTextInput}
                        disabled={true}
                        className={!clymDataPrivacyComplianceFee ? 'not-filled padding-left-30' : 'form-input padding-left-30'}
                        onBlur={updatePricingSectionStatus}
                      />
                    </Table.Cell>
                </Table.Row>}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(PricingSection)
