import React, { useState, useEffect, useContext, useCallback } from 'react'
import {
  Dropdown,
  Button,
  Radio,
  CheckboxProps,
  Icon,
  Modal,
  Confirm,
  TextArea,
} from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Footer } from '../PageLayout/Footer'
import api from '../../Service/Api'
import backend from '../../Service/Backend'
import { useHistory, useLocation } from 'react-router-dom'
import { auth } from '../../Firebase/firebase'
import { signInWithCustomToken, signOut } from 'firebase/auth'
import ProgressLoader from '../Common/ProgressLoader'
import axios from 'axios'
import { firstBy } from 'thenby'
// import { storage } from '../../Firebase/firebase'
import { decrypt, encrypt } from '../../Utils/encryption'
import { Layout } from '../PageLayout/Layout'
import * as Sentry from "@sentry/react";
import { BANK_CODES, FIELDS_INFO, MERCHANT_APP_STATUS, RISK_LEVELS, ROLES, EQUIPMENT_TYPE, CLYM_FEE_ACCEPT_BANKS, connection_type_physical, auto_close_default, option_yesno, Pricing_Type, Card_Type, billedOptions, equipment_terminal, equipment_deployment, equipment_billTo, equipment_deployment_default, equipment_application, ticketSizeOptions, discountPercentageOptions, serviceFeeOptions, equipment_quantity, mailing_address, connection_type, cdBanks, monthAvgSalesHighRisk, riskLevelValue, otherFees, otherFees_cfsb, monthOptions } from "../../Utils/constants";
import { notification } from 'antd';
import PreScreeningSection from './AppSectionComponents/PreScreeningSection';
import PricingSection from './AppSectionComponents/PricingSection';
import CorpInformationSection from './AppSectionComponents/CorpInformationSection';
import DbaInformationSection from './AppSectionComponents/DbaInformationSection';
import BankDetailsSection from './AppSectionComponents/BankDetailsSection';
import BusinessDetailsSection from './AppSectionComponents/BusinessDetailsSection';
import TransactionInformationSection from './AppSectionComponents/TransactionInformationSection';
import EquipmentInformationsection from './AppSectionComponents/EquipmentInformationsection';
import SecureFieldsModal from './AppSectionComponents/SecureFieldsModal';
import RenderBlocks from './StartAppUtils';
import useCheckMobileScreen, { useCheckTabScreen } from '../../hooks/useCheckMobileScreen';
import ButtonScrollTop from '../Common/ButtonScrollTop';
import { ref, getDownloadURL, getStorage } from 'firebase/storage';
import { AppContext } from '../../context/appContext';
import { getDecodedToken, getParsedList } from '../../Utils/helpers';
import BasicButton from '../Common/Button';
import { Progress } from 'antd';

let autoSaveIntervalRef: any = null

const format = 'HH:mm';

const email_pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

interface OwnerSectionDataType {
  firstName: string,
  lastName: string,
  ownerTitle: string,
  ownerAddress: string,
  city: string,
  state: string,
  zip: string,
  yearsThisAddress: string,
  mobileNo: string,
  dl_Id: string,
  documentType: string,
  issueState: string,
  issueDate: string,
  expireDate: string,
  ssn: string,
  dob: string,
  ownerEmail: string,
  ownership: string,
  individualsMoreOwnership: string,
  priorBankrupties: string,
  dateDischarge: string,
  control_prong: string,
  ssnInvalid: string,
  residenceYear: string,
  residenceMonth: string,
}

const sellarea = [
  { text: 'Locally', value: 'Locally' },
  { text: 'Nationally', value: 'Nationally' }
]
const document_type = [
  { text: 'Driving License', value: 'Driving License' },
  { text: 'Passport', value: 'Passport' }
]

const legalentity = [
  { text: 'Solo prop', value: 'Solo prop' },
  { text: 'Partnership', value: 'Partnership' },
  { text: 'LLC/LLP', value: 'LLC/LLP' },
  { text: 'C Corp', value: 'C Corp' },
  { text: 'S Corp', value: 'S Corp' },
  { text: 'Non-Profit Corp', value: 'Non-Profit Corp' },
]
const retrive_info = [
  { text: 'Corp Address', value: 'Legal Address' },
  { text: 'DBA Address', value: 'DBA Address' },
  { text: 'FAX', value: 'FAX' }
]

const charge_customer = [
  { text: 'At Order', value: 'At Order' },
  { text: 'Upon Shipment', value: 'Upon Shipment' }
]
const billingmethod = [
  { id: '1', text: 'One time', value: 'One time', ischecked: false },
  { id: '2', text: 'Monthly', value: 'Monthly', ischecked: false },
  { id: '3', text: 'Quarterly', value: 'Quarterly', ischecked: false },
  { id: '4', text: 'Yearly', value: 'Yearly', ischecked: false },
]
const seasonal_month = [
  { id: '1', text: 'Jan', value: 'Jan', ischecked: false },
  { id: '2', text: 'Feb', value: 'Feb', ischecked: false },
  { id: '3', text: 'Mar', value: 'Mar', ischecked: false },
  { id: '4', text: 'Apr', value: 'Apr', ischecked: false },
  { id: '5', text: 'May', value: 'May', ischecked: false },
  { id: '6', text: 'Jun', value: 'Jun', ischecked: false },
  { id: '7', text: 'Jul', value: 'Jul', ischecked: false },
  { id: '8', text: 'Aug', value: 'Aug', ischecked: false },
  { id: '9', text: 'Sep', value: 'Sep', ischecked: false },
  { id: '10', text: 'Oct', value: 'Oct', ischecked: false },
  { id: '11', text: 'Nov', value: 'Nov', ischecked: false },
  { id: '12', text: 'Dec', value: 'Dec', ischecked: false }
]
const business_location_options = [
  { text: 'Store Front', value: 'Store Front' },
  { text: 'Office', value: 'Office' },
  { text: 'Home', value: 'Home' },
  { text: 'Other', value: 'Other' }
]

const productStoredAtPhysicalAdd_yesno = [
  { text: 'No', value: 'No' },
  { text: 'Yes', value: 'Yes' },
  { text: 'Other/Online', value: 'Other/Online' }
]
const control_prong = [
  { text: 'Yes', value: 'Yes' },
  { text: 'No', value: 'No' }
]
const tax_type = [
  { text: 'EIN', value: 'EIN' },
  { text: 'SSN', value: 'SSN' }
]

const sectionsPrevNextData = [
  {
    name: "prescreening",
    prev: "prescreening",
    next: "pricing"
  },
  {
    name: "pricing",
    prev: "prescreening",
    next: "legal"
  },
  {
    name: "legal",
    prev: "pricing",
    next: "tdba"
  },
  {
    name: "tdba",
    prev: "legal",
    next: "owner"
  },
  {
    name: "owner",
    prev: "tdba",
    next: "business"
  },
  {
    name: "business",
    prev: "owner",
    next: "transaction"
  },
  {
    name: "transaction",
    prev: "business",
    next: "equipment"
  },
  {
    name: "equipment",
    prev: "transaction",
    next: "misc"
  },
  {
    name: "misc",
    prev: "equipment",
    next: "message"
  },
  {
    name: "message",
    prev: "equipment",
    next: "message"
  }
]

const { REACT_APP_BACKEND_MYSQL } = process.env;

const StartApp = () => {
  const history = useHistory()
  const { config, setConfig }: any = useContext(AppContext);
  
  const tenantId = config.client_auth_tenent_id || ""

  const [signButtonDisableStatus, setSignButtonDisableStatus] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage1, setSuccessMessage1] = useState('')
  const [errorMessage1, setErrorMessage1] = useState('')
  const [errorMessage2, setErrorMessage2] = useState('')
  const [loading, setLoading] = useState(false)
  const [overlay, setOverlay] = useState({ style: { display: 'none' } })
  const [overlayMsg, setOverlaymsg] = useState('')
  const [saveFlag, setSaveFlag] = useState(false)
  const [saveMerchantAppId, setSaveMerchantAppId] = useState('')
  const [saveMerchantId, setSaveMerchantId] = useState('')
  const [saveMerchantAppStatus, setSaveMerchantAppStatus] = useState('')
  const [saveUpdatedByUserId, setSaveUpdatedByUserId] = useState('')
  const [showSensitiveFields, setShowSensitiveFields] = useState(true)
  const [merchantEditFlag, setMerchantEditFlag] = useState(false)
  const [sendToMerchantFlag, setSendToMerchantFlag] = useState(false)

  const [autoSaveTimer, setAutoSaveTimer] = useState(0); 
  const [autoSave, setAutoSave] = useState(false)
  const [resumeTimer, setresumeTimer] = useState(false)

  // merchantInfo
  const [business_name, setBusinessName] = useState('')
  const [business_email, setBusinessEmail] = useState('')
  const [business_contact, setBusinessContact] = useState('')
  const [merchantAppStartDate, setMerchantAppStartDate] = useState('')
  const [activesection, setActiveSection] = useState('prescreening')

  const [sellTypeValue, setSellTypeValue] = useState('')
  const [sellGoodValue, setSellGoodValue] = useState('')
  const [equipmentTypeValue, setEquipmentTypeValue] = useState('')
  const [equipmentOrderValue, setEquipmentOrderValue] = useState('')
  const [equipmentQuantityValue, setEquipmentQuantityValue] = useState('')
  const [controlProng, setControlProng] = useState(control_prong)
  const [billingMethod, setBillingMethod] = useState(billingmethod)
  const [billValue, setBillValue] = useState([] as any)

  const [cardValue, setCardValue] = useState('')

  const [mode, setMode] = useState('create')
  const [invalidAppLink, setInvalidAppLink] = useState(false)
  const [invalidAppLinkMsg, setInvalidAppLinkMsg] = useState('This link has expired.')

  // prescreening section
  const [businessConduct, setBusinessConduct] = useState([] as any)
  const [isRetail, setIsRetail] = useState(false)
  const [isMailOrder, setIsMailOrder] = useState(false)
  const [isEcommerce, setIsEcommerce] = useState(false)
  const [businessCategory, setBusinessCategory] = useState('')
  const [businessCategoryWarningText, setbusinessCategoryWarningText] = useState('')
  const [businessTypeConditionalOptions, setBusinessTypeConditionalOption] = useState(
    [{ text: '', value: '' }]
  )
  const [businessCategoryConditionalOptions, setBusinessCategoryConditionalOptions] = useState(
    [{ text: '', value: '' }]
  )
  const [businessTypeOptions, setBusinessTypeOptions] = useState([] as any)
  const [businessSectionBusinessTypeConditionalOptions, setbusinessSectionBusinessTypeConditionalOptions] = useState([{
    text: '',
    value: ''
  }])
  const [businessCategoryFreeFormText, setBusinessCategoryFreeFormText] = useState('')
  const [businessCategoryFreeFormErrorMessage, setBusinessCategoryFreeFormErrorMessage] = useState('')
  const [businessTypeFreeFormText, setBusinessTypeFreeFormText] = useState('')
  const [businessTypeFreeFormErrorMessage, setBusinessTypeFreeFormErrorMessage] = useState('')
  const [conditionalSellType, setConditionalSellType] = useState('')

  const [conditionalSellGood, setConditionalSellGood] = useState('')
  const [selectedSellTime, setSelectedSellTime] = useState('')
  const [businessType, setBusinessType] = useState('')
  const [riskLevel, setRiskLevel] = useState('')
  const [selectedBankOption, setSelectedBankOption] = useState('')
  const [applicationType, setApplicationType] = useState('')
  const [sellService, setSellService] = useState('No')
  const [sellProduct, setSellProduct] = useState('No')
  const [showCashDiscount, setShowCashDiscount] = useState(false)
  const [disableSalesMethod, setDisableSalesMethod] = useState(false)
  

  // Pricing_Section
  const [pricingType, setPricingType] = useState('')
  const [pricingCardType, setPricingCardType] = useState(Card_Type)
  const [pricingCardValue, setPricingCardValue] = useState([] as any)
  const [monthlyFee, setMonthlyFee] = useState('')
  const [annualFee, setAnnualFee] = useState('')
  const [tieredQualDiscount, setTieredQualDiscount] = useState('')
  const [tieredQualPerItem, setTieredQualPerItem] = useState('')
  const [tieredMidDiscount, setTieredMidDiscount] = useState('')
  const [tieredMidPerItem, setTieredMidPerItem] = useState('')
  const [tieredNonDiscount, setTieredNonDiscount] = useState('')
  const [tieredNonPerItem, setTieredNonPerItem] = useState('')
  const [tieredDebitDiscount, setTieredDebitDiscount] = useState('')
  const [tieredDebitPerItem, setTieredDebitPerItem] = useState('')
  const [errQualDiscount, setErrQualDiscount] = useState('')
  const [errQualPerItem, setErrQualPerItem] = useState('')
  const [errNonDiscount, setErrNonDiscount] = useState('')
  const [errNonPerItem, setErrNonPerItem] = useState('')
  const [intAmexDiscountRate, setIntAmexDiscountRate] = useState('')
  const [intAmexperItem, setIntAmexperItem] = useState('')
  const [amexQual, setAmexQual] = useState('')
  const [amexPerItem, setAmexPerItem] = useState('')
  const [amexMidQual, setAmexMidQual] = useState('')
  const [amexMidPerItem, setAmexMidPerItem] = useState('')
  const [amexNonQual, setAmexNonQual] = useState('')
  const [amexNonPerItem, setAmexNonPerItem] = useState('')
  const [discountRate, setDiscountRate] = useState('')
  const [perItem, setPerItem] = useState('')
  const [debitDiscountRate, setDebitDiscountRate] = useState('')
  const [debitNetworkFees, setDebitNetworkFees] = useState('Yes')
  const [pinDebit, setPinDebit] = useState('')
  const [pinDebitAuth, setPinDebitAuth] = useState('')
  const [acceptEbt, setAcceptEbt] = useState('')
  const [ebt, setEbt] = useState('')
  const [ebtAuth, setEbtAuth] = useState('')
  const [wirelessFee, setWirelessFee] = useState('')
  const [offerAmex, setOfferAmex] = useState('')
  const [mc, setMc] = useState('')
  const [visa, setVisa] = useState('')
  const [discAuth, setDiscAuth] = useState('')
  const [amexAuth, setAmexAuth] = useState('')
  const [avs, setAvs] = useState('')
  const [batchFee, setBatchFee] = useState('')
  const [chargeBackFee, setChargeBackFee] = useState('')
  const [monthlyMinimum, setMonthlyMinimum] = useState('')
  const [earlyTerminationFee, setEarlyTerminationFee] = useState('')
  const [manualTerminationFee, setManualTerminationFee] = useState('')
  const [disableEarlyTermination, setDisableEarlyTermination] = useState(false)
  const [accountReserve, setAccountReserve] = useState('')
  const [other, setOther] = useState('')
  const [billed, setBilled] = useState('')
  const [ticketSize, setTicketSize] = useState('') 
  const [discountPercentage, setDiscountPercentage] = useState('') 
  const [serviceFee, setServiceFee] = useState('') 
  const [licenseTechFees, setLicenseTechFees] = useState('25')
  const [voiceAuth, setVoiceAuth] = useState('1.96')
  const [excessiveElectronicAuth, setExcessiveElectronicAuth] = useState('0.10')
  const [irsReportingFee, setIrsReportingFee] = useState('4.95')
  const [retreivalFee, setRetreivalFee] = useState('20')
  const [invalidTinFee, setInvalidTinFee] = useState('19.95')
  const [achRejectFee, setAchRejectFee] = useState(selectedBankOption === BANK_CODES.DART ? '25' : '30')
  const [collectionsFee, setCollectionsFee] = useState('20')
  const [pciValidation, setPciValidation] = useState('119.99')
  const [pciNonCompliance, setPciNonCompliance] = useState('')
  const [industryCompliance, setIndustryCompliance] = useState('9.95')
  const [returnTransactionFee, setReturnTransactionFee] = useState('0.10')
  const [amexOptubleSupportFee, setAmexOptubleSupportFee] = useState('0.50')
  const [amexOptubleNetworkFee, setAmexOptubleNetworkFee] = useState('0.15')
  const [debitAccessFee, setDebitAccessFee] = useState('3.95')
  const [clymDataPrivacyCompliance, setClymDataPrivacyCompliance] = useState('Yes')
  const [clymDataPrivacyComplianceFee, setClymDataPrivacyComplianceFee] = useState('20')

  // legal_section
  const [legalName, setLegalName] = useState('')
  const [legalAddress, setLegalAddress] = useState('')
  const [legalAddressCity, setLegalAddressCity] = useState('')
  const [selectedLegalAddressState, setSelectedLegalAddressState] = useState('')
  const [legalAddressPostalCode, setLegalAddressPostalCode] = useState('')
  const [legalBusinessContact, setLegalBusinessContact] = useState('')
  const [legalBusinessContactTitle, setLegalBusinessContactTitle] = useState('')
  const [legalBusinessPhone, setLegalBusinessPhone] = useState('')
  const [legalBusinessFax, setLegalBusinessFax] = useState('')
  const [fedTaxID, setFedTaxID] = useState('')
  const [fedTaxIDDummy, setFedTaxIDDummy] = useState('')
  const [fedTaxIdValid, setFedTaxIdValid] = useState(false)
  const [selectedTaxType, setSelectedTaxType] = useState('')
  const [taxFilingName, setTaxFilingName] = useState('')
  const [businessStartDate, setBusinessStartDate] = useState(null)
  const [lengthOfOwnership, setLengthOfOwnership] = useState('')
  const [lengthOfOwnershipYears, setLengthOfOwnershipYears] = useState('');
  const [selectedLegalEntityType, setSelectedLegalEntityType] = useState('')
  const [selectedStateIncorporated, setSelectedStateIncorporated] = useState('')
  const [isPriorSecurityBreach, setIsPriorSecurityBreach] = useState('No')
  const [productPrices, setProductPrices] = useState([])
  const [productOptions, setProductOptions] = useState([{
    text: '',
    value: ''
  }])
  const condProductOptions = [
    {
      text: 'NMI',
      value: 'NMI'
    },
    {
      text: 'Other',
      value: 'Other'
    }
  ]
  const condApplicationOptions = [
    {
      text: 'Full Gateway',
      value: 'Full Gateway'
    },
    {
      text: 'Mobile Only',
      value: 'Mobile Only'
    }
  ]
  const [states, setStates] = useState([{
    text: '',
    value: ''
  }])
  const [fullFillmentCompanyOptions, setFullFillmentCompanyOptions] = useState([{
    text: '',
    value: ''
  }])
  const [chargebackCompanyOptions, setChargebackCompanyOptions] = useState([{
    text: '',
    value: ''
  }])
  const [callCenterOptions, setCallCenterOptions] = useState([{
    text: '',
    value: ''
  }])
  const [affiliateMarketingOptions, setAffiliateMarketingOptions] = useState([{
    text: '',
    value: ''
  }])
  const [crmCompanyOptions, setCrmCompanyOptions] = useState([{
    text: '',
    value: ''
  }])

  // dba_section
  const [dbaName, setDBAName] = useState('')
  const [dbaAddress, setDBAAddress] = useState('')
  const [dbaCity, setDBACity] = useState('')
  const [selectedDBAState, setSelectedDBAState] = useState('')
  const [dbaZipCode, setDBAZipCode] = useState('')
  const [dbaBusinessEmail, setDBABusinessEmail] = useState('')
  const [isDbaBusinessEmailValid, setDbaBusinessEmailValid] = useState(true)
  const [dbaCustomerServicePhone, setDBACustomerServicePhone] = useState('')
  const [dbaWebsiteURL, setDBAWebsiteURL] = useState('')
  const [selectedDBAMultipleLocationOption, setSelectedDBAMultipleLocationOption] = useState('No')
  const [useLegalAddress, setUseLegalAddress] = useState(false)

  // bank_section
  const [bankRoutingNo, setBankRoutingNo] = useState('')
  const [bankAccountNo, setBankAccountNo] = useState('')
  const [confirmBankAccountNo, setConfirmBankAccountNo] = useState('')
  const [bankAccountNoDummy, setBankAccountNoDummy] = useState('')
  const [confirmBankAccountNoDummy, setConfirmBankAccountNoDummy] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankPhone, setBankPhone] = useState('')
  // const [selectedBankACHMethod, setSelectedBankACHMethod] = useState('Combined')
  const [bankRoutingNumberErrMessage, setBankRoutingNumberErrMessage] = useState('')

  // Owner section
  const [ownerSectionData, setOwnerSectionData] = useState([
    {
      firstName: '',
      lastName: '',
      ownerTitle: '',
      ownerAddress: '',
      city: '',
      state: '',
      zip: '',
      yearsThisAddress: '',
      mobileNo: '',
      dl_Id: '',
      documentType: '',
      issueState: '',
      issueDate: '',
      expireDate: '',
      ssn: '',
      dob: '',
      ownerEmail: '',
      ownership: '',
      individualsMoreOwnership: '',
      priorBankrupties: '',
      dateDischarge: '',
      control_prong: 'Yes',
      ssnInvalid:'',
      residenceYear:'',
      residenceMonth:'',
    }
  ] as OwnerSectionDataType[])
  const [ownerSectionSecureDataDummy, setOwnerSectionSecureDataDummy] = useState([
    {
      dl_Id: '',
      ssn: '',
      ssnInvalid:''
    }
  ])

  const [ownerSectionErrorFileds, setOwnerSectionErrorFileds] = useState([{
    dob_valid: '',
    dl_issue_date_valid: '',
    dl_expire_date_valid: '',
    date_discharge_valid: '',
    address_valid: '',
    emailInvalid:'',
    emailAlreadyExist: '',
    invalidOwnership:'',
    ownership_valid: '',
  }])
  const [numberOfOwners, setNumberOfOwners] = useState(1)
  const [ownerEmailErrorMessage, setOwnerEmailErrorMessage] = useState('')

  // business_details_section
  const [selectedGoodsSold, setSelectedGoodsSold] = useState('')
  const [isSeasonalMerchant, setIsSeasonalMerchant] = useState('No')
  const [isRetailLocation, setIsRetailLocation] = useState('No')
  const [isExported, setIsExported] = useState('No')
  const [businessAddress, setBusinessAddress] = useState('')
  const [
    selectedSendChargeback_AddressType,
    setSelectedSendChargeback_AddressType
  ] = useState('')
  //const [fax, setFax] = useState('')
  const [isOwnInventory, setIsOwnInventory] = useState('')
  const [vendorNames, setVendorNames] = useState('')
  const [
    isInventoryStoredatRetailLocation,
    setIsInventoryStoredatRetailLocation
  ] = useState('')
  const [inventoryLocation, setInventoryLocation] = useState('')
  const [foreignSalesPct, setForeignSalesPct] = useState('')
  const [selectedSellNationalLocal, setSelectedSellNationalLocal] = useState('')
  const [internetSalesPct, setInternetSalesPct] = useState('')
  const [salesSumWarningText, setSalesSumWarningText] = useState('')
  const [salesSumWarningTextFlag, setSalesSumWarningTextFlag] = useState(false)
  const [billingSumWarningText, setBillingSumWarningText] = useState('')
  const [billingSumWarningTextFlag, setBillingSumWarningTextFlag] = useState(false)
  const [encryptionMethod, setEncryptionMethod] = useState('')
  const [telephoneSalesPct, setTelephoneSalesPct] = useState('')
  const [retailSalesPct, setRetailSalesPct] = useState('')
  // const [mailOrderSalesPct, setMailOrderSalesPct] = useState('')
  const [isPrevProcessorTerminated, setIsPrevProcessorTerminated] = useState('No')
  const [fulfillmentCompanyName, setFulfillmentCompanyName] = useState('')
  const [crmCompanyName, setCrmCompanyName] = useState('')
  const [chargeBackCompanyName, setChargeBackCompanyName] = useState('')
  const [
    affliateMarketingPartnerName,
    setAffliateMarketingPartnerName
  ] = useState('')
  const [callCenterName, setCallCenterName] = useState('')
  const [rdrPreferredVendor, setRdrPreferredVendor] = useState('')
  const [disableAddress, setDisableAddress] = useState(false)
  const [fulfillmentCompanyNameFreeFormText, setFulfillmentCompanyNameFreeFormText] = useState('')
  const [crmCompanyNameFreeFormText, setCrmCompanyNameFreeFormText] = useState('')
  const [chargeBackCompanyNameFreeFormText, setChargeBackCompanyNameFreeFormText] = useState('')
  const [affliateMarketingPartnerNameFreeFormText, setAffliateMarketingPartnerNameFreeFormText] = useState('')
  const [callCenterNameFreeFormText, setCallCenterNameFreeFormText] = useState('')
  const [advertisingMethods, setAdvertisingMethods] = useState('')
  const [salesProcessDesc, setSalesProcessDesc] = useState('')
  const [
    selectedChargeCustomerMethod,
    setSelectedChargeCustomerMethod
  ] = useState('')
  const [daysToDelivery, setDaysToDelivery] = useState('')
  const [returnPolicyDesc, setReturnPolicyDesc] = useState('')
  const [refundPolicyDesc, setRefundPolicyDesc] = useState('')

  const [numberOfLocations, setNumberOfLocations] = useState('')
  const [businessLocation, setBusinessLocation] = useState('')
  const [otherBusinessLocation, setOtherBusinessLocation] = useState('')
  const [seasonalMonth, setSeasonalMonth] = useState(seasonal_month)
  const [seasonalMonthValue, setSeasonalMonthValue] = useState([] as any)
  const [condBusiDispValues, setCondBusiDispValues] = useState({
    is_exported_visible: true,
    is_own_inventory: true,
    vendorNames: true,
    is_inventory_stored_retail: true,
    selected_sell_national_local: true,
    internetSalesPct: true,
    telephoneSalesPct: true,
    // mailOrderSalesPct: true,
    retailSalesPct: true,
    advertisingMethods: true,
    charge_customer: true,
    fulfillmentCompanyName: true,
    crmCompanyName: true,
    chargeBackCompanyName: true,
    affliateMarketingPartnerName: true,
    callCenterName: true,
  })

  // transaction_section
  const [monthAvgSales, setMonthAvgSales] = useState('')
  const [amexMonthAvgSales, setAmexMonthAvgSales] = useState('')
  const [highestTicketAmount, setHighestTicketAmount] = useState('')
  const [avgTicketAmount, setAvgTicketAmount] = useState('')
  const [selectedAMEXType, setSelectedAMEXType] = useState('')
  const [existingAMEXno, setExistingAMEXno] = useState('')
  const [publicSalesPCT, setPublicSalesPCT] = useState('')
  const [businessTransPCT, setBusinessTransPCT] = useState('')
  const [salesTotalWarningText, setSalesTotalWarningText] = useState('')
  const [avgSalesWarning, setAvgSalesWarning] = useState('')
  const [avgTicketAmountWarning, setAvgTicketAmountWarning] = useState('')
  const [highestTicketAmountWarning, setHighestTicketAmountWarning] = useState('')
  const [amexMonthAvgWarning, setAmexMonthAvgWarning] = useState('')

  // equipment_section
  const [existingEquipmentName, setExistingEquipmentName] = useState('')
  const [selectedNewEquipmentName, setSelectedNewEquipmentName] = useState('')
  const [isTipOptionNeeded, setIsTipOptionNeeded] = useState('')
  const [selectedConnectionType, setSelectedConnectionType] = useState('')
  const [isEBT, setIsEBT] = useState('')
  const [isPINDebitRequired, setIsPINDebitRequired] = useState('')
  const [
    selectedEquipmentMailingAddress,
    setSelectedEquipmentMailingAddress
  ] = useState('')
  const user_id: any = sessionStorage.getItem('user_id');
  const fromAdditionalLocation = sessionStorage.getItem('fromAdditionalLocation')==='true'?true:false;
  const user_guid: any = sessionStorage.getItem('user_guid');
  const role = sessionStorage.getItem('role')
  const office_id = sessionStorage.getItem('office_id')
  const allowed_highrisk_processors = sessionStorage.getItem('allowed_high_risk_processors')?.split(',') || []
  const allowed_lowrisk_processors = sessionStorage.getItem('allowed_low_risk_processors')?.split(',') || []
  const allowed_risk_levels = sessionStorage.getItem('allowed_risk_levels')?.split(',') || []
  const paper_apps_enabled = sessionStorage.getItem('paper_apps_enabled')
  const [optionalMessage, setOptionalMessage] = useState('')
  const [equipment, setEquipment] = useState('')
  const [equipmentTypeConditionalOptions, setEquipmentTypeConditionalOption] = useState(
    [{ text: '', value: '' }]
  )
  const [equipmentTerminal, setEquipmentTerminal] = useState(
    [{ text: '', value: '' }]
  )
  const [mobileEquipmentOptions, setMobileEquipmentOptions] = useState(
    [{ text: '', value: '' }]
  )
  const [rdrPreferredVendorOptions, setRdrPreferredVendorOptions] = useState(
    [{ text: '', value: '' }]
  )
  const [equipmentProductOptions, setEquipmentProductOptions] = useState([] as any)

  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);

  // equipment_section
  const equip_default_obj = {
    connection_type: "",
    auto_close: "22:00",
    ebt: "",
    pin_debit_required: "",
    equipment_mailing_addr_type: "",
    equipment_type: "",
    equipment_order_type: "",
    quantity: "",
    special_instructions: "",
    deployment: "",
    billTo: "",
    cost: "",
    // platform: "",
    application: "",
    product: "",
    pos_name: "",
    pos_version: "",
    pin_name: "",
    receipt_footer_info:"",
    check_reader_text: "",
    virtual_terminal_nmi: "",
    virtual_terminal_auth: "",
    virtual_terminal_other: "",
    tokenization_needed: false,
    purchasing_cards: false,
    avs: false,
    server_clerk: false,
    sn_man: "",
    sim_esn: "",
    wireless: false,
    invoice_number: false,
    cvv2: true,
    fraud_check: false,
    rm_added: false,
    mobile_equipment:"None",
    ach_electronic_check:false,
    customer_vault:false,
    automatic_card_updater:false,
    invoicing:false,
    level_three_processing:false,
    key_injection_needed:false,
    tip_option:false,
    multi_merchant:false,
    pin_debit:false,
    cash_back_option:false
  }

  const [equipmentSectionData, setEquipmentSectionData] = useState([
    equip_default_obj
  ])
  const [numberOfEquipments, setNumberOfEquipments] = useState(0)
  const [equipmentBillTo, setEquipmentBillTo] = useState(equipment_billTo)

  // MISC section
  const [downloadWarningText, setDownloadWarningText] = useState('')
  const [isCBDFormDownloaded, setIsCBDFormDownloaded] = useState(false)
  const [isMcFormDownloaded, setIsMcFormDownloaded] = useState(false)
  const [isEsquireFormDownloaded, setIsEsquireFormDownloaded] = useState(false)

  // partner_data
  const [partnerName, setPartnerName] = useState('')
  const [partnerId, setPartnerId] = useState('')
  const [partners, setPartners] = useState([] as any)
  const [partnerRm, setPartnerRm] = useState([] as any)
  const [partnerRmId, setPartnerRmId] = useState('')
  const [partnerRmName, setPartnerRmName] = useState('')
  const [fetchedPartnerDetails, setFetchedPartnerDetails] = useState({} as any)
  const [ownerActive, setOwnerActive] = useState(0)
  const [hideOwner, setHideOwner] = useState(false)
  const [tempPartnerId, setTempPartnerId] = useState('')

  const [equipmentActive, setEquipmentActive] = useState(0)
  const [hideEquipment, setHideEquipment] = useState(false)

  const [merchantAppID, setMerchantAppID] = useState('')
  const [merchantAppCode, setMerchantAppCode] = useState('')

  const [merchantID, setMerchantID] = useState('')
  const [merchantAppStatus, setMerchantAppStatus] = useState('')
  const [parentAppID, setParentAppID] = useState('')

  const location = useLocation()
  const [processors, setProcessors] = useState([] as any)
  const [signDisabled, setSignDisabled] = useState(true)

  // Address_validations
  const [legalInfoAddressValid, setLegalInfoAddressValid] = useState(true)
  const [legalInfoAddressErrMessage, setLegalInfoAddressErrMessage] = useState('')
  const [dbaInfoAddressValid, setDbaInfoAddressValid] = useState(true)
  const [dbaInfoAddressErrMessage, setDbaInfoAddressErrMessage] = useState('')
  const [ownerInfoAddressValid, setOwnerInfoAddressValid] = useState(true)
  const [ownerInfoAddressErrMessage, setOwnerInfoAddressErrMessage] = useState('')

  // Section_validations
  const [preScreeningSectionStatus, setPreScreeningSectionStatus] = useState('')
  const [pricingSectionStatus, setPricingSectionStatus] = useState('')
  const [legalSectionStatus, setLegalSectionStatus] = useState('')
  const [dbaSectionStatus, setdbaSectionStatus] = useState('')
  const [bankSectionStatus, setBankSectionStatus] = useState('')
  const [ownerSectionStatus, setOwnerSectionStatus] = useState('')
  const [businessSectionStatus, setBusinessSectionStatus] = useState('')
  const [transactionSectionStatus, setTransactionSectionStatus] = useState('')
  const [equipmentSectionStatus, setEquipmentSectionStatus] = useState('')
  const [miscSectionStatus, setMiscSectionStatus] = useState('')

  // settoken
  const [merchantToken, setMerchantToken] = useState('')

  // edit/add status
  const [editStatus, setEditStatus] = useState(false)
  const [editStatusDeleteUser, setEditStatusDeleteUser] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWelcomModal, setShowWelcomModal] = useState(false);
  const [signModalOpen, setSignModalOpen] = useState(false)
  const [sentMailModalOpen, setSentMailModalOpen] = useState(false)
  const [userMessage, setUserMessage] = useState('')

  const [showWarningModal, setShowWarningModal] = useState(false);

  // getLink
  const [getLink, setGetLink] = useState(false)

  //Accout Copy paste alert
  const [alertCopyMsg, setAlertCopyMsg] = useState('');
  //Cancel Alert
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);
  const [cancelContent, setCancelContent] = useState('Are you Sure? You may loose the Data')
  //Toggle DL/ID AND SSN in owner section
  const [eyeIcondl, setEyeIcondl] = useState({} as any)
  const [eyeIconssn, setEyeIconssn] = useState({} as any)
  const [dlIdShown, setDlIdShown] = useState({} as any)
  const [isValidDlIdIssueDate, setIsValidDlIdIssueDate] = useState(true)
  const [ssnShown, setssnShown] = useState({} as any)
  const [eyeIconFedexTaxId, setEyeIconFedexTaxId] = useState(false)
  const [fedexTaxIdShown, setFedexTaxIdShown] = useState(false)

  const [showAppLinkModal, setShowAppLinkModal] = useState(false);
  const [appLink, setAppLink] = useState("");
  const [appLinkID, setAppLinkID] = useState("");

  const [oneTimePct, setOneTimePct] = useState("")
  const [monthlyPct, setMonthlyPct] = useState("")
  const [quarterlyPct, setQuarterlyPct] = useState("")
  const [yearlyPct, setYearlyPct] = useState("")
  const [hourlyPct , setHourlyPct] = useState("");
  const [tokenID, setTokenID] = useState("")
  const [linkExpiredModal, setLinkExpiredModal] = useState(false)

  const [parentOffices, setParentOffices] = useState([] as any)
  const [showRiskLevels, setShowRiskLevels] = useState([] as any)
  const [showProcessors, setshowProcessors] = useState([] as any)
  const [showLowRiskProcessors, setShowLowRiskProcessors] = useState([] as any)
  const [showHighRiskProcessors, setShowHighRiskProcessors] = useState([] as any)

  const [minEquipmentCost, setMinEquipmentCost] = useState(0)
  const [equipmentCostStatus, setEquipmentCostStatus] = useState(true)
  const [equipmentTotalCost, setEquipmentTotalCost] = useState('')

  const [legalTrinitySectionStatus, setLegalTrinitySectionStatus] = useState('')
  const [dbaTrinitySectionStatus, setdbaTrinitySectionStatus] = useState('')
  const [ownerTrinitySectionStatus, setOwnerTrinitySectionStatus] = useState('')
  const [sendToTrinityFlag, setsendToTrinityFlag] = useState(false)
  const [sendToTrinityModalOpen,setSendToTrinityModalOpen] = useState(false)
  const [offlineApp, setOfflineApp] = useState(false)
  const [showSendToTrinity, setShowSendToTrinity] = useState(false)
  const [skipAddressValidation, setSkipAddressValidation] = useState(false)

  const [fetchingOffices, setFetchingOffices] = useState(true)
  const [fetchingProcessors, setFetchingProcessors] = useState(true)
  const [fetchingPartners, setFetchingPartners] = useState(true)
  const [fetchingPartnersRMs, setFetchingPartnersRMS] = useState(true)

  const [sentToSecondSigner, setSentToSecondSigner] = useState(false)
  const [secondSignerName, setSecondSignerName] = useState('')
  const [secondSignerEmail, setSecondSignerEmail] = useState('')
  const [freeEquipmentPlacementForMerchant, setFreeEquipmentPlacementForMerchant] = useState(false)
  const [cdBankOptionsForCD, setCdBankOptionsForCD] = useState([] as string[]);
  const [pciError, setPciError] = useState(false);
  const [pciErrorMessage, setPciErrorMessage] = useState('');
  const [chargeBackError, setChargeBackError] = useState (false);
  const [chargeBackErrorMessage, setChargeBackErrorMessage] = useState ('');

  const additional_location = sessionStorage.getItem("addLocation")

  const partner_id: any = sessionStorage.getItem('partner_id')
  const fetchProcessorsData = async () => {
    try{
      const data = {
        api: !!role ? api.processorData.getProcessors : api.processorData.getProcessorsUnprotected
      }

      const response = !!role ? await backend.fetch(data) : await backend.fetchUnprotected(data);

      response.sort(
        firstBy(function (option: any) {
          return option?.processor_name?.toLowerCase();
        })
      )
      let options=sortProcessors(response)
      setFetchingProcessors(false);
      setProcessors(options)
      const allowedProcessors = sessionStorage.getItem('allowedProcessors')?.split(',') || []
      if (allowedProcessors && allowedProcessors.length > 0) {
        const filteredProcessors = response.filter((item: any) => {
          if (allowedProcessors.indexOf(item.processor_code) > -1) {
            return item
          }
        }).map((item: any) => {
          return {...item, text: item.processor_name, value: item.processor_code }
        })
        let options= sortProcessors(filteredProcessors)     
        setProcessors(options)
      }
    }catch(err){
      console.error('ERROR >> while fetching processors ',err)
    }finally{
      setFetchingProcessors(false);
    }
  }

  const fetchPartners = async () => {
    try{
      let data = {}
      if(role === ROLES.SUPER_ADMIN){
         data = {
          api:api.rm.listPartnersForSA,
          queryParam:''
        }
      }
      else{
       data = {
        api: role === ROLES.RELATIONSHIP_MANAGER ? api.rm.listPartnersForRM : api.users.fetchPartnersByOfficeId,
        queryParam: role === ROLES.RELATIONSHIP_MANAGER ? { rm_id: user_id } : { office_id: office_id }
      }
    }
      if(role){
      const response = await backend.fetch(data)
      setFetchingPartners(false)
      if(role === ROLES.RELATIONSHIP_MANAGER){
        setPartners(
          response.map((item: any) => {
            const partner_name = REACT_APP_BACKEND_MYSQL ? `${item.user_name_first} ${item.user_name_last}` : item.partner_name
            return { ...item, text: partner_name, value: item.partner_id }
          })
        )
      }else{
        setPartners(
          response.map((item: any) => {
            const first_name = REACT_APP_BACKEND_MYSQL ? item.user_name_first : item.user_name.first_name
            const last_name = REACT_APP_BACKEND_MYSQL ? item.user_name_last : item.user_name.last_name
            return { ...item, text: `${first_name} ${last_name}`, value: item.partner_id };
          })
        );
      }
    }
    }catch(err){
      console.error("ERROR >> while fetching partners ",err)
    }finally{
      setFetchingPartners(false)
    } 
  }

  const isValidArray = (arr: any[]) => arr && Array.isArray(arr) && arr.length
  
  useEffect(()=>{
    if(selectedBankOption){
        const list: any = [...equipmentSectionData]
        const updatedList:any = []
        list.forEach((element:any) => {
            if(element.equipment_type && element.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL){
                element['auto_close']=selectedBankOption && auto_close_default[selectedBankOption] ? auto_close_default[selectedBankOption] : equip_default_obj['auto_close']
            }
            updatedList.push(element)
        });
        setEquipmentSectionData([...updatedList])
    }
},[selectedBankOption])

useEffect(() => {
  if(riskLevel === RISK_LEVELS.HIGH_RISK){
    const list: any = [...equipmentSectionData]
    const updatedList:any = []
    list.forEach((element:any) => {
        if(element.equipment_type && element.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL){
            element['deployment']= ""
        }
        updatedList.push(element)
    });
    setEquipmentSectionData([...updatedList])
}
}, [riskLevel])

  useEffect(()=>{
    if(isValidArray(partnerRm) && partnerRm.length > 1 &&  isEditFlow && !!partnerRmId && 
    !partnerRm.find((rm :any) => rm.user_id === partnerRmId)){
      (async()=>{
        const data: any = { api: api.users.getByDefaultRm };
        const profiledata = await backend.fetch(data);
        
        if((profiledata[0].user_id !== partnerRm[0].value)) {
          setPartnerRmId("")
          setSendToMerchantFlag(false)
        }
      })()
    }
    if(!ROLES.SUPER_ADMIN &&!partnerRm.find((rm :any) => rm.user_id === partnerRmId) && isEditFlow)
    setPartnerRmId("")
  },[partnerRm])

  const fetchPartnerRms = async (id: string, onlyFetch = false) => {
    if(role !== ROLES.SUPER_ADMIN){setPartnerRmId("") }
    try {
      const data = {
        api: api.partnerUsrRoles.listRmsByPartnerId,
        queryParam: { partner_id: id },
      }
      const response = await backend.fetch(data)
      setFetchingPartnersRMS(false)
      const backupResponse = !!response ? response.map((item: any) => {
        const first_name = REACT_APP_BACKEND_MYSQL ? item.user_name_first : item.user_name.first_name
        const last_name = REACT_APP_BACKEND_MYSQL ? item.user_name_last : item.user_name.last_name
        return { ...item, text: first_name + " " + last_name, value: item.user_id };
      }) : [];
      setPartnerRm(backupResponse);
      if (role === ROLES.RELATIONSHIP_MANAGER && !isEditFlow && !!response && response.length > 1) {
        const data = { api: api.users.getByGUID, urlParam: user_guid };
        const profiledata = await backend.fetch(data);
        setPartnerRmId(profiledata.user_id)
        setPartnerRm(backupResponse);
      }
      if (!onlyFetch) {
        if (!!response && response.length === 1) {
          backupResponse[0].options = [backupResponse[0]]
          handleChangePartnerRms({}, backupResponse[0])
        }
      }
    } catch (err) {
      console.error("ERROR >> while fetching RMs ", err)
    } finally {
      setFetchingPartnersRMS(false)
    }
  }
  const fetchLinkedSalesUserDetails = async (partner_id:string) => {
    try{
      const data = {
        api: api.users.getByPartnerId,
        urlParam: partner_id||partnerId
      }
      console.log("request>>>>>",data)

      const response = await backend.fetch(data)
      setFetchingPartners(false)
      setFetchedPartnerDetails(response)
      setFreeEquipmentPlacementForMerchant(response?.free_equipment_placement_enabled||false)
    }catch(err){
      console.error("ERROR >> while fetching partner ",err)
    }finally{
      setFetchingPartners(false)
    } 
  }

  useEffect(() => {
    if (!freeEquipmentPlacementForMerchant) {
      const filterEquipmentBillTo = equipment_billTo.filter(item => (item.value !== 'Trinity'));
      setEquipmentBillTo(filterEquipmentBillTo)
    } else {
      setEquipmentBillTo(equipment_billTo)
    }
  }, [freeEquipmentPlacementForMerchant])

  const fetchOffices = async () => {
    if( role === ROLES.UNDER_WRITER){ 
      if(location.pathname.includes('edit/MA')){
        //fetch linked sales user details and office and parent offices of linked sales user
      }else{
        //fetch office and parent offices of logged in user
      }
    }else if( role === ROLES.SALES_LIMITED){ 
      //fetch linked sales user details and office and parent offices of linked sales user
    }
    try{
      const data: any = {
        api: (role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.SUPER_ADMIN) ? api.offices.getAllOffices : api.offices.getParentChildOfficesDetailsByOfficeId,
      }
      if(role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.SUPER_ADMIN) data.queryParam = { child_office_id: office_id }
      if(role){
        const response = await backend.fetch(data)
        setFetchingOffices(false);
        setParentOffices(response)
     }
    }catch(err){
      console.error('ERROR >> while fetching offices ',err)
    }finally{
      setFetchingOffices(false);
    }
  }

  const prepareOptions = (data: any) => {
    let options = []
    if (data && data.length > 0) {
      data.sort(
        firstBy(function (option: any) {
          return option.toLowerCase();
        })
      )

      for (const option of data) {
        options.push({
          text: option,
          value: option
        })
      }
    }
    return options
  }

  const prepareOptionsWithoutSort = (data: any) => {
    let options = []
    if (data && data.length) {

      for (const option of data) {
        options.push({
          text: option,
          value: option
        })
      }
    }
    return options
  }

  const fetchAllLookupData = async () => {
    const data = {
      api: !!role ? api.lookupData.getAllLookupData : api.lookupData.getAllLookupDataUnprotected
    }
    const response = !!role ? await backend.fetch(data) : await backend.fetchUnprotected(data);

    let current_index = -1
    let options = [{ text: '', value: '' }]

    // 'BUSINESS_CATEGORY' 
    current_index = response.findIndex((option: any) => option.LookupDataType === 'BUSINESS_CATEGORY')
    options = current_index > -1 ? prepareOptions(response[current_index]?.BusinessCategoryName) : [{ text: '', value: '' }]
    setBusinessCategoryConditionalOptions(options)

    // 'BUSINESS_TYPE' 
    current_index = response.findIndex((option: any) => option.LookupDataType === 'BUSINESS_TYPE')
    setBusinessTypeOptions(response[current_index]?.BusinessCategory)

    // 'FULFILLMENT' 
    current_index = response.findIndex((option: any) => option.LookupDataType === 'FULFILLMENT')
    options = current_index > -1 ? prepareOptions(response[current_index]?.FulfillmentName) : [{ text: '', value: '' }]
    setFullFillmentCompanyOptions(options)

    // 'CRM' 
    current_index = response.findIndex((option: any) => option.LookupDataType === 'CRM')
    options = current_index > -1 ? prepareOptions(response[current_index]?.CRMCompanyName) : [{ text: '', value: '' }]
    setCrmCompanyOptions(options)

    // 'CHARGEBACK' 
    current_index = response.findIndex((option: any) => option.LookupDataType === 'CHARGEBACK')
    options = current_index > -1 ? prepareOptions(response[current_index]?.ChargebackName) : [{ text: '', value: '' }]
    setChargebackCompanyOptions(options)

    // 'AFFILIATES' 
    current_index = response.findIndex((option: any) => option.LookupDataType === 'AFFILIATES')
    options = current_index > -1 ? prepareOptions(response[current_index]?.AffiliateName) : [{ text: '', value: '' }]
    setAffiliateMarketingOptions(options)

    // 'CALLCENTER' 
    current_index = response.findIndex((option: any) => option.LookupDataType === 'CALLCENTER')
    options = current_index > -1 ? prepareOptions(response[current_index]?.CallcenterName) : [{ text: '', value: '' }]
    setCallCenterOptions(options)

    // 'STATES' 
    current_index = response.findIndex((option: any) => option.LookupDataType === 'STATES')
    options = current_index > -1 ? prepareOptions(response[current_index]?.StateName) : [{ text: '', value: '' }]
    setStates(options)

    // 'PRODUCT_PRICES'
    current_index = response.findIndex((option: any) => option.LookupDataType === 'PRODUCT_PRICES')
    const productPrices = current_index > -1 ? response[current_index]?.ProductPrices : ['']
    setProductPrices(productPrices)
    const productOptions = productPrices.map((product: any) => {
      return product?.split('__')[0] || ''
    })
    options = current_index > -1 ? prepareOptions(productOptions) : [{ text: '', value: '' }]
    setProductOptions(options)

     //'EQUIPMENT_TYPE'
     current_index = response.findIndex((option: any) => option.LookupDataType === 'EQUIPMENT_TYPE')
     options = current_index > -1 ? prepareOptions(response[current_index]?.Type) : [{ text: '', value: '' }]
     setEquipmentTerminal(options)

     // 'EQUIPMENT_PRODUCT_NAME' 
     current_index = response.findIndex((option: any) => option.LookupDataType === 'PRODUCT_NAME')
     setEquipmentProductOptions(response[current_index]?.EquipmentType)

     //MOBILE_EQUIPMENT
     current_index = response.findIndex((option: any) => option.LookupDataType === 'MOBILE_EQUIPMENT')
     options = current_index > -1 ? prepareOptions(response[current_index]?.MobileEquipmentType) : [{ text: '', value: '' }]
     setMobileEquipmentOptions(options)

    //RDR_PREFERRED_VENDOR
    current_index = response.findIndex((option: any) => option.LookupDataType === 'RDR_PREFERRED_VENDOR')
    options = current_index > -1 ? prepareOptionsWithoutSort(response[current_index]?.vendorType) : [{ text: '', value: '' }]
    setRdrPreferredVendorOptions(options)
  }

  const handleLowRiskProcessorsFromOffices = (office_id: string) => {
      
    let dummyLowRiskProcessors: any = []
  
    for(let i=0;i<parentOffices.length;i++){
      let index = parentOffices.findIndex((office: any) => office.office_id.toString() === office_id.toString())
      if(index > -1){
      dummyLowRiskProcessors = parentOffices[index].allowed_low_risk_processors || []
      if( (dummyLowRiskProcessors && dummyLowRiskProcessors.length>0) || parentOffices[index].office_level === '1'){
        break;
      }else{
        office_id = parentOffices[index].parent_office_id
      }
    }}
    return getParsedList(dummyLowRiskProcessors)
  }
  
  const handleHighRiskProcessorsFromOffices = (office_id: string) => {
    let dummyHighRiskProcessors: any = []
  
    for(let i=0;i<parentOffices.length;i++){
      let index = parentOffices.findIndex((office: any) => office.office_id.toString() === office_id.toString())
      if(index > -1){
      dummyHighRiskProcessors = parentOffices[index].allowed_high_risk_processors || []
      if( (dummyHighRiskProcessors && dummyHighRiskProcessors.length>0) || parentOffices[index].office_level === '1'){
        break;
      }else{
        office_id = parentOffices[index].parent_office_id
      }
    }}
    return getParsedList(dummyHighRiskProcessors)
  }

  const handleRiskLevelsFromOffices = (office_id: any) => {
    let dummyRiskLevels: any = []

    for (let i = 0; i < parentOffices.length; i++) {
      let index = parentOffices.findIndex((office: any) => office.office_id.toString() === office_id.toString())
      if(index > -1){
      dummyRiskLevels = parentOffices[index].allowed_risk_levels
      if ((dummyRiskLevels && dummyRiskLevels.length > 0) || parentOffices[index].office_level === '1') {
        break;
      } else {
        office_id = parentOffices[index].parent_office_id
      }
    }}
    return getParsedList(dummyRiskLevels) || []
  }

  const updateBanksAndRiskLevels = (partner_office_id: string, partnerDetails?: any) =>{
    //execute when partners, partnerId, partnerName, parentOffices, processors  are updated
    setShowLowRiskProcessors([])
    setShowHighRiskProcessors([])
    setShowRiskLevels([])
    let dummyRiskLevels = [] as any
    let dummyLowRiskBanks = [] as any
    let dummyHighRiskBanks = [] as any
    if(role === ROLES.PARTNER){ 
      // 0. fetch office and parent offices of salesuser
      // 1. check if salesuser have risklevels and low risk & high risk banks
      // 2. If don't have any, check for salesuser office
      // 4. If office dont have, check for parent offices
      // 5. If parent offices don't have show all   
      dummyRiskLevels = allowed_risk_levels 
      dummyLowRiskBanks =  allowed_lowrisk_processors
      dummyHighRiskBanks =  allowed_highrisk_processors

    }else if( role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.RELATIONSHIP_MANAGER){ 
      //for RM same as admin&manager but need to fetch all office or offices which are linked with RM and their parent offices

      // 0. fetch partners, office and parent offices of salesuser
      // 1. check if selected salesuser have risklevels and low risk & high risk banks
      // 2. If don't have any, check for salesuser office
      // 4. If office dont have, check for parent offices
      // 5. If parent offices don't have show all
      dummyRiskLevels = getParsedList(partnerDetails?.allowed_risk_levels)
      dummyLowRiskBanks = getParsedList(partnerDetails?.allowed_low_risk_processors)
      dummyHighRiskBanks = getParsedList(partnerDetails?.allowed_high_risk_processors)
    }else if( role === ROLES.UNDER_WRITER){ 
      // 0. if editing an app, fetch app linked salesuser details,  linked sales user office details and parent offices details  or else fetch office and parent offices of UW user
      // 1. if editing an app check if fetched salesuser have risklevels and low risk & high risk banks else check logged in UW has risklevels and low risk & high risk
      // 2. If don't have any, check for salesuser office
      // 4. If office dont have, check for parent offices
      // 5. If parent offices don't have show all
      if(location.pathname.includes('edit/MA')){
        dummyRiskLevels = getParsedList(partnerDetails?.allowed_risk_levels)
        dummyLowRiskBanks = getParsedList(partnerDetails?.allowed_low_risk_processors)
        dummyHighRiskBanks = getParsedList(partnerDetails?.allowed_high_risk_processors)
        if((dummyRiskLevels && dummyRiskLevels.length) && !dummyRiskLevels.includes(riskLevel)){
          setRiskLevel('')
       }
      }else{
        dummyRiskLevels = allowed_risk_levels 
        dummyLowRiskBanks =  allowed_lowrisk_processors
        dummyHighRiskBanks =  allowed_highrisk_processors
      }
    }else if( role === ROLES.SALES_LIMITED){
      // 0. if editing an app, fetch app linked salesuser details, linked sales user office details and parent offices details
      // 1. check if salesuser have risklevels and low risk & high risk banks
      // 2. If don't have any, check for salesuser office
      // 4. If office dont have, check for parent offices
      // 5. If parent offices don't have show all
      dummyRiskLevels = getParsedList(partnerDetails?.allowed_risk_levels)
      dummyLowRiskBanks = getParsedList(partnerDetails?.allowed_low_risk_processors)
      dummyHighRiskBanks = getParsedList(partnerDetails?.allowed_high_risk_processors)
    }else if( role === ROLES.VIEW_ONLY){
      //Don't have access to sartapp, can ignore this role
    }

    if((!dummyRiskLevels || dummyRiskLevels.length <= 0) && (office_id || partner_office_id)){
      dummyRiskLevels = handleRiskLevelsFromOffices(!!office_id ? office_id : partner_office_id)
    }
    if((!dummyLowRiskBanks || dummyLowRiskBanks.length <= 0) && (office_id || partner_office_id)){
      dummyLowRiskBanks = handleLowRiskProcessorsFromOffices(!!office_id ? office_id : partner_office_id)
    }
    if((!dummyHighRiskBanks || dummyHighRiskBanks.length <= 0) && (office_id || partner_office_id)){
      dummyHighRiskBanks = handleHighRiskProcessorsFromOffices(!!office_id ? office_id : partner_office_id)
    }
    if(dummyRiskLevels.length !==0 && !dummyRiskLevels.includes(riskLevel)){
      setRiskLevel('')
    }
    setShowRiskLevels((dummyRiskLevels && dummyRiskLevels.length > 0) ? dummyRiskLevels : [RISK_LEVELS.LOW_RISK , RISK_LEVELS.HIGH_RISK])
    setShowLowRiskProcessors((dummyLowRiskBanks && dummyLowRiskBanks.length > 0) ? processors?.filter((processor: any)=> dummyLowRiskBanks.includes(processor.value) && processor.value !== BANK_CODES.DART) : processors?.filter((processor: any) => processor.value !== BANK_CODES.DART))
    setShowHighRiskProcessors((dummyHighRiskBanks && dummyHighRiskBanks.length > 0) ? processors?.filter((processor: any)=> dummyHighRiskBanks.includes(processor.value)) : processors?.filter((processor: any) => cdBanks.indexOf(processor.value) === -1))
  }

  const sortProcessors = (data: any) => {
    let processors: any = data
    if (data && data.length) {
      data.sort(
        firstBy(function (option: any) {
          return option.processor_name.toLowerCase();
        })
      )
    }
    let options = []
    for (const option of data) {
      let index = processors.findIndex((processor: any) => processor.processor_name.toLowerCase() === option.processor_name.toLowerCase())

      options.push({
        text: processors[index].processor_name,
        value: REACT_APP_BACKEND_MYSQL ? processors[index].processor_internal_id : processors[index].processor_code
      })
    }
    return options
  }

  useEffect(() => {
    setLoading(true)
    fetchProcessorsData()
    fetchAllLookupData()
    fetchOffices()
    fetchPartners()
    if( (role === ROLES.UNDER_WRITER && !history.location.pathname.includes('/startapp/edit')) ||  role === ROLES.PARTNER){
      const partner_name: any = sessionStorage.getItem('partner_name')
      const partner_rm_id: any = sessionStorage.getItem('partner_rm_id')
      const partner_rm_name: any = sessionStorage.getItem('partner_rm_name')
      setPartnerId(partner_id)
      setPartnerName(partner_name)
      fetchLinkedSalesUserDetails(partner_id)
      // setPartnerRmId(partner_rm_id)
      // setPartnerRmName(partner_rm_name)
      setShowSendToTrinity( paper_apps_enabled === 'true' ? true : false)
    }
    if(pricingType === ''){
      pricingCardType.forEach((item: any) => {
        item.ischecked = false
      })
    }
    setPricingCardType(pricingCardType)
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      //Clearing setInterval
      clearInterval(autoSaveIntervalRef)
      autoSaveIntervalRef = null
    };
  }, []);
  useEffect(() => {
    if(selectedBankOption === BANK_CODES.CFSB || selectedBankOption === BANK_CODES.CFSB_CD)
      {
        setPciNonCompliance(otherFees_cfsb.pciNonCompliance)
      } 
      else {
        setPciNonCompliance(otherFees.pciNonCompliance)
      }

  },[selectedBankOption]);

  const isEditFlow = history.location.pathname.includes('/startapp/edit');
  
  const getUserInfo = async () => {
    const data = { api: api.users.getByGUID, urlParam: user_guid};
    const profiledata = await backend.fetch(data);
    const first_name = REACT_APP_BACKEND_MYSQL ? profiledata.user_name_first : profiledata.name?.first_name
    const last_name = REACT_APP_BACKEND_MYSQL ? profiledata.user_name_last : profiledata.name?.last_name
    setPartnerRm([{
      text: `${first_name} ${last_name}`,
      value: profiledata.user_id
    }]);
    setPartnerRmId(profiledata.user_id)
  }

  useEffect(()=>{
    if(role === ROLES.RELATIONSHIP_MANAGER && !isEditFlow){
      // it will automatically select the logged in user's RM
      getUserInfo();
    } else if(!isEditFlow) {
      if(role === ROLES.PARTNER || role === ROLES.UNDER_WRITER){
      fetchPartnerRms(partner_id, false)
        
      }
      
    }
  },[]);

  //clearing selected options if selected options are removed for user or offices
  useEffect(()=>{
    const allowedLowRiskBanks = showLowRiskProcessors?.filter((item: any) => item.value === selectedBankOption)
    const allowedHighRiskBanks = showHighRiskProcessors?.filter((item: any) => item.value === selectedBankOption)
    if(riskLevel == RISK_LEVELS.LOW_RISK && allowedLowRiskBanks.length <= 0) setSelectedBankOption('') 
    if(riskLevel == RISK_LEVELS.HIGH_RISK && allowedHighRiskBanks.length <= 0) setSelectedBankOption('')
    // if(showRiskLevels.length > 0 && !showRiskLevels.includes(riskLevel)) {
    //   setRiskLevel('')
    //   setSelectedBankOption('')
    // }
    updatePreScreeningSectionStatus()
  },[showRiskLevels, showLowRiskProcessors, showHighRiskProcessors])
  
  useEffect(() => {
    const notlistCFSBLowRiskBanks = showLowRiskProcessors?.filter((item: any) => cdBanks.indexOf(item.value) > -1)
    //const notalistedCFSBHighRiskBanks = showHighRiskProcessors?.filter((item: any) => item.value === BANK_CODES.CFSB)
    if (notlistCFSBLowRiskBanks.length > 0 && riskLevel === RISK_LEVELS.LOW_RISK && showLowRiskProcessors.some((processor: any) => cdBanks.indexOf(processor.value) > -1)) {
      setShowCashDiscount(true)
    }else {
      setShowCashDiscount(false)
    }
    if (notlistCFSBLowRiskBanks.length === 0 && riskLevel === RISK_LEVELS.LOW_RISK && selectedSellTime === 'Cash Discount') {
      setSelectedSellTime('')
    }
    updatePreScreeningSectionStatus()

  }, [showLowRiskProcessors, showHighRiskProcessors, riskLevel])
  
  useEffect(() => {
    let check = false;
    let partnersCheck = (!location.pathname.includes('edit/MA') && role === ROLES.UNDER_WRITER) ? false : fetchingPartners;
    if(role !== ROLES.PARTNER && !partnersCheck && !fetchingOffices && !fetchingProcessors && businessCategoryConditionalOptions) check = true
    if(role === ROLES.PARTNER && !fetchingOffices && !fetchingProcessors && businessCategoryConditionalOptions) check = true
    setTempPartnerId(partnerId)
    if(check){
      const partnerDetails = partners.filter((item: any) => (item.value).toString() === partnerId.toString())
      if (partnerDetails && partnerDetails.length <= 0 && partners.length > 0) {
        setPartnerId('')
        setPartnerName('')
      } else if (partnerId) {
        updateBanksAndRiskLevels(partnerDetails[0]?.office_id, partnerDetails[0])
        checkPaperAppsEnabledStatus(partnerDetails[0]?.office_id)
      }
      setLoading(false)
    }
    if (partnerId && location.pathname.includes('edit/MA') && (role === ROLES.SALES_LIMITED || role === ROLES.UNDER_WRITER)) {
      //fetch linked partner details
      fetchLinkedSalesUserDetails(partnerId)
    }
  }, [processors, parentOffices, partners, partnerId])

  useEffect(()=>{
    const params = new URLSearchParams(history.location.search)
    const tokenID: any = params.get('token')
      if (tokenID){
        setDisableSalesMethod(true) 
        setTokenID(tokenID)
    }
  },[])

  useEffect(()=>{
    if(selectedSellTime){
        const list: any = [...equipmentSectionData]
        const updatedList:any = []
        list.forEach((element:any) => {
                element['product']=''
                element['application'] = ''
                element['billTo'] = ''
                element['quantity']=''
                element['cost']=''
                element['equipment_mailing_addr_type'] = ''
                element['pos_name'] = ''
            updatedList.push(element)
        });
        setEquipmentSectionData(list)
    }
},[selectedSellTime])

  const merchantLogin = async () => {
    setLoading(true)
    const params = new URLSearchParams(history.location.search)
    const tokenID: any = params.get('token')
    console.log('tokenID :>> ', tokenID);
    sessionStorage.setItem('merchant_access_token',tokenID)

    let request = {
      api: api.merchant.verifyToken,
      queryParam: { token: tokenID }
    }

    try{
      let tokenInfo = await backend.fetchUnprotected(request)
      console.log('tokenInfo', tokenInfo)
      console.log('tokenInfo.status', tokenInfo.status)
      if (tokenInfo.status) {
        console.log("signInWithCustomToken-before")
        auth.tenantId = tenantId
        const user: any = await signInWithCustomToken(auth, tokenInfo.token)
        console.log("signInWithCustomToken-after")

        const token: any = await user.user.getIdToken()
        console.log('merchantToken', token)
        // settoken - session storage
        setMerchantToken(token);
        sessionStorage.setItem("merchantToken", token);
        localStorage.setItem('merchant_login', 'merchant_login')
      } else {
        setShowWelcomModal(false)
        setInvalidAppLinkMsg(tokenInfo.message)
        setInvalidAppLink(true)
        setLoading(false)
      }
    }catch(err){
      Sentry.captureException(err);
      console.log('merchantLogin Err : ',err)
      setShowWelcomModal(false)
      setInvalidAppLink(true)
    }finally{
      setLoading(false)
    }
  }
  
  const appLinkMerchantLogin = async (url: string) => {
    const tokenID = url.split('=')[1]
    let request = {
      api: api.merchant.verifyToken,
      queryParam: { token: tokenID }
    }

    sessionStorage.setItem('merchant_access_token',tokenID)
    try{
      let tokenInfo = await backend.fetchUnprotected(request)
      if (tokenInfo.status) {
        auth.tenantId = tenantId
        const user: any = await signInWithCustomToken(auth, tokenInfo.token)
        
        const token: any = await user.user.getIdToken()
        console.log('token :>> ', token);

        // settoken - session storage
        setMerchantToken(token)
        sessionStorage.setItem("merchantToken", token);
        localStorage.setItem('merchant_login', 'merchant_login')
      } else {
        setShowWelcomModal(false)
        setInvalidAppLinkMsg(tokenInfo.message)
        setInvalidAppLink(true)
      }
    }catch(err){
      Sentry.captureException(err);
      console.log('appLinkMerchantLogin Err : ',err)
      setShowWelcomModal(false)
      setInvalidAppLink(true)
    }finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    if (history.location.pathname.includes('/startapp/edit') && merchantAppStatus === MERCHANT_APP_STATUS.DELETED || merchantAppStatus === MERCHANT_APP_STATUS.WITHDRAWN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
      setShowDeleteModal(true)
    }
  }, [merchantAppStatus, role])

  useEffect(() => {
    if(sendToMerchantFlag && (role === ROLES.SUPER_ADMIN || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.PARTNER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED)){
      scrollToBottom();
    }
  }, [sendToMerchantFlag]);

  useEffect(() => {
    if(!!riskLevel && riskLevelValue.indexOf(riskLevel) > -1){
      const mapping = monthAvgSalesHighRisk.find(mapping => mapping.key === selectedBankOption);
      const avgMonthForHigh = mapping ? mapping.values : '';
      setMonthAvgSales(avgMonthForHigh)
      }
    }, [selectedBankOption, riskLevel, monthAvgSales, setMonthAvgSales])

  useEffect(() => {
    if (location.pathname.includes('ds-return')) {
      const returnFunc = async () => {
        try{
          if (!!localStorage.merchant_app_id) {
            setMerchantAppID(localStorage.merchant_app_id);
          }
          let ownerTwoEmail = ''
          let ownerTwoName = ''
          let app_status = ''
          let processor = ''
          let riskLevel = ''
          let envelopeId = ''
          let merchant_app_id = ''
          let dba_name = ''
          const params = new URLSearchParams(history.location.search)
          const event: any = params.get('event')
          envelopeId = params.get('envid') || ''
          
          let message = ''
          //handling signing event
          if(event !== 'signing_complete'){
            message = 'Document signing was not completed. Please complete signing process to proceed...'
            throw { errMessage : message }
          } 

          if (!!localStorage.merchant_app_id && !!sessionStorage.merchantToken) {
  
            Sentry.configureScope(function (scope) {
              scope.setUser({ "id": merchantID });
              scope.setTag("APP_ID", localStorage.merchant_app_id);
            });
            Sentry.captureMessage("App Returned from DocuSign for appID : "+localStorage.merchant_app_id);

            if (sessionStorage.merchantToken !== merchantToken) {
              setMerchantToken(sessionStorage.merchantToken);
            }
            
            const response = await updateStatusOfMerchantApp(localStorage.merchant_app_id, sessionStorage.merchantToken, envelopeId);
            app_status = response.merchant_app_status
            processor = response.merchant_prescreen.processor_name
            riskLevel = response.merchant_prescreen.price
            merchant_app_id = response.merchant_app_id
            dba_name = response.app_data_sections.dba_section.dba_name
            response?.app_data_sections?.owner_section?.forEach((owner: any, index: number) => {
              if(index === 1 ) {
                ownerTwoEmail = owner.ownerEmail
                ownerTwoName = owner.firstName+' '+owner.lastName
              }
              return owner
            })
            
            
            
            if( app_status === MERCHANT_APP_STATUS.SIGNED ) await sendMailtoRMPartner(localStorage.merchant_app_id,response.partner_id)
          }
          if (localStorage.getItem('merchant_login') === 'merchant_login') {
            setSignDisabled(false)
          }
    
          if( app_status === MERCHANT_APP_STATUS.SIGNED){
            // Single owner signing banks
            const riskLvl = sessionStorage.riskLevel
            if (riskLvl === RISK_LEVELS.HIGH_RISK || riskLevel === RISK_LEVELS.HIGH_RISK) {
              const parentAppDosUploadStatus = await checkParentAppDocsUplodedStatus(localStorage.merchant_app_id)
              console.log('parentAppDosUploadStatus3',parentAppDosUploadStatus)
              if(parentAppDosUploadStatus&&parentAppDosUploadStatus.copy){
                navigateToThankyouPageByAppId()
              }else{
                navigateToDocUploadPageByAppId(localStorage.merchant_app_id)
              }
              
            } else if (riskLvl === RISK_LEVELS.LOW_RISK || riskLevel === RISK_LEVELS.LOW_RISK) {
              navigateToThankyouPageByAppId()
            }
          }
          if( app_status === MERCHANT_APP_STATUS.PARTIALLY_SIGNED){
            await sendMailToSecondSigner(merchant_app_id, envelopeId, dba_name)
            const riskLvl = sessionStorage.riskLevel
            if (riskLvl === RISK_LEVELS.HIGH_RISK || riskLevel === RISK_LEVELS.HIGH_RISK) {
              const parentAppDosUploadStatus = await checkParentAppDocsUplodedStatus(localStorage.merchant_app_id)
              console.log('parentAppDosUploadStatus2',parentAppDosUploadStatus)
              if(parentAppDosUploadStatus&&parentAppDosUploadStatus.copy){
                history.replace('/thankspage', { tokenID: sessionStorage.merchant_access_token,Merchant_app_id: merchant_app_id, sent_to_owner_two: true, ownerTwoEmail: ownerTwoEmail, ownerTwoName: ownerTwoName })
              }else{
                navigateToDocUploadPageByAppId(localStorage.merchant_app_id)
              }
            } else if (riskLvl === RISK_LEVELS.LOW_RISK || riskLevel === RISK_LEVELS.LOW_RISK) {
              history.replace('/thankspage', { tokenID: sessionStorage.merchant_access_token,Merchant_app_id: merchant_app_id, sent_to_owner_two: true, ownerTwoEmail: ownerTwoEmail, ownerTwoName: ownerTwoName })
            }
          }
        }catch(err: any){
          const params = new URLSearchParams(history.location.search)
          const event: any = params.get('event')
          let message = 'Something went wrong while updating data, after signing'
          //handling signing cancelled event
          if(event === 'cancel') message = 'Document signing was not completed. Please complete signing process to proceed...'
          setOverlaymsg(err.errMessage || message)
          showOverlayForThreeSeconds()
          setLoading(false)
          Sentry.configureScope(function (scope) {
            scope.setUser({ "id": merchantID });
            scope.setTag("APP_ID", localStorage.merchant_app_id);
          });
          Sentry.captureMessage(err.errMessage || message);
          Sentry.captureException(err)
          setTimeout(() => {
            history.replace('/')
          }, 1500);
          console.log(err)
        }
      }

      returnFunc()
    }
  }, []);

  useEffect(() => {
    let options = []
    const index = businessTypeOptions.findIndex((option: any) => option.BusinessCategoryName === businessCategory)
    const business_types = businessTypeOptions[index]?.BusinessTypeName || []
    business_types.sort(
      firstBy((option: any) => option.toLowerCase())  
    );
    for (const option of business_types) {
      options.push({
        text: option,
        value: option
      })
    }
    // setBusinessType("")
    // if (options.length > 0) {
    //   setBusinessType(options[0].value)
    // }
    setBusinessTypeConditionalOption(options);
    setbusinessSectionBusinessTypeConditionalOptions(options);

  }, [businessCategory]);

  useEffect(() => {
    let options = []
    const index = equipmentProductOptions.findIndex((option: any) => option.EquipmentTypeName === equipment)
    const business_types = equipmentProductOptions[index]?.ProductName || []
    business_types.sort(
      firstBy(function (option: any) {
        return option.toLowerCase();
      })
    );
    for (const option of business_types) {
      options.push({
        text: option,
        value: option
      })
    }
    
    setEquipmentTypeConditionalOption(options)
  }, [equipment]);

  const getProductOptions = (type:string)=>{
    let options = []
    if(type&&type.length){
      const index = equipmentProductOptions.findIndex((option: any) => option.EquipmentTypeName === type)
      const business_types = equipmentProductOptions[index]?.ProductName || []
      business_types.sort(
        firstBy(function (option: any) {
          return option.toLowerCase();
        })
      );
      for (const option of business_types) {
        options.push({
          text: option,
          value: option
        })
      }
    }
    return options
  }

  useEffect(() => {
    // clearing business form section when key variables risk level and business type is changed
    clearBusinessFormSection()

    if (riskLevel === RISK_LEVELS.LOW_RISK) {
      if (isRetail) {
        clearBusinessFormSection()
        setIsExported('No')
        setIsOwnInventory('Yes')
        setIsInventoryStoredatRetailLocation('Yes')
        setSelectedSellNationalLocal('Locally')
        setInternetSalesPct('0')
        setTelephoneSalesPct('5')
        setRetailSalesPct('95')
        // setMailOrderSalesPct('0')
        setCondBusiDispValues({
          is_exported_visible: true,
          is_own_inventory: true,
          vendorNames: false,
          is_inventory_stored_retail: true,
          selected_sell_national_local: true,
          internetSalesPct: true,
          telephoneSalesPct: true,
          // mailOrderSalesPct: true,
          retailSalesPct: true,
          advertisingMethods: false,
          charge_customer: false,
          fulfillmentCompanyName: false,
          crmCompanyName: false,
          chargeBackCompanyName: false,
          affliateMarketingPartnerName: false,
          callCenterName: false
        })
      }
      if (isMailOrder) {
       clearBusinessFormSection()
        setTelephoneSalesPct('100')
        // setMailOrderSalesPct('50')
        setInternetSalesPct('0')
        setRetailSalesPct('0')
        setCondBusiDispValues({
          is_exported_visible: true,
          is_own_inventory: true,
          vendorNames: true,
          is_inventory_stored_retail: true,
          selected_sell_national_local: true,
          internetSalesPct: true,
          telephoneSalesPct: true,
          // mailOrderSalesPct: true,
          retailSalesPct: true,
          advertisingMethods: true,
          charge_customer: true,
          fulfillmentCompanyName: false,
          crmCompanyName: false,
          chargeBackCompanyName: false,
          affliateMarketingPartnerName: false,
          callCenterName: false
        })
      }
      if (isEcommerce) {
        clearBusinessFormSection()
        setInternetSalesPct('95')
        setTelephoneSalesPct('5')
        // setMailOrderSalesPct('0')
        setRetailSalesPct('0')
        setCondBusiDispValues({
          is_exported_visible: true,
          is_own_inventory: true,
          vendorNames: true,
          is_inventory_stored_retail: true,
          selected_sell_national_local: true,
          internetSalesPct: true,
          telephoneSalesPct: true,
          // mailOrderSalesPct: true,
          retailSalesPct: true,
          advertisingMethods: true,
          charge_customer: true,
          fulfillmentCompanyName: false,
          crmCompanyName: false,
          chargeBackCompanyName: false,
          affliateMarketingPartnerName: false,
          callCenterName: false
        })
      }
      if (isRetail && isMailOrder) {
        clearBusinessFormSection()
        setIsExported('No')
        setIsOwnInventory('Yes')
        setIsInventoryStoredatRetailLocation('Yes')
        setSelectedSellNationalLocal('Locally')
        setInternetSalesPct('0')
        setTelephoneSalesPct('5')
        setRetailSalesPct('95')
        // setMailOrderSalesPct('0')
        setCondBusiDispValues({
          is_exported_visible: true,
          is_own_inventory: true,
          vendorNames: true,
          is_inventory_stored_retail: true,
          selected_sell_national_local: true,
          internetSalesPct: true,
          telephoneSalesPct: true,
          // mailOrderSalesPct: true,
          retailSalesPct: true,
          advertisingMethods: true,
          charge_customer: true,
          fulfillmentCompanyName: false,
          crmCompanyName: false,
          chargeBackCompanyName: false,
          affliateMarketingPartnerName: false,
          callCenterName: false
        })
      }
      if (isRetail && isEcommerce) {
        clearBusinessFormSection()
        setIsExported('No')
        setIsOwnInventory('Yes')
        setIsInventoryStoredatRetailLocation('Yes')
        setSelectedSellNationalLocal('Locally')
        setInternetSalesPct('0')
        setTelephoneSalesPct('5')
        setRetailSalesPct('95')
        // setMailOrderSalesPct('0')
        setCondBusiDispValues({
          is_exported_visible: true,
          is_own_inventory: true,
          vendorNames: true,
          is_inventory_stored_retail: true,
          selected_sell_national_local: true,
          internetSalesPct: true,
          telephoneSalesPct: true,
          // mailOrderSalesPct: true,
          retailSalesPct: true,
          advertisingMethods: true,
          charge_customer: true,
          fulfillmentCompanyName: false,
          crmCompanyName: false,
          chargeBackCompanyName: false,
          affliateMarketingPartnerName: false,
          callCenterName: false
        })
      }
      if (isMailOrder && isEcommerce) {
        clearBusinessFormSection()
        setIsExported('No')
        setIsOwnInventory('Yes')
        setIsInventoryStoredatRetailLocation('Yes')
        setSelectedSellNationalLocal('Locally')
        setTelephoneSalesPct('50')
        // setMailOrderSalesPct('50')
        setInternetSalesPct('0')
        setRetailSalesPct('0')
        setCondBusiDispValues({
          is_exported_visible: true,
          is_own_inventory: true,
          vendorNames: true,
          is_inventory_stored_retail: true,
          selected_sell_national_local: true,
          internetSalesPct: true,
          telephoneSalesPct: true,
          // mailOrderSalesPct: true,
          retailSalesPct: true,
          advertisingMethods: true,
          charge_customer: true,
          fulfillmentCompanyName: false,
          crmCompanyName: false,
          chargeBackCompanyName: false,
          affliateMarketingPartnerName: false,
          callCenterName: false
        })
      }
      if (isRetail && isMailOrder && isEcommerce) {
        clearBusinessFormSection()
        setIsExported('No')
        setIsOwnInventory('Yes')
        setIsInventoryStoredatRetailLocation('Yes')
        setSelectedSellNationalLocal('Locally')
        setInternetSalesPct('0')
        setTelephoneSalesPct('5')
        setRetailSalesPct('95')
        // setMailOrderSalesPct('0')
        setCondBusiDispValues({
          is_exported_visible: true,
          is_own_inventory: true,
          vendorNames: true,
          is_inventory_stored_retail: true,
          selected_sell_national_local: true,
          internetSalesPct: true,
          telephoneSalesPct: true,
          // mailOrderSalesPct: true,
          retailSalesPct: true,
          advertisingMethods: true,
          charge_customer: true,
          fulfillmentCompanyName: false,
          crmCompanyName: false,
          chargeBackCompanyName: false,
          affliateMarketingPartnerName: false,
          callCenterName: false
        })
      }
    }
    if (riskLevel === RISK_LEVELS.HIGH_RISK) {
      if (isEcommerce) {
        clearBusinessFormSection()
        setIsRetailLocation('No')
        setNumberOfLocations('1')
        setInternetSalesPct('100')
        setTelephoneSalesPct('0')
        // setMailOrderSalesPct('0')
        setRetailSalesPct('0')
        setCondBusiDispValues({
          is_exported_visible: true,
          is_own_inventory: true,
          vendorNames: true,
          is_inventory_stored_retail: true,
          selected_sell_national_local: true,
          internetSalesPct: true,
          telephoneSalesPct: true,
          // mailOrderSalesPct: true,
          retailSalesPct: true,
          advertisingMethods: true,
          charge_customer: true,
          fulfillmentCompanyName: true,
          crmCompanyName: true,
          chargeBackCompanyName: true,
          affliateMarketingPartnerName: true,
          callCenterName: true
        })
      } else {
        clearBusinessFormSection()
        setCondBusiDispValues({
          is_exported_visible: true,
          is_own_inventory: true,
          vendorNames: true,
          is_inventory_stored_retail: true,
          selected_sell_national_local: true,
          internetSalesPct: true,
          telephoneSalesPct: true,
          // mailOrderSalesPct: true,
          retailSalesPct: true,
          advertisingMethods: true,
          charge_customer: true,
          fulfillmentCompanyName: true,
          crmCompanyName: true,
          chargeBackCompanyName: true,
          affliateMarketingPartnerName: true,
          callCenterName: true
        })
      }

    }
  }, [riskLevel, isEcommerce, isRetail, isMailOrder])

  const updateStatusOfMerchantApp = async (merchantApp_id: string, token: string, envelopeId: string) => {
    
    const data: any = {
      api: api.merchant.updateMerchantAppSigningStatus,
      payLoad: JSON.stringify({
          "envelop_id":envelopeId,
          "merchant_app_id":merchantApp_id
      })
    }

    Sentry.configureScope(function (scope) {
      scope.setUser({ "id": merchantID });
      scope.setTag("APP_ID", localStorage.merchant_app_id);
    });
    showOverlay()
    setOverlaymsg(`We are saving your data, please don't close or refresh`)
    const response = await backend.save(data, true, sessionStorage.merchantToken)
    console.log(response)
    //ADD SENTRY HERE
    Sentry.captureMessage(`APP: ${localStorage.merchant_app_id} SUCCESSFULLY STATUS CHANGED TO SIGNED`);
    return response
  }

  const sendMailtoRMPartner = async (merchant_app_id: string, partner_id: string) => {
    // const partner_id = sessionStorage.getItem('partnerId')
    const send_mail_data: any = {
      api: api.merchant.sendMailToRMPartner,
      payLoad: JSON.stringify({
        merchant_app_id: merchant_app_id,
        partner_id: partner_id
      })
    }
    await backend.save(send_mail_data)
  }
  
  useEffect(() => {
    if (isEditFlow) {
      
      setEditStatus(true)
      setEditStatusDeleteUser(true)
      const merchant_login = async () => {
        const index = history.location.pathname.indexOf('?')
        let merchant_app_id = ''
        if (!!history.location.search && history.location.search !== '') {
          await merchantLogin()
        }
        if (index !== -1) {
          merchant_app_id = history.location.pathname.substr(18, index - 1)
        } else {
          merchant_app_id = history.location.pathname.substr(18)
        }
        const getLinkStatus = history.location.pathname.substr(15, 2)
        setGetLink(getLinkStatus === 'AL')
        if (getLinkStatus === 'AL') {
          setAppLinkID(merchant_app_id);
        } else {
          setMerchantAppID(merchant_app_id);
        }

        localStorage.setItem('merchant_app_id', merchant_app_id)
        if (localStorage.getItem('merchant_login') === 'merchant_login') {
          setSignDisabled(false)
        }
      }

      merchant_login()
    }

  }, [history.location.pathname, history.location.search, role, user_id])

  const formatDate = (date: any) => {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [month, day, year].join('-')
  }

  const hasEmptyString = (list: OwnerSectionDataType[]): boolean => {
    if(!list.length) return false;
    let hasEmptyString = true;
    list.map((item)=>{
      if(item.ownership !== "" && !!hasEmptyString){
        hasEmptyString = false
      }
    })
    return hasEmptyString;
  };
  const checkChargeBackValue = (val: any) => {
    if (selectedBankOption === BANK_CODES.CFSB_CD || selectedBankOption === BANK_CODES.ESQUIRE_CD) {
      const currentValue = val ? parseInt(val) : 15;
      if (!(role === ROLES.SUPER_ADMIN || role === ROLES.RELATIONSHIP_MANAGER) && currentValue < 15) {
        setChargeBackError(true);
        setChargeBackErrorMessage('Please Enter higher amount');
      } else {
        setChargeBackError(false);
        setChargeBackErrorMessage('');
      }
    } else if(selectedBankOption === BANK_CODES.HARRIS || selectedBankOption === BANK_CODES.CENTRAL) {
      if ((riskLevel === "High Risk") && val < 40){
        console.log("entered high risk")
        setChargeBackError(true);
        setChargeBackErrorMessage('Amount must be $40 or more');
      }
      else if((riskLevel === "Low Risk") && val < 25){
        console.log("entered Low risk")
        setChargeBackError(true);
        setChargeBackErrorMessage('Amount must be $25 or more');
      } else {
        setChargeBackError(false);
        setChargeBackErrorMessage('');
      }
    }
  } 
  const checkPciValue = (val: any) => {
    if (selectedBankOption === BANK_CODES.CFSB_CD || selectedBankOption === BANK_CODES.ESQUIRE_CD) {
      const currentValue = val ? parseFloat(val) : 119.99;
      if (!(role === ROLES.SUPER_ADMIN || role === ROLES.RELATIONSHIP_MANAGER) && currentValue < 119.99) {
      setPciError(true);
      setPciErrorMessage('Please Enter higher amount');
      } else {
      setPciError(false);
      setPciErrorMessage('');
      }
    }
  }

  const validateBilling = (hourly: any) => {
    if (hourly === '0' || hourly === '') {
      setBillingSumWarningText('');
      setBillingSumWarningTextFlag(false);
    } else {
      setBillingSumWarningText('Sum of percentages should Total 100%');
      setBillingSumWarningTextFlag(true);
    }
  }

  useEffect(() => {
    const fetchMerchantAppData = async () => {    
      try{
        const data: any = {}
        if (getLink) {
          data.api = api.saveLinks.getSaveLink
          data.queryParam = { save_link_id: appLinkID }
        } else {
          data.queryParam = { merchant_app_id: merchantAppID }
          if (role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) {
            data.api = api.merchantAppData.getMerchantAppById
          } else {
            data.api = api.merchant.getMerchantAppById
            if (tokenID.length > 0) {
              data.queryParam.token = tokenID
            }
          }
        }
        setLoading(true)
        let response: any;
        if (getLink) {
          response = await backend.fetchUnprotected(data)
          if (response) {
            setPartnerId(response.partner_id)
            setPartnerName(response.partner_name)
            const partner_rm_id = REACT_APP_BACKEND_MYSQL ? response?.partner_rm_user_id : response?.partner_rm_id
            setPartnerRmId(partner_rm_id)
            setPartnerRmName(response.partner_rm_name)
            setFreeEquipmentPlacementForMerchant(response?.free_equipment_placement_enabled||false)
            
            if(response.merchant_app_code){
              setMerchantAppCode(response.merchant_app_code)
            }
            if (response.merchant_prescreen) {
              const {
                business_category,
                business_type,
                business_subtype,
                business_category_free_form,
                business_type_free_form,
                goods_sold,
                physical_goods_type,
                price,
                processor_name,
                sales_frequency
              } = response.merchant_prescreen
              setBusinessCategory(business_category || '')
              handleBusinessCategory({}, { value: business_category })
              setBusinessType(business_type || '')
              setBusinessCategoryFreeFormText(business_category_free_form || '')
              setBusinessTypeFreeFormText(business_type_free_form || '')
              setConditionalSellType(business_subtype || '')
              setSellGoodValue(goods_sold || '')
              setConditionalSellGood(
                physical_goods_type || ''
              )
              setRiskLevel(price || '')
              setSelectedBankOption(processor_name || '')
              setSelectedSellTime(sales_frequency || '')
              const businessConduct_value: any[] = response.merchant_prescreen.business_conducted
              if (businessConduct_value.includes('Retail')) {
                setIsRetail(true)
              }
              if (businessConduct_value.includes('Mail Order/Tel')) {
                setIsMailOrder(true)
              }
              if (businessConduct_value.includes('E-Commerce')) {
                setIsEcommerce(true)
              }
              setBusinessConduct(businessConduct_value)
            }
            if (response.app_data_sections) {
              if (response.app_data_sections.pricing_details) {
                const {
                  pricing_type,
                  card_type,
                  monthly_fee,
                  annual_fee,
                  err_qual_discount,
                  err_qual_per_item,
                  err_non_discount,
                  err_non_per_item,
                  intamex_discount,
                  intamex_per_item,
  
                  tiered_qual_discount,
                  tiered_qual_per_item,
                  tiered_mid_discount,
                  tiered_mid_per_item,
                  tiered_non_discount,
                  tiered_non_per_item,
                  tiered_debit_discount,
                  tiered_debit_per_item,
                  discount_rate,
                  per_item,
                  debit_discount_rate,
                  debit_network_fees,
                  amex_qual,
                  amex_per_item,
                  amex_mid_qual,
                  amex_mid_per_item,
                  amex_non_qual,
                  amex_non_per_item,
                  pin_debit,
                  pin_debit_auth,
                  accept_ebt,
                  ebt,
                  ebt_auth,
                  wireless_fee,
                  offer_amex,
                  mc,
                  visa,
                  disc_auth,
                  amex_auth,
                  avs,
                  batch_fee,
                  charge_back_fee,
                  monthly_minimum,
                  early_termination_fee,
                  account_reserve,
                  other,
                  billed,
                  voice_auth,
                  excessive_electronic_auth,
                  irs_reporting_fee,
                  retreival_fee,
                  invalid_tin_fee,
                  ach_reject_fee,
                  collections_fee,
                  pci_validation,
                  pci_non_compliance,
                  industry_compliance,
                  return_transaction_fee,
                  amex_optuble_support_fee,
                  amex_optuble_network_fee,
                  debit_access_fee,
                  clym_data_privacy_compliance,
                  clym_data_privacy_compliance_fee,
                  ticketSize,
                  discountPercentage,
                  serviceFee,
                  licenseTechFees
                } = response.app_data_sections.pricing_details
                setPricingType(pricing_type || '')
                const pciNonCompliance : any = (data?.app_data_sections?.pricing_details?.pci_non_compliance || (selectedBankOption === BANK_CODES.CFSB || selectedBankOption === BANK_CODES.CFSB_CD) ? otherFees_cfsb.pciNonCompliance : otherFees.pciNonCompliance)
                setPciNonCompliance(pciNonCompliance);
                const pricing_cardType: any[] = response.app_data_sections.pricing_details.card_type
                if (pricing_cardType) {
                  pricingCardType.forEach((item: any) => {
                    pricing_cardType.forEach((selectedItem: any) => {
                      if (item.value === selectedItem) {
                        item.ischecked = true
                      }
                    })
                    setPricingCardValue(pricing_cardType)
                  })
                } else {
                  setPricingCardValue([])
                }
                setAnnualFee(annual_fee || '')
                setMonthlyFee(monthly_fee || '')
                setErrQualDiscount(err_qual_discount || '')
                setErrQualPerItem(err_qual_per_item || '')
                setErrNonDiscount(err_non_discount || '')
                setErrNonPerItem(err_non_per_item || '')
                setIntAmexDiscountRate(intamex_discount || '')
                setIntAmexperItem(intamex_per_item || '')
  
                setTieredQualDiscount(tiered_qual_discount || '')
                setTieredQualPerItem(tiered_qual_per_item || '')
                
                if(selectedBankOption === BANK_CODES.AXIOM || selectedBankOption === BANK_CODES.SYNOVUS){
                  setTieredMidPerItem(tiered_qual_per_item ? (parseFloat(tiered_qual_per_item) + 0.1).toFixed(2).toString() : '');
                  setTieredNonPerItem(tiered_qual_per_item ? (parseFloat(tiered_qual_per_item) + 0.1).toFixed(2).toString() : '');
                  setTieredMidDiscount(tiered_qual_discount ? (parseFloat(tiered_qual_discount) + 1.05).toFixed(2).toString() : '');
                  setTieredNonDiscount(tiered_qual_discount ? (parseFloat(tiered_qual_discount) + 1.55).toFixed(2).toString() : '');
  
                } else {
                  setTieredMidPerItem(tiered_mid_per_item || '')
                  setTieredNonPerItem(tiered_non_per_item || '')
                  setTieredMidDiscount(tiered_mid_discount || '')
                  setTieredNonDiscount(tiered_non_discount || '')
                }
  
                setTieredDebitDiscount(tiered_debit_discount || '')
                setTieredDebitPerItem(tiered_debit_per_item || '')
  
                setDiscountRate(discount_rate || '')
                setPerItem(per_item || '')
                setDebitDiscountRate(debit_discount_rate || '')
                setDebitNetworkFees(debit_network_fees || 'Yes')
                setClymDataPrivacyCompliance(clym_data_privacy_compliance || 'Yes')
                setClymDataPrivacyComplianceFee( clym_data_privacy_compliance_fee || '20')
                setAmexQual(amex_qual || '')
                setAmexPerItem(amex_per_item || '')
                setAmexMidQual(amex_mid_qual || '')
                setAmexMidPerItem(amex_mid_per_item || '')
                setAmexNonQual(amex_non_qual || '')
                setAmexNonPerItem(amex_non_per_item || '')
                setPinDebit(pin_debit || '')
                setPinDebitAuth(pin_debit_auth || '')
                setAcceptEbt(accept_ebt || '')
                setEbt(ebt || '')
                setEbtAuth(ebt_auth || '')
                setWirelessFee(wireless_fee || '')
                setOfferAmex(offer_amex || '')
                setMc(mc || '')
                setVisa(visa || '')
                setDiscAuth(disc_auth || '')
                setAmexAuth(amex_auth || '')
                setAvs(avs || '')
                setBatchFee(batch_fee || '')
                setChargeBackFee(charge_back_fee || '')
                setMonthlyMinimum(monthly_minimum || '')
                setEarlyTerminationFee(
                  early_termination_fee || ''
                )
                setManualTerminationFee(early_termination_fee || '')
                setAccountReserve(account_reserve || '')
                setOther(other || '')
                setBilled(billed || '')
                setVoiceAuth(voice_auth || '1.96')
                setExcessiveElectronicAuth(
                  excessive_electronic_auth || '0.10'
                )
                setIrsReportingFee(irs_reporting_fee || '4.95')
                setRetreivalFee(retreival_fee || '20')
                setInvalidTinFee(invalid_tin_fee || '19.95')
                setAchRejectFee(response?.merchant_prescreen?.processor_name === BANK_CODES.DART ? '25' : ach_reject_fee || '30')
                checkChargeBackValue(charge_back_fee);
                setCollectionsFee(collections_fee || '20')
                checkPciValue(pci_validation);
                setPciValidation(pci_validation || '119.99')
                setPciNonCompliance(pciNonCompliance)
                setIndustryCompliance(
                  industry_compliance || '9.95'
                )
                setReturnTransactionFee(
                  return_transaction_fee || '0.10'
                )
                setAmexOptubleSupportFee(
                  amex_optuble_support_fee || '0.50'
                )
                setAmexOptubleNetworkFee(
                  amex_optuble_network_fee || '0.15'
                )
                setDebitAccessFee(debit_access_fee || '3.95')
                setTicketSize(ticketSize || '')
                setLicenseTechFees(licenseTechFees || '25')
                setDiscountPercentage(discountPercentage || '')
                setServiceFee(serviceFee || '')
              }
              if (response.app_data_sections.equipment_section) {
                if (response.app_data_sections.equipment_section.length > 0) {
                  setNumberOfEquipments(response.app_data_sections.equipment_section.length)
                  setEquipmentSectionData(response.app_data_sections.equipment_section)
                }
              }
            }
            setParentAppID(response?.parent_app_id || '');
            if(response.partner_id && response.partner_name){
               handleAutoSave(true)
            }
          } else {
            setInvalidAppLink(true)
          }
        } else {
          response = await backend.fetch(data)
          if (!response) {
            setInvalidAppLink(true)
          } else {
            if (response.merchant_id) {
              setMerchantID(response.merchant_id)
            }
            if(response.merchant_app_code){
              setMerchantAppCode(response.merchant_app_code)
            }
            console.log(!!response.free_equipment_placement_enabled,"!!response.free_equipment_placement_enabled")
            //ADD SENTRY HERE
            Sentry.configureScope(function (scope) {
              scope.setUser({ "id": response.merchant_id });
              scope.setTag("APP_ID", response.merchant_app_id);
              scope.setTag("APP_STATUS", response.merchant_app_status);
            });
            Sentry.captureMessage(`Before Signing ${response.merchant_app_id} App Status changing from ${response.merchant_app_status} to started`);
            if (response.merchant_app_status) {
              setMerchantAppStatus(response.merchant_app_status)
              if (role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED&& response.merchant_app_status === 'sent to merchant') {
                const update_status = 'started'
                const payload = {
                  merchant_app_id: merchantAppID,
                  status: update_status
                }
  
                const data: any = {
                  api: api.merchant.updateMerchantAppStatus,
                  payLoad: { ...payload }
                }
  
                await backend.save(data)
                response.merchant_app_status = update_status
                setMerchantAppStatus(update_status)
                setSaveMerchantAppStatus(update_status)
              }
            }
  
            if (role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
              const riskLvl = response.merchant_prescreen.price || ''
              if (sessionStorage.getItem("initialStatus") === null) {
                sessionStorage.setItem("initialStatus", response.merchant_app_status);
              }
              if (response.merchant_app_status === 'signed' && sessionStorage.getItem("initialStatus") === "signed" && riskLvl === RISK_LEVELS.HIGH_RISK) {
                const parentAppDosUploadStatus = await checkParentAppDocsUplodedStatus(localStorage.merchant_app_id)
                // console.log('parentAppDosUploadStatus1',parentAppDosUploadStatus)
                if(parentAppDosUploadStatus&&parentAppDosUploadStatus.copy){
                  history.replace('/thankspage', { tokenID: sessionStorage.merchant_access_token, Merchant_app_id: localStorage.merchant_app_id })
                }else{
                  navigateToDocUploadPageByAppId(merchantAppID)
                }
              }
              if (response.merchant_app_status === 'signed' && sessionStorage.getItem("initialStatus") === "signed" && riskLvl === RISK_LEVELS.LOW_RISK) {
                history.replace('/thankspage', { tokenID: sessionStorage.merchant_access_token, Merchant_app_id: localStorage.merchant_app_id })
              }
              if (response.merchant_app_status === 'docs uploaded' && sessionStorage.getItem("initialStatus") === "docs uploaded") {
                history.replace('/thankspage', { tokenID: sessionStorage.merchant_access_token, Merchant_app_id: localStorage.merchant_app_id })
              }
              if (response.merchant_app_status === MERCHANT_APP_STATUS.PARTIALLY_SIGNED ) {
                setSecondSignerName(response?.app_data_sections?.owner_section[1]?.firstName+" "+response?.app_data_sections?.owner_section[1]?.lastName)
                setSecondSignerEmail(response?.app_data_sections?.owner_section[1]?.ownerEmail)
                setSentToSecondSigner(true)
              }
            }
            setPartnerId(response.partner_id)
            setPartnerName(response.partner_name)
            setPartnerRmId(response.partner_rm_id)
            setFreeEquipmentPlacementForMerchant(response?.free_equipment_placement_enabled||false)
            if(role === ROLES.SUPER_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER || role === ROLES.SALES_LIMITED || role === ROLES.UNDER_WRITER || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER){
              fetchPartnerRms(response.partner_id);
            }
            setPartnerRmId(response?.partner_rm_id)
            setPartnerRmName(response.partner_rm_name)
            setParentAppID(response?.parent_app_id || '');
            setMerchantEditFlag(response?.merchant_edit_flag || false)
            setAppLinkID(response?.app_link_id || '');
            setOptionalMessage(response?.optional_message || '')
            setOfflineApp(response?.offline_app_status || false)
            setSkipAddressValidation(response?.skipAddressValidation || false)
            if (isEditFlow && (role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED)) {
              setMerchantEditFlag(true);
              if (response.merchant_edit_flag) {
                setShowSensitiveFields(false);
                setShowWelcomModal(false);
              } else {
                let showWelcomeModal = !!sessionStorage.getItem("showWelcomModal") ? sessionStorage.getItem("showWelcomModal") !== "false" : "true"
                setShowWelcomModal(true && showWelcomeModal === "true");
              }
            }
            if (response.merchant_name) {
              setBusinessName(response.merchant_name)
            }
            if (response.merchant_email) {
              setBusinessEmail(response.merchant_email)
            }
            if (response.merchant_contact) {
              const { country_code, phone_no } = response.merchant_contact
              const contact_no = country_code + '' + phone_no
              setBusinessContact(contact_no)
            }
            if (response.merchant_app_start_ts) {
              const createdTS = REACT_APP_BACKEND_MYSQL ? formatDate(new Date(response.created_ts)) : formatDate(
                new Date(
                  response.merchant_app_start_ts._seconds * 1000 +
                  response.merchant_app_start_ts._nanoseconds / 1000000
                ).toDateString()
              )
              setMerchantAppStartDate(createdTS)
            }
            if (response.merchant_prescreen) {
              const {
                business_category,
                business_category_free_form,
                business_type,
                business_type_free_form,
                business_subtype,
                goods_sold,
                physical_goods_type,
                price,
                processor_name,
                sales_frequency
              } = response.merchant_prescreen
              setBusinessCategory(business_category || '')
              handleBusinessCategory({}, { value: business_category })
              setBusinessCategoryFreeFormText(business_category_free_form || '')
              setBusinessTypeFreeFormText(business_type_free_form || '')
              setBusinessType(business_type || '')
              setConditionalSellType(business_subtype || '')
              setSellGoodValue(goods_sold || '')
              setConditionalSellGood(
                physical_goods_type || ''
              )
              setRiskLevel(price || '')
              setSelectedBankOption(processor_name || '')
              setSelectedSellTime(sales_frequency || '')
              const businessConduct_value: any[] = response.merchant_prescreen.business_conducted
              if (businessConduct_value.includes('Retail')) {
                setIsRetail(true)
              }
              if (businessConduct_value.includes('Mail Order/Tel')) {
                setIsMailOrder(true)
              }
              if (businessConduct_value.includes('E-Commerce')) {
                setIsEcommerce(true)
              }
              setBusinessConduct(businessConduct_value)
            }
  
            let encryptedDataJson: any = {}
            if (!!sessionStorage.encryptedData) {
              encryptedDataJson = await getEncryptionDataFromSession(sessionStorage.encryptedData)
            }
  
            if (response.app_data_sections) {
              if (response.app_data_sections.owner_section) {
                if (response.app_data_sections.owner_section.length > 0) {
                  setNumberOfOwners(response.app_data_sections.owner_section.length)
                  setOwnerActive(response.app_data_sections.owner_section.length - 1)
                  let ownerErrorFields: any = []
                  let ownerPercentTotal: number = 0;
                  [...response?.app_data_sections?.owner_section].map((owner) => {
                    ownerPercentTotal += parseFloat(owner.ownership || "0")
                  })
                  const hasEmptyStringOwner = hasEmptyString([...response?.app_data_sections?.owner_section]);
                  response?.app_data_sections?.owner_section?.forEach((item: any, index: number) => {
                    if (encryptedDataJson?.owner_section && encryptedDataJson?.owner_section[index]) {
                      item.dl_Id = encryptedDataJson.owner_section[index].dl_Id
                      item.ssn = encryptedDataJson.owner_section[index].ssn
                    }
                    // Push yearsThisAddress to residenceYear
                    if (isEditFlow && !item.residenceMonth && !item.residenceYear && !!item.yearsThisAddress) {
                      item.residenceYear = item.yearsThisAddress; 
                      item.residenceMonth = "0"
                    }
                    ownerErrorFields.push({
                      dob_valid: '',
                      dl_issue_date_valid: '',
                      dl_expire_date_valid: '',
                      date_discharge_valid: '',
                      address_valid: '',
                      emailAlreadyExist: '',
                      emailInvalid: '',
                      ownership_valid: '',
                      invalidOwnership: ownerPercentTotal < 25 && hasEmptyStringOwner === false ? "Add all Owners with 25% or more ownership" : ""
                    })
                    let email_check = ownerEmailDuplicationCheck(response.app_data_sections.owner_section,index,item.ownerEmail)
                    if(!email_check) ownerErrorFields[index]['emailAlreadyExist']="This email is already Exists"
                    if(item.ownerEmail && !email_pattern.test(item.ownerEmail)) {
                      ownerErrorFields[index]['emailInvalid'] = "invalid email"
                    }
                    return item
                  })
                  setOwnerSectionSecureDataDummy(response?.app_data_sections?.owner_section?.map((owner: any)=>{
                    return {
                      dl_Id: '',
                      ssn: ''
                    }
                  }))
                  setOwnerSectionData([])
                  setOwnerSectionData(response?.app_data_sections?.owner_section)
                  setOwnerSectionErrorFileds(ownerErrorFields)
                }
              }
              if (response.app_data_sections.pricing_details) {
                const {
                  pricing_type,
                  card_type,
                  monthly_fee,
                  annual_fee,
                  err_qual_discount,
                  err_qual_per_item,
                  err_non_discount,
                  err_non_per_item,
                  intamex_discount,
                  intamex_per_item,
  
                  tiered_qual_discount,
                  tiered_qual_per_item,
                  tiered_mid_discount,
                  tiered_mid_per_item,
                  tiered_non_discount,
                  tiered_non_per_item,
                  tiered_debit_discount,
                  tiered_debit_per_item,
                  discount_rate,
                  per_item,
                  debit_discount_rate,
                  debit_network_fees,
                  amex_qual,
                  amex_per_item,
                  amex_mid_qual,
                  amex_mid_per_item,
                  amex_non_qual,
                  amex_non_per_item,
                  pin_debit,
                  pin_debit_auth,
                  accept_ebt,
                  ebt,
                  ebt_auth,
                  wireless_fee,
                  offer_amex,
                  mc,
                  visa,
                  disc_auth,
                  amex_auth,
                  avs,
                  batch_fee,
                  charge_back_fee,
                  monthly_minimum,
                  early_termination_fee,
                  account_reserve,
                  other,
                  billed,
                  voice_auth,
                  excessive_electronic_auth,
                  irs_reporting_fee,
                  retreival_fee,
                  invalid_tin_fee,
                  ach_reject_fee,
                  collections_fee,
                  pci_validation,
                  pci_non_compliance,
                  industry_compliance,
                  return_transaction_fee,
                  amex_optuble_support_fee,
                  amex_optuble_network_fee,
                  debit_access_fee,
                  clym_data_privacy_compliance,
                  clym_data_privacy_compliance_fee,
                  ticketSize,
                  discountPercentage,
                  serviceFee,
                  licenseTechFees
                } = response.app_data_sections.pricing_details
                setPricingType(pricing_type || '')
                const pciNonCompliance : any = ((selectedBankOption === BANK_CODES.CFSB || selectedBankOption === BANK_CODES.CFSB_CD) ? otherFees_cfsb.pciNonCompliance : otherFees.pciNonCompliance)
                const pricing_cardType: any[] = response.app_data_sections.pricing_details.card_type
                if (pricing_cardType) {
                  pricingCardType.forEach((item: any) => {
                    pricing_cardType.forEach((selectedItem: any) => {
                      if (item.value === selectedItem) {
                        item.ischecked = true
                      }
                    })
                    setPricingCardValue(pricing_cardType)
                  })
                } else {
                  setPricingCardValue([])
                }
  
                setAnnualFee(annual_fee || '')
                setMonthlyFee(monthly_fee || '')
                setErrQualDiscount(err_qual_discount || '')
                setErrQualPerItem(err_qual_per_item || '')
                setErrNonDiscount(err_non_discount || '')
                setErrNonPerItem(err_non_per_item || '')
                setIntAmexDiscountRate(intamex_discount || '')
                setIntAmexperItem(intamex_per_item || '')
  
                setTieredQualDiscount(tiered_qual_discount || '')
                setTieredQualPerItem(tiered_qual_per_item || '')
                
                if(selectedBankOption === BANK_CODES.AXIOM || selectedBankOption === BANK_CODES.SYNOVUS){
                  setTieredMidPerItem(tiered_qual_per_item ? (parseFloat(tiered_qual_per_item) + 0.1).toFixed(2).toString() : '');
                  setTieredNonPerItem(tiered_qual_per_item ? (parseFloat(tiered_qual_per_item) + 0.1).toFixed(2).toString() : '');
                  setTieredMidDiscount(tiered_qual_discount ? (parseFloat(tiered_qual_discount) + 1.05).toFixed(2).toString() : '');
                  setTieredNonDiscount(tiered_qual_discount ? (parseFloat(tiered_qual_discount) + 1.55).toFixed(2).toString() : '');
                } else {
                  setTieredMidPerItem(tiered_mid_per_item || '')
                  setTieredNonPerItem(tiered_non_per_item || '')
                  setTieredMidDiscount(tiered_mid_discount || '')
                  setTieredNonDiscount(tiered_non_discount || '')
                }
  
                setTieredDebitDiscount(tiered_debit_discount || '')
                setTieredDebitPerItem(tiered_debit_per_item || '')
                setDiscountRate(discount_rate || '')
                setPerItem(per_item || '')
                setDebitDiscountRate(debit_discount_rate || '')
                setDebitNetworkFees( debit_network_fees || 'Yes')
                setClymDataPrivacyCompliance(clym_data_privacy_compliance || 'Yes')
                setClymDataPrivacyComplianceFee(clym_data_privacy_compliance_fee || '20')
                setAmexQual(amex_qual || '')
                setAmexPerItem(amex_per_item || '')
                setAmexMidQual(amex_mid_qual || '')
                setAmexMidPerItem(amex_mid_per_item || '')
                setAmexNonQual(amex_non_qual || '')
                setAmexNonPerItem(amex_non_per_item || '')
                setPinDebit(pin_debit || '')
                setPinDebitAuth(pin_debit_auth || '')
                setAcceptEbt(accept_ebt || '')
                setEbt(ebt || '')
                setEbtAuth(ebt_auth || '')
                setWirelessFee(wireless_fee || '')
                setOfferAmex(offer_amex || '')
                setMc(mc || '')
                setVisa(visa || '')
                setDiscAuth(disc_auth || '')
                setAmexAuth(amex_auth || '')
                setAvs(avs || '')
                setBatchFee(batch_fee || '')
                setChargeBackFee(charge_back_fee || '')
                setMonthlyMinimum(monthly_minimum || '')
                setEarlyTerminationFee(
                  early_termination_fee || ''
                )
                setManualTerminationFee(early_termination_fee || '')
                setAccountReserve(account_reserve || '')
                setOther(other || '')
                setBilled(billed || '')
                setVoiceAuth(voice_auth || '1.96')
                setExcessiveElectronicAuth(
                  excessive_electronic_auth || '0.10'
                )
                setIrsReportingFee(irs_reporting_fee || '4.95')
                setRetreivalFee(retreival_fee || '20')
                setInvalidTinFee(invalid_tin_fee || '19.95')
                setAchRejectFee(response?.merchant_prescreen?.processor_name === BANK_CODES.DART ? '25' : ach_reject_fee || '30')
                checkChargeBackValue(charge_back_fee);
                setChargeBackFee(charge_back_fee || '')
                setCollectionsFee(collections_fee || '20')
                setPciValidation(pci_validation ||'119.99') 
                checkPciValue(pci_validation);
                setIndustryCompliance(
                  industry_compliance || '9.95'
                )
                setReturnTransactionFee(
                  return_transaction_fee || '0.10'
                )
                setAmexOptubleSupportFee(
                  amex_optuble_support_fee || '0.50'
                )
                setAmexOptubleNetworkFee(
                  amex_optuble_network_fee || '0.15'
                )
                setDebitAccessFee(debit_access_fee || '3.95')
                setTicketSize(ticketSize || '')
                setDiscountPercentage(discountPercentage || '')
                setServiceFee(serviceFee || '')
                setLicenseTechFees(licenseTechFees || '25')
              }
              if (response.app_data_sections.legal_section) {
                const {
                  legal_name,
                  legal_addr_1,
                  legal_addr_city,
                  legal_addr_state,
                  legal_addr_postalcode,
                  business_contact,
                  business_contact_title,
                  business_phone,
                  business_fax,
                  fed_tax_id_encrypted,
                  tax_type,
                  tax_filing_name,
                  business_start_date,
                  length_of_ownership,
                  legal_entity_type,
                  state_incorporated,
                  prior_security_breach,
                  processor_name
                } = response.app_data_sections.legal_section
                setLegalName(legal_name || '')
                setLegalAddress(legal_addr_1 || '')
                setLegalAddressCity(legal_addr_city || '')
                setSelectedLegalAddressState(
                  legal_addr_state || ''
                )
                setLegalAddressPostalCode(
                  legal_addr_postalcode || ''
                )
                setLegalBusinessContact(business_contact || '')
                setLegalBusinessContactTitle(business_contact_title || '')
                setLegalBusinessPhone(business_phone || '')
                setLegalBusinessFax(business_fax || '')
                setFedTaxID(fed_tax_id_encrypted || encryptedDataJson.fed_tax_id_encrypted || '')
                setFedTaxIdValid(validateFedTaxId(fed_tax_id_encrypted || encryptedDataJson.fed_tax_id_encrypted || ''))
                setSelectedTaxType(tax_type || '')
                setTaxFilingName(tax_filing_name || '')
                if (business_start_date) {
                  setBusinessStartDate(business_start_date)
                }
  
                setLengthOfOwnership(
                  length_of_ownership || ''
                )
                setSelectedLegalEntityType(
                  legal_entity_type || ''
                )
                setApplicationType(processor_name || '')
                setSelectedStateIncorporated(
                  state_incorporated || ''
                )
                setIsPriorSecurityBreach(
                  prior_security_breach || ''
                )
              }
              if (response.app_data_sections.dba_section) {
                const {
                  dba_name,
                  dba_addr_1,
                  dba_addr_city,
                  dba_addr_state,
                  dba_addr_postalcode,
                  business_email,
                  cust_service_phone,
                  website_url,
                  multiple_locations,
                  use_legal_address
                } = response.app_data_sections.dba_section
                setDBAName(dba_name || '')
                setDBAAddress(dba_addr_1 || '')
                setDBACity(dba_addr_city || '')
                setSelectedDBAState(dba_addr_state || '')
                setDBAZipCode(dba_addr_postalcode || '')
                if(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(business_email))
                setDbaBusinessEmailValid(true)
                else
                setDbaBusinessEmailValid(false)
                setDBABusinessEmail(business_email || '')
                setDBACustomerServicePhone(
                  cust_service_phone || ''
                )
                setDBAWebsiteURL(website_url || '')
                setSelectedDBAMultipleLocationOption(
                  multiple_locations || ''
                )
                setUseLegalAddress(use_legal_address || false)
              }
              if (response.app_data_sections.bank_section) {
                const {
                  bank_routing_no,
                  bank_account_no_encrypted,
                  bank_name,
                  bank_phone,
                  bank_ach_method
                } = response.app_data_sections.bank_section
                setBankRoutingNo(bank_routing_no || '')
                setBankAccountNo(
                  bank_account_no_encrypted || encryptedDataJson.bank_account_no_encrypted || ''
                )
                setConfirmBankAccountNo(bank_account_no_encrypted || encryptedDataJson.bank_account_no_encrypted || '')
                setBankName(bank_name || '')
                setBankPhone(bank_phone || '')
                // setSelectedBankACHMethod(bank_ach_method || '')
              }
              if (response.app_data_sections.business_section) {
                const business_section_keys = Object.keys(response.app_data_sections.business_section)
                if (business_section_keys.length > 0) {
                  const {
                    business_type,
                    goods_sold,
                    seasonal_merchant,
                    retail_location,
                    is_exported,
                    business_addr,
                    send_chargeback_addr_type,
                    own_inventory,
                    vendor_names,
                    inventory_stored_at_retail_location,
                    inventory_location,
                    sell_national_local,
                    internet_sales_pct,
                    encryption_method,
                    telephone_sales_pct,
                    retail_sales_pct,
                    mailorder_sales_pct,
                    prev_processor_terminated,
                    fufillment_company_name,
                    fufillment_company_name_free_form,
                    crm_company_name,
                    crm_company_name_free_form,
                    chargeback_company_name,
                    chargeback_company_name_free_form,
                    affliate_marketing_partner_name,
                    affliate_marketing_partner_name_free_form,
                    call_center_name,
                    call_center_name_free_form,
                    //fax,
                    business_fax,
                    advertising_methods,
                    sales_process_desc,
                    charge_customer_method,
                    days_to_delivery,
                    return_policy_desc,
                    refund_policy_desc,
                    card_types_accepted,
                    foreign_sales_pct,
                    business_location,
                    other_business_location,
                    number_of_locations,
                    oneTimePct,
                    monthlyPct,
                    quarterlyPct,
                    yearlyPct,
                    hourlyPct,
                    sell_service,
                    sell_product,
                    rdr_preferred_vendor
                  } = response.app_data_sections.business_section
                  setSellService(sell_service || 'No')
                  setSellProduct(sell_product || 'No')
                  setOneTimePct(oneTimePct || '')
                  setMonthlyPct(monthlyPct || '')
                  setQuarterlyPct(quarterlyPct || '')
                  setYearlyPct(yearlyPct || '')
                  setHourlyPct(hourlyPct || '')
                  validateBilling(hourlyPct);
                  setSelectedGoodsSold(goods_sold || '')
                  setIsSeasonalMerchant(seasonal_merchant || 'No')
                  setIsRetailLocation(retail_location || 'No')
                  setIsExported(is_exported || 'No')
                  setForeignSalesPct(foreign_sales_pct || '')
                  setBusinessAddress(business_addr || '')
                  setSelectedSendChargeback_AddressType(
                    send_chargeback_addr_type || ''
                  )
                  //setFax(fax || '')
                  setLegalBusinessFax(business_fax || '')
                  setIsOwnInventory(own_inventory || '')
                  setVendorNames(vendor_names || '')
                  setIsInventoryStoredatRetailLocation(
                    inventory_stored_at_retail_location || ''
                  )
                  setInventoryLocation(inventory_location || '')
                  setSelectedSellNationalLocal(
                    sell_national_local || ''
                  )
                  setInternetSalesPct(internet_sales_pct || '')
                  setEncryptionMethod(encryption_method || '')
                  setTelephoneSalesPct(
                    telephone_sales_pct || ''
                  )
                  setRetailSalesPct(retail_sales_pct || '')
                  // setMailOrderSalesPct(
                  //   mailorder_sales_pct || ''
                  // )
                  let sum = (Number(internet_sales_pct) || 0) + (Number(telephone_sales_pct) || 0) + (Number(retail_sales_pct) || 0) + (Number(mailorder_sales_pct) || 0)
                  if (sum !== 100) {
                    setSalesSumWarningText('sum of internet, telephone, retail,mailorder sales should be 100');
                    setSalesSumWarningTextFlag(true);
                  } else {
                    setSalesSumWarningText('');
                    setSalesSumWarningTextFlag(false);
                  }
                  setIsPrevProcessorTerminated(
                    prev_processor_terminated || 'No'
                  )
                  setFulfillmentCompanyName(
                    fufillment_company_name || ''
                  )
                  setFulfillmentCompanyNameFreeFormText(fufillment_company_name_free_form || '')
                  setCrmCompanyNameFreeFormText(crm_company_name_free_form || '')
                  setChargeBackCompanyNameFreeFormText(chargeback_company_name_free_form || '')
                  setAffliateMarketingPartnerNameFreeFormText(affliate_marketing_partner_name_free_form || '')
                  setCallCenterNameFreeFormText(call_center_name_free_form || '')
                  setCrmCompanyName(crm_company_name || '')
                  setChargeBackCompanyName(
                    chargeback_company_name || ''
                  )
                  setAffliateMarketingPartnerName(
                    affliate_marketing_partner_name || ''
                  )
                  setCallCenterName(call_center_name || '')
                  setRdrPreferredVendor(rdr_preferred_vendor || '')
                  const billing_value: any[] = response.app_data_sections.business_section.billing_method
                  if (billing_value) {
                    billingMethod.forEach((bill: any) => {
                      billing_value.forEach((selectedBill: any) => {
                        if (bill.value === selectedBill) {
                          bill.ischecked = true
                        }
                      })
                      setBillValue(billing_value)
                    })
                  } else {
                    setBillValue([])
                  }
                  setAdvertisingMethods(
                    advertising_methods || ''
                  )
                  setSalesProcessDesc(sales_process_desc || '')
                  setSelectedChargeCustomerMethod(
                    charge_customer_method || ''
                  )
                  setDaysToDelivery(days_to_delivery || '')
                  setReturnPolicyDesc(return_policy_desc || '')
                  setRefundPolicyDesc(refund_policy_desc || '')
  
                  const seasonal_months: any[] = response.app_data_sections.business_section.seasonal_merchant_months
                  if (seasonal_months) {
                    seasonalMonth.forEach((month: any) => {
                      seasonal_months.forEach((selectedMonth: any) => {
                        if (month.value === selectedMonth) {
                          month.ischecked = true
                        }
                      })
                      setSeasonalMonthValue(seasonal_months)
                    })
                  } else {
                    setSeasonalMonthValue([])
                  }
                  setBusinessLocation(business_location || '')
                  setOtherBusinessLocation(other_business_location || '')
                  setNumberOfLocations(number_of_locations || '')
                }
              }
              if (response.app_data_sections.transaction_section) {
                const {
                  month_avg_sales,
                  amex_month_avg_sales,
                  highest_ticket_amt,
                  avg_ticket_amt,
                  existing_amex_no,
                  public_sales_pct,
                  business_trans_pct
                } = response.app_data_sections.transaction_section
                setMonthAvgSales(month_avg_sales || '')
                setAmexMonthAvgSales(amex_month_avg_sales || '')
                setHighestTicketAmount(
                  highest_ticket_amt || ''
                )
                setAvgTicketAmount(avg_ticket_amt || '')
                setExistingAMEXno(existing_amex_no || '')
                setPublicSalesPCT(public_sales_pct || '')
                setBusinessTransPCT(business_trans_pct || '')
              }
              if (response.app_data_sections.equipment_section) {
                if (response.app_data_sections.equipment_section.length > 0) {
                  setNumberOfEquipments(response.app_data_sections.equipment_section.length)
                  setEquipmentSectionData(response.app_data_sections.equipment_section)
                }
              }
              if (response.app_data_sections.misc_section) {
                const { is_cbd_form_downloaded, is_mc_form_downloaded, is_esquire_form_downloaded } = response.app_data_sections.misc_section
                setIsCBDFormDownloaded(is_cbd_form_downloaded || false)
                setIsMcFormDownloaded(is_mc_form_downloaded || false)
                setIsEsquireFormDownloaded(is_esquire_form_downloaded || false)
              }
            }
            if (dbaName && (business_email && email_pattern.test(business_email)) && business_name && preScreeningSectionStatus === 'filled' && pricingSectionStatus === 'filled' && partnerId && partnerRm && (partnerRm.length > 0? !!partnerRmId : true)) {
              setSendToMerchantFlag(true);
            }
          }
          if(response.merchant_name && response.merchant_email && response.partner_id && response.partner_name && response.partner_rm_id &&  response.partner_rm_name && response.app_data_sections.dba_section.dba_name  ){
             handleAutoSave(true)
          }
        }
        setLoading(false)
        setMode('update')
      }catch(error){
        setLoading(false)
      }
    }
    if (merchantAppID !== '' || appLinkID !== '') {
        fetchMerchantAppData()
    }
  }, [getLink, merchantAppID, merchantToken, role])

  const handlePrevNextNavigation = (current_section_name: string, direction: string) => {
    window.scroll({ top: 0, left: 0 });
    const index = sectionsPrevNextData.findIndex(section => section.name === current_section_name);
    let current_section = sectionsPrevNextData[index];
    if (direction === "prev" && current_section.prev === "pricing" && !(role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.PARTNER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED || role === ROLES.SUPER_ADMIN)) {
      current_section = sectionsPrevNextData[index - 1];
    }
    if (direction === "next" && current_section.next === "pricing" && !(role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.PARTNER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED || role === ROLES.SUPER_ADMIN)) {
      current_section = sectionsPrevNextData[index + 1];
    }
    direction === "prev" ? setActiveSection(current_section.prev) : setActiveSection(current_section.next)

    const preScreeningCheck = (((businessConduct.includes('E-Commerce') && businessCategory === 'E COMMERCE' && businessType === 'CBD'
     && (selectedBankOption === 'PB&T' || selectedBankOption === 'Esquire')) || (selectedSellTime === 'Trial')))

    if(direction === "prev" && current_section_name === 'message') preScreeningCheck ? setActiveSection('misc') : setActiveSection('equipment')
    if(direction === "next" && current_section_name === 'equipment') preScreeningCheck ? setActiveSection('misc') : setActiveSection('message')

  }
  const handleBusinessTypeChange = (e: any, data: any) => {
    if (!businessCategory) {
      setbusinessCategoryWarningText('Please select the value for Business Category in Pre Screening Section to fill this field');
      return;
    }
    setBusinessType(data.value);
    if (data.value.toLowerCase() !== 'other') {
      setBusinessTypeFreeFormText('');
      setBusinessTypeFreeFormErrorMessage('')
    }
  }
  const handleBusinessCategory = (e: any, data: any) => {

    setBusinessCategory(data.value)
    if (data.value.toLowerCase() !== 'other') {
      setBusinessCategoryFreeFormText('');
      setBusinessTypeFreeFormText('');
      setBusinessCategoryFreeFormErrorMessage('')
      setBusinessTypeFreeFormErrorMessage('')
    }
  }
  const handlePricingCardTypeValueChange = () => {
    const pricingCardValueVMD: boolean = pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover');
    const pricingCardValueAMEX: boolean = pricingCardValue.includes('AMEX');

    if (pricingCardValueVMD) {
      if(selectedBankOption === BANK_CODES.CFSB && (pricingType === "Tiered" || pricingType === "Err")){
        setPricingType('')} else {
      if (pricingType === "Tiered") {
        clearErrVMD()                                                                                                                                                                                                                                            
        clearInterChangeFlatRateVMD()
      } else {
        clearTieredVMD()
        if (pricingType === "Err") {
          clearInterChangeFlatRateVMD()
        } else {
          clearErrVMD()
        }
      }}
    } else {
      clearTieredVMD()
      clearErrVMD()
      clearInterChangeFlatRateVMD()
    }
    if (pricingCardValueAMEX) {
      if (pricingType === "Tiered") {
        clearInterFlatErrAMEX()
      } else {
        clearTieredAMEX()
      }
    } else {
      clearTieredAMEX()
      clearInterFlatErrAMEX()
    }
  }
  const handleBilledChange = (e: any, data: any) => {
    setBilled(data.value)
  }  
  const handleTicketSizeChange = (e: any, data: any) => {
    setTicketSize(data.value)
    if(data.value === 'Above $20'){
      setServiceFee("")
    }else{
      setDiscountPercentage("")
    }
    clearTieredVMD()
    clearErrVMD()
    clearInterChangeFlatRateVMD()
}
  const handleDiscountPercentChange = (e: any, data: any) => {
    setDiscountPercentage(data.value)
  }
  const handleServiceFeeChange = (e: any, data: any) => {
    setServiceFee(data.value)
  }
  const clearErrVMD = () => {
    setErrQualDiscount('')
    setErrQualPerItem('')
    setErrNonDiscount('')
    setErrNonPerItem('')
    updatePricingSectionStatus()
  }

  const clearTieredVMD = () => {
    setTieredQualDiscount('')
    setTieredQualPerItem('')
    setTieredMidDiscount('')
    setTieredMidPerItem('')
    setTieredNonDiscount('')
    setTieredNonPerItem('')
    setTieredDebitDiscount('')
    setTieredDebitPerItem('')
    updatePricingSectionStatus()
  }

  const clearInterChangeFlatRateVMD = () => {
    setDiscountRate('')
    setPerItem('')
    setDebitDiscountRate('')
    setDebitNetworkFees('Yes')
  }

  const clearTieredAMEX = () => {
    setAmexQual('')
    setAmexPerItem('')
    setAmexMidQual('')
    setAmexMidPerItem('')
    setAmexNonQual('')
    setAmexNonPerItem('')
  }

  const clearInterFlatErrAMEX = () => {
    setIntAmexDiscountRate('')
    setIntAmexperItem('')
  }

  const clearCashDiscount = () =>{
    setTicketSize("")
    setServiceFee("")
    setDiscountPercentage("")
    setLicenseTechFees("25")
  }

  useEffect(() => {
    handlePricingCardTypeValueChange();
  }, [pricingType, pricingCardValue]);

  useEffect(() => {
    if (businessCategory.toLocaleLowerCase() === 'other') {
      if (!businessCategoryFreeFormText) {
        setBusinessCategoryFreeFormErrorMessage('Please Enter Business Category Value')
      } else {
        setBusinessCategoryFreeFormErrorMessage('')
      }
    } else {
      setBusinessCategoryFreeFormErrorMessage('')
      setBusinessTypeFreeFormErrorMessage('')
    }
  }, [businessCategory, businessCategoryFreeFormText])

  useEffect(() => {
    if (businessType.toLocaleLowerCase() === 'other') {
      if (!businessTypeFreeFormText) {
        setBusinessTypeFreeFormErrorMessage('Please Enter Business Type Value')
      } else {
        setBusinessTypeFreeFormErrorMessage('')
      }
    } else {
      setBusinessTypeFreeFormErrorMessage('')
    }
  }, [businessType, businessTypeFreeFormText])

  const globalFormat = (value: any) => {
    const x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const z = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    return z
  }
  const ssnFormat = (value: any) => {
    const x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
    const z = !x[2] ? x[1] : "" + x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : ""); 
    return z
  }

  useEffect(()=>{
    if(selectedSellTime === "Cash Discount"){
      let filteredBanks = showLowRiskProcessors.filter((banks: any) => banks.value === BANK_CODES.CFSB_CD || banks.value === BANK_CODES.ESQUIRE_CD)
      setCdBankOptionsForCD(filteredBanks);
      if(filteredBanks.length < 0){
        setShowCashDiscount(false);
      } else if(filteredBanks.length === 1) {
        setSelectedBankOption(filteredBanks[0].value);
        // setDisableSalesMethod(true)
      }
    }
  },[selectedSellTime, showLowRiskProcessors])

  const handleChangeSellTime = (e: any, data: any) => {
    setChargeBackFee("")
    setSelectedSellTime(data.value)
    if(data.value !== 'Cash Discount'){
      setSelectedBankOption('')
    }
    setPciValidation('119.99')
    if(data.value !== 'Cash Discount'){
      clearCashDiscount()
      setPricingType('')
    }else{
      setPricingType('Flat Rate')
      clearTieredVMD()
      clearErrVMD()
      clearInterChangeFlatRateVMD()
      clearTieredAMEX()
      clearInterFlatErrAMEX()
    }
  }
  const handleRiskLevel = (e: any, data: any) => {
    setPinDebit("")
    setMonthAvgSales("")
    setAmexMonthAvgSales("")
    setChargeBackError(false)
    setChargeBackErrorMessage("")
    const riskLevel = data.value
    if(selectedBankOption === BANK_CODES.HARRIS || selectedBankOption === BANK_CODES.CENTRAL){
      if(riskLevel === RISK_LEVELS.LOW_RISK){
        setChargeBackFee("25")
      } else if (riskLevel === RISK_LEVELS.HIGH_RISK){
        setChargeBackFee("40")
      }
    }
    setRiskLevel(riskLevel)
    if(riskLevel === RISK_LEVELS.HIGH_RISK){
      setHighestTicketAmount('500')
    }
    else{
      setHighestTicketAmount('')
    }
    const allowedLowRiskBanks = showLowRiskProcessors?.filter((item: any) => item.value === selectedBankOption)
    const allowedHighRiskBanks = showHighRiskProcessors?.filter((item: any) => item.value === selectedBankOption)
    if(riskLevel == RISK_LEVELS.LOW_RISK && allowedLowRiskBanks.length <= 0) setSelectedBankOption('') 
    if(riskLevel == RISK_LEVELS.HIGH_RISK && allowedHighRiskBanks.length <= 0) setSelectedBankOption('')
    setSelectedSellTime('')
  }
  const handleChangeLegalEntityType = (e: any, data: any) => {
    setSelectedLegalEntityType(data.value)
  }
  const handleChangeBankOption = (e: any, data: any) => {
    setChargeBackFee("")
    setChargeBackError(false)
    setChargeBackErrorMessage("")
    if(data.value === BANK_CODES.HARRIS || data.value === BANK_CODES.CENTRAL){
      if(riskLevel === RISK_LEVELS.LOW_RISK){
        setChargeBackFee("25")
      } else if (riskLevel === RISK_LEVELS.HIGH_RISK){
        setChargeBackFee("40")
      }
    }
    setMonthAvgSales("")
    setAmexMonthAvgSales("")
    const mapping = monthAvgSalesHighRisk.find(mapping => mapping.key === data.value);
    const avgMonthForHigh = mapping ? mapping.values : '';
    if(!!riskLevel && riskLevelValue.indexOf(riskLevel) > -1){
      setMonthAvgSales(avgMonthForHigh)
    } else{
      setMonthAvgSales("")
    }
    setSelectedBankOption(data.value)
    setPciValidation('119.99')
    setPciError(false);
    setPciErrorMessage('');
    if (data.value === BANK_CODES.CFSB_CD || data.value === BANK_CODES.ESQUIRE_CD) {
      setChargeBackFee('15')
      setChargeBackError(false);
      setChargeBackErrorMessage('');
    } 

    if(data.value === BANK_CODES.DART || data.value === BANK_CODES.CFSB){
      if(pricingType !== 'Interchange' && pricingType !== 'Flat Rate') setPricingType('')
      if(pricingCardValue.includes('AMEX')){
        const cardvalue = [...pricingCardValue];
        cardvalue.splice(cardvalue.indexOf('AMEX'), 1);
        setPricingCardValue(cardvalue)
        
        const newPricingCardType = [...pricingCardType];
        const index = newPricingCardType.findIndex((Item: any) => Item.value === 'AMEX')
        newPricingCardType[index].ischecked = false;
        setPricingCardType(newPricingCardType)
      }
      setAchRejectFee('25')
      clearTieredVMD();
      clearErrVMD();
    }else{
      setAchRejectFee('30')
    }

  }
  const handleChangePricingType = (e: any, data: any) => {
    setPricingType(data.value)
    if (data.value === 'Tiered' && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover'))) {
      setErrQualDiscount('')
      setErrQualPerItem('')
      setErrNonDiscount('')
      setErrNonPerItem('')
      setIntAmexDiscountRate('')
      setIntAmexperItem('')
      setDiscountRate('')
      setPerItem('')
      setDebitDiscountRate('')
      setDebitNetworkFees('Yes')
      setAmexQual('')
      setAmexPerItem('')
      setAmexMidQual('')
      setAmexMidPerItem('')
      setAmexNonQual('')
      setAmexNonPerItem('')
    } else if (data.value === 'Tiered' && (pricingCardValue.includes('AMEX'))) {
      setErrQualDiscount('')
      setErrQualPerItem('')
      setErrNonDiscount('')
      setErrNonPerItem('')
      setIntAmexDiscountRate('')
      setIntAmexperItem('')
      setDiscountRate('')
      setPerItem('')
      setDebitDiscountRate('')
      setDebitNetworkFees('Yes')
      setTieredQualDiscount('')
      setTieredQualPerItem('')
      setTieredMidDiscount('')
      setTieredMidPerItem('')
      setTieredNonDiscount('')
      setTieredNonPerItem('')
      setTieredDebitDiscount('')
      setTieredDebitPerItem('')
      setDiscountRate('')
      setPerItem('')
    } else if (data.value === 'Interchange' && (pricingCardValue.includes('AMEX'))) {
      setErrQualDiscount('')
      setErrQualPerItem('')
      setErrNonDiscount('')
      setErrNonPerItem('')
      setAmexQual('')
      setAmexPerItem('')
      setAmexMidQual('')
      setAmexMidPerItem('')
      setAmexNonQual('')
      setAmexNonPerItem('')
      setTieredQualDiscount('')
      setTieredQualPerItem('')
      setTieredMidDiscount('')
      setTieredMidPerItem('')
      setTieredNonDiscount('')
      setTieredNonPerItem('')
      setTieredDebitDiscount('')
      setTieredDebitPerItem('')
      setErrNonPerItem('')
      setIntAmexDiscountRate('')
      setIntAmexperItem('')
    } else if (data.value === 'Interchange' && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover'))) {
      setErrQualDiscount('')
      setErrQualPerItem('')
      setErrNonDiscount('')
      setErrNonPerItem('')
      setDiscountRate('')
      setPerItem('')
      setDebitDiscountRate('')
      setDebitNetworkFees('Yes')
      setAmexQual('')
      setAmexPerItem('')
      setAmexMidQual('')
      setAmexMidPerItem('')
      setAmexNonQual('')
      setAmexNonPerItem('')
      setTieredQualDiscount('')
      setTieredQualPerItem('')
      setTieredMidDiscount('')
      setTieredMidPerItem('')
      setTieredNonDiscount('')
      setTieredNonPerItem('')
      setTieredDebitDiscount('')
      setTieredDebitPerItem('')
    } else if (data.value === 'Err' && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover'))) {
      setPerItem('')
      setDebitNetworkFees('Yes')
      setAmexQual('')
      setAmexPerItem('')
      setAmexMidQual('')
      setAmexMidPerItem('')
      setAmexNonQual('')
      setAmexNonPerItem('')
      setTieredQualDiscount('')
      setTieredQualPerItem('')
      setTieredMidDiscount('')
      setTieredMidPerItem('')
      setTieredNonDiscount('')
      setTieredNonPerItem('')
      setTieredDebitDiscount('')
      setTieredDebitPerItem('')
      setDiscountRate('')
      setPerItem('')
      setIntAmexDiscountRate('')
      setIntAmexperItem('')
    } else if (data.value === 'Err' && (pricingCardValue.includes('AMEX'))) {
      setPerItem('')
      setDebitNetworkFees('Yes')
      setAmexQual('')
      setAmexPerItem('')
      setAmexMidQual('')
      setAmexMidPerItem('')
      setAmexNonQual('')
      setAmexNonPerItem('')
      setTieredQualDiscount('')
      setTieredQualPerItem('')
      setTieredMidDiscount('')
      setTieredMidPerItem('')
      setTieredNonDiscount('')
      setTieredNonPerItem('')
      setTieredDebitDiscount('')
      setTieredDebitPerItem('')
      setDiscountRate('')
      setPerItem('')
    } else if (data.value === 'Flat Rate' && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover'))) {
      setErrQualDiscount('')
      setErrQualPerItem('')
      setErrNonDiscount('')
      setErrNonPerItem('')
      setDiscountRate('')
      setPerItem('')
      setDebitNetworkFees('Yes')
      setAmexQual('')
      setAmexPerItem('')
      setAmexMidQual('')
      setAmexMidPerItem('')
      setAmexNonQual('')
      setAmexNonPerItem('')
      setTieredQualDiscount('')
      setTieredQualPerItem('')
      setTieredMidDiscount('')
      setTieredMidPerItem('')
      setTieredNonDiscount('')
      setTieredNonPerItem('')
      setTieredDebitDiscount('')
      setTieredDebitPerItem('')
    } else if (data.value === 'Flat Rate' && (pricingCardValue.includes('AMEX'))) {
      setErrQualDiscount('')
      setErrQualPerItem('')
      setErrNonDiscount('')
      setErrNonPerItem('')
      setAmexQual('')
      setAmexPerItem('')
      setAmexMidQual('')
      setAmexMidPerItem('')
      setAmexNonQual('')
      setAmexNonPerItem('')
      setTieredQualDiscount('')
      setTieredQualPerItem('')
      setTieredMidDiscount('')
      setTieredMidPerItem('')
      setTieredNonDiscount('')
      setTieredNonPerItem('')
      setTieredDebitDiscount('')
      setTieredDebitPerItem('')
      setErrNonPerItem('')
      setIntAmexDiscountRate('')
      setIntAmexperItem('')
    }
  }

  const handleTimeAtResidenceYear = (e: any, index: number) => {
    const { value } = e.target;
    const onlyNumbers = /^[0-9]*$/;
    if (!onlyNumbers.test(value)) {
      return;
    }
    const list: any = [...ownerSectionData];
    list[index].residenceYear = value;
    if (list[index].residenceMonth === "") {
      list[index].residenceMonth = "0";
    }
    const currentTimeAtResidence = `${value}.${list[index].residenceMonth}`;
    if ((value === "" || value === "0") && list[index].residenceMonth === "0") {
      list[index].yearsThisAddress = "";
    } else {
      list[index].yearsThisAddress = currentTimeAtResidence;
    }
    setOwnerSectionData(list);
  };

  const handleTimeAtResidenceMonth = (data: any, index: number) => {
    console.log(data.value, "value from dropdown");
    const value = data?.value || "";
    console.log(value, "selected value");

    const list: any = [...ownerSectionData];
    list[index].residenceMonth = value;

    const currentTimeAtResidence = `${list[index].residenceYear}.${value}`;

    if (!isEditFlow) {
      list[index].yearsThisAddress = "";
    }
    if (
      (list[index].residenceYear === "" || list[index].residenceYear === "0") &&
      value === "0"
    ) {
      list[index].residenceMonth = "0";
    } else {
      list[index].residenceMonth = value;
      list[index].yearsThisAddress = currentTimeAtResidence;
    }
    setOwnerSectionData(list);
  };

  const handleChangeDBAMultipleLocationOption = (e: any, data: any) => {
    setSelectedDBAMultipleLocationOption(data.value)
  }
  const handleChangePriorSecurityBreach = (e: any, data: any) => {
    setIsPriorSecurityBreach(data.value)
  }
  const handleChangeSeasonalMerchant = (e: any, data: any) => {
    setIsSeasonalMerchant(data.value)
  }
  const handleChangeRetailLocation = (e: any, data: any) => {
    setIsRetailLocation(data.value)
  }
  const handleChangeBusinessLocation = (e: any, data: any) => {
    setBusinessLocation(data.value)
  }
  const handleChangeIsExported = (e: any, data: any) => {
    setIsExported(data.value)
  }

  const handleChangeSendChargeback_AddressType = (e: any, data: any) => {
    setSelectedSendChargeback_AddressType(data.value)
    if (data.value !== 'FAX') {
      //setFax('');
      setLegalBusinessFax('');
    }
  }

  const handleChangeOwnInventory = (e: any, data: any) => {
    setIsOwnInventory(data.value)
  }
  const handleLegalState = (e: any, data: any) => {
    setSelectedLegalAddressState(data.value)
    if (useLegalAddress) {
      setSelectedDBAState(data.value)
    }
  }
  const handleStateIncorporated = (e: any, data: any) => {
    setSelectedStateIncorporated(data.value)
  }

  const handleChangeTaxType = (e: any, data: any) => {
    setSelectedTaxType(data.value)
  }
  const handleChangeInventoryStoredatRetailLocation = (e: any, data: any) => {
    setIsInventoryStoredatRetailLocation(data.value)
    if (data && data.value && data.value === 'Yes') {
      setDisableAddress(true)
    } else {
      setDisableAddress(false)
    }
  }

  const handleChangeSellNationalLocal = (e: any, data: any) => {
    setSelectedSellNationalLocal(data.value)
  }

  const handleChangePrevProcessorTerminated = (e: any, data: any) => {
    setIsPrevProcessorTerminated(data.value)
  }

  const handleFullFillmentCompanyChange = (e: any, data: any) => {
    setFulfillmentCompanyName(data.value);
    if (data.value.toLowerCase() !== 'other') {
      setFulfillmentCompanyNameFreeFormText('');
    }
  }

  const handleCRMCompanyChange = (e: any, data: any) => {
    setCrmCompanyName(data.value)
    if (data.value.toLowerCase() !== 'other') {
      setCrmCompanyNameFreeFormText('');
    }
  }

  const handleChargebackCompanyChange = (e: any, data: any) => {
    setChargeBackCompanyName(data.value)
    if (data.value.toLowerCase() !== 'other') {
      setChargeBackCompanyNameFreeFormText('');
    }
  }

  const handleAffiliateMarketingChange = (e: any, data: any) => {
    setAffliateMarketingPartnerName(data.value)
    if (data.value.toLowerCase() !== 'other') {
      setAffliateMarketingPartnerNameFreeFormText('');
    }
  }

  const handleCallCenterChange = (e: any, data: any) => {
    setCallCenterName(data.value)
    if (data.value.toLowerCase() !== 'other') {
      setCallCenterNameFreeFormText('');
    }
  }
  const handleRDRVendorChange = (e: any, data: any) => {
    setRdrPreferredVendor(data.value)
  }
  const handleChangeChargeCustomerMethod = (e: any, data: any) => {
    setSelectedChargeCustomerMethod(data.value)
  }
  const handleChangePinDebit = (e: any, data: any) => {
    setPinDebit(data.value)
  }
  const handleAcceptEbt = (e: any, data: any) => {
    setAcceptEbt(data.value)
  }

  const checkPaperAppsEnabledStatus = async (partnerDetails: any) => {
    if(partnerDetails){
      let index = parentOffices.findIndex((office: any) => office.office_id === partnerDetails)
      setShowSendToTrinity( parentOffices[index]?.paper_apps_enabled ? true : false )
    }
  }

  

  useEffect(() => {
    if (!freeEquipmentPlacementForMerchant) {
      const list: any = [...equipmentSectionData]
      const filterEquipmentBillTo = equipment_billTo.filter(item => (item.value !== 'Trinity'));
      const updatedList: any = []
      list.forEach((element: any) => {
        if (element.billTo === 'Trinity') {
          element.billTo = ''
        }
        updatedList.push(element)
      });
      setEquipmentSectionData(updatedList)
      setEquipmentBillTo(filterEquipmentBillTo)
    } else {
      setEquipmentBillTo(equipment_billTo)
    }
  }, [freeEquipmentPlacementForMerchant])

  const handleChangePartner = (e: any, data: any) => {
    fetchPartnerRms(data.value)
    setPartnerId(data.value)
    const partnerDetails = data.options.filter((item: any) => item.value === data.value)
    const partnerName = partnerDetails?.map((item: any) => item.text)[0]
    setPartnerName(partnerName)
    handleAutoSave()
    checkPaperAppsEnabledStatus(partnerDetails[0]?.office_id)
    setFreeEquipmentPlacementForMerchant(!!partnerDetails[0]?.free_equipment_placement_enabled)
    updateBanksAndRiskLevels(partnerDetails[0]?.office_id,partnerDetails[0])
    setSelectedSellTime("")
  }

  const handleChangePartnerRms = (e:any, data:any) => {
    setPartnerRmId(data.value)
    const rmDetails = data.options.filter((item: any) => item.value === data.value)
    const partnerRmName = rmDetails?.map((item: any) => item.text)[0]
    setPartnerRmName(partnerRmName)
    handleAutoSave()
  }



  // handle input change
  const handleInputChange = (e: any, index: any, data?: any) => {
    const list: any = [...ownerSectionData]
    let errorList: any = [...ownerSectionErrorFileds]
     if (data && data.name && data.hasOwnProperty('value')) { // to handle the dropdown event
      const dd_name = data.name
      const dd_value = data.value
 
      if(dd_name === "equipment_type"){
        list[index]['application'] = ''
      }
      list[index][dd_name] = dd_value
    } else {
     const { name, value } = e.target;
      if(name === "ownerEmail"){
        list[index].ownerEmail = value
        let dummy_index = 0
        for (const owner of list){
          let email_check = ownerEmailDuplicationCheck(list,dummy_index,owner.ownerEmail)
          if(!email_check) errorList[dummy_index]['emailAlreadyExist'] = "This email is already Exists"
          else  errorList[dummy_index]['emailAlreadyExist'] = ""
          if( owner.ownerEmail && !email_pattern.test(owner.ownerEmail))
          {errorList[dummy_index]['emailInvalid'] = "invalid email"
          }else {
            errorList[dummy_index]['emailInvalid'] = ""
          }
          dummy_index++
        }
      }
      if (name === "mobileNo") {
        let mobileNo = globalFormat(value);
        list[index][name] = mobileNo;
      }
      else if (name === "yearsThisAddress") {
        let yearsAtThisAdd = value ? value.replace(/[^0-9]/, "") : ""
        list[index][name] = yearsAtThisAdd;
      } 
      else if (name==="zip"){
        let zip = value ? value.replace(/[^0-9]/, "") : ""
        list[index][name] = zip;
      }
      else if(name === "ssn"){
        let formated_value = ssnFormat(value)
        if(value.length === 0 || validateSSNId(formated_value)){
          list[index]["ssnInvalid"]=""
        } else{
        list[index]["ssnInvalid"]="Invalid SSN number"
        }
        list[index][name] = formated_value;
      }else if(name === "ownership"){
        if( validateNumbers(value) && !value.includes(".") && ((value.length <=3 && parseInt(value) <= 100) || parseFloat(value) <= 100 || value === '')){
          list[index][name] = value
        }
      }
      else {
        list[index][name] = value
      }
      
      if(name==="ownership"){
        let totalOwnership = 0;
        list.map((owner: any) =>{
          totalOwnership += parseFloat(owner.ownership || 0)
        })
        if(totalOwnership >= 25 || hasEmptyString(list) === true){
          errorList = [...errorList].map((val: any) => {
            val['invalidOwnership'] = "";
            return val;
          });
        } else {
          if(hasEmptyString(list) === false){
            [...errorList].forEach((element, indexElement) => {
              errorList[indexElement]['invalidOwnership'] = "Add all Owners with 25% or more ownership"
            })
          }
        }
      }
    }
    setOwnerSectionData(list)
    setOwnerSectionErrorFileds(errorList)
  }
  const handleInputBlur = (index: any) => {
    const { ownerAddress, city, zip, state } = ownerSectionData[index]
    console.log('zip', zip)
    if (ownerAddress !== '' && city !== '' && zip !== '' && state !== '') {
      validateOwnerSectionAddress(index)
    }
  }
  
  const ownerEmailDuplicationCheck = (list: any, current_index: number, current_email: string) => {
    let email_occurences_count = 0
    let dummy_index = 0
    for (const owner of list){
      if (current_email !== '' && dummy_index !== current_index && owner.ownerEmail === current_email ) {
        email_occurences_count = email_occurences_count + 1
      }
      dummy_index++
    }
    if (email_occurences_count > 0) {
      return false
    }
    return true
  }
  
  const handleCopy = (e: any) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    setAlertCopyMsg('Cannot copy paste the Bank Account Number');
  }
  const handlePaste = (e: any) => {
    e.preventDefault();
    setAlertCopyMsg('Cannot copy paste the Bank Account Number');
    return false;
  }

  // handle click event of the Remove button
  const handleRemoveClick = (index: any) => {
    const list = [...ownerSectionData]
    list.splice(index, 1)
    var errorList = [...ownerSectionErrorFileds]
    errorList.splice(index, 1)

    let dummy_index = 0
    for (const owner of list){
      let email_check = ownerEmailDuplicationCheck(list,dummy_index,owner.ownerEmail)
      if(!email_check) errorList[dummy_index]['emailAlreadyExist'] = "This email is already Exists"
      else  errorList[dummy_index]['emailAlreadyExist'] = ""
      if( owner.ownerEmail && !email_pattern.test(owner.ownerEmail)){
        errorList[dummy_index]['emailInvalid'] = "invalid email"
      }else {
        errorList[dummy_index]['emailInvalid'] = ""
      }
      dummy_index++
    }
    let totalOwnership = 0;
    list.map((owner: any) =>{
      totalOwnership += parseFloat(owner.ownership || 0)
    });
    if(totalOwnership >= 25 || hasEmptyString(list) === true) {
      errorList = [...errorList].map((val: any) => {
        val['invalidOwnership'] = "";
        return val;
      });
    } else {
      if(hasEmptyString(list) === false){
        errorList = [...errorList].map((val: any) => {
          val['invalidOwnership'] = "Add all Owners with 25% or more ownership";
          return val;
        });
      }
    }
    setOwnerSectionData(list)
    setOwnerSectionErrorFileds(errorList)
    const ownerDummyList = [...ownerSectionSecureDataDummy]
    ownerDummyList.splice(index, 1)
    setOwnerSectionSecureDataDummy(ownerDummyList)
    handleOwnerClick( ownerActive !== index ? ownerActive < list.length ? ownerActive : ownerActive-1 : index-1 >= 0 ? index-1 : 0 )
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setOwnerActive(ownerSectionData.length)
    setOwnerSectionData([
      ...ownerSectionData,
      {
        firstName: '',
        lastName: '',
        ownerTitle: '',
        ownerAddress: '',
        city: '',
        state: '',
        zip: '',
        yearsThisAddress: '',
        mobileNo: '',
        dl_Id: '',
        documentType: '',
        issueState: '',
        issueDate: '',
        expireDate: '',
        ssn: '',
        dob: '',
        ownerEmail: '',
        ownership: '',
        individualsMoreOwnership: '',
        priorBankrupties: 'No',
        dateDischarge: '',
        control_prong: 'No',
        ssnInvalid:'',
        residenceYear:'',
        residenceMonth:'',
      }
    ])
    setOwnerSectionErrorFileds([
      ...ownerSectionErrorFileds,
      {
        dob_valid: '',
        dl_issue_date_valid: '',
        dl_expire_date_valid: '',
        date_discharge_valid: '',
        address_valid: '',
        emailInvalid: '', 
        emailAlreadyExist: '',
        ownership_valid: '',
        invalidOwnership:''
      }
    ])
    setOwnerSectionSecureDataDummy([...ownerSectionSecureDataDummy,
      {
        dl_Id: '',
        ssn: '',
        ssnInvalid:''
      }
    ])
  }
  const handleOwnerClick = (index: any) => {
    setOwnerActive(index)
    setHideOwner(true)
  }

  // handle click event of the Remove button
  const handleRemoveEquipClick = (index: any) => {
    const list = [...equipmentSectionData]
    list.splice(index, 1)
    setEquipmentSectionData(list)
    // after removing any owner data set fiest owner as active
    handleEquipmentClick(0)
  }

  // handle click event of the Add button
  const handleAddEquipClick = (index: any) => {
    handleEquipmentClick(equipmentSectionData.length);
    setEquipmentSectionData([
      ...equipmentSectionData,
      equip_default_obj
    ])
  }

  const handleEquipmentClick = (index: any) => {
    setEquipmentActive(index)
    setHideEquipment(true)
  }

  const handleControlProng = (value: any, index: any) => {
    const list: any = [...ownerSectionData]
    list[index]['control_prong'] = (value === 'Yes') ? 'No' : 'Yes'
    setOwnerSectionData(list)
  }

  const bankInfoAPI = async () => {
    try {
      const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })

      const url = `https://www.routingnumbers.info/api/data.json?rn=${bankRoutingNo}`
      // as of now making call with static routing number
      const response = await axiosInstance.get(url)
      const { code, message } = response.data
      if (code === 200 && message === 'OK') {
        setBankRoutingNumberErrMessage('')
        const bank_name = response?.data?.customer_name || '';
        const bank_phone = response?.data?.telephone || '';
        setBankName(bank_name);
        setBankPhone(bank_phone);
        updatedbaSectionStatus()
      } else {
        if (code === 404) {
          setBankRoutingNumberErrMessage('Please provide a valid routing number to proceed.')
        } else {
          setBankRoutingNumberErrMessage(message || 'Invalid routing number')
          setBankName('')
          setBankPhone('')
        }
      }
    } catch (err: any) {
      showOverlayForThreeSeconds()
      setOverlaymsg('Error occured while fetching bank details')
      console.log('err : ', err)
    }
  }

  const validateNumbers = (value: string) => {
    const numberFormat = (/^\d{0,10}(\.\d{0,2})?$/).test(value)
    return numberFormat
  }
  const handleChangeTextInput = (e: any) => {
    console.log("--handlechangetex-------",e.target)
    const { name, value } = e.target
    let sum = 0;
    let salesTotal = 0;
    let formatedValue = ''
    switch (name) {
      // merchant_info
      case 'business_name':
        setBusinessName(value)
        if(value===" ")
        setBusinessName("")
        else
        setBusinessName(value)
        return
      case 'business_email':
        setBusinessEmail(value)
        if(value===" ")
        setBusinessEmail("")
        else
        setBusinessEmail(value)
        return
      case 'business_contact':
        setBusinessContact(value)
        if(value===" ")
        setBusinessContact("")
        else
        setBusinessContact(value)
        return
      // pre_screening
      case 'dbaName':
        if(value===" ")
        setDBAName("")
        else
          setDBAName(value.trimLeft())
        return
      case 'dbaAddress':
        setDBAAddress(value)
        setDbaInfoAddressValid(false)
        return
      case 'dbaCity':
        setDBACity(value)
        setDbaInfoAddressValid(false)
        return
      case 'dbaZipCode':
        setDBAZipCode(value ? value.replace(/[^0-9]/, "") : "")
        setDbaInfoAddressValid(false)
        return
      case 'dbaBusinessEmail':
        setDBABusinessEmail(value)
        if(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(value))
          setDbaBusinessEmailValid(true)
        else
          setDbaBusinessEmailValid(false)
        return
      case 'dbaCustomerServicePhone':
        formatedValue = globalFormat(value)
        setDBACustomerServicePhone(formatedValue)
        return
      case 'dbaWebsiteURL':
        setDBAWebsiteURL(value)
        return
      // Pricing
      case 'annualFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAnnualFee(value)
        return
      case 'monthlyFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setMonthlyFee(value)
        return
      case 'tieredQualDiscount':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        // if (selectedBankOption === BANK_CODES.AXIOM || selectedBankOption === BANK_CODES.SYNOVUS) {
        //   setTieredMidDiscount(value ? (parseFloat(value) + 1.05).toFixed(2).toString() : '');
        //   setTieredNonDiscount(value ? (parseFloat(value) + 1.55).toFixed(2).toString() : '');
        // }
        setTieredQualDiscount(value)
        return
      case 'tieredQualPerItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setTieredQualPerItem(value)
        // if (selectedBankOption === BANK_CODES.AXIOM || selectedBankOption === BANK_CODES.SYNOVUS) {
        //   setTieredMidPerItem(value ? (parseFloat(value) + 0.1).toFixed(2).toString() : '');
        //   setTieredNonPerItem(value ? (parseFloat(value) + 0.1).toFixed(2).toString() : '');
        // }
        return
      case 'tieredMidDiscount':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setTieredMidDiscount(value)
        return
      case 'tieredMidPerItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setTieredMidPerItem(value)
        return
      case 'tieredNonDiscount':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setTieredNonDiscount(value)
        return
      case 'tieredNonPerItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setTieredNonPerItem(value)
        return
      case 'tieredDebitDiscount':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setTieredDebitDiscount(value)
        return
      case 'tieredDebitPerItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setTieredDebitPerItem(value)
        return
      case 'errQualDiscount':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setErrQualDiscount(value)
        return
      case 'errQualPerItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setErrQualPerItem(value)
        return
      case 'errNonDiscount':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setErrNonDiscount(value)
        return
      case 'errNonPerItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setErrNonPerItem(value)
        return
      case 'intAmexDiscountRate':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setIntAmexDiscountRate(value)
        return
      case 'intAmexperItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setIntAmexperItem(value)
        return
      case 'discountRate':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setDiscountRate(value)
        return
      case 'perItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setPerItem(value)
        return
      case 'debitDiscountRate':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setDebitDiscountRate(value)
        return
      case 'amexQual':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAmexQual(value)
        return
      case 'amexPerItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAmexPerItem(value)
        return
      case 'amexMidQual':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAmexMidQual(value)
        return
      case 'amexMidPerItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAmexMidPerItem(value)
        return
      case 'amexNonQual':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAmexNonQual(value)
        return
      case 'amexNonPerItem':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAmexNonPerItem(value)
        return
      case 'pinDebitAuth':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setPinDebitAuth(value)
        return
      case 'ebt':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setEbt(value)
        return
      case 'ebtAuth':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setEbtAuth(value)
        return
      case 'wirelessFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setWirelessFee(value)
        return
      case 'mc':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setMc(value)
        return
      case 'visa':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setVisa(value)
        return
      case 'amexAuth':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAmexAuth(value)
        return
      case 'avs':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAvs(value)
        return
      case 'batchFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setBatchFee(value)
        return
      case 'chargeBackFee':
        if (!validateNumbers(value) || value.length > 9){
          return false
        }
        checkChargeBackValue(value)
        setChargeBackFee(value)
        return
        
      case 'monthlyMinimum':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setMonthlyMinimum(value)
        return
      case 'earlyTerminationFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setEarlyTerminationFee(value)
        return
      case 'accountReserve':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAccountReserve(value)
        return
      case 'other':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setOther(value)
        return
      case 'voiceAuth':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setVoiceAuth(value)
        return
      case 'excessiveElectronicAuth':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setExcessiveElectronicAuth(value)
        return
      case 'irsReportingFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setIrsReportingFee(value)
        return

      case 'retreivalFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setRetreivalFee(value)
        return
      case 'invalidTinFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setInvalidTinFee(value)
        return
      case 'achRejectFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAchRejectFee(value)
        return
      case 'collectionsFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setCollectionsFee(value)
        return
      case 'pciValidation':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        checkPciValue(value);
        setPciValidation(value)
        return
      case 'pciNonCompliance':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        if((selectedBankOption === BANK_CODES.CFSB || selectedBankOption === BANK_CODES.CFSB_CD))
          {
            setPciNonCompliance(otherFees_cfsb.pciNonCompliance)
          }
          else {
        setPciNonCompliance(otherFees.pciNonCompliance)}
        return
      case 'industryCompliance':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setIndustryCompliance(value)
        return
      case 'returnTransactionFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setReturnTransactionFee(value)
        return
      case 'amexOptubleSupportFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAmexOptubleSupportFee(value)
        return 
      case 'amexOptubleNetworkFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setAmexOptubleNetworkFee(value)
        return
      case 'debitAccessFee':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setDebitAccessFee(value)
        return
      case 'licenseTechFees':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        setLicenseTechFees(value)
        return 

      // legal_section
      case 'legalName':
        setLegalName(value.trimLeft())
        return
      case 'legalAddress':
        setLegalAddress(value)
        setLegalInfoAddressValid(false)
        if (useLegalAddress) {
          setDBAAddress(value)
        }
        return
      case 'legalAddressCity':
        setLegalAddressCity(value)
        setLegalInfoAddressValid(false)
        if (useLegalAddress) {
          setDBACity(value)
        }
        return
      case 'legalAddressPostalCode':
        let legalZipcode = value ? value.replace(/[^0-9]/, "") : ""
        setLegalAddressPostalCode(legalZipcode)
        setLegalInfoAddressValid(false)
        if (useLegalAddress) {
          setDBAZipCode(value)
        }
        return
      case 'legalBusinessPhone':
        formatedValue = globalFormat(value)
        setLegalBusinessPhone(formatedValue)
        return
      case 'legalBusinessFax':
        formatedValue = globalFormat(value)
        setLegalBusinessFax(formatedValue)
        return
      case 'taxFilingName':
        setTaxFilingName(value)
        return
      case 'bankAccountNo':
        if(value === " ")
        setBankAccountNo("")
        else
        setBankAccountNo(value)
        let accountValue= value.replace(" ","")
        setBankAccountNo(accountValue.replace(" ",""))
        localStorage.setItem("merchantAppId", merchantAppID);
        localStorage.setItem('bankAccountNo', value);
        // user changing a/c number after entering the confirm a/c number
        setAlertCopyMsg("");
        setErrorMessage1("");
        if (confirmBankAccountNo) {
          if ((accountValue === confirmBankAccountNo) || confirmBankAccountNo === "") {
            setErrorMessage1("");
          } else if(confirmBankAccountNo !== value){
            setSuccessMessage1("");
            setErrorMessage1("Bank account numbers do not match. Please confirm your bank account number.");
          }
        }
        return
      case 'bankPhone':
        formatedValue = globalFormat(value);
        setBankPhone(formatedValue)
        return
      // business_details
      case 'internetSalesPct':
        if (Number(value) < 0 || Number(value) > 100) {
          return
        }
        setInternetSalesPct(value.replace(/\D/,''))
        sum = ((Number(value) || 0) + (Number(telephoneSalesPct) || 0) + (Number(retailSalesPct) || 0));
        if (sum !== 100) {
          setSalesSumWarningText('sum of internet, telephone, retail,mailorder sales should be 100');
          setSalesSumWarningTextFlag(true);
        } else {
          setSalesSumWarningText('');
          setSalesSumWarningTextFlag(false);
        }
        return
      case 'telephoneSalesPct':
        if (Number(value) < 0 || Number(value) > 100) {
          return
        }
        setTelephoneSalesPct(value.replace(/\D/,''))
        sum = (Number(internetSalesPct) || 0) + (Number(value) || 0) + (Number(retailSalesPct) || 0)
        if (sum !== 100) {
          setSalesSumWarningText('sum of internet, telephone, retail,mailorder sales should be 100');
          setSalesSumWarningTextFlag(true);
        } else {
          setSalesSumWarningText('');
          setSalesSumWarningTextFlag(false);
        }
        return
      case 'retailSalesPct':
        if (Number(value) < 0 || Number(value) > 100) {
          return
        }
        setRetailSalesPct(value.replace(/\D/,''))
        sum = (Number(internetSalesPct) || 0) + (Number(telephoneSalesPct) || 0) + (Number(value) || 0)
        if (sum !== 100) {
          setSalesSumWarningText('sum of internet, telephone, retail,mailorder sales should be 100');
          setSalesSumWarningTextFlag(true);
        } else {
          setSalesSumWarningText('');
          setSalesSumWarningTextFlag(false);
        }
        return
      // case 'mailOrderSalesPct':
      //   if (Number(value) < 0 || Number(value) > 100) {
      //     return
      //   }
      //   setMailOrderSalesPct(value)
      //   sum = (Number(internetSalesPct) || 0) + (Number(telephoneSalesPct) || 0) + (Number(retailSalesPct) || 0) + (Number(value) || 0)
      //   if (sum !== 100) {
      //     setSalesSumWarningText('sum of internet, telephone, retail,mailorder sales should be 100');
      //     setSalesSumWarningTextFlag(true);
      //   } else {
      //     setSalesSumWarningText('');
      //     setSalesSumWarningTextFlag(false);
      //   }
      //   return
      // transaction_section
      case 'monthAvgSales':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        let avgvalue=parseInt(value)
        if(avgvalue<=0)
          setAvgSalesWarning("Invalid Avg Monthly Volume (VS/MC/Disc)")
          else
          setAvgSalesWarning('')
        setMonthAvgSales(value)
        const defaultAmexPercent = 15;
        const calculatedPercent = (defaultAmexPercent/100) * parseInt(value);
        setAmexMonthAvgSales(!isNaN(calculatedPercent) ? calculatedPercent.toString() : "")
        return
      case 'amexMonthAvgSales':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        let amexMonth=parseInt(value)
        if(amexMonth<=0)
          setAmexMonthAvgWarning("Invalid Avg Monthly Volume (Amex)")
          else
          setAmexMonthAvgWarning('')
        setAmexMonthAvgSales(value)
        return
      case 'highestTicketAmount':
        if ((!validateNumbers(value) || value.length > 9) && riskLevel !== RISK_LEVELS.HIGH_RISK) {
          return false
        }
       if(validateNumbers(value)){
        let highestAmount=parseInt(value)
        if(highestAmount<=0)
          setHighestTicketAmountWarning("Invalid Highest Ticket Amount")
          else
          setHighestTicketAmountWarning('')
        setHighestTicketAmount(value)
       }
        return
      case 'avgTicketAmount':
        if (!validateNumbers(value) || value.length > 9) {
          return false
        }
        let avgAmount=parseInt(value)
        if(avgAmount<=0)
          setAvgTicketAmountWarning("Invalid Avg Ticket Amount")
          else
          setAvgTicketAmountWarning('')
        setAvgTicketAmount(value)
        return
      case 'existingAMEXno':
        setExistingAMEXno(value)
        return
      case 'publicSalesPCT':
        const convertedSalesValue = validateNumbers(value) ? value : 0
        setPublicSalesPCT(convertedSalesValue.replace(/\D/,''))
        const balanceTransVal =  convertedSalesValue > 100 ? "0" : (100 - convertedSalesValue).toString()
        if(convertedSalesValue === ""){
          setBusinessTransPCT("")
        } else {
          setBusinessTransPCT(balanceTransVal)
        }
        salesTotal = (Number(convertedSalesValue) || 0) + (Number(balanceTransVal) || 0);
        if (salesTotal !== 100) {
          setSalesTotalWarningText('Sum of B2C & B2B sales should be equal 100%');
        } else {
          setSalesTotalWarningText('');
        }
        return
      case 'businessTransPCT':
        const convertedTransValue = validateNumbers(value) ? value : 0
        setBusinessTransPCT(convertedTransValue.replace(/\D/,''))
        const balanceSalesVal = convertedTransValue > 100 ? "0" : (100 - convertedTransValue).toString()
        if(convertedTransValue === ""){
          setPublicSalesPCT("")
        } else {
          setPublicSalesPCT(balanceSalesVal)
        }
        salesTotal = (Number(convertedTransValue) || 0) + (Number(balanceSalesVal) || 0);
        if (salesTotal !== 100) {
          setSalesTotalWarningText('Sum of B2C & B2B sales should be equal 100%');
        } else {
          setSalesTotalWarningText('');
        }
        return
      // case 'fax': 
      //   if(Number(value) < 0 || Number(value) >9999999999 || (value.length && Number(value) === 0)) {
      //     return
      //   }
      //   setFax(value)
      //   return
      case 'One time':
        sum = (Number(value) || 0) 
        + (Number(monthlyPct) || 0) + (Number(quarterlyPct) || 0) 
        + (Number(yearlyPct) || 0) 
        setOneTimePct(value)
        handleBilling(value)
        if (sum !== 100) {
          setBillingSumWarningText('Sum of percentages should Total 100%');
          setBillingSumWarningTextFlag(true);
        } else {
          setBillingSumWarningText('');
          setBillingSumWarningTextFlag(false);
        }
        return
      case 'Monthly':
        sum = (Number(oneTimePct) || 0) + (Number(value) || 0) + (Number(quarterlyPct) || 0) + (Number(yearlyPct) || 0) || 0
        setMonthlyPct(value)
        handleBilling(value)
        if (sum !== 100) {
          setBillingSumWarningText('Sum of percentages should Total 100%');
          setBillingSumWarningTextFlag(true);
        } else {
          setBillingSumWarningText('');
          setBillingSumWarningTextFlag(false);
        }
        return
      case 'Quarterly':
        sum = (Number(oneTimePct) || 0) + (Number(monthlyPct) || 0) + (Number(value) || 0) + (Number(yearlyPct) || 0)|| 0
        setQuarterlyPct(value)
        handleBilling(value)
        if (sum !== 100) {
          setBillingSumWarningText('Sum of percentages should Total 100%');
          setBillingSumWarningTextFlag(true);
        } else {
          setBillingSumWarningText('');
          setBillingSumWarningTextFlag(false);
        }
        return
      case 'Yearly':
         sum = (Number(oneTimePct) || 0) + (Number(monthlyPct) || 0) + (Number(quarterlyPct) || 0) + (Number(value) || 0)
        setYearlyPct(value)
        handleBilling(value)
        if (sum !== 100) {
          setBillingSumWarningText('Sum of percentages should Total 100%');
          setBillingSumWarningTextFlag(true);
        } else {
          setBillingSumWarningText('');
          setBillingSumWarningTextFlag(false);
        }
        return

        case 'clymDataPrivacyComplianceFee':
        setClymDataPrivacyComplianceFee(value)
        return
        case 'legalBusinessContact':
        const validateBusinessContact = value ? value.replace(/[^0-9]/, "") : ""
        setLegalBusinessContact(validateBusinessContact);
        return
      case 'numberOfLocations':
        const validateNumberOfLocations = value ? value.replace(/[^0-9]/, "") : ""
        setNumberOfLocations(validateNumberOfLocations);
        return

      case 'lengthOfOwnership':
        const validateLengthofownership = value ? value.replace(/[^0-9]/, "") : ""
        setLengthOfOwnership(validateLengthofownership);
        return
      default:
    }
  }

  const getDownloadLink = async (filename: string) => {
    const storage = getStorage()
    const fileRef = ref(storage, `MiscContents/${filename}`);
    const downloadURL: any = await getDownloadURL(fileRef)
    return downloadURL
  }

  const downloadFile = async (file_url: string, filename: string) => {
    var hiddenElement = document.createElement("a");
    const response = await fetch(file_url)
    const blob = await response.blob()
    hiddenElement.href = window.URL.createObjectURL(blob)
    hiddenElement.target = "_blank"
    hiddenElement.download = filename;
    hiddenElement.click();
  }

  const handleDownload = async (filename: string) => {
    if (history.location.pathname.includes('/AL')) {
      setDownloadWarningText('Please Save the application to download the forms')
    } else {
      setDownloadWarningText('')
      const file_url: any = await getDownloadLink(filename)
      await downloadFile(file_url, filename)
      switch (filename) {
        case 'MC_Registration.pdf':
          console.log('setIsMcFormDownloaded : true')
          setIsMcFormDownloaded(true)
          break
        case 'CBD_Forms.pdf':
          console.log('setIsCBDFormDownloaded : true')
          setIsCBDFormDownloaded(true)
          break
        case 'Esquire_Attestation.docx':
          console.log('setIsEsquireFormDownloaded : true')
          setIsEsquireFormDownloaded(true)
          break
        default:
          break
      }
    }
  }

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  }

  const showOverlayForThreeSeconds = async () => {
    const sstyle: any = { style: { display: 'block', position: 'fixed' } }
    setOverlay(sstyle)
    await sleep(2000)
    const hstyle: any = { style: { display: 'none' } }
    setOverlay(hstyle)
  }

  const showOverlay = async () => {
    const sstyle: any = { style: { display: 'block' , position: 'fixed' } }
    setOverlay(sstyle)
  }

  const hideOverlay = async () => {
    const hstyle: any = { style: { display: 'none' } }
    setOverlay(hstyle)
  }

  const handleSaveAppMerchant = (event: any, send_flag?: boolean) => {
    let valid = false;
    if (bankAccountNo !== "") {
      valid = true;
    }
    if (fedTaxID !== "") {
      valid = true;
    }

    ownerSectionData.map((item: any) => {
      if (item.dl_Id !== "") {
        valid = true;
      }
      if (item.ssn !== "") {
        valid = true;
      }
    })
    if (valid) {
      setShowWarningModal(true);
    } else {
      handleSaveApp(false,event, false)
    }
  }

  // handle input change
  const handleOwnerSEctionDummyData = (e: any, index: any, data?: any) => {
    const { name, value } = e.target
    let ownerSecureDummy: any = [...ownerSectionSecureDataDummy]
    ownerSecureDummy[index][name] = value
    if(name === "ssn") {
      let formated_value = ssnFormat(value)
      if(value.length===0 || validateSSNId(formated_value)){
        ownerSecureDummy[index]["ssnInvalid"]=""
      } 
      else{
      ownerSecureDummy[index]["ssnInvalid"]="Invalid SSN number"
      }
      ownerSecureDummy[index][name] = formated_value;
    }
    setOwnerSectionSecureDataDummy(ownerSecureDummy)
  }
  const handleSendToTrinty = async () => {
    try{
      setOfflineApp(true)
      setLoading(true)
      await handleSaveApp(false,null,false,false,true)
      setSendToTrinityModalOpen(true)
      setLoading(false)
    }catch(err: any){
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg(err.errMessage || 'Error occured while signing')
      console.log("Error while siging app",err)
      Sentry.configureScope(function (scope) {
        scope.setTag("APP_ID", localStorage.getItem('merchant_app_id'));
      });
      Sentry.captureMessage("Error while signing App "+localStorage.getItem('merchant_app_id')+" :- "+ err?.message || '');
    }
  }

  const handleSaveSecureFileds = async () => {

    setLoading(true)
    // session encryption data - sed
    let encryptedDataJson = {
      bank_account_no_encrypted: bankAccountNoDummy,
      fed_tax_id_encrypted: fedTaxIDDummy,
      owner_section: [] as any[]
    }

    ownerSectionSecureDataDummy.forEach((item: any) => {
      encryptedDataJson.owner_section.push({
        dl_Id: item.dl_Id,
        ssn: item.ssn
      })
    })

    const payload = {
      merchant_app_id: merchantAppID || saveMerchantAppId,
      ...encryptedDataJson
    }

    const data = {
      api: api.merchant.updateEncryptionData,
      payLoad: { ...payload }
    }

    try{
      showOverlay()
      setOverlaymsg(`We are saving your data, please don't close or refresh`)
      const response = await backend.save(data, true)
      console.log("response : ", response)
      history.replace(`/docupload?merchant_app_id=${merchantAppID || saveMerchantAppId}`)
    }catch(err: any){
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg(err.errMessage || 'Error occured while saving secure data')
      console.log("Error while siging app",err)
      Sentry.configureScope(function (scope) {
        scope.setTag("APP_ID", localStorage.getItem('merchant_app_id'));
      });
      Sentry.captureMessage("Error while signing App "+localStorage.getItem('merchant_app_id')+" :- "+ err?.message || '');
    }
    
  }

  const handleCancelTrinityModal= ()=> {
    setSendToTrinityModalOpen(false)
    setBankAccountNoDummy('')
    setAlertCopyMsg('')
    setErrorMessage1('')
    setFedTaxIDDummy('')
    setConfirmBankAccountNoDummy('')
    setOwnerSectionSecureDataDummy(ownerSectionData.map((owner: any)=>{
      return {
        dl_Id: '',
        ssn: '',
        ssnInvalid:''
      }
    }))
  }


  const handleSaveApp = async (autoSave: boolean,event?: any, send_flag?: boolean, skip_appLink?: boolean, offline_app?: boolean) => {
    // event.preventDefault()
    // event.stopPropagation()

    const ownerSectionDataArr = ownerSectionData.map((item: any) => {
      return {
        ...item,
        dl_Id: "",
        ssn: ""
      }
    })
    let input = {
      merchant_app_id: merchantAppID,
      app_link_id: appLinkID,
      merchant_id: merchantID,
      parent_app_id: parentAppID,
      merchant_app_status: merchantAppStatus,
      partner_id: partnerId,
      partner_name: partnerName,
      partner_rm_id: partnerRmId,
      partner_rm_name: partnerRmName,
      merchant_name: business_name,
      merchant_email: business_email,
      offline_app_status: offline_app || offlineApp || false,
      app_data_sections: {
        pricing_details: {
          pricing_type: pricingType,
          card_type: pricingCardType
            .filter((item) => item.ischecked)
            .map((item) => item.value),
          annual_fee: annualFee,
          monthly_fee: monthlyFee,
          err_qual_discount: errQualDiscount,
          err_qual_per_item: errQualPerItem,
          err_non_discount: errNonDiscount,
          err_non_per_item: errNonPerItem,
          intamex_discount: intAmexDiscountRate,
          intamex_per_item: intAmexperItem,
          tiered_qual_discount: tieredQualDiscount,
          tiered_qual_per_item: tieredQualPerItem,
          tiered_mid_discount: tieredMidDiscount,
          tiered_mid_per_item: tieredMidPerItem,
          tiered_non_discount: tieredNonDiscount,
          tiered_non_per_item: tieredNonPerItem,
          tiered_debit_discount: tieredDebitDiscount,
          tiered_debit_per_item: tieredDebitPerItem,
          discount_rate: discountRate,
          per_item: perItem,
          debit_discount_rate: debitDiscountRate,
          debit_network_fees: debitNetworkFees,
          clym_data_privacy_compliance: clymDataPrivacyCompliance,
          clym_data_privacy_compliance_fee: clymDataPrivacyComplianceFee,
          amex_qual: amexQual,
          amex_per_item: amexPerItem,
          amex_mid_qual: amexMidQual,
          amex_mid_per_item: amexMidPerItem,
          amex_non_qual: amexNonQual,
          amex_non_per_item: amexNonPerItem,
          pin_debit: pinDebit,
          pin_debit_auth: pinDebitAuth,
          accept_ebt: acceptEbt,
          ebt: ebt,
          ebt_auth: ebtAuth,
          wireless_fee: wirelessFee,
          offer_amex: offerAmex,
          mc: mc,
          visa: visa,
          disc_auth: discAuth,
          amex_auth: amexAuth,
          avs: avs,
          batch_fee: batchFee,
          charge_back_fee: chargeBackFee,
          monthly_minimum: monthlyMinimum,
          early_termination_fee: earlyTerminationFee,
          account_reserve: accountReserve,
          other: other,
          billed: billed,
          voice_auth: voiceAuth,
          excessive_electronic_auth: excessiveElectronicAuth,
          irs_reporting_fee: irsReportingFee,
          retreival_fee: retreivalFee,
          invalid_tin_fee: invalidTinFee,
          ach_reject_fee: achRejectFee,
          collections_fee: collectionsFee,
          pci_validation: pciValidation,
          pci_non_compliance: pciNonCompliance,
          industry_compliance: industryCompliance,
          return_transaction_fee: returnTransactionFee,
          amex_optuble_support_fee: amexOptubleSupportFee,
          amex_optuble_network_fee: amexOptubleNetworkFee,
          debit_access_fee: riskLevel === RISK_LEVELS.LOW_RISK ? debitAccessFee : "",
          ticketSize: ticketSize,
          discountPercentage: discountPercentage,
          serviceFee: serviceFee,
          licenseTechFees: licenseTechFees
        },
        bank_section: {
          bank_routing_no: bankRoutingNo,
          bank_account_no_encrypted: "",
          bank_name: bankName,
          bank_phone: bankPhone,
          // bank_ach_method: selectedBankACHMethod
        },
        business_section: {
          retail_location: isRetailLocation,
          business_addr: businessAddress,
          business_location: businessLocation,
          other_business_location: otherBusinessLocation,
          number_of_locations: numberOfLocations,
          seasonal_merchant: isSeasonalMerchant,
          seasonal_merchant_months: seasonalMonth.filter((item) => item.ischecked).map((item) => item.value),
          is_exported: isExported,
          foreign_sales_pct: foreignSalesPct,
          own_inventory: isOwnInventory,
          vendor_names: vendorNames,
          inventory_stored_at_retail_location: isInventoryStoredatRetailLocation,
          inventory_location: inventoryLocation,
          sell_national_local: selectedSellNationalLocal,
          internet_sales_pct: internetSalesPct,
          telephone_sales_pct: telephoneSalesPct,
          retail_sales_pct: retailSalesPct,
          // mailorder_sales_pct: mailOrderSalesPct,
          billing_method: billingMethod.filter((item) => item.ischecked).map((item) => item.value),
          advertising_methods: advertisingMethods,
          sales_process_desc: salesProcessDesc,
          charge_customer_method: selectedChargeCustomerMethod,
          days_to_delivery: daysToDelivery,
          return_policy_desc: returnPolicyDesc,
          refund_policy_desc: refundPolicyDesc,
          prev_processor_terminated: isPrevProcessorTerminated,
          send_chargeback_addr_type: selectedSendChargeback_AddressType,
          business_fax: legalBusinessFax,
          fufillment_company_name: fulfillmentCompanyName,
          fufillment_company_name_free_form: fulfillmentCompanyNameFreeFormText,
          crm_company_name: crmCompanyName,
          crm_company_name_free_form: crmCompanyNameFreeFormText,
          chargeback_company_name: chargeBackCompanyName,
          chargeback_company_name_free_form: chargeBackCompanyNameFreeFormText,
          affliate_marketing_partner_name: affliateMarketingPartnerName,
          affliate_marketing_partner_name_free_form: affliateMarketingPartnerNameFreeFormText,
          call_center_name: callCenterName,
          rdr_preferred_vendor:rdrPreferredVendor,
          call_center_name_free_form: callCenterNameFreeFormText,
          business_type: businessType,
          goods_sold: selectedGoodsSold,
          encryption_method: encryptionMethod,
          oneTimePct: oneTimePct,
          monthlyPct: monthlyPct,
          quarterlyPct: quarterlyPct,
          yearlyPct: yearlyPct,
          hourlyPct: "0",
          sell_product: sellProduct,
          sell_service: sellService
        },
        dba_section: {
          use_legal_address: useLegalAddress,
          dba_name: dbaName,
          dba_addr_1: dbaAddress,
          dba_addr_city: dbaCity,
          dba_addr_state: selectedDBAState,
          dba_addr_postalcode: dbaZipCode,
          business_email: dbaBusinessEmail,
          cust_service_phone: dbaCustomerServicePhone,
          website_url: dbaWebsiteURL,
          multiple_locations: selectedDBAMultipleLocationOption
        },
        equipment_section: equipmentSectionData,
        legal_section: {         
          legal_name: legalName,
          legal_addr_1: legalAddress,
          legal_addr_city: legalAddressCity,
          legal_addr_state: selectedLegalAddressState,
          legal_addr_postalcode: legalAddressPostalCode,
          business_contact: legalBusinessContact,
          business_contact_title: legalBusinessContactTitle,
          business_phone: legalBusinessPhone,
          business_fax: legalBusinessFax,
          fed_tax_id_encrypted: "",
          tax_type: selectedTaxType,
          tax_filing_name: taxFilingName,
          business_start_date: businessStartDate,
          length_of_ownership: lengthOfOwnership,
          legal_entity_type: selectedLegalEntityType,
          state_incorporated: selectedStateIncorporated,
          prior_security_breach: isPriorSecurityBreach
        },
        owner_section: ownerSectionDataArr,
        transaction_section: {
          month_avg_sales: monthAvgSales,
          amex_month_avg_sales: amexMonthAvgSales,
          highest_ticket_amt: highestTicketAmount,
          avg_ticket_amt: avgTicketAmount,
          card_types_accepted: cardValue,
          amex_type: selectedAMEXType,
          existing_amex_no: existingAMEXno,
          public_sales_pct: publicSalesPCT,
          business_trans_pct: businessTransPCT
        },
        misc_section: {
          is_cbd_form_downloaded: isCBDFormDownloaded,
          is_mc_form_downloaded: isMcFormDownloaded,
          is_esquire_form_downloaded: isEsquireFormDownloaded,
        }
      },
      merchant_prescreen: {
        business_conducted: businessConduct,
        business_category: businessCategory,
        business_category_free_form: businessCategoryFreeFormText,
        business_type_free_form: businessTypeFreeFormText,
        business_type: businessType,
        goods_sold: sellGoodValue,
        physical_goods_type: conditionalSellGood,
        price: riskLevel,
        processor_name: selectedBankOption,
        sales_frequency: selectedSellTime
      },
      merchant_edit_flag: merchantEditFlag,
      merchant_send_mail_flag: sendToMerchantFlag,
      optional_message: optionalMessage,
      free_equipment_placement_enabled: freeEquipmentPlacementForMerchant
    }

    sessionStorage.setItem("riskLevel", riskLevel)
    sessionStorage.setItem("partnerId", partnerId)
    sessionStorage.setItem("partnerRmId", partnerRmId)
    sessionStorage.setItem('partnerRmName', partnerRmName)

    const data: any = {
      payLoad: input
    }
    let tokenResponse;
    if (isEditFlow) {
      if (role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER ||  role === ROLES.SALES_LIMITED) {
        data.api = api.merchantAppData.updateMerchantApp
      } else if (history.location.pathname.includes('/MA')) {
        data.api = api.merchant.updateMerchantApp
      } else if (history.location.pathname.includes('/AL')) {
        const tokenData: any = {

          api: api.merchant.getAccessToken,
          payLoad: JSON.stringify({
            merchant_name: business_name,
            merchant_email: business_email,
            tenantId: tenantId
          })
        }
        tokenResponse = getLink ? await backend.save(tokenData, true) : await backend.save(tokenData)
        data.api = api.merchant.createMerchantApp
      }
    } else {
      if (role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER|| role === ROLES.SALES_LIMITED) {
        data.api = api.merchantAppData.saveMerchantApp
      }
    }
    try {
      if(autoSave){
        setLoading(false)
      }
      else{
        setLoading(true)
      }
      setSignModalOpen(false)
      let response;
      if (history.location.pathname.includes('/AL')) {
        const decodedToken: any = await getDecodedToken(tokenResponse)

        const customInput = {
          ...input,
          app_link_id: appLinkID,
          merchant_id: decodedToken.uid
        }

        const encryptionData = await getEncryptedDataJson()
        await setEncryptionDataToSession(encryptionData)

        data.payLoad = JSON.stringify(customInput)
        
        response = await backend.saveAppLink(data, tokenResponse)

        localStorage.setItem('merchant_app_id', response?.merchant_app_id)
        const payload = {
          to_email: business_email,
          body: userMessage || '',
          merchant_app_id: response.merchant_app_id,
          dba_name: dbaName
        }
        payload.merchant_app_id = response.merchant_app_id

        auth.tenantId = tenantId
    
        const user = await signInWithCustomToken(auth, tokenResponse)
        console.log('user', user)

        const token = await user.user.getIdToken()
        console.log('token', token)

        const sendData: any = {}
        sendData.api = api.integrations.sendMail
        sendData.payLoad = { ...payload }
        const sendMailResponse = await backend.saveAppLink(sendData, token)
        if (!skip_appLink) {
          setAppLink(sendMailResponse.merchant_link);
          setShowAppLinkModal(true);
          sessionStorage.setItem("showWelcomModal", "false");
        } else {
          appLinkMerchantLogin(sendMailResponse.merchant_link)
        }
      }
      else {
        if (saveFlag) { // if the app already saved then call update API
          if ((role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.PARTNER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER|| role === ROLES.SALES_LIMITED)) {
            data.api = api.merchantAppData.updateMerchantApp;
          } else {
            data.api = api.merchant.updateMerchantApp
          }
          data.payLoad = JSON.stringify({
            ...data.payLoad,
            merchant_app_id: merchantAppID || saveMerchantAppId,
            merchant_id: merchantID || saveMerchantId,
            merchant_app_status: merchantAppStatus || saveMerchantAppStatus,
            updated_by_user_id: user_id || saveUpdatedByUserId
          });
        }
        response = await backend.save(data,true);
        setSaveMerchantAppId(response?.merchant_app_id || saveMerchantAppId);
        setSaveMerchantId(response?.merchant_id || saveMerchantId);
        setSaveMerchantAppStatus(response?.merchant_app_status || saveMerchantAppStatus);
        setSaveUpdatedByUserId(response?.updated_by_user_id || saveUpdatedByUserId);
        setSaveFlag(true); // this flag indicates that already merchantApp is saved

        if (isEditFlow && (role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED)) {
          setMerchantEditFlag(true);
          setShowSensitiveFields(false);
        }
      }
      if (send_flag) {
        // This is a very bad fix, both keeping the send flag and putting these many `||` fix it in stage 2 - SarojK
        const applicationID = merchantAppID || saveMerchantAppId || response?.merchant_app_id
        const payload = {
          to_email: business_email,
          body: userMessage || '',
          merchant_app_id: applicationID,
          dba_name:dbaName
        }
        const sendData: any = {}
        sendData.api = api.integrations.sendMail
        sendData.payLoad = { ...payload }
        setLoading(false)
        setShowProgress(true)
        showOverlay()
        setProgress(2)
        setOverlaymsg("We’re saving your application details. Please wait, and don’t refresh or close the page!");
        setTimeout(() => {
          setProgress(30)
          setTimeout(() => {
            setProgress(50);
            setOverlaymsg("We’re working on the email! It’s almost ready to go. Please wait, and don’t refresh or close the page!");
            setTimeout(() => {
              setProgress(70);
            }, 3000);

          }, 4500);
        }, 2000);

        await backend.save(sendData);
        setProgress(100);
        await new Promise(resolve => setTimeout(resolve, 500));
        setShowProgress(false)
        setSentMailModalOpen(true)
        setUserMessage('')
      }
      setLoading(false)
     if(!autoSave) {
      showOverlayForThreeSeconds()
      setOverlaymsg("Data saved successfully")
       }
      if(autoSave){
        openNotification()
      }
      // showOverlayForThreeSeconds()
      // setOverlaymsg('Data saved successfully')
    } catch (err: any) {
      let message = err.errMessage || 'Error occured while saving'
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg(message)
      Sentry.captureMessage("Error while saving merchant app : "+message);
    }
    setAutoSaveTimer(0)
  }
  const clearBusinessFormSection = () => {
    // business_section
    setSelectedGoodsSold('')
    setIsSeasonalMerchant('')
    setIsExported('')
    setForeignSalesPct('')
    setIsRetailLocation('')
    setBusinessAddress('')
    setBusinessLocation('')
    setOtherBusinessLocation('')
    setNumberOfLocations('')
    seasonalMonth.forEach((item: any) => {
      item.ischecked = false
    })
    setSeasonalMonth(seasonalMonth)
    billingmethod.forEach((item: any) => {
      item.ischecked = false
    })
    setBillingMethod(billingmethod)
    setOneTimePct('')
    setMonthlyPct('')
    setQuarterlyPct('')
    setYearlyPct('')
    setAdvertisingMethods('')
    setSalesProcessDesc('')
    setSelectedChargeCustomerMethod('')
    setDaysToDelivery('')
    setReturnPolicyDesc('')
    setRefundPolicyDesc('')
    setSelectedSendChargeback_AddressType('')
    //setFax('')
    setLegalBusinessFax('')
    setIsOwnInventory('')
    setVendorNames('')
    setIsInventoryStoredatRetailLocation('')
    setInventoryLocation('')
    setSelectedSellNationalLocal('')
    setInternetSalesPct('')
    setEncryptionMethod('')
    setTelephoneSalesPct('')
    setRetailSalesPct('')
    // setMailOrderSalesPct('')
    setIsPrevProcessorTerminated('')
    setFulfillmentCompanyName('')
    setFulfillmentCompanyNameFreeFormText('')
    setCrmCompanyName('')
    setCrmCompanyNameFreeFormText('')
    setChargeBackCompanyName('')
    setChargeBackCompanyNameFreeFormText('')
    setAffliateMarketingPartnerName('')
    setAffliateMarketingPartnerNameFreeFormText('')
    setCallCenterName('')
    setCallCenterNameFreeFormText('')
  }
  const clearForm = () => {
    // merchantinfo
    setBusinessName('')
    setBusinessEmail('')
    setBusinessContact('')
    // merchant_prescreen
    setBusinessConduct([])
    setSellTypeValue('')
    setConditionalSellType('')
    setBusinessCategory('')
    setBusinessType('')
    setRiskLevel('')
    setSelectedBankOption('')
    setSelectedSellTime('')
    // Pricing_section
    pricingCardType.forEach((item: any) => {
      item.ischecked = false
    })
    setPricingCardType(pricingCardType)
    setPricingType('')
    setAnnualFee('')
    setMonthlyFee('')
    setErrQualDiscount('')
    setErrQualPerItem('')
    setErrNonDiscount('')
    setErrNonPerItem('')
    setIntAmexDiscountRate('')
    setIntAmexperItem('')

    setTieredQualDiscount('')
    setTieredQualPerItem('')
    setTieredMidDiscount('')
    setTieredMidPerItem('')
    setTieredNonDiscount('')
    setTieredNonPerItem('')
    setTieredDebitDiscount('')
    setTieredDebitPerItem('')
    setDiscountRate('')
    setPerItem('')
    setDebitDiscountRate('')
    setDebitNetworkFees('Yes')
    setClymDataPrivacyCompliance('Yes')
    setClymDataPrivacyComplianceFee('20')
    setAmexQual('')
    setAmexPerItem('')
    setAmexMidQual('')
    setAmexMidPerItem('')
    setAmexNonQual('')
    setAmexNonPerItem('')
    setPinDebit('')
    setPinDebitAuth('')
    setAcceptEbt('')
    setEbt('')
    setEbtAuth('')
    setWirelessFee('')
    setOfferAmex('')
    setMc('')
    setVisa('')
    setDiscAuth('')
    setAmexAuth('')
    setAvs('')
    setBatchFee('')
    setChargeBackFee('')
    setMonthlyMinimum('')
    setEarlyTerminationFee('')
    setAccountReserve('')
    setOther('')
    setBilled('')    
    setVoiceAuth('1.96')
    setExcessiveElectronicAuth('0.10')
    setIrsReportingFee('4.95')
    setRetreivalFee('20')
    setInvalidTinFee('19.95')
    setAchRejectFee(selectedBankOption === BANK_CODES.DART ? '25' : '30')
    setCollectionsFee('20')
    setPciValidation('119.99')
    setPciNonCompliance((selectedBankOption === BANK_CODES.CFSB || selectedBankOption === BANK_CODES.CFSB_CD) ? otherFees_cfsb.pciNonCompliance : otherFees.pciNonCompliance)
    setIndustryCompliance('9.95')
    setReturnTransactionFee('0.10')
    setAmexOptubleSupportFee('0.50')
    setAmexOptubleNetworkFee('0.15')
    setDebitAccessFee('3.95')
    // bank_section
    setBankRoutingNo('')
    setBankAccountNo('')
    setBankName('')
    setBankPhone('')
    // setSelectedBankACHMethod('')

    // business section 
    clearBusinessFormSection()

    // dba_section
    setDBAName('')
    setDBAAddress('')
    setDBACity('')
    setSelectedDBAState('')
    setDBAZipCode('')
    setDBABusinessEmail('')
    setDBACustomerServicePhone('')
    setDBAWebsiteURL('')
    setSelectedDBAMultipleLocationOption('')
    // equipment_section
    setEquipmentSectionData([equip_default_obj])
    // legal_section
    setLegalName('')
    setLegalAddress('')
    setLegalAddressCity('')
    setSelectedLegalAddressState('')
    setLegalAddressPostalCode('')
    setLegalBusinessContact('')
    setLegalBusinessContactTitle('')
    setLegalBusinessPhone('')
    setLegalBusinessFax('')
    setFedTaxID('')
    setFedTaxIdValid(false)
    setSelectedTaxType('')
    setTaxFilingName('')
    setBusinessStartDate(null)
    setLengthOfOwnership('')
    setLengthOfOwnershipYears('')
    setSelectedLegalEntityType('')
    setApplicationType('')
    setSelectedStateIncorporated('')
    setIsPriorSecurityBreach('')
    // transaction_section
    setMonthAvgSales('')
    setAmexMonthAvgSales('')
    setHighestTicketAmount('')
    setAvgTicketAmount('')
    setSelectedAMEXType('')
    setExistingAMEXno('')
    setPublicSalesPCT('')
    setBusinessTransPCT('')
    setOptionalMessage('')
  }
  //Confirm Alerts
  const handleCancelConfirm = async () => {
    setCancelAlertOpen(false);
    clearForm();
    if (isEditFlow) {
      try {
        const data = {
          api: api.merchant.updateMerchantAppMerchantEditFlag,
          payLoad: JSON.stringify({ merchant_app_id: merchantAppID, merchant_edit_flag: true })
        }

        setLoading(true)
        const response = await backend.save(data)
        setLoading(false)

      } catch (err: any) {
        const msg = 'Error while updating merchant edit flag!'
        console.error(msg)
        if (err.errMessage) {
          setErrorMessage(err.errMessage)
        } else {
          setErrorMessage(msg)
        }
      }
      setShowWelcomModal(false);
      window.history.back();
    }
  }
  const handleCancelCancel = async () => {
    try {
      setCancelAlertOpen(false);
      if (role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) {
        setPartnerName(partnerName)
        setPartnerId(partnerId)
        setPartnerRmName(partnerRmName)
        setPartnerRmId(partnerRmId)
      }
      setBusinessName(business_name)
      setBusinessEmail(business_email)
      setBusinessContact(business_contact)
      // merchant_prescreen
      setBusinessConduct(businessConduct)
      setSellTypeValue(sellTypeValue)
      setBusinessCategory(businessCategory)
      setBusinessType(businessType)
      setRiskLevel(riskLevel)
      setSellGoodValue(sellGoodValue)
      setConditionalSellGood(conditionalSellGood)
      setSelectedBankOption(selectedBankOption)
      setSelectedSellTime(selectedSellTime)
      // Pricing_Section
      setPricingType(pricingType)
      setPricingCardType(pricingCardType)
      setMonthlyFee(monthlyFee)
      setAnnualFee(annualFee)
      setErrQualDiscount(errQualDiscount)
      setErrQualPerItem(errQualPerItem)
      setErrNonDiscount(errNonDiscount)
      setErrNonPerItem(errNonPerItem)
      setIntAmexDiscountRate(intAmexDiscountRate)
      setIntAmexperItem(intAmexperItem)

      setTieredQualDiscount(tieredQualDiscount)
      setTieredQualPerItem(tieredQualPerItem)
      setTieredMidDiscount(tieredMidDiscount)
      setTieredMidPerItem(tieredMidPerItem)
      setTieredNonDiscount(tieredNonDiscount)
      setTieredNonPerItem(tieredNonPerItem)
      setTieredDebitDiscount(tieredDebitDiscount)
      setTieredDebitPerItem(tieredDebitPerItem)
      setDiscountRate(discountRate)
      setPerItem(perItem)
      setDebitDiscountRate(debitDiscountRate)
      setDebitNetworkFees(debitNetworkFees)
      setClymDataPrivacyCompliance(clymDataPrivacyCompliance)
      setClymDataPrivacyComplianceFee(clymDataPrivacyComplianceFee)
      setAmexQual(amexQual)
      setAmexPerItem(amexPerItem)
      setAmexMidQual(amexMidQual)
      setAmexMidPerItem(amexMidPerItem)
      setAmexNonQual(amexNonQual)
      setAmexNonPerItem(amexNonPerItem)
      setPinDebit(pinDebit)
      setPinDebitAuth(pinDebitAuth)
      setAcceptEbt(acceptEbt)
      setEbt(ebt)
      setEbtAuth(ebtAuth)
      setWirelessFee(wirelessFee)
      setOfferAmex(offerAmex)
      setMc(mc)
      setVisa(visa)
      setDiscAuth(discAuth)
      setAmexAuth(amexAuth)
      setAvs(avs)
      setBatchFee(batchFee)
      setChargeBackFee(chargeBackFee)
      setMonthlyMinimum(monthlyMinimum)
      setEarlyTerminationFee(earlyTerminationFee)
      setAccountReserve(accountReserve)
      setOther(other)
      setBilled(billed)
      setVoiceAuth(voiceAuth)
      setExcessiveElectronicAuth(excessiveElectronicAuth)
      setIrsReportingFee(irsReportingFee)
      setRetreivalFee(retreivalFee)
      setInvalidTinFee(invalidTinFee)
      setAchRejectFee(selectedBankOption === BANK_CODES.DART ? '25' : achRejectFee || '30')
      setCollectionsFee(collectionsFee)
      setPciValidation(pciValidation)
      setPciNonCompliance(pciNonCompliance)
      setIndustryCompliance(industryCompliance)
      setReturnTransactionFee(returnTransactionFee)
      setAmexOptubleSupportFee(amexOptubleSupportFee)
      setAmexOptubleNetworkFee(amexOptubleNetworkFee)
      setDebitAccessFee(debitAccessFee)
      // bank_section
      setBankRoutingNo(bankRoutingNo)
      setBankAccountNo(bankAccountNo)
      setBankName(bankName)
      setBankPhone(bankPhone)
      // setSelectedBankACHMethod(selectedBankACHMethod)

      // business_section
      setSelectedGoodsSold(selectedGoodsSold)
      setIsSeasonalMerchant(isSeasonalMerchant)
      setIsExported(isExported)
      setForeignSalesPct(foreignSalesPct)
      setIsRetailLocation(isRetailLocation)
      setBusinessAddress(businessAddress)
      setSelectedSendChargeback_AddressType(selectedSendChargeback_AddressType)
      //setFax(fax)
      setLegalBusinessFax(legalBusinessFax)
      setIsOwnInventory(isOwnInventory)
      setVendorNames(vendorNames)
      setIsInventoryStoredatRetailLocation(isInventoryStoredatRetailLocation)
      setInventoryLocation(inventoryLocation)
      setSelectedSellNationalLocal(selectedSellNationalLocal)
      setInternetSalesPct(internetSalesPct)
      setEncryptionMethod(encryptionMethod)
      setTelephoneSalesPct(telephoneSalesPct)
      setRetailSalesPct(retailSalesPct)
      // setMailOrderSalesPct(mailOrderSalesPct)
      setIsPrevProcessorTerminated(isPrevProcessorTerminated)
      setFulfillmentCompanyName(fulfillmentCompanyName)
      setCrmCompanyName(crmCompanyName)
      setChargeBackCompanyName(chargeBackCompanyName)
      setAffliateMarketingPartnerName(affliateMarketingPartnerName)
      setCallCenterName(callCenterName)
      setBillingMethod(billingmethod)
      setAdvertisingMethods(advertisingMethods)
      setSalesProcessDesc(salesProcessDesc)
      setSelectedChargeCustomerMethod(selectedChargeCustomerMethod)
      setDaysToDelivery(daysToDelivery)
      setReturnPolicyDesc(returnPolicyDesc)
      setRefundPolicyDesc(refundPolicyDesc)
      // dba_section
      setDBAName(dbaName)
      setDBAAddress(dbaAddress)
      setDBACity(dbaCity)
      setSelectedDBAState(selectedDBAState)
      setDBAZipCode(dbaZipCode)
      if(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(dbaBusinessEmail))
      setDbaBusinessEmailValid(true)
      else
      setDbaBusinessEmailValid(false)
      setDBABusinessEmail(dbaBusinessEmail)
      setDBACustomerServicePhone(dbaCustomerServicePhone)
      setDBAWebsiteURL(dbaWebsiteURL)
      setSelectedDBAMultipleLocationOption(selectedDBAMultipleLocationOption)
      // equipment_section
      setEquipmentTypeValue(equipmentTypeValue)
      setEquipmentOrderValue(equipmentOrderValue)
      setEquipmentQuantityValue(equipmentQuantityValue)
      setExistingEquipmentName(existingEquipmentName)
      setSelectedNewEquipmentName(selectedNewEquipmentName)
      setIsTipOptionNeeded(isTipOptionNeeded)
      setSelectedConnectionType(selectedConnectionType)
      setIsEBT(isEBT)
      setIsPINDebitRequired(isPINDebitRequired)
      setSelectedEquipmentMailingAddress(selectedEquipmentMailingAddress)
      // legal_section
      setLegalName(legalName)
      setLegalAddress(legalAddress)
      setLegalAddressCity(legalAddressCity)
      setSelectedLegalAddressState(selectedLegalAddressState)
      setLegalAddressPostalCode(legalAddressPostalCode)
      setLegalBusinessContact(legalBusinessContact)
      setLegalBusinessContactTitle(legalBusinessContactTitle)
      setLegalBusinessPhone(legalBusinessPhone)
      setLegalBusinessFax(legalBusinessFax)
      setFedTaxID(fedTaxID)
      setFedTaxIdValid(validateFedTaxId(fedTaxID))
      setSelectedTaxType(selectedTaxType)
      setTaxFilingName(taxFilingName)
      setBusinessStartDate(businessStartDate)
      setLengthOfOwnership(lengthOfOwnership)
      setSelectedLegalEntityType(selectedLegalEntityType)
      setApplicationType(applicationType)
      setSelectedStateIncorporated(selectedStateIncorporated)
      setIsPriorSecurityBreach(isPriorSecurityBreach)
      // owner_section
      // transaction_section
      setMonthAvgSales(monthAvgSales)
      setAmexMonthAvgSales(amexMonthAvgSales)
      setHighestTicketAmount(highestTicketAmount)
      setAvgTicketAmount(avgTicketAmount)
      // setCardType(cardtype)
      setSelectedAMEXType(selectedAMEXType)
      setExistingAMEXno(existingAMEXno)
      setPublicSalesPCT(publicSalesPCT)
      setBusinessTransPCT(businessTransPCT)
      setOptionalMessage(optionalMessage)
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getEncryptedDataJson = async () => {
    let encryptionData = {
      bank_account_no_encrypted: bankAccountNo,
      fed_tax_id_encrypted: fedTaxID,
      owner_section: [] as any[]
    }

    ownerSectionData.forEach(item => {
      encryptionData.owner_section.push({
        dl_Id: item.dl_Id,
        ssn: item.ssn
      })
    })

    return encryptionData
  }

  const setEncryptionDataToSession = async (encryptionData: any) => {
    // session encryption data - sed
    let sed = {
      ban: encryptionData.bank_account_no_encrypted,
      fti: encryptionData.fed_tax_id_encrypted,
      os: [] as any[]
    }

    ownerSectionData.forEach(item => {
      sed.os.push({
        di: item.dl_Id,
        sn: item.ssn
      })
    })

    sessionStorage.setItem("encryptedData", encrypt(sed))
  }

  const getEncryptionDataFromSession = async (encryptedData: any) => {
    const sed = decrypt(encryptedData)

    // session encryption data - sed
    let encryptedDataJson = {
      bank_account_no_encrypted: sed.ban,
      fed_tax_id_encrypted: sed.fti,
      owner_section: [] as any[]
    }

    sed.os.forEach((item: any) => {
      encryptedDataJson.owner_section.push({
        dl_Id: item.di,
        ssn: item.sn
      })
    })

    return encryptedDataJson
  }

  const handleSignApp = async (event: any) => {
    try{
      setLoading(true)
      await handleSaveApp(false, event, false)
      setLoading(true)
      const accessTokenData = {
        api: api.merchant.getDocusignAccessToken
      }
      const response = await backend.fetch(accessTokenData)
      const merchant_app_id = localStorage.getItem('merchant_app_id')
      const dsCode = response.access_token
  
      const encryptionData = await getEncryptedDataJson()
  
      const input = {
        merchant_app_id: merchant_app_id,
        access_token: dsCode,
        encryption_data: encryptionData,
        tenantId
      }
  
      const data: any = {
        payLoad: JSON.stringify(input),
        api: api.merchant.embeddSignMerchantApp
      }
  
      await setEncryptionDataToSession(encryptionData)
  
      const result = await backend.save(data, false)
  
      if (result) {
        const signingUrl = result.redirectUrl
        window.location.href = signingUrl
      }
    }catch(err: any){
      setLoading(false)
      showOverlayForThreeSeconds()
      setOverlaymsg(err.message || 'Error occured while signing')
      console.log("Error while siging app",err)
      Sentry.configureScope(function (scope) {
        scope.setTag("APP_ID", localStorage.getItem('merchant_app_id'));
      });
      Sentry.captureMessage("Error while signing App "+localStorage.getItem('merchant_app_id')+" :- "+ err?.message || '');  
    }
    
  }

  const handleAppLinkSign = async (event: any) => {
    try{
      setLoading(true)

      await handleSaveApp(false, event, false, true)

      const accessTokenData = {
        api: api.merchant.getDocusignAccessToken
      }
      const response = await backend.fetchUnprotected(accessTokenData)
      const merchant_app_id = localStorage.getItem('merchant_app_id')
      const dsCode = response.access_token

      const encryptionData = await getEncryptedDataJson()

      const input = {
        merchant_app_id: merchant_app_id,
        access_token: dsCode,
        encryption_data: encryptionData,
        tenantId
      }

      const data: any = {
        payLoad: JSON.stringify(input),
        api: api.merchant.embeddSignMerchantApp
      }

      await setEncryptionDataToSession(encryptionData)

      const result = await backend.save(data, false)
      
      if (result) {
        const signingUrl = result.redirectUrl
        window.location.href = signingUrl
      }
    }catch(err: any){
      setLoading(false)
      console.log("Error while siging app",err)
      setOverlaymsg(err.message || 'Error occured while signing')
      showOverlayForThreeSeconds()
      Sentry.configureScope(function (scope) {
        scope.setTag("APP_ID", localStorage.getItem('merchant_app_id'));
      });
      Sentry.captureMessage("Error while signing App "+localStorage.getItem('merchant_app_id')+" :- "+ err?.message || ''); 
    }
  }

  //Toggle eye icon for DL/ID
  const toggleDlIdVisiblity = (index: any) => {
    setDlIdShown((prevDlIdShown: any) => ({
      ...prevDlIdShown,
      [index]: !prevDlIdShown[index]
    }));
    setEyeIcondl((prevEyeIcon: any) => ({
      ...prevEyeIcon,
      [index]: !prevEyeIcon[index]
    }));
  }
  //Toggle eye icon for SSN
  const togglessnVisiblity = (index: any) => {
    setssnShown((prevssnShown: any) => ({
      ...prevssnShown,
      [index]: !prevssnShown[index]
    }));
    setEyeIconssn((prevEyeIcon: any) => ({
      ...prevEyeIcon,
      [index]: !prevEyeIcon[index]
    }));
  }
  //Toggle eye icon for FedexTaxId
  const togglefedexTaxIdVisiblity = () => {
    setEyeIconFedexTaxId(!eyeIconFedexTaxId)
    setFedexTaxIdShown(!fedexTaxIdShown)
  }

  //Bank account no match check
  const handleConfirmBankAccountNo = (event: any) => {
    if(event.target.value === " "){
    setConfirmBankAccountNo("")
    }
    else
    setConfirmBankAccountNo(event.target.value);
    let confirmAccountNoValue = event.target.value.replace(" ","")
    setConfirmBankAccountNo(event.target.value.replace(" ",""));
    setAlertCopyMsg("");
    setErrorMessage1("");
    if ((bankAccountNo === confirmAccountNoValue) || bankAccountNo === "") {
      setErrorMessage1("");
    } else if(bankAccountNo !== confirmAccountNoValue){
      setSuccessMessage1("");
      setErrorMessage1("Bank account numbers do not match. Please confirm your bank account number.");
    }
  };

  const getIsMerchant = () => {
    if (localStorage.getItem('merchant_login') === 'merchant_login') {
      return false;
    }

    return true;
  }

  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  }

  const getSignButtonStatus = () => {
    if (preScreeningSectionStatus === "filled" &&
      legalSectionStatus === "filled" &&
      dbaSectionStatus === "filled" &&
      ownerSectionStatus === "filled" &&
      transactionSectionStatus === "filled" && 
      equipmentSectionStatus === "filled" &&
      ((businessConduct.length === 0 || businessConduct.includes('Mail Order/Tel') || businessConduct.includes('E-Commerce')) ? businessSectionStatus === "filled" : true) && !!business_name && (!!business_email && email_pattern.test(business_email)) && !!dbaName
      
    ) {
      scrollToBottom();
      return false;
    }
    return true;
  }

  useEffect(() => {
    setSignButtonDisableStatus(getSignButtonStatus())
  }, [preScreeningSectionStatus, legalSectionStatus, dbaSectionStatus, ownerSectionStatus, transactionSectionStatus, businessSectionStatus,equipmentSectionStatus, business_email, business_name, dbaName])

  const getTrinityButtonStatus = () => {
    if (dbaName && (business_email && email_pattern.test(business_email)) && business_name && partnerId && (partnerRm.length > 0? !!partnerRmId : true) &&
        preScreeningSectionStatus === "filled" &&
        legalTrinitySectionStatus === "filled" &&
        dbaTrinitySectionStatus === "filled" &&
        ownerTrinitySectionStatus === "filled" &&
        pricingSectionStatus === "filled" &&
        transactionSectionStatus === "filled" &&
        ((businessConduct.length === 0 || businessConduct.includes('Mail Order/Tel') || businessConduct.includes('E-Commerce')) ? businessSectionStatus === "filled" : true)
    ) {
      scrollToBottom();
      return false;
    }
    return true;
  }

  useEffect(() => {
    setsendToTrinityFlag(getTrinityButtonStatus())
  }, [preScreeningSectionStatus, pricingSectionStatus, legalTrinitySectionStatus, dbaTrinitySectionStatus, ownerTrinitySectionStatus, transactionSectionStatus, businessSectionStatus,
    dbaName,business_email,business_name,partnerId, partnerRmId])

  // prescreening section status
  const updatePreScreeningSectionStatus = () => {
    const businessCategoryFilled = businessCategory.toLocaleLowerCase() === 'other' ? businessCategoryFreeFormText : businessCategory
    const businessTypeFilled = businessType.toLocaleLowerCase() === 'other' ? businessTypeFreeFormText : businessType
    if (!!businessConduct && !!businessCategoryFilled && !!businessTypeFilled && showRiskLevels.includes(riskLevel) && !!riskLevel && !!selectedBankOption && !!selectedSellTime && (isRetail || isMailOrder || isEcommerce)) {
      setPreScreeningSectionStatus('filled')
      if (dbaName && (business_email && email_pattern.test(business_email)) && business_name && pricingSectionStatus === 'filled' && partnerId && partnerRm && (partnerRm.length > 0? !!partnerRmId : true)) {
        setSendToMerchantFlag(true);
      }
    } else {
      setPreScreeningSectionStatus('')
      setSendToMerchantFlag(false);
    }
  }

  const updateEarlyterminationFee = () =>{
    if (!!earlyTerminationFee) {      
      setManualTerminationFee(earlyTerminationFee)
    }
  }
  useEffect(() => {
    updatePreScreeningSectionStatus()
    setSignButtonDisableStatus(getSignButtonStatus())
  }, [sellTypeValue, businessCategory, businessType, riskLevel, selectedBankOption, selectedSellTime, businessCategoryFreeFormText, businessTypeFreeFormText,
    isRetail, isMailOrder, isEcommerce, partnerId, partnerRmId])

  // pricing section status
  const updatePricingSectionStatus = () => {
    const tieredQualDiscountFilled = pricingType === 'Tiered' && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover')) ? !!tieredQualDiscount && !!tieredQualPerItem : true
    const tieredMidDiscountFilled = pricingType === 'Tiered' && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover')) ? !!tieredMidDiscount : true
    const tieredNonDiscountFilled = pricingType === 'Tiered' && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover')) ? !!tieredNonDiscount : true
    const tieredDebitDiscountFilled = pricingType === 'Tiered' && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover')) ? !!tieredDebitDiscount && !!tieredDebitPerItem : true
    const amexQualFilled = pricingType === 'Tiered' && pricingCardValue.includes('AMEX') ? !!amexQual && !!amexPerItem : true
    const amexMidQualFilled = pricingType === 'Tiered' && pricingCardValue.includes('AMEX') ? !!amexMidQual && !!amexMidPerItem : true
    const amexNonQualFilled = pricingType === 'Tiered' && pricingCardValue.includes('AMEX') ? !!amexNonQual && !!amexNonPerItem : true
    const errQualDiscountFilled = pricingType === 'Err' && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover')) ? !!errQualDiscount && !!errQualPerItem : true
    const errNonDiscountFilled = pricingType === 'Err' && (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover')) ? !!errNonDiscount && !!errNonPerItem : true
    const intAmexDiscountRateFilled = (pricingType === 'Err' || pricingType === 'Interchange' || pricingType === 'Flat Rate') ? pricingCardValue.includes('AMEX') ? !!intAmexDiscountRate && !!intAmexperItem : true : true
    const discountRateFilled = pricingType === 'Interchange' || pricingType === 'Flat Rate' ? (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover')) ? !!discountRate && !!perItem : true : true
    const debitDiscountRateFilled = pricingType === 'Interchange' ? (pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover')) ? !!debitDiscountRate : true : true
    const pinDebitAuthFilled = pinDebit === 'Yes' ? !!pinDebitAuth : true
    const ebtAuthFilled = acceptEbt === 'Yes' ? !!ebtAuth : true
    const accountReserveFilled = businessConduct.includes('E-Commerce') && riskLevel === RISK_LEVELS.HIGH_RISK ? !!accountReserve : true
    const debitAccessFeeFilled = riskLevel === RISK_LEVELS.LOW_RISK && pinDebit === "Yes" ? !!debitAccessFee : true
    const amexOptubleSupportFeeFilled = pricingCardValue.includes('AMEX') ? !!amexOptubleSupportFee : true
    const amexOptubleNetworkFeeFilled = pricingCardValue.includes('AMEX') ? !!amexOptubleNetworkFee : true
    const ebtFilled = riskLevel === RISK_LEVELS.LOW_RISK && acceptEbt === "Yes" ? !!ebt : true
    const mcFilled = pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover') ? !!mc : true
    const amexAuthFilled = pricingCardValue.includes('AMEX') ? !!amexAuth : true

    if (!!pricingType && (pricingCardValue.length > 0) && (!!tieredQualDiscountFilled && !!tieredMidDiscountFilled && !!tieredNonDiscountFilled && !!tieredDebitDiscountFilled)
      && (!!intAmexDiscountRateFilled)
      && (!!amexQualFilled && !!amexMidQualFilled && !!amexNonQualFilled)
      && (!!errQualDiscountFilled && !!errNonDiscountFilled && !!discountRateFilled && !!debitDiscountRateFilled) &&
      ((!!pinDebit && !!pinDebitAuthFilled && !!acceptEbt && !!ebtAuthFilled) || riskLevel === RISK_LEVELS.HIGH_RISK)
      && (!!annualFee && !!monthlyFee && !!chargeBackFee && !!monthlyMinimum && !!earlyTerminationFee)
      && (accountReserveFilled && debitAccessFeeFilled && amexOptubleSupportFeeFilled && amexOptubleNetworkFeeFilled && !!voiceAuth && !!excessiveElectronicAuth && !!irsReportingFee && !!retreivalFee && !!invalidTinFee && !!achRejectFee && !!collectionsFee && !!pciValidation && !!returnTransactionFee && !!pciNonCompliance && !!industryCompliance)
      && (!!avs && !!batchFee && ebtFilled && mcFilled && amexAuthFilled && !pciError && !chargeBackError)
    ) {
      setPricingSectionStatus('filled')
      if (dbaName && (business_email && email_pattern.test(business_email)) && business_name && preScreeningSectionStatus === 'filled' && partnerId && partnerRm && (partnerRm.length > 0? !!partnerRmId : true)) {
        setSendToMerchantFlag(true);
      }
    } else if (selectedSellTime === 'Cash Discount' && !!pricingType && (pricingCardValue.length > 0) && !!ticketSize && (!!discountPercentage || !!serviceFee) 
      && !!licenseTechFees && !!acceptEbt && !!ebtAuthFilled 
      && !!earlyTerminationFee && !!chargeBackFee && !!pciValidation && !!pciNonCompliance && !pciError && !chargeBackError && ebtFilled) {
      setPricingSectionStatus('filled')
      if (dbaName && business_email && business_name && preScreeningSectionStatus === 'filled' && partnerId && partnerRm && (partnerRm.length > 0? !!partnerRmId : true)) {
        setSendToMerchantFlag(true);
      }
    } else {
      setSendToMerchantFlag(false);
      setPricingSectionStatus('')
    }
    
  }

  useEffect(() => {
    updatePricingSectionStatus()
    setSignButtonDisableStatus(getSignButtonStatus())
  }, [ pricingType,
    pricingCardType,
    pricingCardValue,
    tieredQualDiscount,
    tieredMidDiscount,
    tieredNonDiscount,
    tieredDebitDiscount,
    amexQual,
    amexMidQual,
    amexNonQual,
    errQualDiscount,
    errNonDiscount,
    intAmexDiscountRate,
    discountRate,
    pinDebit,
    pinDebitAuth,
    acceptEbt,
    ebt,
    ebtAuth,
    ticketSize, 
    selectedSellTime, 
    licenseTechFees,
    discountPercentage,
    accountReserve,
    riskLevel,
    loading])


  // legal or corp section status
  const updatelegalSectionStatus = () => {
    let addressValidation = skipAddressValidation ? true : legalInfoAddressValid
    if (!!legalName && !!legalAddress && !!legalAddressCity && !!selectedLegalAddressState && !!legalAddressPostalCode && !!legalBusinessContact && !!legalBusinessPhone &&
      !!fedTaxID && fedTaxIdValid && !!selectedTaxType && !!businessStartDate && !!lengthOfOwnership && !!lengthOfOwnershipYears && !!selectedLegalEntityType && !!selectedStateIncorporated && !!isPriorSecurityBreach && !!addressValidation && !!legalBusinessContactTitle) {
      setLegalSectionStatus('filled');
    } else {
      setLegalSectionStatus('')
    }
    if (!!legalName && !!legalAddress && !!legalAddressCity && !!selectedLegalAddressState && !!legalAddressPostalCode && !!legalBusinessContact && !!legalBusinessPhone &&
      !!selectedTaxType && !!businessStartDate && !!lengthOfOwnership && !!selectedLegalEntityType && !!selectedStateIncorporated && !!isPriorSecurityBreach && !!legalBusinessContactTitle){
        setLegalTrinitySectionStatus('filled')
    } else {
      setLegalTrinitySectionStatus('')
    }
  }
  
  useEffect(() => {
    updatelegalSectionStatus()
    setSignButtonDisableStatus(getSignButtonStatus())
  }, [selectedLegalAddressState, selectedTaxType, taxFilingName, businessStartDate, selectedLegalEntityType, selectedStateIncorporated, isPriorSecurityBreach, legalAddress, legalAddressCity, legalAddressPostalCode, legalInfoAddressValid, legalBusinessContactTitle])

  // dba section status
  const updatedbaSectionStatus = () => {
    let dbaWebsiteUrlBool = riskLevel !== RISK_LEVELS.LOW_RISK ? !!dbaWebsiteURL : true
    let addressValidation = skipAddressValidation ? true : dbaInfoAddressValid
    if (!!dbaName!! && dbaAddress!! && dbaCity!! && selectedDBAState!! && dbaZipCode!! && dbaBusinessEmail!! && dbaCustomerServicePhone!! && dbaWebsiteUrlBool
      && (bankRoutingNo !== bankAccountNo) && selectedDBAMultipleLocationOption && !!bankRoutingNo && !!bankAccountNo && !!confirmBankAccountNo &&
      (bankAccountNo === confirmBankAccountNo) && isDbaBusinessEmailValid && !!addressValidation && !!bankName && !!bankPhone && !!bankAccountNo && !!confirmBankAccountNo) { //!!bankName && !!bankPhone && !!selectedBankACHMethod) {
      setdbaSectionStatus('filled')
    } else {
      setdbaSectionStatus('')
    }
    if (!!dbaName!! && dbaAddress!! && dbaCity!! && selectedDBAState!! && dbaZipCode!! && dbaBusinessEmail!! && dbaCustomerServicePhone!! && dbaWebsiteUrlBool
      && selectedDBAMultipleLocationOption && !! bankRoutingNo && isDbaBusinessEmailValid) {
      setdbaTrinitySectionStatus('filled')
    }else{
      setdbaTrinitySectionStatus('')
    }
  }
  useEffect(() => {
    //const bankAccountNoFilled = bankAccountNo === confirmBankAccountNo ? !!confirmBankAccountNo : true
    updatedbaSectionStatus()
  }, [selectedDBAState, selectedDBAMultipleLocationOption, bankRoutingNo, dbaAddress, dbaCity, selectedDBAState, bankName, bankPhone, dbaZipCode, dbaInfoAddressValid, bankAccountNo, confirmBankAccountNo])

  // owner section status
  const updateownerSectionStatus = () => {
    if (ownerSectionData && ownerSectionData.length) {
      let filled_flag = true;
      let filled_flag_trinity = true;
      const validation_check = ownerSectionErrorFileds.filter((error) => (error.address_valid !== '' || error.date_discharge_valid !== '' || error.dl_expire_date_valid !== '' || error.dl_issue_date_valid !== '' || error.dob_valid !== '' || error.emailInvalid !== '' || error.emailAlreadyExist !== '' || error.invalidOwnership !== ''))
      for (const owner of ownerSectionData) {
        const dateDischarge = owner.priorBankrupties === 'Yes' ? owner.dateDischarge : true
        const issueState = owner.documentType === 'Driving License' ? owner.issueState : true
        const addressValidation = validation_check.length>0 ? false : true
        if (!owner.firstName || !owner.lastName || !owner.ownerTitle || !owner.ownerAddress || !owner.city ||
          !owner.state || !owner.zip || !owner.yearsThisAddress || !owner.mobileNo || !owner.dl_Id || !owner.documentType ||
          !owner.issueDate || !owner.expireDate || !owner.ssn || !owner.dob || !owner.ownerEmail || !owner.ownership
          || !owner.priorBankrupties || !dateDischarge || !issueState || !addressValidation || !!owner.ssnInvalid) {
          filled_flag = false;
        }
        if (!owner.firstName || !owner.lastName || !owner.ownerTitle || !owner.ownerAddress || !owner.city ||
          !owner.state || !owner.zip || !owner.yearsThisAddress || !owner.mobileNo  || !owner.documentType ||
          !owner.issueDate || !owner.expireDate || !owner.dob || !owner.ownerEmail || !owner.ownership
          || !owner.priorBankrupties || !dateDischarge || !issueState || !addressValidation|| !!owner.ssnInvalid) {
          filled_flag_trinity = false;
        }
      }
      filled_flag ? setOwnerSectionStatus('filled') : setOwnerSectionStatus('');
      filled_flag_trinity ? setOwnerTrinitySectionStatus('filled') : setOwnerTrinitySectionStatus('');
    } else {
      setOwnerSectionStatus('')
      setOwnerTrinitySectionStatus('')
    }
  }

  const validateAllOwnersAddresses = async () => {
    
    let index = 0
    for (const owner of ownerSectionData) {
      const { ownerAddress, city, state, zip } = owner
      if ((!!ownerAddress && !!city && !!state && !!zip)) {
        const ownerAddressValid: any = await validateAddress('', ownerAddress, city, state, zip)
        setOwnerInfoAddressValid(ownerAddressValid.isValid)
        const error_list: any = [...ownerSectionErrorFileds]
        if (ownerAddressValid.isValid) {
          const list: any = [...ownerSectionData]
          updateownerSectionStatus()
          error_list[index].address_valid = ''
        } else {
          setOwnerInfoAddressErrMessage(ownerAddressValid.errMessage)
          error_list[index].address_valid = 'Invalid address'
        }
      }
      index = index + 1
    }
  }

  useEffect(() => {
    if(ownerSectionData.length === ownerSectionErrorFileds.length) validateAllOwnersAddresses()
  }, [ownerSectionData.length])

  useEffect(() => {
    updateownerSectionStatus()
    setSignButtonDisableStatus(getSignButtonStatus())
  }, [ownerSectionData])

  // bank section status
  const updateBankSectionStatus = () => {
    if (!!bankName && !!bankPhone && !!bankRoutingNo && (bankRoutingNo !== bankAccountNo) && (bankAccountNo === confirmBankAccountNo)) {
      setBankSectionStatus('filled')
      updatedbaSectionStatus()
    } else {
      setBankSectionStatus('')
    }
  }
  useEffect(() => {
    updateBankSectionStatus()
  }, [bankName, bankPhone, bankRoutingNo, bankAccountNo])

  useEffect(() =>{
    if(!!bankRoutingNo && bankAccountNo === bankRoutingNo){
      setErrorMessage2("Please provide a valid bank account number to proceed.");
    }
    else{
      setErrorMessage2("");
    }
    },[bankAccountNo,bankRoutingNo])

  // business section status
  const updatebusinessSectionStatus = () => {
    const businessAddressFilled = isRetailLocation === 'Yes' ? !!businessAddress : true
    const inventoryLocationFilled = isInventoryStoredatRetailLocation === 'No' ? !!inventoryLocation : true
    const billingMethodFilled = ((Number(oneTimePct) || 0) + (Number(monthlyPct) || 0) + (Number(quarterlyPct) || 0) +
    (Number(yearlyPct) || 0) || 0) === 100

    // condition based question values
    const isSeasonalMerchantBool = isSeasonalMerchant === 'Yes' ? (seasonalMonth.filter((item) => item.ischecked)).length > 0 : true
    const is_own_inventoryBool = condBusiDispValues.is_own_inventory ? !!isOwnInventory : true
    const vendor_namesBool = condBusiDispValues.vendorNames ? !!vendorNames : true
    const is_inventory_stored_retailBool = condBusiDispValues.is_inventory_stored_retail ? !!isInventoryStoredatRetailLocation : true
    const selected_sell_national_localBool = condBusiDispValues.selected_sell_national_local ? !!selectedSellNationalLocal : true
    const internetSalesPctBool = condBusiDispValues.internetSalesPct ? !!internetSalesPct : true
    const telephoneSalesPctBool = condBusiDispValues.telephoneSalesPct ? !!telephoneSalesPct : true
    // const mailOrderSalesPctBool = condBusiDispValues.mailOrderSalesPct ? !!mailOrderSalesPct : true
    const retailSalesPctBool = condBusiDispValues.retailSalesPct ? !!retailSalesPct : true
    const advertisingMethodsBool = condBusiDispValues.advertisingMethods ? (!!advertisingMethods && !!salesProcessDesc) : true
    const charge_customerBool = condBusiDispValues.charge_customer ? (!!selectedChargeCustomerMethod && !!daysToDelivery && parseInt(daysToDelivery)>0 ) : true
    const fulfillmentCompanyNameBool = condBusiDispValues.fulfillmentCompanyName ? !!fulfillmentCompanyName : true
    const crmCompanyNameBool = condBusiDispValues.crmCompanyName ? !!crmCompanyName : true
    const chargeBackCompanyNameBool = condBusiDispValues.chargeBackCompanyName ? !!chargeBackCompanyName : true
    const affliateMarketingPartnerNameBool = condBusiDispValues.affliateMarketingPartnerName ? !!affliateMarketingPartnerName : true
    const callCenterNameBool = condBusiDispValues.callCenterName ? !!callCenterName : true
    const legalFaxBool = selectedSendChargeback_AddressType === 'FAX' ? !!legalBusinessFax : true
    const sellProductOrService = (sellProduct==='Yes'|| sellService==='Yes') ? true : false
    const isFulfillmentCompanyName = condBusiDispValues.fulfillmentCompanyName && fulfillmentCompanyName.toLowerCase() === 'other'? !!fulfillmentCompanyNameFreeFormText : true
    const isCrmCompanyName = condBusiDispValues.crmCompanyName && crmCompanyName.toLowerCase() === 'other'? !!crmCompanyNameFreeFormText : true
    const isChargeBackCompanyName = condBusiDispValues.chargeBackCompanyName && chargeBackCompanyName.toLowerCase() === 'other'? !!chargeBackCompanyNameFreeFormText : true
    const isAffliateMarketingPartnerName = condBusiDispValues.affliateMarketingPartnerName && affliateMarketingPartnerName.toLowerCase() === 'other'? !!affliateMarketingPartnerNameFreeFormText : true
    const isCallCenterName = condBusiDispValues.callCenterName && callCenterName.toLowerCase() === 'other'? !!callCenterNameFreeFormText : true
    const isrdrPreferredVendor = riskLevel === RISK_LEVELS.HIGH_RISK ? !!rdrPreferredVendor : true 
     if (
      !!sellProductOrService && isSeasonalMerchantBool &&
      !!isRetailLocation &&
      businessAddressFilled &&
      !!selectedSendChargeback_AddressType &&
      legalFaxBool &&
      is_own_inventoryBool &&
      vendor_namesBool &&
      is_inventory_stored_retailBool &&
      selected_sell_national_localBool &&
      internetSalesPctBool &&
      telephoneSalesPctBool &&
      // mailOrderSalesPctBool &&
      retailSalesPctBool &&
      advertisingMethodsBool &&
      charge_customerBool &&
      fulfillmentCompanyNameBool &&
      crmCompanyNameBool &&
      chargeBackCompanyNameBool &&
      affliateMarketingPartnerNameBool &&
      callCenterNameBool &&
      !!isPrevProcessorTerminated &&
      !!inventoryLocationFilled &&
      !!returnPolicyDesc &&
      billingMethodFilled && !salesSumWarningTextFlag && numberOfLocations &&
      !!isCallCenterName &&
      !!isAffliateMarketingPartnerName &&
      !!isChargeBackCompanyName &&
      !!isCrmCompanyName &&
      !!isFulfillmentCompanyName &&
      !!isrdrPreferredVendor) {
      setBusinessSectionStatus('filled')
    } else {
      setBusinessSectionStatus('')
    }
  }
  useEffect(() => {
    updatebusinessSectionStatus()
    setSignButtonDisableStatus(getSignButtonStatus())
  }, [sellService, sellProduct, businessType, selectedGoodsSold, isSeasonalMerchant, isRetailLocation,
    selectedSendChargeback_AddressType, isOwnInventory, isInventoryStoredatRetailLocation, selectedSellNationalLocal, encryptionMethod,
    isPrevProcessorTerminated, fulfillmentCompanyName, crmCompanyName,
    chargeBackCompanyName, affliateMarketingPartnerName, callCenterName, billValue,
    selectedChargeCustomerMethod, billingMethod, seasonalMonth, seasonalMonthValue, numberOfLocations,rdrPreferredVendor])

  // transaction section status
  const updatetransactionSectionStatus = () => {
    const salesTotal = (Number(businessTransPCT) || 0) + (Number(publicSalesPCT) || 0);
        if (salesTotal !== 100) {
          setSalesTotalWarningText('Sum of B2C & B2B sales should be equal 100%');
        } else {
          setSalesTotalWarningText('');
        }
    let avgSales=parseInt(monthAvgSales)
    let highestAmount=parseInt(highestTicketAmount)
    let avgAmount=parseInt(avgTicketAmount)
    let amexMonth=parseInt(amexMonthAvgSales)
    if(avgSales<=0)
    setAvgSalesWarning("Invalid Avg Monthly Volume (VS/MC/Disc)")
    else 
    setAvgSalesWarning("")
    if(highestAmount<=0)
    setHighestTicketAmountWarning("Invalid Highest Ticket Amount")
    else 
    setHighestTicketAmountWarning("")
    if(avgAmount<=0)
    setAvgTicketAmountWarning("Invalid Avg Ticket Amount")
    else 
    setAvgTicketAmountWarning("")
    if(amexMonth<=0)
    setAmexMonthAvgWarning("Invalid Avg Monthly Volume (Amex)")
    else 
    setAmexMonthAvgWarning("")

    if ((riskLevel === 'Low Risk'? !!avgSales : true) && (pricingCardValue.includes('AMEX') ? !!amexMonth : true) && !!highestAmount && !!avgAmount && !!publicSalesPCT && !!businessTransPCT && !!(salesTotal===100)) {
      setTransactionSectionStatus('filled')
    } else {
      setTransactionSectionStatus('')
    }
  }
  useEffect(() => {
    updatetransactionSectionStatus()
    setSignButtonDisableStatus(getSignButtonStatus())
  }, [monthAvgSales, amexMonthAvgSales, highestTicketAmount, avgTicketAmount, publicSalesPCT, businessTransPCT])

  // equipmentSectionStatus
  const updateEquipSectionStatus = () => {
if (equipmentSectionData && equipmentSectionData.length) {
  let equipmentLength = [];
      for (const equipment of equipmentSectionData) {
        let checkBoxValidator = equipment.tokenization_needed ||
          equipment.purchasing_cards ||
          equipment.avs ||
          equipment.server_clerk ||
          equipment.wireless ||
          equipment.invoice_number ||
          equipment.cvv2 ||
          equipment.fraud_check;

        let billedTovalidator = (equipment.billTo === 'Merchant' && equipment.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL) ? !!equipment.cost : true;

          let equipmentCostValidator = true
          if(equipment.billTo === 'Merchant' && equipment.equipment_type ===EQUIPMENT_TYPE.PHYSICAL_TERMINAL && !!equipment.cost){
            equipmentCostValidator = parseFloat(equipment.cost) >= minEquipmentCost
            setEquipmentCostStatus(parseFloat(equipment.cost) >= minEquipmentCost)
          } else {
            setEquipmentCostStatus(true)
          }
          

          let typeValidator = false;
          if (equipment.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL) {
            typeValidator = !!equipment.product
          } else if (equipment.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL) {
            typeValidator = (!!equipment.product && equipment.product === 'Other') ? !!equipment.virtual_terminal_other : !!equipment.product
          } else if (equipment.equipment_type === EQUIPMENT_TYPE.PIN_PAD) {
            typeValidator = !!equipment.product
          } else if (equipment.equipment_type === EQUIPMENT_TYPE.POS) {
            typeValidator = !!equipment.pos_name
          } else if (equipment.equipment_type === EQUIPMENT_TYPE.CHECK_READER) {
            typeValidator = !!equipment.check_reader_text
          }

          let equipment_quantity = !!equipment.quantity
          let equipment_mailing_addr_type = !!equipment.equipment_mailing_addr_type
          let connection_type = !!equipment.connection_type
          let auto_close = !!equipment.auto_close;
          let application = !!equipment.application
          let mobile_equipment = !!equipment.mobile_equipment
          if (equipment.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL) {
            billedTovalidator = true
            equipment_quantity = true
            equipment_mailing_addr_type = true
            connection_type = true
            auto_close = true
            checkBoxValidator = true
            if(equipment.mobile_equipment==="BBPOS Chipper 2X BT__$105"){
              equipment_quantity = !!equipment.quantity
              equipment_mailing_addr_type = !!equipment.equipment_mailing_addr_type
              billedTovalidator = !!equipment.billTo
              
            }
          }else if(equipment.equipment_type === EQUIPMENT_TYPE.POS){
            billedTovalidator = true
            equipment_quantity = true
            equipment_mailing_addr_type = true
            connection_type = true
            auto_close = true
            checkBoxValidator = true
            application = true
          }else if(equipment.equipment_type === EQUIPMENT_TYPE.PIN_PAD){
            connection_type = true
            auto_close = true
            checkBoxValidator = true
            application = true
          }else{
            equipment_mailing_addr_type = equipment.deployment === "New"?!!equipment.equipment_mailing_addr_type:true
          }

          if (connection_type &&
            auto_close &&
            checkBoxValidator &&
            equipment_quantity &&
            // !!equipment.platform &&
            application &&
            equipment_mailing_addr_type &&
            !!equipment.deployment &&
            billedTovalidator &&
            equipmentCostValidator &&
            typeValidator
          ) {
            if (role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) {
              equipment.rm_added = true
            }
            equipmentLength.push("filled");
          } else {
            setEquipmentSectionStatus('')
          }
        }
        if(equipmentSectionData.length === equipmentLength.length){
          setEquipmentSectionStatus("filled");
        }  
    } else {
      setEquipmentSectionStatus('')
    }
  }
  useEffect(() => {
    updateEquipSectionStatus()
  }, [equipmentSectionData])

  useEffect(() => {
   if(equipmentTotalCost > manualTerminationFee){
    setEarlyTerminationFee(equipmentTotalCost)
    setDisableEarlyTermination(true)
   }else {
    setEarlyTerminationFee(manualTerminationFee)
    setDisableEarlyTermination(false)
   }
  }, [equipmentTotalCost,manualTerminationFee])

  useEffect(() => {
    const handleUpdateMiscSectionStatus = async () => {
      if (isMcFormDownloaded || isCBDFormDownloaded || isEsquireFormDownloaded) {
        const mc_downloaded = selectedSellTime === 'Trial' ? isMcFormDownloaded : true
        const cbd_downloaded = (businessConduct.includes('E-Commerce') &&
          businessCategory === 'E COMMERCE' &&
          businessType === 'CBD'
        ) ? isCBDFormDownloaded : true
        const esquire_downloaded = (selectedSellTime === 'One time' &&
          riskLevel === RISK_LEVELS.HIGH_RISK &&
          businessConduct.includes('E-Commerce') &&
          businessCategory === 'E COMMERCE' &&
          selectedBankOption === 'ESQUIRE') ? isEsquireFormDownloaded : true

        if (mc_downloaded && cbd_downloaded && esquire_downloaded) {
          setMiscSectionStatus('filled')
        } else {
          setMiscSectionStatus('')
        }
      }
    }
    handleUpdateMiscSectionStatus()
  }, [isMcFormDownloaded, isCBDFormDownloaded, isEsquireFormDownloaded, businessCategory, businessType, businessConduct, riskLevel, selectedSellTime, selectedBankOption])
  useEffect(() => {
    const updateMiscSectionStatus = async () => {
      if (isEditFlow && merchantAppID) {
        try {
          const payload = {
            merchant_app_id: merchantAppID,
            misc_section: {
              is_cbd_form_downloaded: isCBDFormDownloaded,
              is_mc_form_downloaded: isMcFormDownloaded,
              is_esquire_form_downloaded: isEsquireFormDownloaded
            }
          }
          const data = {
            api: api.merchantAppData.updateMerchantAppMiscStatus,
            payLoad: { ...payload }
          }

          setLoading(true)
          await backend.save(data)

        } catch (err: any) {

        } finally {
          setLoading(false)
        }
      }
    }
    updateMiscSectionStatus()
  }, [isMcFormDownloaded, isCBDFormDownloaded, isEsquireFormDownloaded])
  // send to merchant flag
  const updateSendToMerchantFlag = () => {
    if (dbaName && (business_email && email_pattern.test(business_email)) && business_name && preScreeningSectionStatus === 'filled' && pricingSectionStatus === 'filled' && partnerRm && (partnerRm.length > 0? !!partnerRmId : true)) {
      setSendToMerchantFlag(true);
    } else {
      setSendToMerchantFlag(false);
    }
  }
  
  useEffect(() => {
    updateSendToMerchantFlag();
  }, [preScreeningSectionStatus, pricingSectionStatus, partnerId])

  // useEffect(() => {
    const sendMailAdditionalLocation = async () => {
      if (additional_location === "true" && dbaName && (business_email && email_pattern.test(business_email))) {
        handleSaveApp(true);
        const payload = {
          to_email: business_email,
          body: '',
          merchant_app_id: merchantAppID,
          dba_name: dbaName
        };
        const sendData: any = {};
        sendData.api = api.integrations.sendMail;
        sendData.payLoad = { ...payload };
        const response = await backend.save(sendData);
        if (!!response){
          sessionStorage.setItem('addLocation', 'false');
        }
      }
    };
   
  // }, [dbaName, business_email, preScreeningSectionStatus]);

  const validateDBASectionAddress = async () => {
    if ((!!dbaAddress && !!dbaCity && !!selectedDBAState && !!dbaZipCode)) {
      const dbaAddressValid: any = await validateAddress('', dbaAddress, dbaCity, selectedDBAState, dbaZipCode)
      setDbaInfoAddressValid(dbaAddressValid.isValid)
      if (dbaAddressValid.isValid) {
        if (dbaAddress.toLowerCase() !== dbaAddressValid.Address2[0].toLowerCase()) {
          setDBAAddress(dbaAddressValid.Address2[0])
          updatedbaSectionStatus()
        }
        if (dbaCity.toLowerCase() !== dbaAddressValid.City[0].toLowerCase()) {
          setDBACity(dbaAddressValid.City[0])
          updatedbaSectionStatus()
        }
        if (dbaZipCode.toLowerCase() !== dbaAddressValid.Zip5[0].toLowerCase()) {
          setDBAZipCode(dbaAddressValid.Zip5[0])
          updatedbaSectionStatus()
        }
        if (!selectedDBAState.toLowerCase().includes(`-${dbaAddressValid.State[0].toLowerCase()}`)) {
          const selectedState = states.filter((state: any)=> state.value.toLowerCase().includes(`-${dbaAddressValid.State[0].toLowerCase()}`))
          if(!!selectedState && selectedState.length > 0){
            setSelectedDBAState(selectedState[0].value)
            updatedbaSectionStatus()
          }
        }
      } else {
        setDbaInfoAddressErrMessage(dbaAddressValid.errMessage)
      }
    }
  };

  useEffect(() => {
    validateDBASectionAddress()
  }, [selectedDBAState, !!dbaAddress, !!dbaCity, !!dbaZipCode, !!dbaInfoAddressErrMessage])

  const validateLegalSectionAddress = async () => {
    if ((!!legalAddress && !!legalAddressCity && !!selectedLegalAddressState && !!legalAddressPostalCode)) {
      const legalAddressValid: any = await validateAddress('', legalAddress, legalAddressCity, selectedLegalAddressState, legalAddressPostalCode)
      setLegalInfoAddressValid(legalAddressValid.isValid)
      if (legalAddressValid.isValid) {
        if (legalAddress.toLowerCase() !== legalAddressValid.Address2[0].toLowerCase()) {
          setLegalAddress(legalAddressValid.Address2[0])
          updatelegalSectionStatus()
        }
        if (legalAddressCity.toLowerCase() !== legalAddressValid.City[0].toLowerCase()) {
          setLegalAddressCity(legalAddressValid.City[0])
          updatelegalSectionStatus()
        }
        if (legalAddressPostalCode.toLowerCase() !== legalAddressValid.Zip5[0].toLowerCase()) {
          setLegalAddressPostalCode(legalAddressValid.Zip5[0])
          updatelegalSectionStatus()
        }
        if (!selectedLegalAddressState.toLowerCase().includes(`-${legalAddressValid.State[0].toLowerCase()}`)) {
          const selectedState = states.filter((state: any)=> state.value.toLowerCase().includes(`-${legalAddressValid.State[0].toLowerCase()}`))
          if(!!selectedState && selectedState.length > 0){
            setSelectedLegalAddressState(selectedState[0].value)
            updatelegalSectionStatus()
          }
        }
      } else {
        setLegalInfoAddressErrMessage(legalAddressValid.errMessage)
      }
    }
  }

  useEffect(() => {
    validateLegalSectionAddress()
  }, [selectedLegalAddressState, !!legalAddress, !!legalAddressPostalCode, !!legalAddressCity, !!legalInfoAddressErrMessage])

  useEffect(() => {
    if (autoSave && autoSaveTimer === 60 && /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(business_email) && dbaName && business_name){
      handleSaveApp(true)
    }
  }, [autoSaveTimer, dbaName, business_email, business_name])

  const openNotification = () => {
    notification.open({
      message: '',
      description:
        'App data saved successfully',
      className: 'custom-class',
      style: {
        width: 250,
        background: "#73bf7b",
        color: "#fff",
        margin: "0px 0px 0px 0px"
      },
    });
  };

  useEffect(() => {
    if(resumeTimer){
      handleAutoSave()
    }
  }, [resumeTimer])

  // User has switched back to the tab
  const onFocus = () => {
    setresumeTimer(true)
    setAutoSaveTimer(0)
  };

  // User has switched away from the tab
  const onBlur = () => {
    if(autoSaveIntervalRef) clearInterval(autoSaveIntervalRef)
    autoSaveIntervalRef = null
    setresumeTimer(false)
  };

  useEffect(() => {
    if(business_name && /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(business_email) && dbaName){
      if(autoSaveIntervalRef === null) onFocus()
       setresumeTimer(true)
       setAutoSaveTimer(0)
       handleAutoSave()
    } else{
       onBlur()
      }
  }, [dbaName, business_email, business_name]);
  
  useEffect(()=>{
  const clym_fee_accept_banks = Object.keys(CLYM_FEE_ACCEPT_BANKS)
    if(riskLevel===RISK_LEVELS.HIGH_RISK&&clym_fee_accept_banks.indexOf(selectedBankOption)>-1){
      setClymDataPrivacyCompliance('Yes')
    }
  },[selectedBankOption,riskLevel])
  useEffect(()=>{
    setRiskLevel('');
    setSelectedBankOption('');
  },[partnerName])
  const handleAutoSave = async(editSave?: boolean) => {
    if (((autoSaveTimer === 0 || resumeTimer) && dbaName && business_email && /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(business_email) && business_name) || editSave )  {
      setAutoSave(true)
      onBlur()
      autoSaveIntervalRef = setInterval(() => {
        setAutoSaveTimer(prev => prev+1)
      }, 1000);
    }
  }
 
  const validateOwnerSectionAddress = async (index: any) => {
    const { ownerAddress, city, state, zip } = ownerSectionData[index]

    if ((!!ownerAddress && !!city && !!state && !!zip)) {
      const ownerAddressValid: any = await validateAddress('', ownerAddress, city, state, zip)
      setOwnerInfoAddressValid(ownerAddressValid.isValid)
      const error_list: any = [...ownerSectionErrorFileds]
      if (ownerAddressValid.isValid) {
        const list: any = [...ownerSectionData]
        if (ownerAddress.toLowerCase() !== ownerAddressValid.Address2[0].toLowerCase()) { list[index].ownerAddress = ownerAddressValid.Address2[0] }
        if (city.toLowerCase() !== ownerAddressValid.City[0].toLowerCase()) { list[index].city = ownerAddressValid.City[0] }
        if (zip.toLowerCase() !== ownerAddressValid.Zip5[0].toLowerCase()) { list[index].zip = ownerAddressValid.Zip5[0] }
        if (!state.toLowerCase().includes(`-${ownerAddressValid.State[0].toLowerCase()}`)) {
          const selectedState = states.filter((state: any)=> state.value.toLowerCase().includes(`-${ownerAddressValid.State[0].toLowerCase()}`))
          if(!!selectedState && selectedState.length > 0){
            list[index].state = selectedState[0].value
          }
        }
        setOwnerSectionData(list)
        updateownerSectionStatus()
        error_list[index].address_valid = ''
      } else {
        setOwnerInfoAddressErrMessage(ownerAddressValid.errMessage)
        error_list[index].address_valid = 'Invalid address'
      }
    }
  }

  const validateFedTaxId = (fedTaxId: string) => {
    let fedTaxArray = fedTaxId.split('');
    console.log(fedTaxArray)
    if (fedTaxArray.length === 9 && !fedTaxArray.includes('-')) {
      setFedTaxIdValid(true)
      return true
    } else if(fedTaxArray.length === 10 && fedTaxArray[2] === '-' && !fedTaxArray.splice(0,2).includes('-') && !fedTaxArray.splice(3,9).includes('-')) {
      setFedTaxIdValid(true)
      return true
    }else{
      setFedTaxIdValid(false)
      return false
    }
  }

  const validateSSNId = (ssn: string) => {
    let ssn_format1 = ssn.toString().split('');
    let ssn_format2 = ssn.toString().split('-');
    if(ssn_format1.length === 11 && ssn_format1[3] === '-' && ssn_format1[6] === '-' && ssn_format2.length === 3) {
      return true
    }else{
      return false
    }
  }

  const validateAddress = async (address1: string, address2: string, city: string, state: string, zip5: string) => {
    try {
      state = state.split('-')[1]

      const input = {
        address1: address1.replace('#', ''),
        address2: address2.replace('#', ''),
        city,
        state,
        zip5,
        zip4: ''
      }

      const data = {
        api: api.integrations.validateAddress,
        payLoad: JSON.stringify(input)
      }
      setLoading(true)
      const response = getLink ? await backend.save(data, true) : await backend.save(data)
      setLoading(false)
      return response
    } catch (err: any) {
      const msg = 'Error while validating address!'
      if (err.errMessage) {
        setErrorMessage(err.errMessage)
      } else {
        setErrorMessage(msg)
      }
      return false
    }
  }

  // Render Checkbox ControlProng
  const renderControlProng = (value: string, ind: any) => {
    return controlProng.map((prong: any, index: number) => {
      return (<div className='control_fields'>
        <div className='four wide field'>
          <Radio
            label={prong.value}
            name='prong'
            value={prong.value}
            checked={prong.value === ownerSectionData[ind]['control_prong']}
            onChange={(event) => {
              handleControlProng(value, ind)
            }}
            onMouseUp={updateownerSectionStatus}
          />
        </div>
        </div>)
    })
  }
  const handleBilling=(value: string) => {
    const newBillingMethod = [...billingMethod];
    let oneTime_ST= false
    let mothly_ST= false
    let quarterly_ST= false
    let yearly_ST= false

    if(oneTimePct && oneTimePct!== ""){
      oneTime_ST= true
    }
    if(monthlyPct  && monthlyPct!== ""){
      mothly_ST= true
    }
    if(quarterlyPct && quarterlyPct!== ""){
      quarterly_ST= true
    }
    if(yearlyPct && yearlyPct!== ""){
      yearly_ST= true
    }
    if(newBillingMethod.length >= 0){
      newBillingMethod[0].ischecked = oneTime_ST;
      newBillingMethod[1].ischecked = mothly_ST;
      newBillingMethod[2].ischecked = quarterly_ST;
      newBillingMethod[3].ischecked = yearly_ST;
      setBillingMethod(newBillingMethod)
    }
  }

  const handleSeasonalMonthSelect = (event: any, data: CheckboxProps) => {
    const value = data.value;
    const checked: any = data.checked;
    const month_value = [...seasonalMonthValue];
    if (checked) {
      month_value.push(value)
      setSeasonalMonthValue(month_value)
    } else {
      month_value.splice(month_value.indexOf(value), 1);
      setSeasonalMonthValue(month_value)
    }
    const newSeasonalMonth = [...seasonalMonth];
    const index = newSeasonalMonth.findIndex((Item: any) => Item.value === data.value)
    newSeasonalMonth[index].ischecked = checked;
    setSeasonalMonth(newSeasonalMonth)
  }
  
  const handleProduct = (data: any) => {
    setSellProduct(data.checked?'Yes':'No')
  }
  const handleService = (data: any) => {
    setSellService(data.checked?'Yes':'No')
    }
  const handleRetail = (data: any) => {
    setIsRetail(data.checked)
    let business_conduct = businessConduct;
    if (data.checked) {
      business_conduct.push('Retail');
      setBusinessConduct(business_conduct);
    } else {
      const index = business_conduct.indexOf('Retail');
      if (index > -1) {
        business_conduct.splice(index, 1)
        setBusinessConduct(business_conduct);
        setDiscountPercentage("")
      } 
    }
  }

  const handleMailOrder = (data: any) => {
    setIsMailOrder(data.checked)
    let business_conduct = businessConduct;
    if (data.checked) {
      business_conduct.push('Mail Order/Tel');
      setBusinessConduct(business_conduct);
    } else {
      const index = business_conduct.indexOf('Mail Order/Tel');
      if (index > -1) {
        business_conduct.splice(index, 1)
        setBusinessConduct(business_conduct);
      }
    }
  }

  const handleEcommerce = (data: any) => {
    setIsEcommerce(data.checked)
    let business_conduct = businessConduct;
    if (data.checked) {
      business_conduct.push('E-Commerce');
      setBusinessConduct(business_conduct);
    } else {
      const index = business_conduct.indexOf('E-Commerce');
      if (index > -1) {
        business_conduct.splice(index, 1)
        setBusinessConduct(business_conduct);
      }
    }
  }

  const handleUseLegalAddress = (data: CheckboxProps) => {
    setUseLegalAddress(data.checked || false)
    if (data.checked) {
      setDBAAddress(legalAddress);
      setDBACity(legalAddressCity);
      setSelectedDBAState(selectedLegalAddressState);
      setDBAZipCode(legalAddressPostalCode);
      updatedbaSectionStatus()
    } else {
      setDBAAddress('');
      setDBACity('');
      setSelectedDBAState('');
      setDBAZipCode('');
    }
  }

  const handlePricingCardType = (event: any, data: CheckboxProps) => {
    const value = data.value;
    const checked: any = data.checked;
    const cardvalue = [...pricingCardValue];
    if (checked) {
      cardvalue.push(value)
      setPricingCardValue(cardvalue)
    } else {
      cardvalue.splice(cardvalue.indexOf(value), 1);
      setPricingCardValue(cardvalue)
    }
    const newPricingCardType = [...pricingCardType];
    const index = newPricingCardType.findIndex((Item: any) => Item.value === data.value)
    newPricingCardType[index].ischecked = checked;
    setPricingCardType(newPricingCardType)
  }
  
  const allowOnlyNumber = (e: any) => {
    if (e.target.value.length > 9) {
      e.preventDefault()
    }
    const validateFee = e.key
    if (!(/^\d+?$/).test(validateFee)) {
      e.preventDefault()
    }
  }

  const allowOnlyDecimalNumber = (e: any) => {
    if (e.target.value.length > 9) {
      e.preventDefault()
    }
    const validateFee = e.key
    if (!(/^\d|.$/).test(validateFee)) {
      e.preventDefault()
    }
  }
  const allowOnlyNumberIncludingHyphen = (e: any) => {
    if (e.target.value.length > 9) {
      e.preventDefault()
    }
    const validateFee = e.key
    if (!(/^\d|-$/).test(validateFee)) {
      e.preventDefault()
    }
  }

  const allowNumberText = (e: any) => {
    const validateFee = e.key
    console.log('value', validateFee);
    if (!(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9]*$/).test(validateFee)) {
      e.preventDefault()
    }
  }

  const setPersonalMessage = async (event: any) => {
    setUserMessage(event.target.value)
  }

  const handleOptionalMessage = (event: any, data:any) => {
    setOptionalMessage(data.value)
  }

  // RENDER PRE-SCREENING
  const renderPreScreening = () => {
    // console.log('bcat', businessCategoryConditionalOptions, businessCategory);
    return (
      <div className='form-contents'>
        <div className='form-headingleftpadding' style={{ color: config?.client_config_fe?.button_color }}>
          Pre-Screening Information
          <span
            className='navigationright-icon'
            onClick={() => handlePrevNextNavigation('prescreening', 'next')}
          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='SAVE'
            className={disableSave ? "not-active save-button-right button-border" : "save-button-right button-border"}
            onClick={(event: any) => {
              if (role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
                handleSaveAppMerchant(event, false);
              } else {
                handleSaveApp(false, event, false)
              }

            }}
          />
        </div>
        <hr className='top' style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
        <PreScreeningSection
          isRetail={isRetail}
          handleRetail={handleRetail}
          isMailOrder={isMailOrder}
          handleMailOrder={handleMailOrder}
          isEcommerce={isEcommerce}
          handleEcommerce={handleEcommerce}
          businessCategoryConditionalOptions={businessCategoryConditionalOptions}
          businessCategory={businessCategory}
          handleBusinessCategory={handleBusinessCategory}
          businessTypeConditionalOptions={businessTypeConditionalOptions}
          handleBusinessTypeChange={handleBusinessTypeChange}
          businessCategoryFreeFormText={businessCategoryFreeFormText}
          setBusinessCategoryFreeFormText={setBusinessCategoryFreeFormText}
          businessType={businessType}
          businessTypeFreeFormText={businessTypeFreeFormText}
          setBusinessTypeFreeFormText={setBusinessTypeFreeFormText}
          businessCategoryFreeFormErrorMessage={businessCategoryFreeFormErrorMessage}
          businessTypeFreeFormErrorMessage={businessTypeFreeFormErrorMessage}
          role={role}
          showRiskLevels={showRiskLevels}
          riskLevel={riskLevel}
          handleRiskLevel={handleRiskLevel}
          selectedSellTime={selectedSellTime}
          handleChangeSellTime={handleChangeSellTime}
          getLink={getLink}
          showProcessors={showProcessors}
          showLowRiskProcessors={showLowRiskProcessors}
          showHighRiskProcessors={showHighRiskProcessors}
          selectedBankOption={selectedBankOption}
          handleChangeBankOption={handleChangeBankOption}
          merchantToken={merchantToken}
          isMobile={isMobile}
          isTab={isTab}
          showCashDiscount={showCashDiscount}
          disableSalesMethod={disableSalesMethod}
          cdBankOptionsForCD={cdBankOptionsForCD}
        />
        {!isTab ? <div className='center-buttons'>
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('prescreening', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('prescreening', 'next')}

          />
        </div> : <></>}
      </div>
    )
  }
  // RENDER PRICING
  const renderPricing = () => {
    //console.log("types:crdtype, pricetype", pricingCardType, pricingType)
    return (
      <div className={getLink ? 'disableddiv form-contents' : 'form-contents'}>

        <div className='form-headingleftpadding' style={{ color: config?.client_config_fe?.button_color }}>
          <span
            className='navigationleft-icon'
            onClick={() => handlePrevNextNavigation('pricing', 'prev')}
          />
          Pricing
          <span
            className='navigationright-icon'
            onClick={() => handlePrevNextNavigation('pricing', 'next')}
          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='SAVE'
            className={disableSave ? "not-active save-button-right button-border" : "save-button-right button-border"}
            onClick={(event: any) => {
              if (role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
                handleSaveAppMerchant(event, false);
              } else {
                handleSaveApp(false, event, false)
              }

            }}
          />
        </div>
        <hr className='top' style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
        <PricingSection
          isRetail={isRetail}
          businessConduct={businessConduct}
          riskLevel={riskLevel}
          selectedBankOption={selectedBankOption}
          pricingType={pricingType}
          pricingCardValue={pricingCardValue}
          tieredQualDiscount={tieredQualDiscount}
          handleChangeTextInput={handleChangeTextInput}
          updatePricingSectionStatus={updatePricingSectionStatus}
          updateEarlyterminationFee={updateEarlyterminationFee}
          tieredQualPerItem={tieredQualPerItem}
          tieredMidDiscount={tieredMidDiscount}
          tieredNonDiscount={tieredNonDiscount}
          tieredNonPerItem={tieredNonPerItem}
          tieredDebitDiscount={tieredDebitDiscount}
          tieredDebitPerItem={tieredDebitPerItem}
          amexQual={amexQual}
          amexPerItem={amexPerItem}
          amexMidQual={amexMidQual}
          amexMidPerItem={amexMidPerItem}
          amexNonQual={amexNonQual}
          amexNonPerItem={amexNonPerItem}
          errQualDiscount={errQualDiscount}
          errQualPerItem={errQualPerItem}
          errNonDiscount={errNonDiscount}
          errNonPerItem={errNonPerItem}
          discountRate={discountRate}
          perItem={perItem}
          debitDiscountRate={debitDiscountRate}
          debitNetworkFees={debitNetworkFees}
          setDebitNetworkFees={setDebitNetworkFees}
          intAmexDiscountRate={intAmexDiscountRate}
          intAmexperItem={intAmexperItem}
          pinDebit={pinDebit}
          pinDebitAuth={pinDebitAuth}
          acceptEbt={acceptEbt}
          ebt={ebt}
          ebtAuth={ebtAuth}
          mc={mc}
          amexAuth={amexAuth}
          avs={avs}
          batchFee={batchFee}
          annualFee={annualFee}
          monthlyFee={monthlyFee}
          chargeBackFee={chargeBackFee}
          monthlyMinimum={monthlyMinimum}
          earlyTerminationFee={earlyTerminationFee}
          accountReserve={accountReserve}
          wirelessFee={wirelessFee}
          other={other}
          billedOptions={billedOptions}
          billed={billed}
          handleBilledChange={handleBilledChange}
          role={role}
          merchantToken={merchantToken}
          voiceAuth={voiceAuth}
          excessiveElectronicAuth={excessiveElectronicAuth}
          irsReportingFee={irsReportingFee}
          retreivalFee={retreivalFee}
          invalidTinFee={invalidTinFee}
          achRejectFee={achRejectFee}
          collectionsFee={collectionsFee}
          pciValidation={pciValidation}
          returnTransactionFee={returnTransactionFee}
          pciNonCompliance={pciNonCompliance}
          debitAccessFee={debitAccessFee}
          industryCompliance={industryCompliance}
          amexOptubleSupportFee={amexOptubleSupportFee}
          amexOptubleNetworkFee={amexOptubleNetworkFee}
          Pricing_Type={Pricing_Type}
          handleChangePricingType={handleChangePricingType}
          pricingCardType={pricingCardType}
          handlePricingCardType={handlePricingCardType}
          tieredMidPerItem={tieredMidPerItem}
          option_yesno={option_yesno}
          handleChangePinDebit={handleChangePinDebit}
          handleAcceptEbt={handleAcceptEbt}
          clymDataPrivacyCompliance={clymDataPrivacyCompliance}
          setClymDataPrivacyCompliance={setClymDataPrivacyCompliance}
          clymDataPrivacyComplianceFee={clymDataPrivacyComplianceFee}
          disableEarlyTermination={disableEarlyTermination}
          ticketSizeOptions={ticketSizeOptions}
          ticketSize={ticketSize}
          handleTicketSizeChange={handleTicketSizeChange}
          selectedSellTime={selectedSellTime}
          discountPercentageOptions={discountPercentageOptions}
          discountPercentage={discountPercentage}
          handleDiscountPercentChange={handleDiscountPercentChange}
          serviceFeeOptions={serviceFeeOptions}
          serviceFee={serviceFee}
          handleServiceFeeChange={handleServiceFeeChange}
          licenseTechFees={licenseTechFees}
          config={config}
          pciError={pciError}
          pciErrorMessage={pciErrorMessage}
          chargeBackError={chargeBackError}
          chargeBackErrorMessage={chargeBackErrorMessage}
          />
        {!isTab ? <div className='center-buttons'>
        <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('pricing', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('pricing', 'next')}

          />
        </div> : <></>}
      </div>
    )
  }
  // RENDER LEGAL or CORP INFORMATION
  const renderLegalInfo = () => {
    const businessstartdate: any = businessStartDate
    return (
      <div className='form-contents'>
        <div className='form-headingleftpadding' style={{ color: config?.client_config_fe?.button_color }}>
          <span
            className='navigationleft-icon'
            onClick={() => handlePrevNextNavigation('legal', 'prev')}
          />
          Corp Information
          <span
            className='navigationright-icon'
            onClick={() => handlePrevNextNavigation('legal', 'next')}
          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='SAVE'
            className={disableSave ? "not-active save-button-right button-border" : "save-button-right button-border"}
            onClick={(event: any) => {
              if (role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
                handleSaveAppMerchant(event, false);
              } else {
                handleSaveApp(false, event, false)
              }

            }}
          />
        </div>
        <hr className='top' style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}} />
        <CorpInformationSection
          legalInfoAddressValid={legalInfoAddressValid}
          legalInfoAddressErrMessage={legalInfoAddressErrMessage}
          legalName={legalName}
          legalAddress={legalAddress}
          legalAddressCity={legalAddressCity}
          selectedLegalAddressState={selectedLegalAddressState}
          handleLegalState={handleLegalState}
          legalAddressPostalCode={legalAddressPostalCode}
          validateLegalSectionAddress={validateLegalSectionAddress}
          legalBusinessContact={legalBusinessContact}
          setLegalBusinessContact={setLegalBusinessContact}
          legalBusinessContactTitle={legalBusinessContactTitle}
          setLegalBusinessContactTitle={setLegalBusinessContactTitle}
          legalBusinessPhone={legalBusinessPhone}
          handleChangeTextInput={handleChangeTextInput}
          updatelegalSectionStatus={updatelegalSectionStatus}
          legalBusinessFax={legalBusinessFax}
          updatebusinessSectionStatus={updatebusinessSectionStatus}
          fedexTaxIdShown={fedexTaxIdShown}
          role={role}
          fedTaxID={fedTaxID}
          fedTaxIdValid={fedTaxIdValid}
          setFedTaxIdValid={setFedTaxIdValid}
          setFedTaxID={setFedTaxID}
          validateFedTaxId={validateFedTaxId}
          allowOnlyNumberIncludingHyphen={allowOnlyNumberIncludingHyphen}
          eyeIconFedexTaxId={eyeIconFedexTaxId}
          togglefedexTaxIdVisiblity={togglefedexTaxIdVisiblity}
          tax_type={tax_type}
          selectedTaxType={selectedTaxType}
          handleChangeTaxType={handleChangeTaxType}
          businessstartdate={businessStartDate}
          setBusinessStartDate={setBusinessStartDate}
          lengthOfOwnership={lengthOfOwnership}
          setLengthOfOwnership={setLengthOfOwnership}
          lengthOfOwnershipYears={lengthOfOwnershipYears}
          setLengthOfOwnershipYears={setLengthOfOwnershipYears}
          legalentity={legalentity}
          selectedLegalEntityType={selectedLegalEntityType}
          handleChangeLegalEntityType={handleChangeLegalEntityType}
          states={states}
          handleStateIncorporated={handleStateIncorporated}
          selectedStateIncorporated={selectedStateIncorporated}
          isPriorSecurityBreach={isPriorSecurityBreach}
          option_yesno={option_yesno}
          handleChangePriorSecurityBreach={handleChangePriorSecurityBreach}
          setLegalName={setLegalName}
          allowNumberText={allowNumberText}
          isMobile={isMobile}
          isTab={isTab}
        />
        {!isTab ? <div className='center-buttons'>
        <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('legal', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('legal', 'next')}

          />
        </div>:<></>}
      </div>
    )
  }
  // RENDER DBA INFORMATION
  const renderTdbaInfo = () => {
    return (
      <div className='form-contents'>
        <div className='form-headingleftpadding' style={{ color: config?.client_config_fe?.button_color }}>
          <span
            className='navigationleft-icon'
            onClick={() => handlePrevNextNavigation('tdba', 'prev')}
          />
          DBA Information
          <span
            className='navigationright-icon'
            onClick={() => handlePrevNextNavigation('tdba', 'next')}
          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='SAVE'
            className={disableSave ? "not-active save-button-right button-border" : "save-button-right button-border"}
            onClick={(event: any) => {
              if (role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
                handleSaveAppMerchant(event, false);
              } else {
                handleSaveApp(false, event, false)
              }

            }}
          />
        </div>
        <hr className='top' style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
        <DbaInformationSection
          dbaInfoAddressValid={dbaInfoAddressValid}
          dbaInfoAddressErrMessage={dbaInfoAddressErrMessage}
          useLegalAddress={useLegalAddress}
          handleUseLegalAddress={handleUseLegalAddress}
          dbaName={dbaName}
          updatedbaSectionStatus={updatedbaSectionStatus}
          handleChangeTextInput={handleChangeTextInput}
          dbaAddress={dbaAddress}
          validateDBASectionAddress={validateDBASectionAddress}
          dbaCity={dbaCity}
          selectedDBAState={selectedDBAState}
          states={states}
          dbaZipCode={dbaZipCode}
          dbaBusinessEmail={dbaBusinessEmail}
          isDbaBusinessEmailValid={isDbaBusinessEmailValid}
          dbaCustomerServicePhone={dbaCustomerServicePhone}
          riskLevel={riskLevel}
          dbaWebsiteURL={dbaWebsiteURL}
          option_yesno={option_yesno}
          selectedDBAMultipleLocationOption={selectedDBAMultipleLocationOption}
          handleChangeDBAMultipleLocationOption={handleChangeDBAMultipleLocationOption}
          setSelectedDBAState={setSelectedDBAState}
          role={role}
          isMobile={isMobile}
          isTab={isTab}
          sendMailAdditionalLocation={sendMailAdditionalLocation}
        />
        {renderBankDetails()}
        {!isTab ? <div className='center-buttons'>
        <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('tdba', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('tdba', 'next')}

          />
        </div> : <></>}
      </div>
    )
  }
  // RENDER BANK DETAILS
  const renderBankDetails = () => {
    return (
      <div className='form-contents'>
        <div className='form-heading' style={{color: config?.client_config_fe?.button_color}}>
          Bank Account Details
          <hr style={{ margin: '10px 0px', color: config?.client_config_fe?.button_color ,border:'1px solid'}} className='top' />
        </div>
        <BankDetailsSection
          bankRoutingNo={bankRoutingNo}
          bankInfoAPI={bankInfoAPI}
          allowOnlyNumber={allowOnlyNumber}
          setBankRoutingNo={setBankRoutingNo}
          bankName={bankName}
          setBankName={setBankName}
          updateBankSectionStatus={updateBankSectionStatus}
          role={role}
          bankAccountNo={bankAccountNo}
          handleChangeTextInput={handleChangeTextInput}
          handleCopy={handleCopy}
          handlePaste={handlePaste}
          confirmBankAccountNo={confirmBankAccountNo}
          handleConfirmBankAccountNo={handleConfirmBankAccountNo}
          bankPhone={bankPhone}
          bankRoutingNumberErrMessage={bankRoutingNumberErrMessage}
          errorMessage1={errorMessage1}
          alertCopyMsg={alertCopyMsg}
          successMessage1={successMessage1}
          isMobile={isMobile}
          riskLevel={riskLevel}
          errorMessage2={errorMessage2}
        />
      </div>
    )
  }

  const RenderTabButton = (i: any) => {

    return <>
      <div className='three wide field'>
        <Button.Group floated='left'>
          <Button color='blue'className='ownerActive' inverted={ownerActive === i? false : true} onClick= {()=> handleOwnerClick(i)}>
            Owner{i + 1}
          </Button>
          <Button color='blue' icon='close' onClick={() => handleRemoveClick(i)} />
          {ownerSectionData.length - 1 === i && ownerSectionData.length < 4 && (
            <Button color='green' className='margin-left-10' inverted onClick={() => handleAddClick()}>
              {/* Add Owner */} +
            </Button>
          )}
        </Button.Group>
      </div>
    </>
  }

  // RENDER OWNER Buttons
  const renderOwnerButton = () => {
    return ownerSectionData.map((item, i) => {
      const ownerbuttoncolor = config?.client_config_fe?.button_color === '#F16B2E' ? 'orange' : 'blue'            
      return (
        <div>
          {!isTab ? <><div className='three wide field'>
            <Button color={ownerbuttoncolor} className='ownerActive' inverted={ownerActive === i? false : true} onClick= {()=> handleOwnerClick(i)}>
              Owner{i + 1}
            </Button>
            {ownerSectionData.length - 1 === i && ownerSectionData.length < 4 && (
              <Button color='green' inverted onClick={() => handleAddClick()}>
                +
              </Button>
            )}
          </div>
          {(ownerSectionData.length > 1 && (<div className='three wide field' style={{ paddingTop: '10px' }}>
            <Button color='red' inverted onClick={() => handleRemoveClick(i)}>
              -
            </Button>
          </div>))} </> : <>
            {RenderTabButton(i)}
          </>}
        </div>
      )
    })
  }
  // RENDER OWNER
  const renderOwnerInfo = () => {
    return ownerSectionData.map((item, i) => {
      return (
        <div className='ui form'>
          {ownerSectionData.length - 1 === i && hideOwner === false && (
            <div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>First Name <span className="required-text"> *</span> </label>
                  <input
                    type='text'
                    name='firstName'
                    className={ !item.firstName ? 'not-filled' :'form-input'}
                    placeholder='First Name'
                    value={item.firstName}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
                <div className='eight wide field'>
                  <label>Last Name <span className="required-text"> *</span> </label>
                  <input
                    type='text'
                    name='lastName'
                    className={ !item.lastName ? 'not-filled' :'form-input'}
                    placeholder='Last Name'
                    value={item.lastName}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
              </div>
              <div className='inline fields'>
                <div className='eight wide field gdt-100'>
                  <div className='inline-fields'>
                  <label className='no-bold'>Control Owner ?<span className={(role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) ? 'info1-icon' : 'info1-icon-merchant'} 
                  data-tooltip={FIELDS_INFO['Owner / Officer Information'].control_prong.helperText}></span><span className="required-text"> *</span> </label>                
                </div>
              </div>
              {renderControlProng(item.control_prong, i)}
              </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Owner{i + 1} Title <span className="required-text"> *</span> </label>
                  <input
                    type='text'
                    name='ownerTitle'
                    className={ !item.ownerTitle ? 'not-filled' :'form-input'}
                    placeholder='Owner Title'
                    value={item.ownerTitle}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
                <div className='eight wide field'>
                  <label>Home Address <span className="required-text"> *</span> </label>
                  <input
                    className={ (!item.ownerAddress || ownerSectionErrorFileds[i]['address_valid'] !== '') ? 'not-filled' :'form-input'}
                    type='text'
                    name='ownerAddress'
                    value={item.ownerAddress}
                    placeholder='Home Address'
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={() => handleInputBlur(i)}
                  />
                </div>
              </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>City <span className="required-text"> *</span> </label>
                  <input
                    type='text'
                    name='city'
                    className={ (!item.city || ownerSectionErrorFileds[i]['address_valid'] !== '') ? 'not-filled' :'form-input'}
                    placeholder='City'
                    value={item.city}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={() => handleInputBlur(i)}
                  />
                </div>
                <div className='eight wide field'>
                  <label>State <span className="required-text"> *</span> </label>
                  <Dropdown
                    placeholder={isMobile? 'State' : 'Choose One'}
                    className={ (!item.state || ownerSectionErrorFileds[i]['address_valid'] !== '') ? 'not-filled' :'form-input'}
                    name='state'
                    fluid
                    search
                    selection
                    value={item.state}
                    onChange={(e, data) => handleInputChange(e, i, data)}
                    onBlur={() => handleInputBlur(i)}
                    options={states}
                  />
                </div>
              </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Zip Code <span className="required-text"> *</span> </label>
                  <input
                    type='text' name='zip'
                    value={item.zip}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={() => handleInputBlur(i)}
                    className={ (!item.zip || ownerSectionErrorFileds[i]['address_valid'] !== '') ? 'mt-35 not-filled' :'mt-35 form-input'}
                    placeholder='Zip Code'
                  />
                </div>
                <div className='eight wide field'>
                {/* <label>Years At This Address <span className="required-text"> *</span> </label>
                  <input
                    type='text' name='yearsThisAddress'
                    value={item.yearsThisAddress}
                    autoComplete="off"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                    className={ !item.yearsThisAddress ? 'not-filled' :'form-input'}
                    placeholder='Years At This Address'
                  />
                </div> */}
              <label>Time at Residence  <span className="required-text"> *</span> </label>
              <div className='input-wrapper'>
              <div className='eight wide field'>
                <label>Years</label>
                <input type='text'
                  name='residenceYear'
                  className={!item.residenceYear ? 'not-filled' :'form-input'}
                  value={item.residenceYear}
                  autoComplete="new-password"
                  onChange={(e)=>handleTimeAtResidenceYear(e, i)}
                  onBlur={updateownerSectionStatus}
                  onKeyPress={allowNumberText}
                  placeholder='Time at Residence'
                  maxLength={3}
                />
              </div>
              <div className='eight wide field'>
                <label>Months</label>
                <Dropdown
                  placeholder="Choose Months"
                  className={'form-input'}
                  fluid
                  search
                  selection
                  options={monthOptions}
                  onChange={(e, data) => handleTimeAtResidenceMonth(data, i)}
                  value={item.residenceMonth}
                />
            </div>
            </div>
            </div>
              </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Mobile Number <span className="required-text"> *</span> </label>
                  <input
                    type='text'
                    name='mobileNo'
                    className={ !item.mobileNo ? 'not-filled' :'form-input'}
                    placeholder='Mobile Number'
                    value={item.mobileNo}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
                <div className='eight wide field'>
                  <label>DL/ID # <span className="required-text"> *</span> </label>
                  <input
                    type={dlIdShown[i] ? 'text' : 'password'}
                    placeholder={(role === ROLES.SUPER_ADMIN ||role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) ? !isMobile? 'Must be completed by merchant' : 'DL/ID #  ': !isMobile? '' : 'DL/ID # '}
                    name='dl_Id'
                    className={ `${!item.dl_Id && role !== ROLES.SUPER_ADMIN ? 'not-filled' :'form-input'} placeholder-visible`}
                    value={item.dl_Id}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                    // maxLength={20}
                    disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED)}
                  />
                  <Icon
                    name={eyeIcondl[i] ? 'eye slash' : 'eye'}
                    size='large'
                    className='eye-icon-owner-dlId ipad-eye-icon'
                    onClick={() => toggleDlIdVisiblity(i)}
                    disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED)}
                  />
                </div>
              </div>
              <div className='fields'>

                <div className='eight wide field'>
                  <label>Document Type<span className="required-text"> *</span> </label>
                  <Dropdown
                    placeholder={isMobile? 'Document Type' : 'Choose One'}
                    name='documentType'
                    className={ !item.documentType ? 'not-filled' :'form-input'}
                    fluid
                    search
                    selection
                    options={document_type}
                    value={item.documentType}
                    onChange={(e, data) => handleInputChange(e, i, data)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
                {(item.documentType !== 'Passport') && (
                  <div className='eight wide field'>
                    <label>DL/ID Issue State <span className="required-text"> *</span> </label>
                    <Dropdown
                      placeholder={isMobile? 'DL/ID Issue State' : 'Choose One'}
                      className={ !item.issueState ? 'not-filled' :'form-input'}
                      name='issueState'
                      fluid
                      search
                      selection
                      options={states}
                      value={item.issueState ? item.issueState : ''}
                      onChange={(event, { value }: any) => {
                        const list: any = [...ownerSectionData]
                        list[i]['issueState'] = value
                        setOwnerSectionData(list)
                      }}
                      onBlur={updateownerSectionStatus}
                    />
                  </div>
                )}
              </div>
              <div className='fields'>
                <div className='eight wide field  semantic-date-input'>
                  <label>DL/ID Issue Date<span className="required-text"> *</span> </label>
                  <SemanticDatepicker
                    placeholder={isMobile ? 'DL/ID Issue Date (MM-DD-YYYY)' : 'MM-DD-YYYY'}
                    format='MM-DD-YYYY'
                    datePickerOnly={false}
                    className={ `${!item.issueDate ? 'not-filled' :'form-input'} placeholder-visible-child`}
                    name='issueDate'
                    autoComplete='new-password'
                    value={item.issueDate ? new Date(item.issueDate) : null}
                    onChange={(event, { value }: any) => {
                      const list: any = [...ownerSectionData]
                      list[i]['issueDate'] = value < new Date ? value : null
                      const errorList = [...ownerSectionErrorFileds]
                      errorList[i]['dl_issue_date_valid'] = value < new Date() ? '' : 'Invalid issue date'
                      setOwnerSectionData(list) 
                    }}
                    onBlur={updateownerSectionStatus}
                    filterDate={(date) => {
                      const now = new Date();
                      return date < now;
                    }}
                  />
                </div>
                <div className='eight wide field semantic-date-input'>
                  <label>DL/ID Expire Date <span className="required-text"> *</span> </label>
                  <SemanticDatepicker
                    placeholder={isMobile ? 'DL/ID Expire Date (MM-DD-YYYY)' : 'MM-DD-YYYY'}
                    format='MM-DD-YYYY'
                    name='expireDate'
                    autoComplete='new-password'
                    className={ `${!item.expireDate ? 'not-filled' :'form-input'} placeholder-visible-child`}
                    datePickerOnly={false}
                    value={item.expireDate ? new Date(item.expireDate) : null}
                    onChange={(event, { value }: any) => {
                      const list: any = [...ownerSectionData]
                      list[i]['expireDate'] = value > new Date() ? value : null
                      const errorList = [...ownerSectionErrorFileds]
                      errorList[i]['dl_expire_date_valid'] = value > new Date() ? '' : 'Invalid expire date'
                      setOwnerSectionData(list)
                    }}
                    onBlur={updateownerSectionStatus}
                    filterDate={(date) => {
                      if (!!item.issueDate) {
                        const now = new Date(item.issueDate);
                        return date > now;
                      }
                      return true;
                    }}
                  />
                </div>
              </div>
              { ownerSectionErrorFileds[i]['dl_issue_date_valid'] || ownerSectionErrorFileds[i]['dl_expire_date_valid'] ? <div className='fields'>
                <div className='eight wide field'>{ ownerSectionErrorFileds[i]['dl_issue_date_valid'] && <span className='error-message'>{ownerSectionErrorFileds[i]['dl_issue_date_valid']}</span>}</div>
                <div className='eight wide field'>{ ownerSectionErrorFileds[i]['dl_expire_date_valid'] && <span className='error-message'>{ownerSectionErrorFileds[i]['dl_expire_date_valid']}</span>}</div>
              </div> : <></>}
              <div className='fields'>
                <div className='eight wide field'>
                  <label>SSN (xxx-xx-xxxx)<span className="required-text"> *</span> </label>
                  <input
                    type={ssnShown[i] ? 'text' : 'password'}
                    placeholder={(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) ? !isMobile? 'Must be completed by merchant' : 'SSN (xxx-xx-xxxx)': !isMobile? '' : 'SSN (xxx-xx-xxxx)'}
                    name='ssn'
                    className={ `${(!item.ssn  ||  ownerSectionData[i]["ssnInvalid"]) && role !== ROLES.SUPER_ADMIN  ? 'not-filled' :'form-input'} placeholder-visible`}
                    value={item.ssn}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                    disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER|| role === ROLES.SALES_LIMITED)}
                    minLength={11}
                    maxLength={11}
                  />
                  <Icon
                    name={eyeIconssn[i] ? 'eye slash' : 'eye'}
                    size='large'
                    className='eye-icon-owner-dlId ipad-eye-icon'
                    onClick={() => togglessnVisiblity(i)}
                    disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER|| role === ROLES.SALES_LIMITED)}
                  />
                   {(item.ssnInvalid) && <span className='owner-email-validation'></span>}
                </div>
                <div className='eight wide field semantic-date-input'>
                  <label>Date Of Birth<span className="required-text"> *</span> </label>
                  <SemanticDatepicker
                    placeholder={isMobile ? 'Date Of Birth (MM-DD-YYYY)' : 'MM-DD-YYYY'}
                    format='MM-DD-YYYY'
                    name='dob'
                    autoComplete='new-password'
                    className={ `${!item.dob ? 'not-filled' :'form-input'} placeholder-visible-child`}
                    datePickerOnly={false}
                    value={item.dob ? new Date(item.dob) : null}
                    onChange={(event, { value }: any) => {
                      const list: any = [...ownerSectionData]
                      list[i]['dob'] = value < new Date() ? value : null
                      const errorList = [...ownerSectionErrorFileds]
                      errorList[i]['dob_valid'] = value < new Date() ? '' : 'invalid date of birth'
                      setOwnerSectionData(list)
                    }}
                    onBlur={updateownerSectionStatus}
                    filterDate={(date) => {
                      const now = new Date();
                      return date < now;
                    }}
                  />
                </div>

              </div>
              { ownerSectionErrorFileds[i]['dob_valid'] && <div className='fields'>
                <div className='eight wide field'></div>
                <div className='eight wide field'><span className='error-message'>{ownerSectionErrorFileds[i]['dob_valid']}</span></div>
              </div>}
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Owner Email <span className="required-text"> *</span> </label>
                  <input
                    type='text' name='ownerEmail'
                    value={item.ownerEmail}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)} 
                    className={ (!item.ownerEmail || (ownerEmailErrorMessage || ownerSectionErrorFileds[i]['emailInvalid'] || ownerSectionErrorFileds[i]['emailAlreadyExist'])) ? 'not-filled' :'form-input'}
                    onBlur={updateownerSectionStatus}
                    placeholder={isMobile? 'Owner Email' : ''}
                  />
                  { (ownerSectionErrorFileds[i]['emailInvalid'] || ownerSectionErrorFileds[i]['emailAlreadyExist']) && <span className='owner-email-validation'>{ownerSectionErrorFileds[i]['emailInvalid'] || ownerSectionErrorFileds[i]['emailAlreadyExist']}</span>}
                </div>
                <div className='eight wide field'>
                  <label>% Of Ownership<span className="required-text"> *</span> </label>
                  <input
                    type='text' name='ownership' 
                    value={item.ownership}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                    className={ !item.ownership ? 'not-filled' :'form-input'}
                    placeholder='% Of Ownership'
                    onKeyPress={allowOnlyDecimalNumber}
                  />
                   { (ownerSectionErrorFileds[i]['invalidOwnership'] || ownerSectionErrorFileds[i]['ownership_valid']) && <span className='owner-email-validation'>{ownerSectionErrorFileds[i]['invalidOwnership'] || ownerSectionErrorFileds[i]['ownership_valid']}</span>}
                   </div>

              </div>
              <div className='fields'>

                <div className='eight wide field'>
                  <label>Prior Bankrupties<span className="required-text"> *</span> </label>
                  <Dropdown
                    placeholder={isMobile? 'Prior Bankrupties' : 'Choose One'}
                    className={ !item.priorBankrupties ? 'not-filled' : 'form-input'}
                    name='priorBankrupties'
                    fluid
                    search
                    selection
                    options={option_yesno}
                    value={item.priorBankrupties}
                    onChange={(e, data) => handleInputChange(e, i, data)}
                    onBlur={updateownerSectionStatus}
                  />
                {(item.priorBankrupties === 'Yes') && (
                  <div className='eight wide field semantic-date-input'>
                    <label>Date Discharge<span className="required-text"> *</span> </label>
                    <SemanticDatepicker
                      placeholder={isMobile ? 'Date Discharge (MM-DD-YYYY)' : 'MM-DD-YYYY'}
                      format='MM-DD-YYYY'
                      name='dateDischarge'
                      className={ !item.dateDischarge ? 'not-filled' :'form-input'}
                      datePickerOnly={false}
                      value={item.dateDischarge ? new Date(item.dateDischarge) : null}
                      onChange={(event, { value }: any) => {
                        const list: any = [...ownerSectionData]
                        list[i]['dateDischarge'] = value
                        const errorList = [...ownerSectionErrorFileds]
                        errorList[i]['date_discharge_valid'] = value < new Date() ? '' : 'invalid date of discharge'
                        setOwnerSectionData(list)
                      }}
                      filterDate={(date) => {
                        const now = new Date();
                        return date < now;
                      }}
                      onBlur={updateownerSectionStatus}
                    />
                  </div>
                )}
              </div>
              </div>
              { ownerSectionErrorFileds[i]['date_discharge_valid'] && <div className='fields'>
                <div className='eight wide field'></div>
                <div className='eight wide field'><span className='error-message'>{ownerSectionErrorFileds[i]['date_discharge_valid']}</span></div>
              </div>}
            </div>
          )}
          {ownerActive === i && hideOwner === true && (
            <div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>First Name <span className="required-text"> *</span> </label>
                  <input
                    type='text'
                    name='firstName'
                    className={ !item.firstName ? 'not-filled' :'form-input'}
                    placeholder='First Name'
                    value={item.firstName}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
                <div className='eight wide field'>
                  <label>Last Name <span className="required-text"> *</span> </label>
                  <input
                    type='text'
                    name='lastName'
                    className={ !item.lastName ? 'not-filled' :'form-input'}
                    placeholder='Last Name'
                    value={item.lastName}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
              </div>
              <div className='inline fields'>
                <div className='eight wide field gdt-100'>
                  <div className='inline-fields'>
                  <label className='no-bold'>Control Owner ?<span className={(role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) ? 'info1-icon' : 'info1-icon-merchant'} 
                  data-tooltip={FIELDS_INFO['Owner / Officer Information'].control_prong.helperText}></span><span className="required-text"> *</span> </label>
                </div>
              </div>
              {renderControlProng(item.control_prong, i)}
              </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Owner{i + 1} Title <span className="required-text"> *</span> </label>
                  <input
                    type='text'
                    name='ownerTitle'
                    className={ !item.ownerTitle ? 'not-filled' :'form-input'}
                    placeholder='Owner Title'
                    value={item.ownerTitle}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
                <div className='eight wide field'>
                  <label>Home Address <span className="required-text"> *</span> </label>
                  <input
                    className={ (!item.ownerAddress || ownerSectionErrorFileds[i]['address_valid'] !== '') ? 'not-filled' :'form-input'}
                    placeholder='Home Address'
                    type='text'
                    name='ownerAddress'
                    value={item.ownerAddress}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={() => handleInputBlur(i)}
                  />
                </div>
              </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>City <span className="required-text"> *</span> </label>
                  <input
                    type='text'
                    name='city'
                    className={ (!item.city || ownerSectionErrorFileds[i]['address_valid'] !== '') ? 'not-filled' :'form-input'}
                    placeholder='City'
                    value={item.city}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={() => handleInputBlur(i)}
                  />
                </div>
                <div className='eight wide field'>
                  <label>State <span className="required-text"> *</span> </label>
                  <Dropdown
                    placeholder={isMobile? 'State' : 'Choose One'}
                    className={ (!item.state || ownerSectionErrorFileds[i]['address_valid'] !== '') ? 'not-filled' :'form-input'}
                    name='state'
                    fluid
                    search
                    selection
                    value={item.state}
                    onChange={(e, data) => handleInputChange(e, i, data)}
                    onBlur={() => handleInputBlur(i)}
                    options={states}
                  />
                </div>
              </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Zip Code <span className="required-text"> *</span> </label>
                  <input
                    type='text' name='zip'
                    value={item.zip}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={() => handleInputBlur(i)}
                    className={ (!item.zip || ownerSectionErrorFileds[i]['address_valid'] !== '') ? 'mt-35 not-filled' :'mt-35 form-input'}
                    placeholder='Zip Code'
                  />
                </div>
                <div className='eight wide field'>
                {/* <label>Years At This Address <span className="required-text"> *</span> </label>
                  <input
                    type='text' name='yearsThisAddress'
                    value={item.yearsThisAddress}
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                    className={ !item.yearsThisAddress ? 'not-filled' :'form-input'}
                    placeholder='Years At This Address'
                  />
                </div> */}
              <label>Time at Residence <span className="required-text"> *</span> </label>
              <div className='input-wrapper'>
              <div className='eight wide field'>
                <label>Years</label>
                <input type='text'
                  name='residenceYear'
                  className={!item.residenceYear ? 'not-filled' :'form-input'}
                  value={item.residenceYear}
                  autoComplete="new-password"
                  onChange={(e)=>handleTimeAtResidenceYear(e, i)}
                  onBlur={updateownerSectionStatus}
                  onKeyPress={allowNumberText}
                  placeholder='Time at Residence'
                  maxLength={3}
                />
              </div>
              <div className='eight wide field'>
                <label>Months</label>
                <Dropdown
                  placeholder="Choose Months"
                  className={'form-input'}
                  fluid
                  search
                  selection
                  options={monthOptions}
                  onChange={(e, data) => handleTimeAtResidenceMonth(data, i)}
                  value={item.residenceMonth}
                />
            </div>
            </div>
            </div>
              </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Mobile Number <span className="required-text"> *</span> </label>
                  <input
                    type='text'
                    name='mobileNo'
                    className={ !item.mobileNo ? 'not-filled' :'form-input'}
                    placeholder='Mobile Number'
                    value={item.mobileNo}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
                <div className='eight wide field'>
                  <label>DL/ID # <span className="required-text"> *</span> </label>
                  <input
                    type={dlIdShown[i] ? 'text' : 'password'}
                    name='dl_Id'
                    value={item.dl_Id}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                    className={ `${!item.dl_Id  && role !== ROLES.SUPER_ADMIN ? 'not-filled' :'form-input'} placeholder-visible`}
                    disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN  || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED)}
                    placeholder={(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN  || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) ? !isMobile? 'Must be completed by merchant' : 'DL/ID #  ': !isMobile? '' : 'DL/ID # '}
                  />
                  <Icon
                    name={eyeIcondl[i] ? 'eye slash' : 'eye'}
                    size='large'
                    className='eye-icon-owner-dlId ipad-eye-icon'
                    onClick={() => toggleDlIdVisiblity(i)}
                    disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER|| role === ROLES.SALES_LIMITED)}
                  />
                </div>
              </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Document Type<span className="required-text"> *</span></label>
                  <Dropdown
                    placeholder={isMobile? 'Document Type' : 'Choose One'}
                    name='documentType'
                    className={ !item.documentType ? 'not-filled' :'form-input'}
                    fluid
                    search
                    selection
                    options={document_type}
                    value={item.documentType}
                    onChange={(e, data) => handleInputChange(e, i, data)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
                {(item.documentType !== 'Passport') && (
                  <div className='eight wide field'>
                    <label>DL/ID Issue State <span className="required-text"> *</span> </label>
                    <Dropdown
                      placeholder={isMobile? 'DL/ID Issue State' : 'Choose One'}
                      className={ !item.issueState ? 'not-filled' :'form-input'}
                      name='issueState'
                      fluid
                      search
                      selection
                      options={states}
                      value={item.issueState ? item.issueState : ''}
                      onChange={(event, { value }: any) => {
                        const list: any = [...ownerSectionData]
                        list[i]['issueState'] = value
                        setOwnerSectionData(list)
                      }}
                      onBlur={updateownerSectionStatus}
                    />
                  </div>
                )}
              </div>
              <div className='fields'>
                <div className='eight wide field  semantic-date-input'>
                  <label>DL/ID Issue Date<span className="required-text"> *</span> </label>
                  <SemanticDatepicker
                    placeholder={isMobile ? 'DL/ID Issue Date (MM-DD-YYYY)' : 'MM-DD-YYYY'}
                    format='MM-DD-YYYY'
                    name='issueDate'
                    autoComplete='new-password'
                    className={ `${!item.issueDate ? 'not-filled' :'form-input'} placeholder-visible-child`}
                    datePickerOnly={false}
                    value={item.issueDate ? new Date(item.issueDate) : null}
                    onChange={(event, { value }: any) => {
                      const list: any = [...ownerSectionData]
                      list[i]['issueDate'] = value < new Date() ? value : null
                      const errorList = [...ownerSectionErrorFileds]
                      errorList[i]['dl_issue_date_valid'] = value < new Date() ? '' : 'invalid issue date'
                      setOwnerSectionData(list)
                    }}
                    filterDate={(date) => {
                      const now = new Date();
                      return date < now;
                    }}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
                <div className='eight wide field semantic-date-input'>
                  <label>DL/ID Expire Date <span className="required-text"> *</span> </label>
                  <SemanticDatepicker
                    placeholder={isMobile ? 'DL/ID Expire Date (MM-DD-YYYY)' : 'MM-DD-YYYY'}
                    format='MM-DD-YYYY'
                    datePickerOnly={false}
                    name='expireDate'
                    autoComplete='new-password'
                    className={ `${!item.expireDate ? 'not-filled' :'form-input'} placeholder-visible-child`}
                    value={item.expireDate ? new Date(item.expireDate) : null}
                    onChange={(event, { value }: any) => {
                      const list: any = [...ownerSectionData]
                      list[i]['expireDate'] = value > new Date() ? value : null
                      const errorList = [...ownerSectionErrorFileds]
                      errorList[i]['dl_expire_date_valid'] = value > new Date() ? '' : 'invalid expire date'
                      setOwnerSectionData(list)
                    }}
                    filterDate={(date) => {
                      if (!!item.issueDate) {
                        const now = new Date(item.issueDate);
                        return date > now;
                      }
                      return true;
                    }}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
              </div>
              { (ownerSectionErrorFileds[i]['dl_expire_date_valid'] || ownerSectionErrorFileds[i]['dl_issue_date_valid']) && 
              <div className='fields'>
                <div className='eight wide field'>{ ownerSectionErrorFileds[i]['dl_issue_date_valid'] && <span className='error-message'>{ownerSectionErrorFileds[i]['dl_issue_date_valid']}</span>}</div>
                <div className='eight wide field'>{ ownerSectionErrorFileds[i]['dl_expire_date_valid'] && <span className='error-message'>{ownerSectionErrorFileds[i]['dl_expire_date_valid']}</span>}</div>
              </div>}
              <div className='fields'>
                <div className='eight wide field'>
                  <label>SSN (xxx-xx-xxxx)<span className="required-text"> *</span> </label>
                  <input
                    type={ssnShown[i] ? 'text' : 'password'}
                    name='ssn' value={item.ssn}
                    autoComplete="new-password"
                    className={ `${ (!item.ssn || ownerSectionData[i]["ssnInvalid"]) && role !== ROLES.SUPER_ADMIN ? 'not-filled' :'form-input'} placeholder-visible`}
                    onChange={(e) => handleInputChange(e, i)}
                    disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED)}
                    placeholder={(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED) ? !isMobile? 'Must be completed by merchant' : 'SSN (xxx-xx-xxxx)': !isMobile? '' : 'SSN (xxx-xx-xxxx)'}
                    onBlur={updateownerSectionStatus}
                    minLength={11}
                    maxLength={11}
                  />
                  <Icon
                    name={eyeIconssn[i] ? 'eye slash' : 'eye'}
                    size='large'
                    className='eye-icon-owner-dlId ipad-eye-icon'
                    onClick={() => togglessnVisiblity(i)}
                    disabled={!!(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER|| role === ROLES.SALES_LIMITED)}
                  />
                {(item.ssnInvalid) && <span className='owner-email-validation'></span>}
                </div>
                <div className='eight wide field semantic-date-input'>
                  <label>Date Of Birth<span className="required-text"> *</span> </label>
                  <SemanticDatepicker
                    placeholder={isMobile ? 'Date Of Birth (MM-DD-YYYY)' : 'MM-DD-YYYY'}
                    format='MM-DD-YYYY'
                    name='dob'
                    autoComplete='new-password'
                    className={ `${!item.dob ? 'not-filled' :'form-input'} placeholder-visible-child`}
                    datePickerOnly={false}
                    value={item.dob ? new Date(item.dob) : null}
                    onChange={(event, { value }: any) => {
                      const list: any = [...ownerSectionData]
                      list[i]['dob'] = value < new Date() ? value : null
                      const errorList = [...ownerSectionErrorFileds]
                      errorList[i]['dob_valid'] = value < new Date() ? '' : 'invalid date of birth'
                      setOwnerSectionData(list)
                    }}
                    filterDate={(date) => {
                      const now = new Date();
                      return date < now;
                    }}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
              </div>
              { ownerSectionErrorFileds[i]['dob_valid']  && <div className='fields'>
                <div className='eight wide field'></div>
                <div className='eight wide field'><span className='error-message'>{ownerSectionErrorFileds[i]['dob_valid']}</span></div>
              </div>}
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Owner Email <span className="required-text"> *</span> </label>
                  <input
                    type='text' name='ownerEmail'
                    value={item.ownerEmail}
                    autoComplete="new-password"
                    className={ !item.ownerEmail ? 'not-filled' :'form-input'}
                    placeholder={isMobile? 'Owner Email' : ''}
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={updateownerSectionStatus}
                  />
                  { (ownerSectionErrorFileds[i]['emailInvalid'] || ownerSectionErrorFileds[i]['emailAlreadyExist']) && <span className='owner-email-validation'>{ownerSectionErrorFileds[i]['emailInvalid'] || ownerSectionErrorFileds[i]['emailAlreadyExist']}</span>}
                 </div>
                
                <div className='eight wide field'>
                  <label>% Of Ownership<span className="required-text"> *</span> </label>
                  <input
                    type='text' name='ownership' 
                    value={item.ownership}
                    autoComplete="new-password"
                    onChange={(e) => handleInputChange(e, i)}
                    className={ !item.ownership ? 'not-filled' :'form-input'}
                    placeholder='% Of Ownership'
                    onBlur={updateownerSectionStatus}
                    onKeyPress={allowOnlyDecimalNumber}
                  />
                   { (ownerSectionErrorFileds[i]['invalidOwnership'] || ownerSectionErrorFileds[i]['ownership_valid']) && <span className='owner-email-validation'>{ownerSectionErrorFileds[i]['invalidOwnership'] || ownerSectionErrorFileds[i]['ownership_valid']}</span>}
                </div>
                  </div>
              <div className='fields'>
                <div className='eight wide field'>
                  <label>Prior Bankrupties<span className="required-text"> *</span> </label>
                  <Dropdown
                    placeholder={isMobile? 'Bankrupties' : 'Choose One'}
                    className={ !item.priorBankrupties ? 'not-filled' : 'form-input'}
                    name='priorBankrupties'
                    fluid
                    search
                    selection
                    options={option_yesno}
                    value={item.priorBankrupties}
                    onChange={(e, data) => handleInputChange(e, i, data)}
                    onBlur={updateownerSectionStatus}
                  />
                </div>
                {(item.priorBankrupties === 'Yes') && (
                  <div className='eight wide field semantic-date-input'>
                    <label>Date Discharge<span className="required-text"> *</span> </label>
                    <SemanticDatepicker
                      placeholder={isMobile ? 'Date Discharge (MM-DD-YYYY)' : 'MM-DD-YYYY'}
                      format='MM-DD-YYYY'
                      datePickerOnly={false}
                      name='dateDischarge'
                      className={ !item.dateDischarge ? 'not-filled' :'form-input'}
                      value={item.dateDischarge ? new Date(item.dateDischarge) : null}
                      onChange={(event, { value }: any) => {
                        const list: any = [...ownerSectionData]
                        list[i]['dateDischarge'] = value
                        const errorList = [...ownerSectionErrorFileds]
                        errorList[i]['date_discharge_valid'] = value < new Date() ? '' : 'invalid date of discharge'
                        setOwnerSectionData(list)
                      }}
                      filterDate={(date) => {
                        const now = new Date();
                        return date < now;
                      }}
                      onBlur={updateownerSectionStatus}
                    />
                  </div>
                )}
              </div>
              { ownerSectionErrorFileds[i]['date_discharge_valid'] && <div className='fields'>
                <div className='eight wide field'></div>
                <div className='eight wide field'><span className='error-message'>{ownerSectionErrorFileds[i]['date_discharge_valid']}</span></div>
              </div>}
            </div>
          )}
        </div>

      )
    })
  }
  const renderOwnerDetails = () => {
    return (
      <div className='form-contents'>
        <div>
          <div className='form-headingleftpadding' style={{ color: config?.client_config_fe?.button_color }}>
            <span
              className='navigationleft-icon'
              onClick={() => handlePrevNextNavigation('owner', 'prev')}
            />
            Owner/Officer Information
            <span
              className='navigationright-icon'
              onClick={() => handlePrevNextNavigation('owner', 'next')}
            />
            <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='SAVE'
            className={disableSave ? "not-active save-button-right button-border" : "save-button-right button-border"}
            onClick={(event: any) => {
              if (role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
                handleSaveAppMerchant(event, false);
              } else {
                handleSaveApp(false, event, false)
              }

            }}
          />
          </div>
          <hr className='top' style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}} />
          <div className="form-fields owner-info-section">
            {ownerSectionErrorFileds[ownerActive].address_valid !== '' && (
              <div className='warning-text'>{ownerInfoAddressErrMessage}</div>
            )}
            {(
              <div className='warning-text'> * List all Owners with 25% or more ownership</div>
            )}
            <div className={!isTab ? 'inline fields' : 'tab-flex-button inline fields'}>{renderOwnerButton()}</div>
            <div>
              {renderOwnerInfo()}
            </div>

          </div>
        </div>
        {!isTab ? <div className='center-buttons'>
        <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('owner', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('owner', 'next')}

          />
        </div> : <></>}
      </div>
    )
  }
  // RENDER BUSINESS DETAILS
  const renderBusinessDetails = () => {
    return (
      <div className='form-contents'>

        <div className='form-headingleftpadding' style={{ color: config?.client_config_fe?.button_color }}>
          <span
            className='navigationleft-icon'
            onClick={() => handlePrevNextNavigation('business', 'prev')}
          />
          Business Details
          <span
            className='navigationright-icon'
            onClick={() => handlePrevNextNavigation('business', 'next')}
          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='SAVE'
            className={disableSave ? "not-active save-button-right button-border" : "save-button-right button-border"}
            onClick={(event: any) => {
              if (role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
                handleSaveAppMerchant(event, false);
              } else {
                handleSaveApp(false, event, false)
              }

            }}
          />

        </div>
        <hr className='top' style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
        <BusinessDetailsSection
          salesSumWarningText={salesSumWarningText}
          salesSumWarningTextFlag={salesSumWarningTextFlag}
          handleChangeRetailLocation={handleChangeRetailLocation}
          isRetailLocation={isRetailLocation}
          businessAddress={businessAddress}
          setBusinessAddress={setBusinessAddress}
          updatebusinessSectionStatus={updatebusinessSectionStatus}
          business_location_options={business_location_options}
          businessLocation={businessLocation}
          handleChangeBusinessLocation={handleChangeBusinessLocation}
          otherBusinessLocation={otherBusinessLocation}
          numberOfLocations={numberOfLocations}
          setNumberOfLocations={setNumberOfLocations}
          option_yesno={option_yesno}
          isSeasonalMerchant={isSeasonalMerchant}
          handleChangeSeasonalMerchant={handleChangeSeasonalMerchant}
          isExported={isExported}
          handleChangeIsExported={handleChangeIsExported}
          foreignSalesPct={foreignSalesPct}
          setForeignSalesPct={setForeignSalesPct}
          isOwnInventory={isOwnInventory}
          handleChangeOwnInventory={handleChangeOwnInventory}
          vendorNames={vendorNames}
          setVendorNames={setVendorNames}
          isInventoryStoredatRetailLocation={isInventoryStoredatRetailLocation}
          handleChangeInventoryStoredatRetailLocation={handleChangeInventoryStoredatRetailLocation}
          disableAddress={disableAddress}
          inventoryLocation={inventoryLocation}
          setInventoryLocation={setInventoryLocation}
          sellarea={sellarea}
          selectedSellNationalLocal={selectedSellNationalLocal}
          handleChangeSellNationalLocal={handleChangeSellNationalLocal}
          internetSalesPct={internetSalesPct}
          handleChangeTextInput={handleChangeTextInput}
          allowOnlyNumber={allowOnlyNumber}
          telephoneSalesPct={telephoneSalesPct}
          retailSalesPct={retailSalesPct}
          oneTimePct={oneTimePct}
          billingSumWarningTextFlag={billingSumWarningTextFlag}
          advertisingMethods={advertisingMethods}
          setAdvertisingMethods={setAdvertisingMethods}
          salesProcessDesc={salesProcessDesc}
          setSalesProcessDesc={setSalesProcessDesc}
          charge_customer={charge_customer}
          selectedChargeCustomerMethod={selectedChargeCustomerMethod}
          handleChangeChargeCustomerMethod={handleChangeChargeCustomerMethod}
          daysToDelivery={daysToDelivery}
          setDaysToDelivery={setDaysToDelivery}
          returnPolicyDesc={returnPolicyDesc}
          setReturnPolicyDesc={setReturnPolicyDesc}
          isPrevProcessorTerminated={isPrevProcessorTerminated}
          handleChangePrevProcessorTerminated={handleChangePrevProcessorTerminated}
          retrive_info={retrive_info}
          selectedSendChargeback_AddressType={selectedSendChargeback_AddressType}
          handleChangeSendChargeback_AddressType={handleChangeSendChargeback_AddressType}
          legalBusinessFax={legalBusinessFax}
          fullFillmentCompanyOptions={fullFillmentCompanyOptions}
          fulfillmentCompanyName={fulfillmentCompanyName}
          handleFullFillmentCompanyChange={handleFullFillmentCompanyChange}
          fulfillmentCompanyNameFreeFormText={fulfillmentCompanyNameFreeFormText}
          setFulfillmentCompanyNameFreeFormText={setFulfillmentCompanyNameFreeFormText}
          crmCompanyOptions={crmCompanyOptions}
          crmCompanyName={crmCompanyName}
          handleCRMCompanyChange={handleCRMCompanyChange}
          crmCompanyNameFreeFormText={crmCompanyNameFreeFormText}
          setCrmCompanyNameFreeFormText={setCrmCompanyNameFreeFormText}
          chargebackCompanyOptions={chargebackCompanyOptions}
          chargeBackCompanyName={chargeBackCompanyName}
          handleChargebackCompanyChange={handleChargebackCompanyChange}
          chargeBackCompanyNameFreeFormText={chargeBackCompanyNameFreeFormText}
          setChargeBackCompanyNameFreeFormText={setChargeBackCompanyNameFreeFormText}
          affiliateMarketingOptions={affiliateMarketingOptions}
          affliateMarketingPartnerName={affliateMarketingPartnerName}
          handleAffiliateMarketingChange={handleAffiliateMarketingChange}
          affliateMarketingPartnerNameFreeFormText={affliateMarketingPartnerNameFreeFormText}
          setAffliateMarketingPartnerNameFreeFormText={setAffliateMarketingPartnerNameFreeFormText}
          callCenterOptions={callCenterOptions}
          callCenterName={callCenterName}
          handleCallCenterChange={handleCallCenterChange}
          callCenterNameFreeFormText={callCenterNameFreeFormText}
          setCallCenterNameFreeFormText={setCallCenterNameFreeFormText}
          setOtherBusinessLocation={setOtherBusinessLocation}
          monthlyPct={monthlyPct}
          quarterlyPct={quarterlyPct}
          yearlyPct={yearlyPct}
          seasonalMonth={seasonalMonth}
          billingMethod={billingMethod}
          setSeasonalMonthValue={setSeasonalMonthValue}
          seasonalMonthValue={seasonalMonthValue}
          setSeasonalMonth={setSeasonalMonth}
          billingSumWarningText={billingSumWarningText}
          condBusiDispValues={condBusiDispValues}
          role={role}
          productStoredAtPhysicalAdd_yesno={productStoredAtPhysicalAdd_yesno} 
          sellService={sellService} 
          sellProduct={sellProduct} 
          handleService={handleService} 
          handleProduct={handleProduct} 
          isMobile={isMobile}
          isTab={isTab}
          rdrPreferredVendorOptions={rdrPreferredVendorOptions}
          rdrPreferredVendor={rdrPreferredVendor}
          handleRDRVendorChange={handleRDRVendorChange}
          riskLevel={riskLevel}
        />
        {!isTab ? <div className='center-buttons'>
        <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('business', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('business', 'next')}

          />
        </div> : <></>}
      </div>
    )
  }
  // RENDER TRANSACTION INFO
  const renderTransactionInfo = () => {
    return (
      <div className='form-contents'>

        <div className='form-headingleftpadding' style={{ color: config?.client_config_fe?.button_color }}>
          <span
            className='navigationleft-icon'
            onClick={() => handlePrevNextNavigation('transaction', 'prev')}
          />
          Transaction Information
          <span
            className='navigationright-icon'
            onClick={() => handlePrevNextNavigation('transaction', 'next')}
          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='SAVE'
            className={disableSave ? "not-active save-button-right button-border" : "save-button-right button-border"}
            onClick={(event: any) => {
              if (role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
                handleSaveAppMerchant(event, false);
              } else {
                handleSaveApp(false, event, false)
              }

            }}
          />
        </div><hr className='top' style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
        <TransactionInformationSection
          monthAvgSales={monthAvgSales}
          handleChangeTextInput={handleChangeTextInput}
          updatetransactionSectionStatus={updatetransactionSectionStatus}
          pricingCardValue={pricingCardValue}
          amexMonthAvgSales={amexMonthAvgSales}
          avgTicketAmount={avgTicketAmount}
          highestTicketAmount={highestTicketAmount} 
          publicSalesPCT={publicSalesPCT}
          allowOnlyNumber={allowOnlyNumber}
          businessTransPCT={businessTransPCT}
          salesTotalWarningText={salesTotalWarningText}
          avgSalesWarning={avgSalesWarning}
          avgTicketAmountWarning={avgTicketAmountWarning}
          highestTicketAmountWarning={highestTicketAmountWarning} 
          amexMonthAvgWarning={amexMonthAvgWarning}   
          isMobile={isMobile}
          riskLevel={riskLevel}
        />
        {!isTab ? <div className='center-buttons'>
        <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('transaction', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('transaction', 'next')}

          />
        </div> : <></>}
      </div>
    )
  }

  const getProductCost = (product: any) => {
    if (product) {
      const index = productPrices.findIndex((item: any) => {
        return item.startsWith(product)
      })
      const productValue: string = productPrices[index]
      const price = product.split("__$")[1]
      return price
    }

    return ""
  }
  // RENDER EQUIPMENT INFORMATION
  const renderEquipmentInfo = () => {
    let merchantWithEquip = (role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) && numberOfEquipments !== 0;
    return (
      <div className='form-contents'>
        <div className='form-headingleftpadding' style={{ color: config?.client_config_fe?.button_color }}>
          <span
            className='navigationleft-icon'
            onClick={() => handlePrevNextNavigation('equipment', 'prev')}
          />
          Equipment Information
          {(((businessConduct.includes('E-Commerce') &&
            businessCategory === 'E COMMERCE' &&
            businessType === 'CBD' &&
            (selectedBankOption === 'PB&T' || selectedBankOption === 'Esquire')
          ) || (selectedSellTime === 'Trial')) && <span
              className='navigationright-icon'
              onClick={() => handlePrevNextNavigation('equipment', 'next')}
            />)}
            <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='SAVE'
            className={disableSave ? "not-active save-button-right button-border" : "save-button-right button-border"}
            onClick={(event: any) => {
              if (role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
                handleSaveAppMerchant(event, false);
              } else {
                handleSaveApp(false, event, false)
              }

            }}
          />
        </div>
        <hr className='top' style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
        <EquipmentInformationsection
          equipmentSectionData={equipmentSectionData}
          role={role}
          equipmentActive={equipmentActive}
          globalFormat={globalFormat}
          getProductCost={getProductCost}
          setMinEquipmentCost={setMinEquipmentCost}
          setEquipmentTotalCost = {setEquipmentTotalCost}
          setEquipmentSectionData={setEquipmentSectionData}
          equip_default_obj={equip_default_obj}
          setEquipmentActive={setEquipmentActive}
          setHideEquipment={setHideEquipment}
          equipment_terminal={equipment_terminal}
          merchantWithEquip={merchantWithEquip}
          equipment_deployment={equipment_deployment}
          condProductOptions={condProductOptions}
          productOptions={productOptions}
          equipment_billTo={equipmentBillTo}
          minEquipmentCost={minEquipmentCost}
          equipment_quantity={equipment_quantity}
          equipment_application={equipment_application}
          mailing_address={mailing_address}
          connection_type={connection_type}
          connection_type_physical={connection_type_physical}
          auto_close_default={auto_close_default}
          selectedBankOption={selectedBankOption}
          format={format}
          isMobile={isMobile}
          isTab={isTab}
          equipmentTerminal={equipmentTerminal}
          mobileEquipmentOptions={mobileEquipmentOptions}
          setEquipment={setEquipment}
          equipment={equipment}
          getProductOptions={getProductOptions}
          equipmentTypeConditionalOptions={equipmentTypeConditionalOptions}
          selectedSellTime={selectedSellTime}
          equipment_deployment_default={equipment_deployment_default}
          tokenID={tokenID}
          riskLevel={riskLevel}
          config={config}
          pinDebit={pinDebit}
        />
        {!isTab ? <div className='center-buttons'>
        <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('equipment', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('equipment', 'next')}

          />

        </div> : <></>}
        {!equipmentCostStatus && 
          <h4 className="warning-text">{`Minimum Equipment Cost should be $${minEquipmentCost.toFixed(2)}`}</h4>
        }
      </div>
    )
  }
  // RENDER MISC
  const renderMisc = () => {
    return (
      <div className='form-contents'>
        <div className='form-headingleftpadding' style={{ color: config?.client_config_fe?.button_color }}>
          <span
            className='navigationleft-icon'
            onClick={() => handlePrevNextNavigation('misc', 'prev')}
          />
          MISC
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='SAVE'
            className={disableSave ? "not-active save-button-right button-border" : "save-button-right button-border"}
            onClick={(event: any) => {
              if (role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
                handleSaveAppMerchant(event, false);
              } else {
                handleSaveApp(false, event, false)
              }

            }}
          />
        </div><hr className='top' style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
        <div style={{ paddingLeft: "40px" }}>
          {selectedSellTime === 'Trial' && (
            <div>
              <div>
                MC Registration Form
              </div>
              <div style={{ paddingTop: "10px" }}>
                <Button color='green' onClick={() => { handleDownload('MC_Registration.pdf') }}>DOWNLOAD</Button>
              </div>
            </div>
          )}
          {(businessConduct.includes('E-Commerce') &&
            businessCategory === 'E COMMERCE' &&
            businessType === 'CBD'
          ) && (
              <div>
                <div>
                  CBD Forms
                </div>
                <Button color='green' onClick={() => { handleDownload('CBD_Forms.pdf') }}>DOWNLOAD</Button>
              </div>
            )}
          {
            selectedSellTime === 'One time' &&
            riskLevel === RISK_LEVELS.HIGH_RISK &&
            businessConduct.includes('E-Commerce') &&
            businessCategory === 'E COMMERCE' &&
            selectedBankOption === 'ESQUIRE' && (
              <div>
                <div>
                  Esquire Attestation
                </div>
                <div style={{ paddingTop: "10px" }}>
                  <Button color='green' onClick={() => { handleDownload('Esquire_Attestation.docx') }}>DOWNLOAD</Button>
                </div>
              </div>
            )
          }
          {downloadWarningText && (
            <div className='warning-text' style={{ paddingTop: "20px" }}>
              {downloadWarningText}
            </div>
          )}
        </div>
        {!isTab ? <div className='center-buttons'>
        <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('misc', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('misc', 'next')}

          />
        </div> : <></>}
      </div>)
  }

  // RENDER MISC
  const renderMessage = () => {
    return (
      <div className='form-contents'>
        <div className='form-heading-message' style={{ color: config?.client_config_fe?.button_color}}>
          MESSAGE (OPTIONAL)
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='SAVE'
            className={disableSave ? "not-active save-button-right button-border" : "save-button-right button-border"}
            onClick={(event: any) => {
              if (role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) {
                handleSaveAppMerchant(event, false);
              } else {
                handleSaveApp(false, event, false)
              }

            }}
          />
        </div>
        <hr className='top' style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
        <div style={{ padding: "20px" }}>
          <div style={{marginBottom:'10px',fontWeight:500,color: config?.client_config_fe?.button_color}}>Additional notes/special instructions for Trinity Payments</div>
          <TextArea placeholder='Tell us more' 
          style={{ minHeight: 200 }} 
          value={optionalMessage}
          onChange={handleOptionalMessage}/>
        </div>
        {!isTab ? <div className='center-buttons'>
        <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('message', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border not-active"
            onClick={() => handlePrevNextNavigation('message', 'next')}

          />
          </div> : <></>}
      </div>)
  }

  const navigateToDocUploadPageByAppId = async (appId: string) => {
    try{
        history.push('/')
        sessionStorage.setItem("showSignModal", "false");
        history.replace(`/docupload?merchant_app_id=${appId}`,{token: sessionStorage.merchant_access_token})
    }catch(err){
      console.log(err)
    }
    
  }

  const checkParentAppDocsUplodedStatus =async (appId:string)=>{
    try{
      setLoading(true)
      const data: any = {
        queryParam: { merchant_app_id: appId },
        api: api.merchantAppData.getParentMerchantAppById
      }
      const parent_merchant_app: any = await backend.fetch(data)
      setLoading(false)
      return parent_merchant_app
    }catch(err){
      setLoading(false)
      throw err;
    }
    
  }

  const doSignOut = async () => {
    try {
      setLoading(true)
      setSentToSecondSigner(false)
      history.replace('/')
      await signOut(auth)
      const clientId = sessionStorage.getItem("clientId")
      sessionStorage.clear();
      if (!!clientId) {
        sessionStorage.setItem("clientId", clientId)
      }
      localStorage.clear()
      setConfig({
        ...config,
        accessible_clients: [],
        user_roles_id : null,
        selectedClientId: config?.id
      })
      setLoading(false)
    } catch (err: any) {
      console.log(err)
    }
  }

  const navigateToThankyouPageByAppId = async () => {
    try {
      setLoading(true)
      // let ownerOneEmail = ''
      // let ownerOneName = ''
      // if (ownerSectionData.length > 1 && secondOwnerSigning) {
      //   ownerOneEmail = ownerSectionData[0].ownerEmail
      //   ownerOneName = ownerSectionData[0].firstName+' '+ownerSectionData[0].lastName
      // }
      // if(secondOwnerSigning) history.replace('/thankspage', { tokenID: sessionStorage.merchant_access_token, Merchant_app_id: localStorage.merchant_app_id, sent_to_owner_one: true, ownerOneEmail: ownerOneEmail, ownerOneName: ownerOneName })
      // else 
      history.replace('/thankspage', { tokenID: sessionStorage.merchant_access_token, Merchant_app_id: localStorage.merchant_app_id })
      await signOut(auth)
      const clientId = sessionStorage.getItem("clientId")
      sessionStorage.clear();
      if (!!clientId) {
        sessionStorage.setItem("clientId", clientId)
      }
      localStorage.clear()
      setConfig({
        ...config,
        accessible_clients: [],
        user_roles_id : null,
        selectedClientId: config?.id
      })
      setLoading(false)
    } catch (err: any) {
      console.log(err)
    }
  }

  const sendMailToSecondSigner = async (merchant_app_id: string, envelope_id: string, dba_name: string) => {
    const data = {
      merchant_app_id: merchant_app_id,
      dba_name: dba_name,
      envelope_id: envelope_id
    }

    const payload: any = {
      api : api.integrations.sendMailToSecondSigner,
      payLoad: data
    }
    const sendMailResponse = await backend.save(payload, true)
  }

  const renderSections = () => {
    switch (activesection) {
      case 'prescreening':
        return <div>{renderPreScreening()}</div>
      case 'pricing':
        return <div>{renderPricing()}</div>
      case 'legal':
        return <div className={(role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED || role === ROLES.SUPER_ADMIN) ? "not-required" : ""}>{renderLegalInfo()}</div>
      case 'tdba':
        return <div className={(role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED|| role === ROLES.SUPER_ADMIN) ? "not-required" : ""}>{renderTdbaInfo()}</div>
      case 'bank':
        return <div className={(role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED|| role === ROLES.SUPER_ADMIN) ? "not-required" : ""}>{renderBankDetails()}</div>
      case 'owner':
        return <div className={(role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED|| role === ROLES.SUPER_ADMIN) ? "not-required" : ""}>{renderOwnerDetails()}</div>
      case 'business':
        return <div className={(role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED|| role === ROLES.SUPER_ADMIN) ? "not-required" : ""}>{renderBusinessDetails()}</div>
      case 'transaction':
        return <div className={(role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.UNDER_WRITER || role === ROLES.SALES_LIMITED|| role === ROLES.SUPER_ADMIN) ? "not-required" : ""}>{renderTransactionInfo()}</div>
      case 'equipment':
        return <div>{renderEquipmentInfo()}</div>
      case 'misc':
        return <div>{renderMisc()}</div>
      case 'message':
        return <div>{renderMessage()}</div>
      default:
        return null
    }
  }
  const buttonControlsData = () => {
    const save1 = (role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && 
      role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED && role !==ROLES.SUPER_ADMIN) &&
    (!signDisabled || (merchantAppStatus === "signed"));
    const save2 = (role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.SUPER_ADMIN &&
    role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) && getIsMerchant();
    const showMerchant = (role === ROLES.SUPER_ADMIN || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.PARTNER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED || role === ROLES.UNDER_WRITER);

    const structure = [
      { display:save1, label:'SIGN', disabled: signButtonDisableStatus, action: handleSignApp},
      { display:showMerchant, label:'SEND TO MERCHANT', disabled: !sendToMerchantFlag, action: () => {
        setSignModalOpen(true)
        setOfflineApp(false)
      }},
      { display:showSendToTrinity, label:'SEND TO TRINITY', disabled: sendToTrinityFlag, action: handleSendToTrinty},
      { display:save2, label:'SIGN', disabled: signButtonDisableStatus, action: handleAppLinkSign},
  ];
  return structure;
  }
  const BottomControls = useCallback((props: any) => {
    const sendtbuttoncolor = config?.client_config_fe?.button_color === '#F16B2E' ? 'orange' : 'blue'
    return (
    <div className={'sixteen wide field' + isTab ? ' tab-content-footer' : ''}>
                  { !isMobile && <h4 className="warning-text">*  Fields are required</h4>}
                  <div className="startapp-action-button">
                    {props.data.map((r: any, index: number) => (r.display &&

                      <BasicButton
                      textColor={config?.client_config_fe?.button_color}
                      backgroundColor={'#FFFFFF'}
                      size='18px'
                      buttonText= {r.label}
                      className={r.disabled
                        ? "save-button button-border startapp-send-button not-active" : "save-button button-border startapp-send-button"
                        
                      }

                      onClick={r.action}
                      />))}
                  </div>
                </div>)
  }, []);
  var desktopclassNames = {
    'legal': {
      class1: legalInfoAddressValid ? legalSectionStatus === 'filled' || (offlineApp && legalTrinitySectionStatus) === 'filled' ? 'green-arrow-icon' : 
      activesection === 'legal' ? 'yellow-arrow-icon' : 'grey-arrow-icon' : 'red-arrow-icon',
      extraCondition: legalInfoAddressValid,
      class2: legalInfoAddressValid ? activesection === 'legal' ? 'section-active-status-bar' : 'section-status-bar' : 'section-error-status-bar',
      class3: legalInfoAddressValid ? activesection === 'legal' ? 'section-active-text section-name' : 'section-name' : 'section-name section-error-text'
    },
    'tdba': {
      extraCondition: dbaInfoAddressValid,
      class1: dbaInfoAddressValid ? dbaSectionStatus === 'filled'? 'green-arrow-icon' : activesection === 'tdba' ? 'yellow-arrow-icon' : 'grey-arrow-icon' : 'red-arrow-icon',
      class2: dbaInfoAddressValid ? activesection === 'tdba' ? 'section-active-status-bar' : 'section-status-bar' : 'section-error-status-bar',
      class3: dbaInfoAddressValid ? activesection === 'tdba' ? 'section-active-text section-name' : 'section-name' : 'section-name section-error-text'
    },
    'owner': {
      extraCondition: ownerInfoAddressValid,
      class1: ownerInfoAddressValid ? ownerSectionStatus === 'filled' ? 'green-arrow-icon' : activesection === 'owner' ? 'yellow-arrow-icon' : 'grey-arrow-icon' : 'red-arrow-icon',
      class2: ownerInfoAddressValid ? activesection === 'owner' ? 'section-active-status-bar' : 'section-status-bar' : 'section-error-status-bar',
      class3: ownerInfoAddressValid ? activesection === 'owner' ? 'section-active-text section-name' : 'section-name' : 'section-name section-error-text'
    },
    'message': {
      class1: 'message-section-icon'
    }
  };

  const SignModalUtils = () => {
    window.scroll({ top: 0, left: 0 });
    return (
      <>
        <div className="top-container">
          <div className="top-content">
            <div className="inline fields">
              <div className="twelve wide field">
                <input
                  type="text"
                  className="form-input"
                  name="business_name"
                  value={business_name}
                  autoComplete="new-password"
                  placeholder="Merchant Name :"
                  readOnly
                />
              </div>
            </div>
            <div className="inline fields">
              <div className="twelve wide field">
                <input
                  type="text"
                  className="form-input"
                  name="dbaName"
                  value={dbaName}
                  placeholder="DBA Name : "
                  readOnly
                />
              </div>
            </div>
            <div className="inline fields">
              <div className="twelve wide field">
                <input
                  type="text"
                  className="form-input"
                  name="business_email"
                  value={business_email}
                  autoComplete="new-password"
                  placeholder="Merchant Email :"
                  readOnly
                />
              </div>
            </div>
            <div className="inline fields">
              <div className="twelve wide field">
                <textarea
                  name="personal_message"
                  placeholder="Personal Message :"
                  onChange={setPersonalMessage}
                  value={userMessage}
                  rows={5}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='button-containers'>
          <Button
            onClick={() => {
              setSignModalOpen(false);
              setUserMessage("");
            }}
            color="blue"
            className="send-email-buttons"
          >
            CANCEL
          </Button>
          <Button
            onClick={(event) => handleSaveApp(false, event, true)}
            color="blue"
            className="send-email-buttons"
          >
            SEND EMAIL
          </Button>
        </div>
      </>
    );
  };
  

  const isMobile = useCheckMobileScreen();
  const isTab = useCheckTabScreen();
  const TopContainer = () => {
    // console.log("--topcontainer--")
    return (<div className='top-container' style={{ background: config?.client_config_fe?.button_color }}>
    <div className='top-content'>
      {((role === ROLES.UNDER_WRITER && sessionStorage.getItem("super_user") === 'true') || role === ROLES.SUPER_ADMIN  || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) && (
        <div className='inline fields'>
          <div className='four wide field'>
            <label className='top-content-label'>
              Choose Sales User Name <span className="required-text"> *</span>
            </label>
          </div>
          <div className='twelve wide field'>
            <Dropdown
              placeholder='select the sales user'
              name=''
              className={ !partnerId ? 'not-filled' :'form-input'}
              fluid
              search
              selection
              options={partners}
              value={partnerId}
              onChange={handleChangePartner}
              disabled={(role === ROLES.OFFICE_ADMIN && (merchantAppStatus === MERCHANT_APP_STATUS.STARTED || merchantAppStatus === MERCHANT_APP_STATUS.SENT_TO_MERC)||role===ROLES.SUPER_ADMIN) ? false : editStatusDeleteUser}
            />
          </div>
        </div>
      )}

     {role && !loading &&(
        <div className='inline fields'>
          <div className='four wide field'>
            <label className='top-content-label'>
            Assigned RM {(partnerRm.length>0)?<span className="required-text"> *</span>:<></>}
            </label>
          </div>
          <div className='twelve wide field'>
            <Dropdown
              placeholder='Assigned RM'
              name=''
              className={(partnerRm.length>0? !partnerRmId : false) ? 'not-filled' :'form-input'}
              fluid
              search
              selection
              options={partnerRm}
              value={partnerRmId}
              onChange={handleChangePartnerRms}
              disabled={partnerRm.length===0}
            />
          </div>
        </div>
      )}
    
      <div className='inline fields'>
        <div className='four wide field'>
          <label className='top-content-label'>
            DBA Name<span className="required-text"> *</span>
          </label>
        </div>
        <div className='twelve wide field'>
          <input
            type='text'
            key="dbaName"
            className={ !dbaName ? 'not-filled' :'form-input'}
            name='dbaName'
            value={dbaName}
            onBlur={()=>{updateSendToMerchantFlag(); handleAutoSave() }} 
            placeholder='DBA Name'
            autoComplete="new-password"
            onChange={handleChangeTextInput}
            disabled={role !== ROLES.SUPER_ADMIN && role !== ROLES.PARTNER && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.SALES_LIMITED && role !== ROLES.UNDER_WRITER && editStatus && !getLink}
          />
        </div>
      </div>

      <div className='inline fields'>
        <div className='four wide field'>
          <label className='top-content-label'>
            Merchant Email<span className="required-text"> *</span>
          </label>
        </div>
        <div className='twelve wide field'>
          <input
            type='text'
            className={ (!business_email || !email_pattern.test(business_email)) ? 'not-filled' :'form-input'}
            name='business_email'
            value={business_email}
            placeholder='Merchant Email'
            autoComplete="new-password"
            onChange={handleChangeTextInput}
            onBlur={()=>{updateSendToMerchantFlag(); handleAutoSave(); sendMailAdditionalLocation() }} 
            disabled={(role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED || role === ROLES.UNDER_WRITER)?false :(!role && additional_location === "true")?false :(!role && getLink)? false :true}
          />
        </div>
      </div>
      {
        business_email !== "" && !email_pattern.test(business_email) ?
          <div className='inline fields'>
            <div className='sixteen wide field'>
              <span className="topmail-warning-text">Please enter a Valid Email Address</span>
            </div>
          </div>
          : null
      }
      <div className='inline fields'>
        <div className='four wide field'>
          <label className='top-content-label'>
            Merchant Contact Name<span className="required-text"> *</span>
          </label>
        </div>
        <div className='twelve wide field'>
          <input
            type='text'
            className={ !business_name ? 'not-filled' :'form-input'}
            name='business_name'
            value={business_name}
            placeholder='Merchant Contact Name'
            autoComplete="new-password"
            onChange={handleChangeTextInput}
            onBlur={()=>{updateSendToMerchantFlag(); handleAutoSave() }} 
            disabled={role !== ROLES.SUPER_ADMIN && role !== ROLES.PARTNER && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.SALES_LIMITED && role !== ROLES.UNDER_WRITER && editStatus && !getLink}
          />
        </div>
      </div>
      <div className='inline fields'>
        <div className='four wide field'>
          <label className='top-content-label'>
            Application Status
          </label>
        </div>
        <div className='twelve wide field'>
          <input
            type='text'
            className='form-input form--grinputey'
            name='business_email'
            value={merchantAppStatus}
            autoComplete="new-password"
            placeholder='Merchant App Status'
            disabled
          />
        </div>
      </div>
      <div className='inline fields'>
        <div className='four wide field'>
          <label className='top-content-label'>
             Application Start Date
          </label>
        </div>
        <div className='twelve wide field'>
          <input
            type='text'
            className='form-input form--grinputey'
            value={merchantAppStartDate}
            autoComplete="new-password"
            placeholder='Merchant App Start Date'
            disabled
          />
        </div>
      </div>
      <div className='inline fields'>
        <div className='four wide field'>
          <label className='top-content-label'>
            AppID
          </label>
        </div>
        <div className='twelve wide field'>
          <input
            type='text'
            className='form-input form--grinputey'
            value={merchantAppCode}
            autoComplete="new-password"
            placeholder='Merchant App Id'
            disabled
          />
        </div>
      </div>
    </div>
  </div>
  )};

  const bottomControls = buttonControlsData()
  const disableSave = !dbaName || !business_email || !business_name || !partnerId || (partnerRm.length > 0? !partnerRmId : false) || !!(business_email !== "" && !email_pattern.test(business_email))
  return (
    <Layout page='startapp'>
      <div className='ui form startapp'>
        {(signModalOpen === true) && isMobile && SignModalUtils()}
        {!signModalOpen || (!!signModalOpen && !isMobile)? TopContainer() : ''}
        {!signModalOpen || (!!signModalOpen && !isMobile)? <>{<div className='form-container'>
          <div className='main-content'>
            <div className='left-content'>
              <ul className='padding-left-0'>
              {<RenderBlocks
                onOpenBlock={() => setActiveSection('prescreening')}
                preScreeningSectionStatus={preScreeningSectionStatus}
                activesection={activesection}
                title={'Pre-Screening Information'}
                renderSections={renderSections}
                block = {'prescreening'}
                handleSaveAppMerchant = {handleSaveAppMerchant} //{handleSaveAppMerchant}
                handleSaveApp = {handleSaveApp}
                buttonControlsData={bottomControls}
                status={preScreeningSectionStatus}
                disableControl={disableSave}
                />}
                {
                 <RenderBlocks
                 onOpenBlock={() => setActiveSection('pricing')}
                 pricingSectionStatus={pricingSectionStatus}
                 activesection={activesection}
                 title={'Pricing'}
                 renderSections={renderSections}
                 block = {'pricing'}
                 handleSaveAppMerchant = {handleSaveAppMerchant}
                 handleSaveApp = {handleSaveApp}
                 status={pricingSectionStatus}
                 display = {role}
                 disableControl={disableSave}
                />}
               {<RenderBlocks
                 onOpenBlock={() => setActiveSection('legal')}
                 //onOpenBlock={callback}
                 legalSectionStatus={legalSectionStatus}
                 activesection={activesection}
                 title={'Corp Information'}
                 renderSections={renderSections}
                 block = {'legal'}
                 handleSaveAppMerchant = {handleSaveAppMerchant}
                 handleSaveApp = {handleSaveApp}
                 buttonControlsData={bottomControls}
                 desktopClasses={desktopclassNames.legal}
                 status={legalSectionStatus}
                 disableControl={disableSave || (legalTrinitySectionStatus === '')}
                />}
                {<RenderBlocks
                 onOpenBlock={() => setActiveSection('tdba')}
                 //onOpenBlock={callback}
                 dbaSectionStatus={dbaSectionStatus}
                 activesection={activesection}
                 title={'DBA Information'}
                 renderSections={renderSections}
                 block = {'tdba'}
                 handleSaveAppMerchant = {handleSaveAppMerchant}
                 handleSaveApp = {handleSaveApp}
                 buttonControlsData={bottomControls}
                 desktopClasses={desktopclassNames.tdba}
                 status={dbaSectionStatus}
                 disableControl={disableSave || (dbaTrinitySectionStatus === '')}
                />}
               {<RenderBlocks
                 onOpenBlock={() => {
                  setActiveSection('owner');
                  handleOwnerClick(0);
                 }}
                //onOpenBlock={callback}
                 ownerSectionStatus={ownerSectionStatus}
                 activesection={activesection}
                 title={'Owner / Officer Information'}
                 renderSections={renderSections}
                 block = {'owner'}
                 handleSaveAppMerchant = {handleSaveAppMerchant}
                 handleSaveApp = {handleSaveApp}
                 buttonControlsData={bottomControls}
                 desktopClasses={desktopclassNames.owner}
                 status={ownerSectionStatus}
                 disableControl={disableSave || (ownerTrinitySectionStatus === '')}
                />}
               {<RenderBlocks
                 onOpenBlock={() => setActiveSection('business')}
                 //setActiveSection={setActiveSection}
                 businessSectionStatus={businessSectionStatus}
                 activesection={activesection}
                 title={'Business Details'}
                 renderSections={renderSections}
                 block = {'business'}
                 handleSaveAppMerchant = {handleSaveAppMerchant}
                 handleSaveApp = {handleSaveApp}
                 buttonControlsData={bottomControls}
                 status={businessSectionStatus}
                 disableControl={disableSave || (businessSectionStatus === '')}
                />}
               {<RenderBlocks
                 onOpenBlock={() => setActiveSection('transaction')}
                 //setActiveSection={setActiveSection}
                 transactionSectionStatus={transactionSectionStatus}
                 activesection={activesection}
                 title={'Transaction Information'}
                 renderSections={renderSections}
                 block = {'transaction'}
                 handleSaveAppMerchant = {handleSaveAppMerchant}
                 handleSaveApp = {handleSaveApp}
                 buttonControlsData={bottomControls}
                 status={transactionSectionStatus}
                 disableControl={disableSave || (transactionSectionStatus === '')}
                />}
               {<RenderBlocks
                 onOpenBlock={() => setActiveSection('equipment')}
                 //setActiveSection={setActiveSection}
                 equipmentSectionStatus={equipmentSectionStatus}
                 activesection={activesection}
                 title={'Equipment Information'}
                 renderSections={renderSections}
                 block = {'equipment'}
                 handleSaveAppMerchant = {handleSaveAppMerchant}
                 handleSaveApp = {handleSaveApp}
                 buttonControlsData={bottomControls}
                 status={equipmentSectionStatus}
                 disableControl={disableSave || (equipmentSectionStatus === '')}
                />}
                {(((businessConduct.includes('E-Commerce') &&
                  businessCategory === 'E COMMERCE' &&
                  businessType === 'CBD') ||
                  (selectedSellTime === 'Trial') ||
                  (selectedSellTime === 'One time' &&
                    riskLevel === RISK_LEVELS.HIGH_RISK &&
                    businessConduct.includes('E-Commerce') &&
                    businessCategory === 'E COMMERCE' &&
                    selectedBankOption === 'ESQUIRE')) && <RenderBlocks
                 onOpenBlock={() => setActiveSection('misc')}
                 //setActiveSection={setActiveSection}
                 equipmentSectionStatus={miscSectionStatus}
                 activesection={activesection}
                 title={'Misc'}
                 renderSections={renderSections}
                 block = {'misc'}
                 handleSaveAppMerchant = {handleSaveAppMerchant}
                 handleSaveApp = {handleSaveApp}
                 status={miscSectionStatus}
                 disableControl={disableSave || miscSectionStatus === ''}
                />
                )}
                 {<RenderBlocks
                 onOpenBlock={() => setActiveSection('message')}
                 //setActiveSection={setActiveSection}
                 activesection={activesection}
                 title={'Message (optional)'}
                 renderSections={renderSections}
                 block = {'message'}
                 handleSaveAppMerchant = {handleSaveAppMerchant}
                 handleSaveApp = {handleSaveApp}
                 buttonControlsData={bottomControls}
                 status={'NA'}
                 desktopClasses={desktopclassNames.message}
                 disableControl={disableSave}
                />}
              </ul>
              {isMobile && <BottomControls data={bottomControls}/>}
            </div>
            <div className='right-content'>
              <div className={(role !== ROLES.PARTNER && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED && merchantAppStatus === 'signed') ? 'disableddiv right-form-content' : 'right-form-content'}>
                {renderSections()}
                <BottomControls data={bottomControls || []}/>
              </div>
            </div>
          </div>
          {errorMessage && (
            <>
              <div className='flex'>
                <span className='alert-icon' />
                <span className='error-message alert-text-message'>
                  {errorMessage}
                </span>
              </div>
            </>
          )}
        </div>}</> : ''}
        </div>
      <ProgressLoader loading={loading} size='large' />

      <Confirm
        open={cancelAlertOpen}
        content={cancelContent}
        cancelButton="CANCEL"
        confirmButton= {<Button style={{ background: config?.client_config_fe?.button_color }}>OK</Button>}
        onCancel={handleCancelCancel}
        onConfirm={handleCancelConfirm}
      />

      {(sentMailModalOpen === true) &&
        <Modal
          centered
          open={sentMailModalOpen}
          onClose={() => setSentMailModalOpen(false)}
          onOpen={() => setSentMailModalOpen(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <Modal.Description>
              <div className='not-found-text' style={{ color: config?.client_config_fe?.button_color}}>
                Successfully sent e-mail to Merchant!
              </div>
            </Modal.Description>

          </Modal.Content>
          <Modal.Actions>
            <button
              onClick={() => {
                setSentMailModalOpen(false);
              }}
              className="save-button button-border"
              style={{ background: config?.client_config_fe?.button_color,color:"#FFF"}}>OKAY</button>
          </Modal.Actions>
        </Modal>
      }
      {showDeleteModal &&
        <Modal
          centered
          closeOnEscape={false}
          closeOnDimmerClick={false}
          open={showDeleteModal && !invalidAppLink}
        >
          <Modal.Content>
            <Modal.Description>
            <div className='deleted-message'>{`Your application is ${merchantAppStatus}. Please contact admin for details`}
            </div>             
             </Modal.Description>
          </Modal.Content>
        </Modal>
      }
      {(showWelcomModal === true && role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED && merchantAppStatus !== "deleted") && 
        <Modal
          centered
          closeOnEscape={false}
          closeOnDimmerClick={false}
          open={showWelcomModal && !invalidAppLink}
          onClose={() => setShowWelcomModal(false)}
          onOpen={() => setShowWelcomModal(true)}
        >
          <Modal.Content>
            <Modal.Description>
              <div className='not-found-text' style={{ color: config?.client_config_fe?.button_color,}}>     
                "Welcome to your {config?.client_name} merchant application!"
              </div>
            </Modal.Description>

          </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setShowWelcomModal(false)} style={{ background: config?.client_config_fe?.button_color, color:'#FFF'}}>CLOSE</Button>
              </Modal.Actions>
          
        </Modal>}
      {
        history.location.pathname.includes('/AL') &&
        <Modal
          centered
          open={showAppLinkModal}
          onClose={() => setShowAppLinkModal(false)}
          onOpen={() => setShowAppLinkModal(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <Modal.Description>
              <div className='not-found-text' style={{ color: config?.client_config_fe?.button_color}}>
                Your Application has been created. We have also sent you a mail for future reference.
              </div>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button style={{ background: config?.client_config_fe?.button_color, color:'#FFF'}}><a href={appLink} className="redirect-link-text">View Application</a></Button>
          </Modal.Actions>
        </Modal>
      }
      <Modal
        centered
        open={invalidAppLink}
        onClose={() => setInvalidAppLink(false)}
        onOpen={() => setInvalidAppLink(true)}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Modal.Description>
            <div className='not-found-text' style={{ color: config?.client_config_fe?.button_color}}>
              {invalidAppLinkMsg}
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <button
            className="save-button button-border"
            style={{ background: config?.client_config_fe?.button_color, color: '#FFF' }} onClick={() => {
              setInvalidAppLink(false)
              history.push('/')
            }}>Close</button>
        </Modal.Actions>
      </Modal>
      {!isMobile?<>{(signModalOpen === true) &&
        <Modal
          centered
          open={signModalOpen}
          onClose={() => setSignModalOpen(false)}
          onOpen={() => setSignModalOpen(true)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <ProgressLoader loading={loading} size='small' />
            <Modal.Description>
              <div className='body-content  min-height-0'>
                <div className='ui form center'>
                  <div className='inline fields'>
                    <div className='three wide field'>
                      <label>Merchant Name :</label>
                    </div>
                    <div className='eight wide field'>
                      <input
                        type='text'
                        className='form-input-grey'
                        name='business_name'
                        value={business_name}
                        autoComplete="new-password"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='inline fields'>
                    <div className='three wide field'>
                      <label>Merchant Email :</label>
                    </div>
                    <div className='eight wide field'>
                      <input
                        type='text' className='form-input-grey'
                        name='business_email'
                        value={business_email}
                        autoComplete="new-password"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='inline fields'>
                    <div className='three wide field'>
                      <label>DBA Name :</label>
                    </div>
                    <div className='eight wide field'>
                      <input
                        type='text' className='form-input-grey'
                        name='dbaName'
                        value={dbaName}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='inline fields'>
                    <div className='three wide field'>
                      <label>Personal Message :</label>
                    </div>
                    <div className='eight wide field'>
                      <textarea name='personal_message' onChange={setPersonalMessage} value={userMessage} rows={5} />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Description>

          </Modal.Content>
          <Modal.Actions>
            <BasicButton
              textColor={config?.client_config_fe?.button_color}
              backgroundColor={'#FFFFFF'}
              size='18px'
              buttonText='CANCEL'
              className="save-button button-border"
              onClick={() => {
                setSignModalOpen(false);
                setUserMessage('');
              }}
            />
            <BasicButton
              textColor={'#FFFFFF'}
              backgroundColor={config?.client_config_fe?.button_color}
              size='18px'
              buttonText='SEND EMAIL'
              className="save-button button-border"
              onClick={(event:any) => handleSaveApp(false, event, true)}
            />              
          </Modal.Actions>
        </Modal>}</> :(signModalOpen === true) && <> </>}

      {(showWarningModal === true && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED) &&
        <Modal
          centered
          closeOnEscape={false}
          closeOnDimmerClick={false}
          open={showWarningModal}
          onClose={() => setShowWarningModal(false)}
          onOpen={() => setShowWarningModal(true)}
        >
          <Modal.Content>
            <Modal.Description>
              <div className='not-found-text' style={{ color: config?.client_config_fe?.button_color}}>
                Your information has been saved with the exception of sensitive fields (ie. SSN, Bank Account #, etc). If you exit the application now, the sensitive fields will need to be re-entered.
              </div>
            </Modal.Description>

          </Modal.Content>
          <Modal.Actions>
            <Button 
              onClick={(event) => {
              setShowWarningModal(false)
              handleSaveApp(false, event, false)
            }} style={{ background: config?.client_config_fe?.button_color, color:'#FFF'}} >CONTINUE</Button>
          </Modal.Actions>
        </Modal>}
        {(linkExpiredModal) &&
        <Modal
          centered
          closeOnEscape={false}
          closeOnDimmerClick={false}
          open={linkExpiredModal}
          onClose={() => setLinkExpiredModal(false)}
          onOpen={() => setLinkExpiredModal(true)}
        >
          <Modal.Content>
            <Modal.Description>
              <div className='not-found-text' style={{ color: config?.client_config_fe?.button_color}}>
                Sorry! Your AppLink has been expired. Generate new link and try again.
              </div>
            </Modal.Description>

          </Modal.Content>
          <Modal.Actions>
            <Button onClick={(event) => {
              history.replace("/");
            }} style={{ background: config?.client_config_fe?.button_color, color:'#FFF'}}>CLOSE</Button>
          </Modal.Actions>
        </Modal>}
        { sentToSecondSigner ? 
          <Modal
            centered
            open={sentToSecondSigner}
            onClose={() => doSignOut()}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Content>
              <Modal.Description>
                <div className='not-found-text' style={{ color: config?.client_config_fe?.button_color}}>
                  Application was already sent to <b>{secondSignerName}</b> at ( {secondSignerEmail} ) for final signature.
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button style={{ background: config?.client_config_fe?.button_color, color:'#FFF'}}  onClick={() => doSignOut()}>OK</Button>
            </Modal.Actions>
          </Modal> : null
        }
        { sendToTrinityModalOpen && 
        <SecureFieldsModal
        sendToTrinityModalOpen={sendToTrinityModalOpen}
        setSendToTrinityModalOpen={setSendToTrinityModalOpen}
        handleCancelTrinityModal={handleCancelTrinityModal}
        loading={loading}
        bankAccountNoDummy={bankAccountNoDummy}
        setBankAccountNoDummy={setBankAccountNoDummy}
        handleChangeTextInput={handleChangeTextInput}
        updateBankSectionStatus={updateBankSectionStatus}
        handleCopy={handleCopy}
        handlePaste={handlePaste}
        confirmBankAccountNoDummy={confirmBankAccountNoDummy}
        setConfirmBankAccountNoDummy={setConfirmBankAccountNoDummy}
        handleConfirmBankAccountNo={handleConfirmBankAccountNo}
        fedexTaxIdShown={fedexTaxIdShown}
        fedTaxIDDummy={fedTaxIDDummy}
        setFedTaxIDDummy={setFedTaxIDDummy}
        validateFedTaxId={validateFedTaxId}
        fedTaxIdValid={fedTaxIdValid}
        setFedTaxIdValid={setFedTaxIdValid}
        updatelegalSectionStatus={updatelegalSectionStatus}
        allowOnlyNumberIncludingHyphen={allowOnlyNumberIncludingHyphen}
        eyeIconFedexTaxId={eyeIconFedexTaxId}
        togglefedexTaxIdVisiblity={togglefedexTaxIdVisiblity}
        ownerSectionSecureDataDummy={ownerSectionSecureDataDummy}
        dlIdShown={dlIdShown}
        handleOwnerSEctionDummyData={handleOwnerSEctionDummyData}
        updateownerSectionStatus={updateownerSectionStatus}
        eyeIcondl={eyeIcondl}
        toggleDlIdVisiblity={toggleDlIdVisiblity}
        ssnShown={ssnShown}
        eyeIconssn={eyeIconssn}
        togglessnVisiblity={togglessnVisiblity}
        handleSaveSecureFileds={handleSaveSecureFileds}
        errorMessage1={errorMessage1}
        alertCopyMsg={alertCopyMsg}
        setErrorMessage1={setErrorMessage1}
        setSuccessMessage1={setSuccessMessage1}
        setAlertCopyMsg={setAlertCopyMsg}
        config={config}
        errorMessage2={errorMessage2}
        setErrorMessage2={setErrorMessage2}
        bankRoutingNo={bankRoutingNo}
        />
        }

      {showProgress && (
        <div className='overlay' style={overlay.style}>
          <div className='overlay-image'>
            <Progress percent={progress} status="active" showInfo={false} className="progress-bar" />
            <span className='overlay-text' style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
          </div>
        </div>
      )}
      <Footer page = 'startapp'/>
      <ButtonScrollTop />
    </Layout>
  )
}
export default StartApp
