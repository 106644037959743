/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, memo, useContext } from "react";
import {
  Dropdown,
  Button,
  Radio,
  Confirm,
  Checkbox,
  CheckboxProps,
} from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { firstBy } from "thenby";
import { Layout } from "../../PageLayout/Layout";
import { Footer } from "../../PageLayout/Footer";
import api from "../../../Service/Api";
import backend from "../../../Service/Backend";
import ProgressLoader from "../../Common/ProgressLoader";
import { get, getParsedList } from "../../../Utils/helpers";
import { BANK_CODES, RISK_LEVELS, ROLES, EQUIPMENT_TYPE, connection_type_physical, auto_close_default, option_yesno, Pricing_Type, Card_Type, billedOptions, equipment_terminal, equipment_deployment, equipment_billTo, equipment_deployment_default, equipment_application, discountPercentageOptions, serviceFeeOptions, ticketSizeOptions, equipment_quantity, mailing_address, connection_type, cdBanks, otherFees, otherFees_cfsb} from "../../../Utils/constants";
import SendAppTable from "./SendAppTable";
import DeleteModalPage from "./DeleteModalPage";
import SentMailModal from "./SentMailModal";
import ShowSaveLinkModal from "./ShowSaveLinkModal";
import ShowViewLinkDetailsModal from "./ShowViewLinkDetailsModal";
import SignModalOpenPage from "./SignModalOpenPage";
import PreScreeningSection from "../AppSectionComponents/PreScreeningSection";
import PricingSection from "../AppSectionComponents/PricingSection";
import EquipmentInformation from "./EquipmentInformation";
import RenderBlocks from "../StartAppUtils";
import useCheckMobileScreen, {
  useCheckTabScreen,
} from "../../../hooks/useCheckMobileScreen";
import TableComponent from "../../TableComponent";
import MobileSignModal from "./MobileSignModal";
import ButtonScrollTop from "../../Common/ButtonScrollTop";
import Select from 'react-select'
import ActionConfirmation from "./ActionConfirmation";
import ActionConfirmationDelete from "./ActionConfirmationDelete";
import { fetchAppLinkListData } from '../../../Service/Algolia'
import { AppContext } from "../../../context/appContext";
import BasicButton from '../../Common/Button'
import { Tooltip } from 'antd';
import { Progress } from 'antd';

const { REACT_APP_BACKEND_MYSQL } = process.env;

const format = "HH:mm";

const email_pattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

const sectionsPrevNextData = [
  { name: "prescreening", prev: "prescreening", next: "pricing" },
  { name: "pricing", prev: "prescreening", next: "equipment" },
  { name: "equipment", prev: "pricing", next: "equipment" },
];

// const equipment_platform = [
//   { text: 'TSYS', value: 'TSYS' },
//   { text: 'FDR', value: 'FDR' },
// ]

  const SendApp = (props: any) => {
    const { data, getAlgolia } = props;

    const { config }: any = useContext(AppContext);

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [algoliaLoading, setAlgoliaLoading] = useState(true);
    const [overlay, setOverlay] = useState({ style: { display: "none" } });
    const [overlayMsg, setOverlaymsg] = useState("Links Fetched Successfully!");
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteAppLinkId, setDeleteAppLinkId] = useState("");

    const [sellTypeValue, setSellTypeValue] = useState("");
    const [activesection, setActiveSection] = useState("prescreening");
    const [emptyTopFields, setEmptyTopFields] = useState(false);
    // merchantInfo
    const [business_name, setBusinessName] = useState("");
    const [business_email, setBusinessEmail] = useState("");
    const [business_contact, setBusinessContact] = useState("");
    const [sellGoodValue, setSellGoodValue] = useState("");
    const [conditionalSellGood, setConditionalSellGood] = useState("");
    const [selectedSellTime, setSelectedSellTime] = useState("");
    // prescreening section
    const [businessConduct, setBusinessConduct] = useState([] as any);
    const [isRetail, setIsRetail] = useState(false);
    const [isMailOrder, setIsMailOrder] = useState(false);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const [businessCategory, setBusinessCategory] = useState("");
    const [businessType, setBusinessType] = useState("");
    const [riskLevel, setRiskLevel] = useState("");
    const [selectedBankOption, setSelectedBankOption] = useState("");
    const [merchantContact, setMerchantContact] = useState("");
    const [merchantEmailValid, setMerchantEmailValid] = useState(true);
    const [personalMessage, setPersonalMessage] = useState("");
    const [partnerName, setPartnerName] = useState("");
    const [partnerId, setPartnerId] = useState("");
    const [partners, setPartners] = useState([]);
    const [partnerRm, setPartnerRm] = useState([] as any)
    const [partnerRmId, setPartnerRmId] = useState('')
    const [partnerRmName, setPartnerRmName] = useState('')
    const [processors, setProcessors] = useState([]);
    const [showCashDiscount, setShowCashDiscount] = useState(false)
    const [disableSalesMethod, setDisableSalesMethod] = useState(false)
    const [tokenID, setTokenID] = useState("")
    

    const [businessTypeConditionalOptions, setBusinessTypeConditionalOption] =
      useState([{ text: "", value: "" }]);
    const [
      businessCategoryConditionalOptions,
      setBusinessCategoryConditionalOptions,
    ] = useState([{ text: "", value: "" }]);
    const [businessTypeOptions, setBusinessTypeOptions] = useState([] as any);
    const [businessCategoryFreeFormText, setBusinessCategoryFreeFormText] =
      useState("");
    const [
      businessCategoryFreeFormErrorMessage,
      setBusinessCategoryFreeFormErrorMessage,
    ] = useState("");
    const [businessTypeFreeFormText, setBusinessTypeFreeFormText] =
      useState("");
    const [
      businessTypeFreeFormErrorMessage,
      setBusinessTypeFreeFormErrorMessage,
    ] = useState("");

    // Pricing_Section
    const [pricingType, setPricingType] = useState("");
    const [pricingCardType, setPricingCardType] = useState(Card_Type);
    const [pricingCardValue, setPricingCardValue] = useState([] as any);
    const [monthlyFee, setMonthlyFee] = useState("");
    const [annualFee, setAnnualFee] = useState("");
    const [tieredQualDiscount, setTieredQualDiscount] = useState("");
    const [tieredQualPerItem, setTieredQualPerItem] = useState("");
    const [tieredMidDiscount, setTieredMidDiscount] = useState("");
    const [tieredMidPerItem, setTieredMidPerItem] = useState("");
    const [tieredNonDiscount, setTieredNonDiscount] = useState("");
    const [tieredNonPerItem, setTieredNonPerItem] = useState("");
    const [tieredDebitDiscount, setTieredDebitDiscount] = useState("");
    const [tieredDebitPerItem, setTieredDebitPerItem] = useState("");
    const [errQualDiscount, setErrQualDiscount] = useState("");
    const [errQualPerItem, setErrQualPerItem] = useState("");
    const [errNonDiscount, setErrNonDiscount] = useState("");
    const [errNonPerItem, setErrNonPerItem] = useState("");
    const [intAmexDiscountRate, setIntAmexDiscountRate] = useState("");
    const [intAmexperItem, setIntAmexperItem] = useState("");
    const [amexQual, setAmexQual] = useState("");
    const [amexPerItem, setAmexPerItem] = useState("");
    const [amexMidQual, setAmexMidQual] = useState("");
    const [amexMidPerItem, setAmexMidPerItem] = useState("");
    const [amexNonQual, setAmexNonQual] = useState("");
    const [amexNonPerItem, setAmexNonPerItem] = useState("");
    const [discountRate, setDiscountRate] = useState("");
    const [perItem, setPerItem] = useState("");
    const [debitDiscountRate, setDebitDiscountRate] = useState("");
    const [debitNetworkFees, setDebitNetworkFees] = useState("Yes");
    const [pinDebit, setPinDebit] = useState("");
    const [pinDebitAuth, setPinDebitAuth] = useState("");
    const [acceptEbt, setAcceptEbt] = useState("");
    const [ebt, setEbt] = useState("");
    const [ebtAuth, setEbtAuth] = useState("");
    const [wirelessFee, setWirelessFee] = useState("");
    const [offerAmex, setOfferAmex] = useState("");
    const [mc, setMc] = useState("");
    const [visa, setVisa] = useState("");
    const [discAuth, setDiscAuth] = useState("");
    const [amexAuth, setAmexAuth] = useState("");
    const [avs, setAvs] = useState("");
    const [batchFee, setBatchFee] = useState("");
    const [chargeBackFee, setChargeBackFee] = useState("");
    const [monthlyMinimum, setMonthlyMinimum] = useState("");
    const [earlyTerminationFee, setEarlyTerminationFee] = useState("");
    const [manualTerminationFee, setManualTerminationFee] = useState('')
    const [disableEarlyTermination, setDisableEarlyTermination] = useState(false)
    const [accountReserve, setAccountReserve] = useState("");
    const [other, setOther] = useState("");
    const [billed, setBilled] = useState("");
    const [ticketSize, setTicketSize] = useState('')
    const [discountPercentage, setDiscountPercentage] = useState('')
    const [serviceFee, setServiceFee] = useState('')  
    const [voiceAuth, setVoiceAuth] = useState("1.96");
    const [excessiveElectronicAuth, setExcessiveElectronicAuth] =
      useState("0.10");
    const [irsReportingFee, setIrsReportingFee] = useState("4.95");
    const [retreivalFee, setRetreivalFee] = useState("20");
    const [invalidTinFee, setInvalidTinFee] = useState("19.95");
    const [achRejectFee, setAchRejectFee] = useState(
      selectedBankOption === BANK_CODES.DART ? "25" : "30"
    );
    const [collectionsFee, setCollectionsFee] = useState("20");
    const [pciValidation, setPciValidation] = useState("119.99");
    const [pciNonCompliance, setPciNonCompliance] = useState('');
    const [industryCompliance, setIndustryCompliance] = useState("9.95");
    const [returnTransactionFee, setReturnTransactionFee] = useState("0.10");
    const [amexOptubleSupportFee, setAmexOptubleSupportFee] = useState("0.50");
    const [amexOptubleNetworkFee, setAmexOptubleNetworkFee] = useState("0.15");
    const [debitAccessFee, setDebitAccessFee] = useState("3.95");
    const [licenseTechFees, setLicenseTechFees] = useState('25')
    const [clymDataPrivacyCompliance, setClymDataPrivacyCompliance] =
      useState("Yes");
    const [clymDataPrivacyComplianceFee, setClymDataPrivacyComplianceFee] =
      useState("20");
    // bank_info_loading
    const [sendLinkUsersLoading, setSendLinkUsersLoading] = useState(false);
    const user_id: any = sessionStorage.getItem("user_id");
    const user_guid: any = sessionStorage.getItem('user_guid');
    const role = sessionStorage.getItem("role");
    const partner_id: any = sessionStorage.getItem("partner_id");
    const office_id = sessionStorage.getItem("office_id");
    const partner_rm_id: any = sessionStorage.getItem("partner_rm_id");
    let client_id: any = Number(sessionStorage.getItem('clientId'))
    const super_user: any = sessionStorage.getItem("super_user") === 'true'
    
    //console.log("Super User Value in office :", super_user)
    
    if (super_user) {
        client_id = Number(sessionStorage.getItem("selectedClientId"))
        console.log(client_id)
    }

    const allowed_risk_levels_partner =
      sessionStorage.getItem("allowed_risk_levels")?.split(",") || [];
    const allowed_procerrsors_partner =
      sessionStorage.getItem("allowedProcessors")?.split(",") || [];
    const allowed_highrisk_processors =
      sessionStorage.getItem("allowed_high_risk_processors")?.split(",") || [];
    const allowed_lowrisk_processors =
      sessionStorage.getItem("allowed_low_risk_processors")?.split(",") || [];
    // equipment_section
    const equip_default_obj = {
      connection_type: "",
      auto_close: "22:00",
      ebt: "",
      pin_debit_required: "",
      equipment_mailing_addr_type: "",
      equipment_type: "",
      equipment_order_type: "",
      quantity: "",
      special_instructions: "",
      deployment: "",
      billTo: "",
      cost: "",
      // platform: "",
      application: "",
      product: "",
      pos_name: "",
      pos_version: "",
      pin_name: "",
      receipt_footer_info:"",
      check_reader_text: "",
      virtual_terminal_nmi: "",
      virtual_terminal_auth: "",
      virtual_terminal_other: "",
      tokenization_needed: false,
      ip_connection: false,
      purchasing_cards: false,
      avs: false,
      server_clerk: false,
      sn_man: "",
      sim_esn: "",
      wireless: false,
      invoice_number: false,
      cvv2: true,
      fraud_check: false,
      rm_added: false,
      mobile_equipment:"None",
      ach_electronic_check:false,
      customer_vault:false,
      automatic_card_updater:false,
      invoicing:false,
      level_three_processing:false,
      key_injection_needed:false,
      tip_option:false,
      multi_merchant:false,
      pin_debit:false,
      cash_back_option:false
    };
    const [equipmentSectionData, setEquipmentSectionData] = useState([
      equip_default_obj,
    ]);
    const [numberOfEquipments, setNumberOfEquipments] = useState(0);

  const [equipmentActive, setEquipmentActive] = useState(0)
  const [hideEquipment, setHideEquipment] = useState(false)
  const [freeEquipmentPlacementStatus, setFreeEquipmentPlacementStatus] = useState(false)
  const [productPrices, setProductPrices] = useState([])
  const [productOptions, setProductOptions] = useState([{
    text: '',
    value: ''
  }])
  const condProductOptions = [
    {
      text: 'NMI',
      value: 'NMI'
    },
    {
      text: 'Auth.net',
      value: 'Auth.net'
    },
    {
      text: 'Other',
      value: 'Other'
    }
  ]
  
    let viewOptions = true;
    if (role === ROLES.VIEW_ONLY || role === ROLES.SALES_LIMITED) { viewOptions = false }

  const [equipmentTerminal, setEquipmentTerminal] = useState(
    [{ text: '', value: '' }]
  )
  const [equipmentTerminalOriginal, setEquipmentTerminalOriginal] = useState(
    [{ text: '', value: '' }]
  )
  const [mobileEquipmentOptions, setMobileEquipmentOptions] = useState(
    [{ text: '', value: '' }]
  )
  const [equipmentProductOptions, setEquipmentProductOptions] = useState([] as any)
  const [equipmentTypeConditionalOptions, setEquipmentTypeConditionalOption] = useState(
    [{ text: '', value: '' }]
  )
  const [equipmentProduct, setEquipmentProduct] = useState('')
  // Section_validations
  const [preScreeningSectionStatus, setPreScreeningSectionStatus] = useState(
    "empty"
  );
  const [pricingSectionStatus, setPricingSectionStatus] = useState("");
  const [equipmentSectionStatus, setEquipmentSectionStatus] = useState('')
  // sendLinks
  const [sendLinkUsers, setSendLinkUsers] = useState([] as any[]);
  const [saveLinkError, setSaveLinkError] = useState("");
  const [showSaveLinkModal, setShowSaveLinkModal] = useState(false);
  const [linkName, setLinkName] = useState("");
  const [appLinkStatus, setAppLinkStatus] = useState("");
  const [linkUrl, setAppLinkUrl] = useState("");
  //view link details modal
  const [showViewLinkDetailsModal, setShowViewLinkDetailsModal] = useState(
    false
  );
  const [linkDetailsID, setLinkDetailsID] = useState("");
  const [linkDetailsName, setLinkDetailsName] = useState("");
  const [linkDetailsURL, setLinkDetailsURL] = useState("");
  const [linkDetailsTinyURL, setLinkDetailsTinyURL] = useState("");
  const [linkDetailsNoOfApps, setLinkDetailsNoOfApps] = useState("");
  const [linkDetailsNoOfClicks, setLinkDetailsNoOfClicks] = useState("");
  const [equipmentBillTo, setEquipmentBillTo] = useState(equipment_billTo);
  const [linkDetailsPartnerName, setLinkDetailsPartnerName] = useState("");
  const [
    linkDetailsconditionalSellType,
    setLinkDetailsconditionalSellType,
  ] = useState("");
  const [linkDetailssellTypeValue, setLinkDetailssellTypeValue] = useState("");
  const [
    linkDetailsconditionalSellGood,
    setLinkDetailsconditionalSellGood,
  ] = useState("");
  const [linkDetailsbusinessType, setLinkDetailsbusinessType] = useState("");
  const [
    linkDetailsselectedBankOption,
    setLinkDetailsselectedBankOption,
  ] = useState("");
  const [
    linkDetailsselectedSellTime,
    setLinkDetailsselectedSellTime,
  ] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [saveLinkId, setSaveLinkId] = useState("");
  const [saveLinkCode, setSaveLinkCode] = useState("");
  const [currentSaveLink, setCurrentSaveLink] = useState({} as any);
  //Cancel Alert
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);

    // sendmail modal
    const [signModalOpen, setSignModalOpen] = useState(false);
    const [sentMailModalOpen, setSentMailModalOpen] = useState(false);
    const [userMessage, setUserMessage] = useState("");

    //getLink
    const [getLink, setGetLink] = useState(false);

    // settoken
    const [merchantToken, setMerchantToken] = useState("");

    //Search and Sorting for Links
    const [searchField, setSearchField] = useState("");
    const [searchText, setSearchText] = useState("");

    const [sortField, setSortField] = useState("createdTS");
    const [sortFieldOrder, setSortFieldOrder] = useState("desc");

    const [parentOffices, setParentOffices] = useState([] as any);
    const [showRiskLevels, setShowRiskLevels] = useState([] as any);
    const [showProcessors, setshowProcessors] = useState([] as any);
    const [showLowRiskProcessors, setShowLowRiskProcessors] = useState(
      [] as any
    );
    const [showHighRiskProcessors, setShowHighRiskProcessors] = useState(
      [] as any
    );

    const [minEquipmentCost, setMinEquipmentCost] = useState(0);
    const [equipmentCostStatus, setEquipmentCostStatus] = useState(true);
    const [equipmentTotalCost, setEquipmentTotalCost] = useState('')
    const [noAppText, setNoAppText] = useState("");

    const [fetchingOffices, setFetchingOffices] = useState(true);
    const [fetchingProcessors, setFetchingProcessors] = useState(true);
    const [fetchingPartners, setFetchingPartners] = useState(true);
    const [fetchingPartnersRMs, setFetchingPartnersRMS] = useState(true)
    const isMobile = useCheckMobileScreen();
    const isTablet = useCheckTabScreen();
    
    //Bulk update
    const appBulkAction: any = [
      { value: 'active', label: 'Activate' },
      { value: 'inactive', label: 'Deactivate'  },
      { value: 'delete', label: 'Delete' },
    ]
    const [appsToUpdate, setApps] = useState([] as any[])
    const [selectedAppBulk, setSelectedAppBulk] = useState([] as any)
    const [bulkaction, setBulkaction] = useState('')
    const [isParentChecked, setIsParentChecked] = useState(false);
    const [actionConfirmation, setActionConfirmation] = useState(false)
    const [modalAction, setModalAction] = useState('')
    const [statusCount, setStatusCount] = useState('')
    const [userMessageResend, setUserMessageResend] = useState('')
    const [bulkUpdateStatus, setBulkUpdateStatus] = useState('')
    const [actionStatus, setActionStatus] = useState('')
    const [inactiveStatusCount, setInactiveStatusCount] = useState('')
    const [actionConfirmationDelete, setActionConfirmationDelete] = useState(false)
    const [cdBankOptionsForCD, setCdBankOptionsForCD] = useState([] as string[]);
    const [pciError, setPciError] = useState(false);
    const [pciErrorMessage, setPciErrorMessage] = useState('');
    const [chargeBackError, setChargeBackError] = useState(false);
    const [chargeBackErrorMessage, setChargeBackErrorMessage] = useState('');
    
    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0);
 
 const CREATE_LINK_PAGE : string[] =
  [ ROLES.RELATIONSHIP_MANAGER,
   ROLES.OFFICE_MANAGER,
   ROLES.OFFICE_ADMIN]

  
    const fetchPartnerRms = async (id:string, onlyFetch = false) => {
      setPartnerRmId("")
      try{
        const data = {
          api: api.partnerUsrRoles.listRmsByPartnerId,
          queryParam:  {partner_id: id},
        }
        const response = await backend.fetch(data)
      setFetchingPartnersRMS(false)
      const backupResponse = !!response ? response.map((item: any) => {
        const rm_name = REACT_APP_BACKEND_MYSQL ? `${item.user_name_first} ${item.user_name_last}` : `${item.user_name.first_name} ${item.user_name.last_name}`
        return { ...item, text: rm_name, value: item.user_id };
      }) : [];
        setPartnerRm(backupResponse);
        if(role === ROLES.RELATIONSHIP_MANAGER && !!response && response.length > 1 && onlyFetch){
          const data = { api: api.users.getByGUID, urlParam: user_guid};
          const profiledata = await backend.fetch(data);
          setPartnerRmId(profiledata.user_id)
          setPartnerRm(backupResponse);
        }
      if(!onlyFetch){
        if(!!response && response.length === 1){
          backupResponse[0].options = [backupResponse[0]]
          handleChangePartnerRms({}, backupResponse[0])
        }
        // if(!response || response.length === 0){
        //   getUserInfoByDefaultRm()
        // }
      }
      }catch(err){
        console.error("ERROR >> while fetching RMs ",err)
        // setPartnerRm("")
      }finally{
        setFetchingPartnersRMS(false)
      }
    }

    const uncheckparentcheckbox = async()=>{
      setBulkaction('')
      let myUsers = [...sendLinkUsers];
      setIsParentChecked(false)
      myUsers.map((user) => {
        user.isChecked = false
        return user;
      });
      setSelectedAppBulk([])
    }
    const bulkUpdateAction  = (item: any) => {
      setBulkaction(item)
      setModalAction(item.label)
      setActionStatus(item.value)
      console.log(selectedAppBulk)

      if(item.value === 'active' || item.value === 'inactive'){
        let checkAppStatus = selectedAppBulk.filter((items: any) => items.app_link_status === item.value);
        const appStatusCount = checkAppStatus.length
        setStatusCount(appStatusCount)
        setActionConfirmation(true)
      }else{
         let checkAppStatus = selectedAppBulk.filter((items: any) => items.app_link_status === 'inactive');
         const appStatusCount = checkAppStatus.length
         setInactiveStatusCount(appStatusCount)
         setActionConfirmationDelete(true)
        }
      
    }

    let finalarray : any[] = [];
    const changeCheckboxStatus = async(e:any, id:string) => {
        setBulkaction('')
        const myApps = [...sendLinkUsers];
        console.log(sendLinkUsers)
          const { checked } = e.target;
          if (id === "p1" ) {
            finalarray = [] 
            setSelectedAppBulk([])
          }
          myApps.map((apps) => {
            if (id === "p1") {
              setIsParentChecked(checked);
              apps.isChecked = checked;
              if(e.target.checked){
                finalarray.push({'app_link_id' : apps.app_link_id,'app_link_status':apps.app_link_status, 'deleted':false})
              setSelectedAppBulk(finalarray)
              }
            } else {
              if (apps.app_link_id === id) {
                apps.isChecked = checked;
                let checkID =  selectedAppBulk.findIndex((app: any) => app.app_link_id === id)
                console.log(checkID)
                if(checkID === -1){
                  selectedAppBulk.push({'app_link_id' : apps.app_link_id,'app_link_status':apps.app_link_status,'deleted':false})
                  setSelectedAppBulk(selectedAppBulk)
                }
                else{
                  let secondRemoved  = selectedAppBulk.filter((app: any) => app.app_link_id !== id)
                  setSelectedAppBulk(secondRemoved)
                }
              }
              const isAllChildsChecked = myApps.every(
                (user) => user.isChecked === true
              );
              if (isAllChildsChecked) {
                setIsParentChecked(checked);
              } else {
                setIsParentChecked(false);
              }
            }
            return apps;
          });
        
          setApps([...myApps]);
  };

    const getRmsByLoginUser = async () => {
      const data: any ={
        api: api.offices.getOfficeDetails ,
        queryParam:  {office_id: office_id},
    }
      const responsedata = await backend.fetch(data);
      const backupResponse = !!responsedata.allowed_RMs ? responsedata.allowed_RMs.map((item: any) => {
        return { ...item, text: `${item.user_name.first_name} ${item.user_name.last_name}`, value: item.user_id };
      }) : [];
        //setPartnerRm(backupResponse);
        if(!!responsedata.allowed_RMs && responsedata.allowed_RMs.length === 1){
          backupResponse[0].options = [backupResponse[0]]
          handleChangePartnerRms({}, backupResponse[0])
        }
    }

    const isEditFlow = isEditMode;

    const getUserInfo = async () => {
      const data = { api: api.users.getByGUID, urlParam: user_guid};
      const profiledata = await backend.fetch(data);
      const rm_name = REACT_APP_BACKEND_MYSQL ? `${profiledata.user_name_first} ${profiledata.user_name_last}` : `${profiledata.name?.first_name} ${profiledata.name?.last_name}`
      setPartnerRm([{
        text: rm_name,
        value: profiledata.user_id
      }]);
      setPartnerRmId(profiledata.user_id)
    }

  useEffect(() => { 
    if ((
      business_name !== "" ||
      business_contact !== "" ||
      business_email !== ""
    ) && isEditMode){
      setSaveLinkError(
        "To update General Link, DBA Name, Merchant Email, and Contact Name on top of the page should be blank"
      );
    }else if((
      business_name !== "" ||
      business_contact !== "" ||
      business_email !== ""
    ) && !isEditMode){
      setSaveLinkError(
        "To create a General Link, DBA Name, Merchant Email, and Contact Name on top of the page should be blank"
      );
    }else{
      setSaveLinkError("");
    }
  },[business_name,business_contact,business_email])
  useEffect(() => {
    if(selectedBankOption === BANK_CODES.CFSB || selectedBankOption === BANK_CODES.CFSB_CD)
      {
        setPciNonCompliance(otherFees_cfsb.pciNonCompliance)
      } 
      else {
        setPciNonCompliance(otherFees.pciNonCompliance)
      }

  },[selectedBankOption]);
    useEffect(() => {
      if (role === ROLES.RELATIONSHIP_MANAGER && !isEditFlow) {
        // it will automatically select the logged in user's RM
        getUserInfo();
      } else if (!isEditFlow) {
        if (role === ROLES.PARTNER) {
          fetchPartnerRms(partner_id, false)
          fetchLinkedSalesUserDetails(partner_id)
        }

      }
    }, []);

    useEffect(()=>{
      if(selectedSellTime === "Cash Discount"){
        let filteredBanks = showLowRiskProcessors.filter((banks: any) => banks.value === BANK_CODES.CFSB_CD || banks.value === BANK_CODES.ESQUIRE_CD)
        setCdBankOptionsForCD(filteredBanks);
        if(filteredBanks.length < 0){
          setShowCashDiscount(false);
        } else if(filteredBanks.length === 1) {
          setSelectedBankOption(filteredBanks[0].value);
          setDisableSalesMethod(true)
        }
      }
    },[selectedSellTime, showLowRiskProcessors])

    const fetchLinkedSalesUserDetails = async (partner_id:string) => {
      try{
        const data = {
          api: api.users.getByPartnerId,
          urlParam: partner_id||partnerId
        }
        const response = await backend.fetch(data)
        console.log("response>>>>>",response)
        setFreeEquipmentPlacementStatus(response?.free_equipment_placement_enabled||false)
      }catch(err){
        console.error("ERROR >> while fetching partner ",err)
      }
    }
    
    useEffect(() => {
      if (!partnerRm.find((rm: any) => rm.user_id === partnerRmId) && isEditFlow)
        setPartnerRmId("")
    }, [partnerRm])

    useEffect(() => {
      const fetchParentOffices = async () => {
        try {
          const data = {
            api: api.offices.getParentChildOfficesDetailsByOfficeId,
            queryParam: { child_office_id: office_id },
          };
          const response = await backend.fetch(data);
          setFetchingOffices(false);
          setParentOffices(response);
        } catch (err) {
          console.error("ERROR >> while fetching offices ", err);
        } finally {
          setFetchingOffices(false);
        }
      };
      const fetchAllOffices = async () => {
        try {
          const data = {
            api: api.offices.getAllOffices,
          };
          const response = await backend.fetch(data);
          setFetchingOffices(false);
          setParentOffices(response);
        } catch (err) {
          console.error("ERROR >> while fetching offices ", err);
        } finally {
          setFetchingOffices(false);
        }
      };
      if (office_id) fetchParentOffices();
      if (role === ROLES.RELATIONSHIP_MANAGER) fetchAllOffices();
    }, []);

    useEffect(() => {
      if(riskLevel === RISK_LEVELS.HIGH_RISK){
        const list: any = [...equipmentSectionData]
        const updatedList:any = []
        list.forEach((element:any) => {
            if(element.equipment_type && element.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL){
                element['deployment']= "New"
            }
            updatedList.push(element)
        });
        setEquipmentSectionData([...updatedList])
    }

    }, [riskLevel])

    useEffect(() => {
      let check = false;
      console.log(
        ">>>>",
        role,
        !fetchingPartners,
        !fetchingOffices,
        !fetchingProcessors,
        businessCategoryConditionalOptions
      );
      if (
        role !== ROLES.PARTNER &&
        !fetchingPartners &&
        !fetchingOffices &&
        !fetchingProcessors &&
        businessCategoryConditionalOptions
      )
        check = true;
      if (
        role === ROLES.PARTNER &&
        !fetchingOffices &&
        !fetchingProcessors &&
        businessCategoryConditionalOptions
      )
        check = true;

      if (check || role === ROLES.VIEW_ONLY) setLoading(false);

      const result: any = partners.find((partner: any) => partner.partner_id === partnerId);
      if ((role === ROLES.RELATIONSHIP_MANAGER ||
        role === ROLES.OFFICE_MANAGER ||
        role === ROLES.OFFICE_ADMIN) && !result?.partner_id) {
          setPartnerId('');
      } 

    }, [processors, parentOffices, partners, partnerId]);
   
    const checkChargeBackValue = (val: any) => {
      if (selectedBankOption === BANK_CODES.CFSB_CD || selectedBankOption === BANK_CODES.ESQUIRE_CD) {
        const currentValue = val ? parseInt(val) : 15;
        if (!(role === ROLES.SUPER_ADMIN || role === ROLES.RELATIONSHIP_MANAGER) && currentValue < 15) {
          setChargeBackError(true);
          setChargeBackErrorMessage('Please Enter higher amount');
        } else {
          setChargeBackError(false);
          setChargeBackErrorMessage('');
        }
      } else if(selectedBankOption === BANK_CODES.HARRIS || selectedBankOption === BANK_CODES.CENTRAL) {
        if ((riskLevel === "High Risk") && val < 40){
          console.log("entered high risk")
          setChargeBackError(true);
          setChargeBackErrorMessage('Amount must be $40 or more');
        }
        else if((riskLevel === "Low Risk") && val < 25){
          console.log("entered Low risk")
          setChargeBackError(true);
          setChargeBackErrorMessage('Amount must be $25 or more');
        } else {
          setChargeBackError(false);
          setChargeBackErrorMessage('');
        }
      }
    } 
    
    const checkPciValue = (val: any) => {
      if (selectedBankOption === BANK_CODES.CFSB_CD || selectedBankOption === BANK_CODES.ESQUIRE_CD) {
      const currentValue = val ? parseFloat(val) : 119.99;
      if (!(role === ROLES.SUPER_ADMIN || role === ROLES.RELATIONSHIP_MANAGER) && currentValue < 119.99) {
        setPciError(true);
        setPciErrorMessage('Please Enter higher amount');
      } else {
        setPciError(false);
        setPciErrorMessage('');
      }
    }
  }
    useEffect(() => {
      if (role === ROLES.PARTNER) {
        if (
          allowed_risk_levels_partner &&
          allowed_risk_levels_partner.length > 0
        ) {
          setShowRiskLevels(allowed_risk_levels_partner);
        } else if (office_id) {
          handleRiskLevelsFromOffices(office_id);
        } else {
          setShowRiskLevels([RISK_LEVELS.LOW_RISK, RISK_LEVELS.HIGH_RISK]);
        }

        if (
          allowed_lowrisk_processors &&
          allowed_lowrisk_processors.length > 0
        ) {
          setShowLowRiskProcessors(
            processors?.filter(
              (processor: any) =>
                allowed_lowrisk_processors.includes(processor.value) &&
                processor.value !== BANK_CODES.DART
            )
          );
        } else if (office_id) {
          handleLowRiskProcessorsFromOffices(office_id);
        } else {
          setShowLowRiskProcessors(
            processors?.filter(
              (processor: any) => processor.value !== BANK_CODES.DART
            )
          );
        }
        if (
          allowed_highrisk_processors &&
          allowed_highrisk_processors.length > 0
        ) {
          setShowHighRiskProcessors(
            processors?.filter((processor: any) =>
              allowed_highrisk_processors.includes(processor.value) && cdBanks.indexOf(processor.value) === -1
            )
          );
        } else if (office_id) {
          handleHighRiskProcessorsFromOffices(office_id);
        } else {
          setShowHighRiskProcessors(processors?.filter((processor: any) => cdBanks.indexOf(processor.value) === -1));
        }
      }
    }, [parentOffices, processors]);

    useEffect(() => {
      const notlistCFSBLowRiskBanks = showLowRiskProcessors?.filter((item: any) => cdBanks.indexOf(item.value) > -1)
      //const notalistedCFSBHighRiskBanks = showHighRiskProcessors?.filter((item: any) => item.value === BANK_CODES.CFSB)
      if (notlistCFSBLowRiskBanks.length > 0 && riskLevel == RISK_LEVELS.LOW_RISK) {
        setShowCashDiscount(true)
      }else {
        setShowCashDiscount(false)
      }
      if (notlistCFSBLowRiskBanks.length === 0 && riskLevel === RISK_LEVELS.LOW_RISK && selectedSellTime === 'Cash Discount') {
        setSelectedSellTime('')
      }
    }, [showLowRiskProcessors, showHighRiskProcessors, riskLevel])

    useEffect(() => {
      const prepareOptions = (data: any) => {
        if (data && data.length) {
          data.sort(
            firstBy(function (option: any) {
              return option.toLowerCase();
            })
          );
        }
        let options = [];
        for (const option of data) {
          options.push({
            text: option,
            value: option,
          });
        }
        return options;
      };
      const fetchLookupDataByType = async (lookup_data_type: string) => {
        const data = {
          api: api.lookupData.getLookupDataByType,
          queryParam: { lookup_data_type: lookup_data_type },
        };
        const response = await backend.fetch(data);
        /**
         * response format { [{"lookup_type": ['value1','value2'], 'lookup_type_name': 'type'}]}
         */
        const lookup_obj = response && response.length ? response[0] : {};
        let key: any = [];
        let value: any = [];
        if (lookup_obj && Object.keys(lookup_obj).length === 2) {
          key = Object.values(lookup_obj)[0] || [];
          value = Object.values(lookup_obj)[1] || [];
        }
        let options = [];
        if (Array.isArray(key) && key.length) {
          for (const option of key) {
            options.push({
              text: option,
              value: option,
            });
          }
        }
        if (Array.isArray(value) && value.length) {
          for (const option of value) {
            options.push({
              text: option,
              value: option,
            });
          }
        }
        switch (lookup_data_type) {
          case "BUSINESS_CATEGORY":
            options.sort(
              firstBy(function (option: any) {
                return option.text.toLowerCase();
              })
            );
            setBusinessCategoryConditionalOptions(options);
          return;
          case "BUSINESS_TYPE":
            setBusinessTypeOptions(response[0]?.BusinessCategory);
            return;
          case "PRODUCT_PRICES":
            const productPrices = !!response[0] ? response[0]?.ProductPrices : ['']
            setProductPrices(productPrices)
            const productOptions = productPrices.map((product: any) => {
              return product?.split('__')[0] || ''
            })
            options = !!response[0] ? prepareOptions(productOptions) : [{text: '', value: ''}]
            setProductOptions(options)
            return;
            case "EQUIPMENT_TYPE":
              options.sort(
                firstBy(function (option: any) {
                  return option.text.toLowerCase();
                })
              );
              setEquipmentTerminalOriginal(options);
              setEquipmentTerminal(options);
              return;
            case "PRODUCT_NAME":
              setEquipmentProductOptions(response[0]?.EquipmentType);
              return;
            case "MOBILE_EQUIPMENT":
              response.findIndex((option: any) => option.LookupDataType === 'MOBILE_EQUIPMENT')
              options = !!response[0] ? prepareOptions(response[0]?.MobileEquipmentType) : [{ text: '', value: '' }]
              setMobileEquipmentOptions(options)
              return;
          default:
            return;
        }
      };

      fetchLookupDataByType("BUSINESS_CATEGORY");
      fetchLookupDataByType("BUSINESS_TYPE");
      fetchLookupDataByType("PRODUCT_PRICES");
      fetchLookupDataByType("EQUIPMENT_TYPE");
      fetchLookupDataByType("PRODUCT_NAME");
      fetchLookupDataByType("MOBILE_EQUIPMENT");
      const params = new URLSearchParams(search)
      const tokenID: any = params.get('token')
      if (tokenID){
        setDisableSalesMethod(true)
        setTokenID(tokenID) 
    }
    }, []);

    useEffect(() => {
      let options = [];
      const index = businessTypeOptions.findIndex(
        (option: any) => option.BusinessCategoryName === businessCategory
      );
      const business_types = businessTypeOptions[index]?.BusinessTypeName || [];
      business_types.sort(
        firstBy(function (option: any) {
          return option.toLowerCase();
        })
      );
      for (const option of business_types) {
        options.push({
          text: option,
          value: option,
        });
      }
      setBusinessTypeConditionalOption(options);
    }, [businessCategory]);

    useEffect(() => {
      let options = [];
      const index = equipmentProductOptions.findIndex(
        (option: any) => option.EquipmentTypeName === equipmentProduct
      );
      const business_types = equipmentProductOptions[index]?.ProductName || [];
      business_types.sort(
        firstBy(function (option: any) {
          return option.toLowerCase();
        })
      );
      for (const option of business_types) {
        options.push({
          text: option,
          value: option,
        });
      }
      setEquipmentTypeConditionalOption(options);
    }, [equipmentProduct]);

    const getProductOptions = (type:string)=>{
      let options = []
      if(type&&type.length){
        const index = equipmentProductOptions.findIndex((option: any) => option.EquipmentTypeName === type)
        const business_types = equipmentProductOptions[index]?.ProductName || []
        business_types.sort(
          firstBy(function (option: any) {
            return option.toLowerCase();
          })
        );
        for (const option of business_types) {
          options.push({
            text: option,
            value: option
          })
        }
      }
      return options
    }
    useEffect(() => {
      const fetchPartnerData = async () => {
        try {
          const data = {
            api: api.rm.listPartnersForRM,
            queryParam: { rm_id: user_id },
          };
          const response = await backend.fetch(data);
          console.log("response:::",response)
          setFetchingPartners(false);
          setPartners(
            response.map((item: any) => {
              const partner_name = REACT_APP_BACKEND_MYSQL ? `${item.user_name_first} ${item.user_name_last}` : item.partner_name
              return {
                ...item,
                text: partner_name,
                value: item.partner_id,
              };
            })
          );
        } catch (err) {
          console.error("ERROR >> while fetching partners ", err);
        } finally {
          setFetchingPartners(false);
        }
      };
      const fetchPartnersByOffice = async () => {
        try {
          const data = {
            api: api.users.fetchPartnersByOfficeId,
            queryParam: { office_id: office_id },
          };
          const response = await backend.fetch(data);
          setFetchingPartners(false);
          setPartners(
            response.map((item: any) => {
              const partner_name = REACT_APP_BACKEND_MYSQL ? `${item.user_name_first} ${item.user_name_last}` : item.partner_name
              return {
                ...item,
                text: partner_name,
                value: item.partner_id,
              };
            })
          );
        } catch (err) {
          console.error("ERROR >> while fetching partners ", err);
        } finally {
          setFetchingPartners(false);
        }
      };
      if (role === ROLES.RELATIONSHIP_MANAGER) {
        fetchPartnerData();
      } else if (role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) {
        fetchPartnersByOffice();
      } else {
        setFetchingPartners(false);
        const partner_id: any = sessionStorage.getItem("partner_id");
        const partner_name: any = sessionStorage.getItem("partner_name");
        const partner_rm_name: any = sessionStorage.getItem("partner_rm_name");
        setPartnerId(partner_id);
        setPartnerName(partner_name);
        // setPartnerRmId(partner_rm_id);
        setPartnerRmName(partner_rm_name);
      }
      const fetchProcessorsData = async () => {
        try {
          const data = {
            api: api.processorData.getProcessors,
          };
          setFetchingProcessors(true);
          const response = await backend.fetch(data);
          response.sort(
            firstBy(function (option: any) {
              return option?.processor_name?.toLowerCase();
            })
          );
          setFetchingProcessors(false);
          setProcessors(
            response.map((item: any) => {
              const processorName = REACT_APP_BACKEND_MYSQL ? {text: item.processor_name, value: item.processor_internal_id} : {text: item.processor_name, value: item.processor_code}
              return processorName;
            })
          );
          if(pricingType === ''){
            pricingCardType.forEach((item: any) => {
              item.ischecked = false
            })
          }
        } catch (err) {
          console.error("ERROR >> while fetching banks ", err);
        } finally {
          setFetchingProcessors(false);
        }
      };
      fetchProcessorsData();
    }, [role, user_id]);

    useEffect(() => {
      fetchSendLinkUsers();
    }, [config.selectedClientId]);

    const refreshData = () => {
      setTimeout(async () => {
        getAlgolia();
      }, 3000);
    };

    const fetchSendLinkUsers = async () => {
      setLoading(true);
      setAlgoliaLoading(true);
      const dataList = await fetchAppLinkListData();
      setAlgoliaLoading(false);
      let updatedDataList = [];
      if (!super_user) {
        if (role === ROLES.RELATIONSHIP_MANAGER) {
          if (REACT_APP_BACKEND_MYSQL) {
            updatedDataList = dataList.filter((item: any) => !item.deleted && item?.partner_rm_user_id == user_id && item.client_id == client_id)
          } else {
            updatedDataList = dataList.filter((item: any) => !item.deleted && item?.rm_ids?.findIndex((item1: any) => item1 === user_id) > -1)
          }
        } else if (role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN) {
          if (REACT_APP_BACKEND_MYSQL) {
            updatedDataList = dataList.filter((item: any) => !item.deleted && item.client_id == client_id &&
              (item?.partner_id == partner_id
                || item?.office_id == office_id
                || item?.parent_office_ids?.split(",").findIndex((item1: any) => item1 == office_id) > -1))
          } else {
            updatedDataList = dataList.filter((item: any) => !item.deleted &&
              (item?.partner_id === partner_id
                || item?.office_id === office_id
                || item?.parent_office_ids?.findIndex((item1: any) => item1 === office_id) > -1))
          }
        } else {
          if (REACT_APP_BACKEND_MYSQL) {
            updatedDataList = dataList?.filter((item: any) => !item.deleted && item.client_id == client_id && (item.partner_id == partner_id))
          } else {
            updatedDataList = dataList?.filter((item: any) => !item.deleted && (item.partner_id == partner_id))
          }
        }
      } else {
        updatedDataList = dataList.filter((item: any) => !item.deleted && item.client_id == client_id)
        if (role === ROLES.RELATIONSHIP_MANAGER) {
          if (REACT_APP_BACKEND_MYSQL) {
            updatedDataList = updatedDataList.filter((item: any) => item?.partner_rm_user_id == user_id)
          } else {
            updatedDataList = updatedDataList.filter((item: any) => item?.rm_ids?.findIndex((item1: any) => item1 === user_id) > -1)
          }
        }
      }
      if (!!updatedDataList) 
        setNoAppText("No App Links as of now, please start from Create Link");
      else setNoAppText("");
      setSendLinkUsers(updatedDataList);
      return updatedDataList;
    };

    const formatDate = (date: any) => {
      if (date === "Invalid Date") {
        return "";
      }
      var d = new Date(date);
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [month, day, year].join("-");
    };
    const handleEmptyFieldAlert = async () => {
      setEmptyTopFields(false);
      setBusinessName("");
      setBusinessEmail("");
      setBusinessContact("");
      if (
        role === ROLES.RELATIONSHIP_MANAGER ||
        role === ROLES.OFFICE_MANAGER ||
        role === ROLES.OFFICE_ADMIN
      ) {
        setPartnerName("");
        setPartnerId("");
      }
    };
    const handleChangeSellTime = (e: any, data: any) => {
      setChargeBackFee("")
      setSelectedBankOption('')
      setSelectedSellTime(data.value)
      // data.value === 'Cash Discount' ? setSelectedBankOption(BANK_CODES.CFSB_CD) : setSelectedBankOption('') 
      setPciValidation('119.99')
      if(data.value !== 'Cash Discount'){
        clearCashDiscount()
        setPricingType('')
      }else{
        let filteredBanks = showLowRiskProcessors.filter((banks: any) => banks.value === BANK_CODES.CFSB_CD || banks.value === BANK_CODES.ESQUIRE_CD)

        setCdBankOptionsForCD(filteredBanks);
        if(filteredBanks.length < 0){
          setShowCashDiscount(false);
        } else if(filteredBanks.length === 1) {
          setSelectedBankOption(filteredBanks[0].value);
          // setDisableSalesMethod(true)
        }

        clearTieredVMD()
        setPricingType('Flat Rate')
        clearErrVMD()
        clearInterChangeFlatRateVMD()
        clearTieredAMEX()
        clearInterFlatErrAMEX()
        const list: any = [...equipmentSectionData]
          const updatedList: any = []
          list.forEach((element: any) => {
            element['product'] = ''
            element['application'] = ''
            element['billTo'] = ''
            element['quantity'] = ''
            element['cost'] = ''
            element['equipment_mailing_addr_type'] = ''
            element['pos_name'] = ''
            updatedList.push(element)
          });
          setEquipmentSectionData([...updatedList])
      }
    }
    const handleChangePricingType = (e: any, data: any) => {
      setPricingType(data.value);
      if (
        data.value === "Tiered" &&
        (pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover"))
      ) {
        setErrQualDiscount("");
        setErrQualPerItem("");
        setErrNonDiscount("");
        setErrNonPerItem("");
        setIntAmexDiscountRate("");
        setIntAmexperItem("");
        setDiscountRate("");
        setPerItem("");
        setDebitDiscountRate("");
        setDebitNetworkFees("Yes");
        setAmexQual("");
        setAmexPerItem("");
        setAmexMidQual("");
        setAmexMidPerItem("");
        setAmexNonQual("");
        setAmexNonPerItem("");
      } else if (data.value === "Tiered" && pricingCardValue.includes("AMEX")) {
        setErrQualDiscount("");
        setErrQualPerItem("");
        setErrNonDiscount("");
        setErrNonPerItem("");
        setIntAmexDiscountRate("");
        setIntAmexperItem("");
        setDiscountRate("");
        setPerItem("");
        setDebitDiscountRate("");
        setDebitNetworkFees("Yes");
        setTieredQualDiscount("");
        setTieredQualPerItem("");
        setTieredMidDiscount("");
        setTieredMidPerItem("");
        setTieredNonDiscount("");
        setTieredNonPerItem("");
        setTieredDebitDiscount("");
        setTieredDebitPerItem("");
        setDiscountRate("");
        setPerItem("");
      } else if (
        data.value === "Interchange" &&
        pricingCardValue.includes("AMEX")
      ) {
        setErrQualDiscount("");
        setErrQualPerItem("");
        setErrNonDiscount("");
        setErrNonPerItem("");
        setAmexQual("");
        setAmexPerItem("");
        setAmexMidQual("");
        setAmexMidPerItem("");
        setAmexNonQual("");
        setAmexNonPerItem("");
        setTieredQualDiscount("");
        setTieredQualPerItem("");
        setTieredMidDiscount("");
        setTieredMidPerItem("");
        setTieredNonDiscount("");
        setTieredNonPerItem("");
        setTieredDebitDiscount("");
        setTieredDebitPerItem("");
        setErrNonPerItem("");
        setIntAmexDiscountRate("");
        setIntAmexperItem("");
      } else if (
        data.value === "Interchange" &&
        (pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover"))
      ) {
        setErrQualDiscount("");
        setErrQualPerItem("");
        setErrNonDiscount("");
        setErrNonPerItem("");
        setDiscountRate("");
        setPerItem("");
        setDebitDiscountRate("");
        setDebitNetworkFees("Yes");
        setAmexQual("");
        setAmexPerItem("");
        setAmexMidQual("");
        setAmexMidPerItem("");
        setAmexNonQual("");
        setAmexNonPerItem("");
        setTieredQualDiscount("");
        setTieredQualPerItem("");
        setTieredMidDiscount("");
        setTieredMidPerItem("");
        setTieredNonDiscount("");
        setTieredNonPerItem("");
        setTieredDebitDiscount("");
        setTieredDebitPerItem("");
      } else if (
        data.value === "Err" &&
        (pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover"))
      ) {
        setPerItem("");
        setDebitNetworkFees("Yes");
        setAmexQual("");
        setAmexPerItem("");
        setAmexMidQual("");
        setAmexMidPerItem("");
        setAmexNonQual("");
        setAmexNonPerItem("");
        setTieredQualDiscount("");
        setTieredQualPerItem("");
        setTieredMidDiscount("");
        setTieredMidPerItem("");
        setTieredNonDiscount("");
        setTieredNonPerItem("");
        setTieredDebitDiscount("");
        setTieredDebitPerItem("");
        setDiscountRate("");
        setPerItem("");
        setIntAmexDiscountRate("");
        setIntAmexperItem("");
      } else if (data.value === "Err" && pricingCardValue.includes("AMEX")) {
        setPerItem("");
        setDebitNetworkFees("Yes");
        setAmexQual("");
        setAmexPerItem("");
        setAmexMidQual("");
        setAmexMidPerItem("");
        setAmexNonQual("");
        setAmexNonPerItem("");
        setTieredQualDiscount("");
        setTieredQualPerItem("");
        setTieredMidDiscount("");
        setTieredMidPerItem("");
        setTieredNonDiscount("");
        setTieredNonPerItem("");
        setTieredDebitDiscount("");
        setTieredDebitPerItem("");
        setDiscountRate("");
        setPerItem("");
      } else if (
        data.value === "Flat Rate" &&
        (pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover"))
      ) {
        setErrQualDiscount("");
        setErrQualPerItem("");
        setErrNonDiscount("");
        setErrNonPerItem("");
        setDiscountRate("");
        setPerItem("");
        setDebitDiscountRate("");
        setDebitNetworkFees("Yes");
        setAmexQual("");
        setAmexPerItem("");
        setAmexMidQual("");
        setAmexMidPerItem("");
        setAmexNonQual("");
        setAmexNonPerItem("");
        setTieredQualDiscount("");
        setTieredQualPerItem("");
        setTieredMidDiscount("");
        setTieredMidPerItem("");
        setTieredNonDiscount("");
        setTieredNonPerItem("");
        setTieredDebitDiscount("");
        setTieredDebitPerItem("");
      } else if (
        data.value === "Flat Rate" &&
        pricingCardValue.includes("AMEX")
      ) {
        setErrQualDiscount("");
        setErrQualPerItem("");
        setErrNonDiscount("");
        setErrNonPerItem("");
        setAmexQual("");
        setAmexPerItem("");
        setAmexMidQual("");
        setAmexMidPerItem("");
        setAmexNonQual("");
        setAmexNonPerItem("");
        setTieredQualDiscount("");
        setTieredQualPerItem("");
        setTieredMidDiscount("");
        setTieredMidPerItem("");
        setTieredNonDiscount("");
        setTieredNonPerItem("");
        setTieredDebitDiscount("");
        setTieredDebitPerItem("");
        setErrNonPerItem("");
        setIntAmexDiscountRate("");
        setIntAmexperItem("");
      }
    };
    const handleChangePinDebit = (e: any, data: any) => {
      setPinDebit(data.value);
    };
    const handleAcceptEbt = (e: any, data: any) => {
      setAcceptEbt(data.value);
    };
    const validateNumbers = (value: string) => {
      return /^\d{0,10}(\.\d{0,2})?$/.test(value);
    };
    const handleChangeTextInput = (e: any) => {
      const { name, value } = e.target;
      switch (name) {
        // merchant_info
        case "business_name":
          setBusinessName(value);
          if (value === " ") setBusinessName("");
          else setBusinessName(value.trimLeft());
          return;
        case "business_email":
          setBusinessEmail(value);
          if (value === " ") setBusinessEmail("");
          else setBusinessEmail(value);
          return;
        case "business_contact":
          setBusinessContact(value);
          if (value === " ") setBusinessContact("");
          else setBusinessContact(value);
          return;
        // Pricing
        case "annualFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAnnualFee(value);
          return;
        case "monthlyFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setMonthlyFee(value);
          return;
        case "tieredQualDiscount":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setTieredQualDiscount(value);
          // if(selectedBankOption === BANK_CODES.AXIOM || selectedBankOption === BANK_CODES.SYNOVUS){
          // setTieredMidDiscount(value ? (parseFloat(value) + 1.05).toFixed(2).toString() : '');
          // setTieredNonDiscount(value ? (parseFloat(value) + 1.55).toFixed(2).toString() : '');
          // }
          return;
        case "tieredQualPerItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setTieredQualPerItem(value);
          // if(selectedBankOption === BANK_CODES.AXIOM || selectedBankOption === BANK_CODES.SYNOVUS){
          //   setTieredMidPerItem(value ? (parseFloat(value) + 0.1).toFixed(2).toString() : '');
          //   setTieredNonPerItem(value ? (parseFloat(value) + 0.1).toFixed(2).toString() : '');
          // }
          return;
        case "tieredMidDiscount":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setTieredMidDiscount(value);
          return;
        case "tieredMidPerItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setTieredMidPerItem(value);
          return;
        case "tieredNonDiscount":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setTieredNonDiscount(value);
          return;
        case "tieredNonPerItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setTieredNonPerItem(value);
          return;
        case "tieredDebitDiscount":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setTieredDebitDiscount(value);
          return;
        case "tieredDebitPerItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setTieredDebitPerItem(value);
          return;
        case "errQualDiscount":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setErrQualDiscount(value);
          return;
        case "errQualPerItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setErrQualPerItem(value);
          return;
        case "errNonDiscount":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setErrNonDiscount(value);
          return;
        case "errNonPerItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setErrNonPerItem(value);
          return;
        case "intAmexDiscountRate":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setIntAmexDiscountRate(value);
          return;
        case "intAmexperItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setIntAmexperItem(value);
          return;
        case "discountRate":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setDiscountRate(value);
          return;
        case "perItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setPerItem(value);
          return;
        case "debitDiscountRate":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setDebitDiscountRate(value);
          return;
        case "amexQual":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAmexQual(value);
          return;
        case "amexPerItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAmexPerItem(value);
          return;
        case "amexMidQual":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAmexMidQual(value);
          return;
        case "amexMidPerItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAmexMidPerItem(value);
          return;
        case "amexNonQual":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAmexNonQual(value);
          return;
        case "amexNonPerItem":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAmexNonPerItem(value);
          return;
        case "pinDebitAuth":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setPinDebitAuth(value);
          return;
        case "ebt":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setEbt(value);
          return;
        case "ebtAuth":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setEbtAuth(value);
          return;
        case "wirelessFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setWirelessFee(value);
          return;
        case "mc":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setMc(value);
          return;
        case "visa":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setVisa(value);
          return;
        case "amexAuth":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAmexAuth(value);
          return;
        case "avs":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAvs(value);
          return;
        case "batchFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setBatchFee(value);
          return;
        case "chargeBackFee":
          if (!validateNumbers(value) || value.length > 9){
            return false;
          }
          checkChargeBackValue(value)
          setChargeBackFee(value);
          return;
          
        case "monthlyMinimum":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setMonthlyMinimum(value);
          return;
        case "earlyTerminationFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setEarlyTerminationFee(value);  
          return;
        case "accountReserve":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAccountReserve(value);
          return;
        case "other":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setOther(value);
          return;
        case "voiceAuth":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setVoiceAuth(value);
          return;
        case "excessiveElectronicAuth":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setExcessiveElectronicAuth(value);
          return;
        case "irsReportingFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setIrsReportingFee(value);
          return;
        case "retreivalFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setRetreivalFee(value);
          return;
        case "invalidTinFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setInvalidTinFee(value);
          return;
        case "achRejectFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAchRejectFee(value);
          return;
        case "collectionsFee":
          setCollectionsFee(value);
          return;
        case "pciValidation":
          checkPciValue(value);
          setPciValidation(value);
          return;
        case "pciNonCompliance":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          if((selectedBankOption === BANK_CODES.CFSB || selectedBankOption === BANK_CODES.CFSB_CD))
            {
              setPciNonCompliance(otherFees_cfsb.pciNonCompliance)
            }
            else {
          setPciNonCompliance(otherFees.pciNonCompliance);}
          return;
        case "industryCompliance":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setIndustryCompliance(value);
          return;
        case "returnTransactionFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setReturnTransactionFee(value);
          return;
        case "amexOptubleSupportFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAmexOptubleSupportFee(value);
          return;
        case "amexOptubleNetworkFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setAmexOptubleNetworkFee(value);
          return;
        case "debitAccessFee":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setDebitAccessFee(value);
          return;
        case "licenseTechFees":
          if (!validateNumbers(value) || value.length > 9) {
            return false;
          }
          setLicenseTechFees(value);
          return;
        case "link_name":
          setLinkName(value.trimLeft());
          return;
        case "clymDataPrivacyComplianceFee":
          setClymDataPrivacyComplianceFee(value);
          return;
        default:
          return;
      }
    };

    const handleLowRiskProcessorsFromOffices = (office_id: string) => {
      let dummyLowRiskProcessors: any = [];

      for (let i = 0; i < parentOffices.length; i++) {
        let index = parentOffices.findIndex(
          (office: any) => office.office_id === office_id
        );
        dummyLowRiskProcessors =
          parentOffices[index]?.allowed_low_risk_processors || [];
        if (
          (dummyLowRiskProcessors && dummyLowRiskProcessors.length > 0) ||
          parentOffices[index]?.office_level === "1"
        ) {
          break;
        } else {
          office_id = parentOffices[index]?.parent_office_id;
        }
      }
      if (dummyLowRiskProcessors && dummyLowRiskProcessors.length === 0) {
        setShowLowRiskProcessors(
          processors?.filter(
            (processor: any) => processor.value !== BANK_CODES.DART
          )
        );
      } else {
        setShowLowRiskProcessors(
          processors?.filter(
            (processor: any) =>
              dummyLowRiskProcessors?.includes(processor.value) &&
              processor.value !== BANK_CODES.DART
          )
        );
      }
    };

    const handleHighRiskProcessorsFromOffices = (office_id: string) => {
      let dummyHighRiskProcessors: any = [];

      for (let i = 0; i < parentOffices.length; i++) {
        let index = parentOffices.findIndex(
          (office: any) => office.office_id === office_id
        );
        dummyHighRiskProcessors =
          parentOffices[index]?.allowed_high_risk_processors || [];
        if (
          (dummyHighRiskProcessors && dummyHighRiskProcessors.length > 0) ||
          parentOffices[index]?.office_level === "1"
        ) {
          break;
        } else {
          office_id = parentOffices[index]?.parent_office_id;
        }
      }
      if (dummyHighRiskProcessors && dummyHighRiskProcessors.length === 0) {
        setShowHighRiskProcessors(processors?.filter((processor: any) => cdBanks.indexOf(processor.value) === -1));
      } else {
        setShowHighRiskProcessors(
          processors?.filter((processor: any) =>
            dummyHighRiskProcessors?.includes(processor.value) && cdBanks.indexOf(processor.value) === -1
          )
        );
      }
    };

    const handleRiskLevelsFromOffices = (office_id: string) => {
      let dummyRiskLevels: any = [];

      for (let i = 0; i < parentOffices.length; i++) {
        let index = getParsedList(parentOffices).findIndex(
          (office: any) => office.office_id == office_id
        );
        dummyRiskLevels = getParsedList(parentOffices[index]?.allowed_risk_levels);
        if (
          (dummyRiskLevels && dummyRiskLevels.length > 0) ||
          getParsedList(parentOffices[index]?.office_level) === "1"
        ) {
          break;
        } else {
          office_id = getParsedList(parentOffices[index]?.parent_office_id);
        }
      }

      if (dummyRiskLevels.length === 0) {
        setShowRiskLevels([RISK_LEVELS.LOW_RISK, RISK_LEVELS.HIGH_RISK]);
      } else {
        setShowRiskLevels(dummyRiskLevels);
      }
    };

    const handleChangePartner = (e: any, data: any) => {
      fetchPartnerRms(data.value)
      if(role === ROLES.RELATIONSHIP_MANAGER){
        fetchPartnerRms(data.value, true)
      }
      console.log("SELECTED PARTNER >>>", data);
      setPartnerId(data.value);
      const partnerName = data.options
        .filter((item: any) => item.value === data.value)
        .map((item: any) => item.text)[0];
      setPartnerName(partnerName);

      let riskLevelsDummy = data.options
        .filter((item: any) => item.value === data.value)
        .map((item: any) => item.allowed_risk_levels)[0];

      let allowed_lowrisk_processors = data.options
        .filter((item: any) => item.value === data.value)
        .map((item: any) => item.allowed_low_risk_processors)[0];
      let allowed_highrisk_processors = data.options
        .filter((item: any) => item.value === data.value)
        .map((item: any) => item.allowed_high_risk_processors)[0];

      let office_id = data.options
        .filter((item: any) => item.value === data.value)
        .map((item: any) => item.office_id)[0];

      if (riskLevelsDummy && riskLevelsDummy.length > 0) {
        setShowRiskLevels(riskLevelsDummy);
      } else if (office_id) {
        handleRiskLevelsFromOffices(office_id);
      } else {
        setShowRiskLevels([RISK_LEVELS.LOW_RISK, RISK_LEVELS.HIGH_RISK]);
      }

      if (allowed_lowrisk_processors && allowed_lowrisk_processors.length > 0) {
        setShowLowRiskProcessors(
          processors?.filter(
            (processor: any) =>
              allowed_lowrisk_processors.includes(processor.value) &&
              processor.value !== BANK_CODES.DART
          )
        );
      } else if (office_id) {
        handleLowRiskProcessorsFromOffices(office_id);
      } else {
        setShowLowRiskProcessors(
          processors?.filter(
            (processor: any) => processor.value !== BANK_CODES.DART
          )
        );
      }
      if (
        allowed_highrisk_processors &&
        allowed_highrisk_processors.length > 0
      ) {
        setShowHighRiskProcessors(
          processors?.filter((processor: any) =>
            allowed_highrisk_processors.includes(processor.value) && cdBanks.indexOf(processor.value) === -1
          )
        );
      } else if (office_id) {
        handleHighRiskProcessorsFromOffices(office_id);
      } else {
        setShowHighRiskProcessors(processors?.filter((processor: any) => cdBanks.indexOf(processor.value) === -1));
      }

      const currentPartner = data.options.filter((item: any) => item.value === data.value)
      setFreeEquipmentPlacementStatus(!!currentPartner[0]?.free_equipment_placement_enabled)
    };

    const handleChangePartnerRms = (e:any, data:any) => {
      setPartnerRmId(data.value)
      const rmDetails = data.options.filter((item: any) => item.value === data.value)
      const partnerRmName = rmDetails?.map((item: any) => item.text)[0]
      setPartnerRmName(partnerRmName)
      console.log('two rm>>>',rmDetails[0])
    }

    useEffect(() => {
      if (partnerId) {
        let riskLevelsDummy = partners
          .filter((item: any) => item.value === partnerId)
          .map((item: any) => item.allowed_risk_levels)[0];

        // let processorsDummy = partners
        //   .filter((item: any) => item.value === partnerId)
        //   .map((item: any) => item.allowedProcessors)[0]

        let allowed_lowrisk_processors = partners
          .filter((item: any) => item.value === partnerId)
          .map((item: any) => item.allowed_low_risk_processors)[0];
        let allowed_highrisk_processors = partners
          .filter((item: any) => item.value === partnerId)
          .map((item: any) => item.allowed_high_risk_processors)[0];

        let office_id = partners
          .filter((item: any) => item.value === partnerId)
          .map((item: any) => item.office_id)[0];

        if (riskLevelsDummy && riskLevelsDummy.length > 0) {
          setShowRiskLevels(riskLevelsDummy);
        } else if (office_id) {
          handleRiskLevelsFromOffices(office_id);
        } else {
          setShowRiskLevels([RISK_LEVELS.LOW_RISK, RISK_LEVELS.HIGH_RISK]);
        }

        if (
          allowed_lowrisk_processors &&
          allowed_lowrisk_processors.length > 0
        ) {
          setShowLowRiskProcessors(
            processors?.filter(
              (processor: any) =>
                allowed_lowrisk_processors.includes(processor.value) &&
                processor.value !== BANK_CODES.DART
            )
          );
        } else if (office_id) {
          handleLowRiskProcessorsFromOffices(office_id);
        } else {
          setShowLowRiskProcessors(
            processors?.filter(
              (processor: any) => processor.value !== BANK_CODES.DART
            )
          );
        }
        if (
          allowed_highrisk_processors &&
          allowed_highrisk_processors.length > 0
        ) {
          setShowHighRiskProcessors(
            processors?.filter((processor: any) =>
              allowed_highrisk_processors.includes(processor.value) && cdBanks.indexOf(processor.value) === -1
            )
          );
        } else if (office_id) {
          handleHighRiskProcessorsFromOffices(office_id);
        } else {
          setShowHighRiskProcessors(processors?.filter((processor: any) => cdBanks.indexOf(processor.value) === -1));
        }
        
        if(riskLevelsDummy && riskLevelsDummy.length !==0 && !riskLevelsDummy.includes(riskLevel)){
          setRiskLevel('')
        }
      }
    }, [partners, partnerId, partnerName]);

    const handleChangeBankOption = (e: any, data: any) => {
      setChargeBackFee("")
      setSelectedBankOption(data.value);
      setPciValidation("119.99");
      setPciError(false);
      setPciErrorMessage('');
      setChargeBackError(false)
      setChargeBackErrorMessage("")
      if(data.value === BANK_CODES.DART || data.value === BANK_CODES.CFSB){
        if(pricingType !== 'Interchange' && pricingType !== 'Flat Rate') setPricingType('')
        if(pricingCardValue.includes('AMEX')){
          const cardvalue = [...pricingCardValue];
          cardvalue.splice(cardvalue.indexOf('AMEX'), 1);
          setPricingCardValue(cardvalue)
          
          const newPricingCardType = [...pricingCardType];
          const index = newPricingCardType.findIndex((Item: any) => Item.value === 'AMEX')
          newPricingCardType[index].ischecked = false;
          setPricingCardType(newPricingCardType)
        }
        setAchRejectFee('25')
        clearTieredVMD();
        clearErrVMD();
      }else{
        if (data.value === BANK_CODES.CFSB_CD || data.value === BANK_CODES.ESQUIRE_CD) {
          setChargeBackFee('15')
          setChargeBackError(false);
          setChargeBackErrorMessage('');
        } else {
          if(data.value === BANK_CODES.HARRIS || data.value === BANK_CODES.CENTRAL){
            if(riskLevel === RISK_LEVELS.LOW_RISK){
              console.log(chargeBackFee, "function call option bank")
              setChargeBackFee('25')
              console.log(chargeBackFee, "value")
            } else if (riskLevel === RISK_LEVELS.HIGH_RISK){
              setChargeBackFee("40")
            }
          } else {
            setChargeBackFee("")
          }
        }
        setAchRejectFee('30')
      }
    };

    const handlePrevNextNavigation = (
      current_section_name: string,
      direction: string
    ) => {
      window.scroll({ top: 0, left: 0 });
      const index = sectionsPrevNextData.findIndex(
        (section) => section.name === current_section_name
      );
      const current_section = sectionsPrevNextData[index];
      direction === "prev"
        ? setActiveSection(current_section.prev)
        : setActiveSection(current_section.next);
    };
    const sleep = (milliseconds: number) => {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };
    const showOverlayForThreeSeconds = async () => {
      const sstyle: any = { style: { display: "block", position: "fixed" } };
      setOverlay(sstyle);
      await sleep(2000);
      const hstyle: any = { style: { display: "none" } };
      setOverlay(hstyle);
    };
    const showOverlay = async () => {
      const sstyle: any = { style: { display: 'flex', position: 'fixed',zIndex:1050 } }
      setOverlay(sstyle)
    }
    const handleCopy = async (tiny_url: string) => {
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = tiny_url;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      showOverlayForThreeSeconds();
      setOverlaymsg("App Link copied to clipboard!");
    };

    const handleDelete = async (save_link_id: any, status: any) => {
      // const input = {
      //   save_link_id,
      //   delete_status: status,
      // };
      // console.log("input:::",input)
      const data: any = {
        api: api.saveLinks.deleteSaveLink,
        payLoad: JSON.stringify({save_link_id , delete_status:true}),
      };
      setLoading(true);
      try {
        const response = await backend.save(data);
        uncheckparentcheckbox()
        if (response) {
          // let updatedLinks = sendLinkUsers.filter(
          //   (appLink: any) => appLink.app_link_id !== save_link_id
          // );
          // setSendLinkUsers(updatedLinks);
          // console.log("Deleted", response);
          await fetchSendLinkUsers()
          setDeleteAppLinkId("");
          setOverlaymsg("Deleted successfully");
          showOverlayForThreeSeconds();
        }
      } catch (err: any) {
        setOverlaymsg(err.message || "Error occured while deleting app link.");
        showOverlayForThreeSeconds();
      } finally {
        clearForm()
        setLoading(false);
      }
    };

    const handleDeleteBulk = async () => {
      setActionConfirmationDelete(false)
      const data: any = {
        api: api.saveLinks.deleteSaveLinkBulk,
        payLoad: JSON.stringify({applist:  selectedAppBulk,delete_status:true})
      };

      setLoading(true);
      try {
        const response = await backend.save(data);
        if (response) {
          uncheckparentcheckbox()
          await fetchSendLinkUsers()
        //   const updatedLinks = sendLinkUsers.filter(({ app_link_id }) =>
        //   !selectedAppBulk.some((exclude: { app_link_id: any; }) => exclude.app_link_id === app_link_id)
        // );
        //   console.log(updatedLinks);
        //   setSendLinkUsers(updatedLinks);
        //   console.log("Deleted", response);
          setDeleteAppLinkId("");
          setOverlaymsg("Deleted successfully");
          showOverlayForThreeSeconds();
        }
      } catch (err: any) {
        setOverlaymsg(err.message || "Error occured while deleting app link.");
        showOverlayForThreeSeconds();
      } finally {
        clearForm()
        setLoading(false);
      }
    };

    const handleActivate = async (save_link_id: any, status: any) => {
      const input = {
        save_link_id,
        status: status === "active" ? "inactive" : "active",
      };

      const data: any = {
        api: api.saveLinks.updateSaveLink,
        payLoad: JSON.stringify(input),
      };

      setLoading(true);
      try {
        const response = await backend.save(data);
        if (response) {
          uncheckparentcheckbox()
          await fetchSendLinkUsers()
          // let updatedLinks = sendLinkUsers.map((appLink: any) => {
          //   if (appLink.app_link_id === save_link_id) {
          //     return { ...appLink, app_link_status: input.status };
          //   } else {
          //     return appLink;
          //   }
          // });
          // setSendLinkUsers(updatedLinks);
          // console.log("Activate", response);
          setOverlaymsg("Updated successfully");
          showOverlayForThreeSeconds();
        }
      } catch (err: any) {
        window.scroll({ top: 0, left: 0 });
        setOverlaymsg(err.message || "Error occured while updating app link.");
        showOverlayForThreeSeconds();
      } finally {
        clearForm()
        setLoading(false);
      }
    };

    const handleActivateBulk = async (status: any) => {
      setActionConfirmation(false)
      const data: any = {
        api: api.saveLinks.updateSaveLinkBulk,
        payLoad: JSON.stringify({applist:  selectedAppBulk,status:actionStatus})
      };

      setLoading(true);
      try {
        const response = await backend.save(data);
        if (response) {
          uncheckparentcheckbox()
          await fetchSendLinkUsers()
          // let updatedLinks = sendLinkUsers.map((appLink: any) => {
          //   if(selectedAppBulk.findIndex((bulkuser: any) => bulkuser.app_link_id === appLink.app_link_id) > -1){
          //   return  { ...appLink, app_link_status:actionStatus}
          //   }else{
          //     return appLink
          //   }
          // })

          // console.log(updatedLinks)
  
          // setSendLinkUsers(updatedLinks);
          // console.log("Activate", response);
          setOverlaymsg("Updated successfully");
          showOverlayForThreeSeconds();
        }
      } catch (err: any) {
        window.scroll({ top: 0, left: 0 });
        setOverlaymsg(err.message || "Error occured while updating app link.");
        showOverlayForThreeSeconds();
      } finally {
        clearForm()
        setLoading(false);
      }
    };

    const handleSendApp = async (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setSignModalOpen(false);
      setShowProgress(true)
      showOverlay()
      setProgress(2)
      setOverlaymsg("We’re saving your application details. Please wait, and don’t refresh or close the page!");
      setTimeout(() => {
        setProgress(30)
        setTimeout(() => {
          setProgress(50);
          setOverlaymsg("We’re working on the email! It’s almost ready to go. Please wait, and don’t refresh or close the page!");
          setTimeout(() => {
            setProgress(70);
          }, 3000);

        }, 4500);
      }, 2000);
      try {
        const payload = {
          to_email: business_email,
          body: personalMessage || userMessage || "",
          merchant_app_id: "",
          dba_name: business_name
        };
        const response = await saveMerchantApp();
        payload.merchant_app_id = response.merchant_app_id;
        const data: any = {};
        data.api = api.integrations.sendMail;
        data.payLoad = { ...payload };
        await backend.save(data);
        setProgress(100);
        await new Promise(resolve => setTimeout(resolve, 500));
        setShowProgress(false)
        window.scroll({ top: 0, left: 0 });
        setUserMessage("");
        showOverlayForThreeSeconds();
        setOverlaymsg("Data saved successfully");
        setSentMailModalOpen(true);
        setLoading(false);
      } catch (err: any) {
        window.scroll({ top: 0, left: 0 });
        setOverlaymsg(err.message || "Error occured while saving data");
        showOverlayForThreeSeconds();
      }
    };

    const saveMerchantApp = async () => {
      const input = {
        app_data_sections: {
          dba_section: {
            dba_name: business_name,
          },
          equipment_section: equipmentSectionData,
          pricing_details: {
            pricing_type: pricingType,
            card_type: pricingCardType
              .filter((item) => item.ischecked)
              .map((item) => item.value),
            annual_fee: annualFee,
            monthly_fee: monthlyFee,
            err_qual_discount: errQualDiscount,
            err_qual_per_item: errQualPerItem,
            err_non_discount: errNonDiscount,
            err_non_per_item: errNonPerItem,
            intamex_discount: intAmexDiscountRate,
            intamex_per_item: intAmexperItem,
            tiered_qual_discount: tieredQualDiscount,
            tiered_qual_per_item: tieredQualPerItem,
            tiered_mid_discount: tieredMidDiscount,
            tiered_mid_per_item: tieredMidPerItem,
            tiered_non_discount: tieredNonDiscount,
            tiered_non_per_item: tieredNonPerItem,
            tiered_debit_discount: tieredDebitDiscount,
            tiered_debit_per_item: tieredDebitPerItem,
            discount_rate: discountRate,
            per_item: perItem,
            debit_discount_rate: debitDiscountRate,
            debit_network_fees: debitNetworkFees,
            amex_qual: amexQual,
            amex_per_item: amexPerItem,
            amex_mid_qual: amexMidQual,
            amex_mid_per_item: amexMidPerItem,
            amex_non_qual: amexNonQual,
            amex_non_per_item: amexNonPerItem,
            pin_debit: pinDebit,
            pin_debit_auth: pinDebitAuth,
            accept_ebt: acceptEbt,
            ebt: ebt,
            ebt_auth: ebtAuth,
            wireless_fee: wirelessFee,
            offer_amex: offerAmex,
            mc: mc,
            visa: visa,
            disc_auth: discAuth,
            amex_auth: amexAuth,
            avs: avs,
            batch_fee: batchFee,
            charge_back_fee: chargeBackFee,
            monthly_minimum: monthlyMinimum,
            early_termination_fee: earlyTerminationFee,
            account_reserve: accountReserve,
            other: other,
            billed: billed,
            voice_auth: voiceAuth,
            excessive_electronic_auth: excessiveElectronicAuth,
            irs_reporting_fee: irsReportingFee,
            retreival_fee: retreivalFee,
            invalid_tin_fee: invalidTinFee,
            ach_reject_fee: achRejectFee,
            collections_fee: collectionsFee,
            pci_validation: pciValidation,
            pci_non_compliance: pciNonCompliance,
            industry_compliance: industryCompliance,
            return_transaction_fee: returnTransactionFee,
            amex_optuble_support_fee: amexOptubleSupportFee,
            amex_optuble_network_fee: amexOptubleNetworkFee,
            debit_access_fee:
              riskLevel === RISK_LEVELS.LOW_RISK ? debitAccessFee : "",
            clym_data_privacy_compliance: clymDataPrivacyCompliance,
            clym_data_privacy_compliance_fee: clymDataPrivacyComplianceFee,
            ticketSize: ticketSize,
            discountPercentage: discountPercentage,
            serviceFee: serviceFee,
            licenseTechFees: licenseTechFees
          },
        },
        merchant_email: business_email,
        merchant_name: business_contact,
        personal_message: personalMessage,
        optional_message: "",
        merchant_prescreen: {
          business_conducted: businessConduct,
          business_category: businessCategory,
          business_type: businessType,
          business_category_free_form: businessCategoryFreeFormText,
          business_type_free_form: businessTypeFreeFormText,
          goods_sold: sellGoodValue,
          physical_goods_type: conditionalSellGood,
          price: riskLevel,
          processor_name: selectedBankOption,
          sales_frequency: selectedSellTime,
        },
        partner_id: partnerId,
        partner_name: partnerName,
        partner_rm_id: partnerRmId,
        partner_rm_name: partnerRmName,
        merchant_send_mail_flag : true,
        free_equipment_placement_enabled: freeEquipmentPlacementStatus,
      };
      const data: any = {
        api: api.merchantAppData.saveMerchantApp,
        payLoad: JSON.stringify(input),
      };
      // setLoading(true);
      try {
        const response = await backend.save(data);
        if (response) {
          console.log("Data saved", response);
          // setOverlaymsg("Data saved successfully");
          clearForm();
          return response;
        }
      } catch (err: any) {
        setOverlaymsg(err.message || "Error occured while saving app link.");
        return err;
      }
    };
    const handleSaveLinkApp = async (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      if (
        business_name !== "" ||
        business_contact !== "" ||
        business_email !== ""
      ) {
        setSaveLinkError(
          "To create a General Link, DBA Name, Merchant Email, and Contact Name on top of the page should be blank"
        );
      } else {
        setSaveLinkError("");
        const input = {
          app_data_sections: {
            equipment_section: equipmentSectionData,
            pricing_details: {
              pricing_type: pricingType,
              card_type: pricingCardType
                .filter((item) => item.ischecked)
                .map((item) => item.value),
              annual_fee: annualFee,
              monthly_fee: monthlyFee,
              err_qual_discount: errQualDiscount,
              err_qual_per_item: errQualPerItem,
              err_non_discount: errNonDiscount,
              err_non_per_item: errNonPerItem,
              intamex_discount: intAmexDiscountRate,
              intamex_per_item: intAmexperItem,
              tiered_qual_discount: tieredQualDiscount,
              tiered_qual_per_item: tieredQualPerItem,
              tiered_mid_discount: tieredMidDiscount,
              tiered_mid_per_item: tieredMidPerItem,
              tiered_non_discount: tieredNonDiscount,
              tiered_non_per_item: tieredNonPerItem,
              tiered_debit_discount: tieredDebitDiscount,
              tiered_debit_per_item: tieredDebitPerItem,
              discount_rate: discountRate,
              per_item: perItem,
              debit_discount_rate: debitDiscountRate,
              debit_network_fees: debitNetworkFees,
              amex_qual: amexQual,
              amex_per_item: amexPerItem,
              amex_mid_qual: amexMidQual,
              amex_mid_per_item: amexMidPerItem,
              amex_non_qual: amexNonQual,
              amex_non_per_item: amexNonPerItem,
              pin_debit: pinDebit,
              pin_debit_auth: pinDebitAuth,
              accept_ebt: acceptEbt,
              ebt: ebt,
              ebt_auth: ebtAuth,
              wireless_fee: wirelessFee,
              offer_amex: offerAmex,
              mc: mc,
              visa: visa,
              disc_auth: discAuth,
              amex_auth: amexAuth,
              avs: avs,
              batch_fee: batchFee,
              charge_back_fee: chargeBackFee,
              monthly_minimum: monthlyMinimum,
              early_termination_fee: earlyTerminationFee,
              account_reserve: accountReserve,
              other: other,
              billed: billed,
              voice_auth: voiceAuth,
              excessive_electronic_auth: excessiveElectronicAuth,
              irs_reporting_fee: irsReportingFee,
              retreival_fee: retreivalFee,
              invalid_tin_fee: invalidTinFee,
              ach_reject_fee: achRejectFee,
              collections_fee: collectionsFee,
              pci_validation: pciValidation,
              pci_non_compliance: pciNonCompliance,
              industry_compliance: industryCompliance,
              return_transaction_fee: returnTransactionFee,
              amex_optuble_support_fee: amexOptubleSupportFee,
              amex_optuble_network_fee: amexOptubleNetworkFee,
              debit_access_fee:
                riskLevel === RISK_LEVELS.LOW_RISK ? debitAccessFee : "",
              clym_data_privacy_compliance: clymDataPrivacyCompliance,
              clym_data_privacy_compliance_fee: clymDataPrivacyComplianceFee,
              ticketSize: ticketSize,
              discountPercentage: discountPercentage,
              serviceFee: serviceFee,
              licenseTechFees:licenseTechFees
            },
          },
          merchant_prescreen: {
            business_conducted: businessConduct,
            business_category: businessCategory,
            business_type: businessType,
            business_category_free_form: businessCategoryFreeFormText,
            business_type_free_form: businessTypeFreeFormText,
            goods_sold: sellGoodValue,
            physical_goods_type: conditionalSellGood,
            price: riskLevel,
            processor_name: selectedBankOption,
            sales_frequency: selectedSellTime,
          },
          partner_id: partnerId,
          partner_name: partnerName,
          partner_rm_id: partnerRmId,
          partner_rm_user_id: partnerRmId,
          partner_rm_name: partnerRmName,
          link_name: linkName,
          free_equipment_placement_enabled:freeEquipmentPlacementStatus
        };
        const data: any = {
          api: api.saveLinks.createSaveLink,
          payLoad: JSON.stringify(input),
        };
        setShowSaveLinkModal(false);
        setLoading(true);
        //refreshData()
        try {
          const response = await backend.save(data);
          console.log(response)
          if (response) {
            uncheckparentcheckbox()
            //setSendLinkUsers([response, ...sendLinkUsers]);
            await fetchSendLinkUsers()

            console.log("Data saved", response);
            //window.scroll({ top: 1000, left: 0 })
            setTimeout(() => {document.getElementById('heading-text-left')?.scrollIntoView();}, 100);
            ///document.getElementById('heading-text-left').scrollIntoView();
            setOverlaymsg("Data saved successfully");
            // refreshData()
            showOverlayForThreeSeconds();
            clearForm();
          }
        } catch (err: any) {
          window.scroll({ top: 0, left: 0 });
          setOverlaymsg(err.message || "Error occured while saving app link.");
          showOverlayForThreeSeconds();
        } finally {
          setLoading(false);
        }
      }
    };

    const handleUpdateLinkApp = async (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      if (
        business_name !== "" ||
        business_contact !== "" ||
        business_email !== ""
      ) {
        setSaveLinkError(
          "To update General Link, DBA Name, Merchant Email, and Contact Name on top of the page should be blank"
        );
      } else {
        setSaveLinkError("");
        const input = {
          app_data_sections: {
            equipment_section: equipmentSectionData,
            pricing_details: {
              pricing_type: pricingType,
              card_type: pricingCardType
                .filter((item) => item.ischecked)
                .map((item) => item.value),
              annual_fee: annualFee,
              monthly_fee: monthlyFee,
              err_qual_discount: errQualDiscount,
              err_qual_per_item: errQualPerItem,
              err_non_discount: errNonDiscount,
              err_non_per_item: errNonPerItem,
              intamex_discount: intAmexDiscountRate,
              intamex_per_item: intAmexperItem,
              tiered_qual_discount: tieredQualDiscount,
              tiered_qual_per_item: tieredQualPerItem,
              tiered_mid_discount: tieredMidDiscount,
              tiered_mid_per_item: tieredMidPerItem,
              tiered_non_discount: tieredNonDiscount,
              tiered_non_per_item: tieredNonPerItem,
              tiered_debit_discount: tieredDebitDiscount,
              tiered_debit_per_item: tieredDebitPerItem,
              discount_rate: discountRate,
              per_item: perItem,
              debit_discount_rate: debitDiscountRate,
              debit_network_fees: debitNetworkFees,
              amex_qual: amexQual,
              amex_per_item: amexPerItem,
              amex_mid_qual: amexMidQual,
              amex_mid_per_item: amexMidPerItem,
              amex_non_qual: amexNonQual,
              amex_non_per_item: amexNonPerItem,
              pin_debit: pinDebit,
              pin_debit_auth: pinDebitAuth,
              accept_ebt: acceptEbt,
              ebt: ebt,
              ebt_auth: ebtAuth,
              wireless_fee: wirelessFee,
              offer_amex: offerAmex,
              mc: mc,
              visa: visa,
              disc_auth: discAuth,
              amex_auth: amexAuth,
              avs: avs,
              batch_fee: batchFee,
              charge_back_fee: chargeBackFee,
              monthly_minimum: monthlyMinimum,
              early_termination_fee: earlyTerminationFee,
              account_reserve: accountReserve,
              other: other,
              billed: billed,
              voice_auth: voiceAuth,
              excessive_electronic_auth: excessiveElectronicAuth,
              irs_reporting_fee: irsReportingFee,
              retreival_fee: retreivalFee,
              invalid_tin_fee: invalidTinFee,
              ach_reject_fee: achRejectFee,
              collections_fee: collectionsFee,
              pci_validation: pciValidation,
              pci_non_compliance: pciNonCompliance,
              industry_compliance: industryCompliance,
              return_transaction_fee: returnTransactionFee,
              amex_optuble_support_fee: amexOptubleSupportFee,
              amex_optuble_network_fee: amexOptubleNetworkFee,
              debit_access_fee:
                riskLevel === RISK_LEVELS.LOW_RISK ? debitAccessFee : "",
              clym_data_privacy_compliance: clymDataPrivacyCompliance,
              clym_data_privacy_compliance_fee: clymDataPrivacyComplianceFee,
              ticketSize: ticketSize,
              discountPercentage: discountPercentage,
              serviceFee: serviceFee,
              licenseTechFees:licenseTechFees
            },
          },
          merchant_prescreen: {
            business_conducted: businessConduct,
            business_category: businessCategory,
            business_type: businessType,
            business_category_free_form: businessCategoryFreeFormText,
            business_type_free_form: businessTypeFreeFormText,
            goods_sold: sellGoodValue,
            physical_goods_type: conditionalSellGood,
            price: riskLevel,
            processor_name: selectedBankOption,
            sales_frequency: selectedSellTime,
          },
          partner_id: partnerId,
          partner_name: partnerName,
          link_name: linkName,
          save_link_id: saveLinkId,
          link_status: appLinkStatus,
          link_url: linkUrl,
          deleted: currentSaveLink?.deleted || "",
          tiny_url: currentSaveLink?.tiny_url || "",
          no_of_clicks: currentSaveLink?.no_of_clicks || 0,
          no_of_apps: currentSaveLink?.no_of_apps || 0,
          created_ts: currentSaveLink?.created_ts || "",
          created_by_user_id: currentSaveLink?.created_by_user_id || "",
          partner_rm_id: partnerRmId,
          partner_rm_name: partnerRmName,
          free_equipment_placement_enabled: freeEquipmentPlacementStatus
          
        };
        setLoading(true);
        // refreshData()
        try {
          const data: any = {
            api: api.saveLinks.upateSaveLinkPatch,
            payLoad: JSON.stringify(input),
          };
          setShowSaveLinkModal(false);

          const response = await backend.save(data);
          if (response) {
            uncheckparentcheckbox()
            // let updatedLinks = sendLinkUsers.map((appLink: any) => {
            //   if (appLink.app_link_id === input.save_link_id) {
            //     return response;
            //   } else {
            //     return appLink;
            //   }
            // });
            // setSendLinkUsers(updatedLinks);
            await fetchSendLinkUsers()
            console.log("Data saved", response);
            window.scroll({ top: 0, left: 0 });
            clearForm();
            setIsEditMode(false);
            setOverlaymsg("SaveLink Updated successfully");
            // refreshData()
            showOverlayForThreeSeconds();
          }
        } catch (err: any) {
          window.scroll({ top: 0, left: 0 });
          setOverlaymsg(err.message || "error occured while update");
          showOverlayForThreeSeconds();
        } finally {
          setLoading(false);
        }
      }
    };

    const handleDuplicate = async (e: any, save_link_id: any) => {
      const input = {
        save_link_id,
      };

      const data: any = {
        api: api.saveLinks.copySaveLink,
        payLoad: JSON.stringify(input),
      };

      setLoading(true);
      try {
        const response = await backend.save(data);
        const fetchlinkUsers = await fetchSendLinkUsers()
          if (response) {
                  uncheckparentcheckbox()
                  //console.log("duplicated", response);
                  //setSendLinkUsers([response, ...sendLinkUsers]);
                  const ApplinkData = fetchlinkUsers.filter((item: any) => item.app_link_id === response.id)
                  window.scroll({ top: 0, left: 0 });
                  clearForm()
                  const app_link_id = REACT_APP_BACKEND_MYSQL ? response.id : response.app_link_id
                  if(app_link_id) handleEditButtonClick(e,app_link_id,true,ApplinkData)
                  setOverlaymsg("Link Duplicated successfully");
                  showOverlayForThreeSeconds();
          }
      } catch (err: any) {
        window.scroll({ top: 0, left: 0 });
        setOverlaymsg(
          err.message || "Error occured while duplicating app link."
        );
        showOverlayForThreeSeconds();
      } finally {
        setLoading(false);
      }
    };

    const clearForm = () => {
      setIsEditMode(false)
      if (
        role === ROLES.RELATIONSHIP_MANAGER ||
        role === ROLES.OFFICE_MANAGER ||
        role === ROLES.OFFICE_ADMIN
      ) {
        setPartnerName("");
        setPartnerId("");
        setPartnerRmId("");
      }
      setSaveLinkError("")
      setBusinessName("");
      setBusinessContact("");
      setBusinessEmail("");
      setMerchantEmailValid(true);
      setMerchantContact("");
      // merchant_prescreen
      setBusinessConduct([]);
      setIsRetail(false);
      setIsMailOrder(false);
      setIsEcommerce(false);
      setSellTypeValue("");
      setBusinessCategory("");
      setBusinessType("");
      setRiskLevel("");
      setSellGoodValue("");
      setConditionalSellGood("");
      setSelectedBankOption("");
      setSelectedSellTime("");
      setLinkName("");
      // Pricing_Section
      setPricingType("");
      pricingCardType.forEach((item: any) => {
        item.ischecked = false;
      });
      setPricingCardType(pricingCardType);
      setMonthlyFee("");
      setAnnualFee("");
      setErrQualDiscount("");
      setErrQualPerItem("");
      setErrNonDiscount("");
      setErrNonPerItem("");
      setIntAmexDiscountRate("");
      setIntAmexperItem("");

      setTieredQualDiscount("");
      setTieredQualPerItem("");
      setTieredMidDiscount("");
      setTieredMidPerItem("");
      setTieredNonDiscount("");
      setTieredNonPerItem("");
      setTieredDebitDiscount("");
      setTieredDebitPerItem("");
      setDiscountRate("");
      setPerItem("");
      setDebitDiscountRate("");
      setDebitNetworkFees("Yes");
      setClymDataPrivacyCompliance("Yes");
      setClymDataPrivacyComplianceFee("20");
      setAmexQual("");
      setAmexPerItem("");
      setAmexMidQual("");
      setAmexMidPerItem("");
      setAmexNonQual("");
      setAmexNonPerItem("");
      setDiscountRate("");
      setPerItem("");
      setPinDebit("");
      setPinDebitAuth("");
      setAcceptEbt("");
      setEbt("");
      setEbtAuth("");
      setWirelessFee("");
      setOfferAmex("");
      setMc("");
      setVisa("");
      setDiscAuth("");
      setAmexAuth("");
      setAvs("");
      setBatchFee("");
      setChargeBackFee("");
      setMonthlyMinimum("");
      setEarlyTerminationFee("");
      setAccountReserve("");
      setOther("");
      setBilled("");
      setVoiceAuth("1.96");
      setExcessiveElectronicAuth("0.10");
      setIrsReportingFee("4.95");
      setRetreivalFee("20");
      setInvalidTinFee("19.95");
      setAchRejectFee(selectedBankOption === BANK_CODES.DART ? "25" : "30");
      setCollectionsFee("20");
      setPciValidation("119.99");
      setPciNonCompliance((selectedBankOption === BANK_CODES.CFSB || selectedBankOption === BANK_CODES.CFSB_CD) ? otherFees_cfsb.pciNonCompliance : otherFees.pciNonCompliance);
      setIndustryCompliance("9.95");
      setReturnTransactionFee("0.10");
      setAmexOptubleSupportFee("0.50");
      setAmexOptubleNetworkFee("0.15");
      setDebitAccessFee("3.95");

      // equipment_section
      setEquipmentSectionData([equip_default_obj]);
    };
    //Confirm Alerts
    const handleCancelConfirm = async () => {
      setCancelAlertOpen(false);
      clearForm();
      if (!isEditMode) {
        history.push({
          pathname: `/listapp`,
        });
      }
      setIsEditMode(false);
    };
    const handleCancelCancel = async () => {
      try {
        setCancelAlertOpen(false);
        if (
          role === ROLES.RELATIONSHIP_MANAGER ||
          role === ROLES.OFFICE_MANAGER ||
          role === ROLES.OFFICE_ADMIN
        ) {
          setPartnerName(partnerName);
          setPartnerId(partnerId);
        }
        setBusinessName(business_name);
        setBusinessContact(business_contact);
        setBusinessEmail(business_email);
        setMerchantEmailValid(true);
        setMerchantContact(merchantContact);

        setLinkName(linkName);
        // merchant_prescreen
        setBusinessConduct(businessConduct);
        setSellTypeValue(sellTypeValue);
        setBusinessCategory(businessCategory);
        setBusinessType(businessType);
        setRiskLevel(riskLevel);
        setSellGoodValue(sellGoodValue);
        setConditionalSellGood(conditionalSellGood);
        setSelectedBankOption(selectedBankOption);
        setSelectedSellTime(selectedSellTime);
        // Pricing_Section
        setPricingType(pricingType);
        pricingCardType.forEach((item: any) => {
          item.ischecked = false;
        });
        setPricingCardType(pricingCardType);
        setMonthlyFee(monthlyFee);
        setAnnualFee(annualFee);
        setErrQualDiscount(errQualDiscount);
        setErrQualPerItem(errQualPerItem);
        setErrNonDiscount(errNonDiscount);
        setErrNonPerItem(errNonPerItem);
        setIntAmexDiscountRate(intAmexDiscountRate);
        setIntAmexperItem(intAmexperItem);

        setTieredQualDiscount(tieredQualDiscount);
        setTieredQualPerItem(tieredQualPerItem);
        setTieredMidDiscount(tieredMidDiscount);
        setTieredMidPerItem(tieredMidPerItem);
        setTieredNonDiscount(tieredNonDiscount);
        setTieredNonPerItem(tieredNonPerItem);
        setTieredDebitDiscount(tieredDebitDiscount);
        setTieredDebitPerItem(tieredDebitPerItem);
        setDiscountRate(discountRate);
        setPerItem(perItem);
        setDebitDiscountRate(debitDiscountRate);
        setDebitNetworkFees(debitNetworkFees);
        setClymDataPrivacyCompliance(clymDataPrivacyCompliance);
        setClymDataPrivacyComplianceFee(clymDataPrivacyComplianceFee);
        setAmexQual(amexQual);
        setAmexPerItem(amexPerItem);
        setAmexMidQual(amexMidQual);
        setAmexMidPerItem(amexMidPerItem);
        setAmexNonQual(amexNonQual);
        setAmexNonPerItem(amexNonPerItem);
        setPinDebit(pinDebit);
        setPinDebitAuth(pinDebitAuth);
        setAcceptEbt(acceptEbt);
        setEbt(ebt);
        setEbtAuth(ebtAuth);
        setWirelessFee(wirelessFee);
        setOfferAmex(offerAmex);
        setMc(mc);
        setVisa(visa);
        setDiscAuth(discAuth);
        setAmexAuth(amexAuth);
        setAvs(avs);
        setBatchFee(batchFee);
        setChargeBackFee(chargeBackFee);
        setMonthlyMinimum(monthlyMinimum);
        setEarlyTerminationFee(earlyTerminationFee);
        setAccountReserve(accountReserve);
        setOther(other);
        setBilled(billed);
        setVoiceAuth(voiceAuth);
        setExcessiveElectronicAuth(excessiveElectronicAuth);
        setIrsReportingFee(irsReportingFee);
        setRetreivalFee(retreivalFee);
        setInvalidTinFee(invalidTinFee);
        setAchRejectFee(
          selectedBankOption === BANK_CODES.DART ? "25" : achRejectFee || "30"
        );
        checkChargeBackValue(collectionsFee);
        setCollectionsFee(collectionsFee);
        checkPciValue(pciValidation);
        setPciValidation(pciValidation);
        setPciNonCompliance(pciNonCompliance);
        setIndustryCompliance(industryCompliance);
        setReturnTransactionFee(returnTransactionFee);
        setAmexOptubleSupportFee(amexOptubleSupportFee);
        setAmexOptubleNetworkFee(amexOptubleNetworkFee);
        setDebitAccessFee(debitAccessFee);
      } catch (err: any) {
        console.log(err);
      }
    };
    const handleCloseModal = ()=>{
      setActionConfirmation(false)
      setActionConfirmationDelete(false)
      setBulkaction('')
    }
    const handleCancel = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setCancelAlertOpen(true);
    };
    const handleRiskLevel = (e: any, data: any) => {
      setPinDebit("")
      setChargeBackError(false)
      setChargeBackErrorMessage("")
      const riskLevel = data.value;
      if(selectedBankOption === BANK_CODES.HARRIS || selectedBankOption === BANK_CODES.CENTRAL){
        if(riskLevel === RISK_LEVELS.LOW_RISK){
          setChargeBackFee("25")
        } else if (riskLevel === RISK_LEVELS.HIGH_RISK){
          setChargeBackFee("40")
        }
      }
      setRiskLevel(riskLevel);
      setSelectedSellTime('')
    };
    const handleBusinessTypeChange = (e: any, data: any) => {
      if (!businessCategory) {
        return;
      }
      setBusinessType(data.value);
      setBusinessType(data.value);
      if (data.value.toLowerCase() !== "other") {
        setBusinessTypeFreeFormText("");
        setBusinessTypeFreeFormErrorMessage("");
      }
    };
    const handleBusinessCategory = (e: any, data: any) => {
      setBusinessCategory(data.value);
      if (data.value.toLowerCase() !== "other") {
        setBusinessCategoryFreeFormText("");
        setBusinessTypeFreeFormText("");
        setBusinessCategoryFreeFormErrorMessage("");
        setBusinessTypeFreeFormErrorMessage("");
      }
    };
    const handleBilledChange = (e: any, data: any) => {
      setBilled(data.value);
    };
    const handleTicketSizeChange = (e: any, data: any) => {
      setTicketSize(data.value)
      if(data.value === 'Above $20'){
        setServiceFee("")
      }else{
        setDiscountPercentage("")
      }
      clearTieredVMD()
      clearErrVMD()
      clearInterChangeFlatRateVMD()
  }
    const handleDiscountPercentChange = (e: any, data: any) => {
      setDiscountPercentage(data.value)
    };
    const handleServiceFeeChange = (e: any, data: any) => {
      setServiceFee(data.value)
    };
    const handlePricingCardTypeValueChange = () => {
      const pricingCardValueVMD: boolean =
        pricingCardValue.includes("Visa") ||
        pricingCardValue.includes("MC") ||
        pricingCardValue.includes("Discover");
      const pricingCardValueAMEX: boolean = pricingCardValue.includes("AMEX");

      if (pricingCardValueVMD) {
        if(selectedBankOption === BANK_CODES.CFSB && (pricingType === "Tiered" || pricingType === "Err")){
          setPricingType('')} else {
        if (pricingType === "Tiered") {
          clearErrVMD();
          clearInterChangeFlatRateVMD();
        } else {
          clearTieredVMD();
          if (pricingType === "Err") {
            clearInterChangeFlatRateVMD();
          } else {
            clearErrVMD();
          }
        }
      }} else {
        clearTieredVMD()
        clearErrVMD();
        clearInterChangeFlatRateVMD();
      }
      if (pricingCardValueAMEX) {
        if (pricingType === "Tiered") {
          clearInterFlatErrAMEX();
        } else {
          clearTieredAMEX();
        }
      } else {
        clearTieredAMEX();
        clearInterFlatErrAMEX();
      }
    };

    const clearErrVMD = () => {
      setErrQualDiscount("");
      setErrQualPerItem("");
      setErrNonDiscount("");
      setErrNonPerItem("");
      updatePricingSectionStatus()
    };

    const clearTieredVMD = () => {
      setTieredQualDiscount("");
      setTieredQualPerItem("");
      setTieredMidDiscount("");
      setTieredMidPerItem("");
      setTieredNonDiscount("");
      setTieredNonPerItem("");
      setTieredDebitDiscount("");
      setTieredDebitPerItem("");
      updatePricingSectionStatus()
    };

    const clearInterChangeFlatRateVMD = () => {
      setDiscountRate("");
      setPerItem("");
      setDebitDiscountRate("");
      setDebitNetworkFees("Yes");
    };

    const clearTieredAMEX = () => {
      setAmexQual("");
      setAmexPerItem("");
      setAmexMidQual("");
      setAmexMidPerItem("");
      setAmexNonQual("");
      setAmexNonPerItem("");
    };

    const clearInterFlatErrAMEX = () => {
      setIntAmexDiscountRate("");
      setIntAmexperItem("");
    };

    const clearCashDiscount = () =>{
      setTicketSize("")
      setServiceFee("")
      setDiscountPercentage("")
      setLicenseTechFees("25")
    };
    useEffect(() => {
      handlePricingCardTypeValueChange();
    }, [pricingType, pricingCardValue]);

    useEffect(() => {
      if (businessCategory.toLocaleLowerCase() === "other") {
        if (!businessCategoryFreeFormText) {
          setBusinessCategoryFreeFormErrorMessage(
            "Please Enter Business Category Value"
          );
        } else {
          setBusinessCategoryFreeFormErrorMessage("");
        }
      } else {
        setBusinessCategoryFreeFormErrorMessage("");
        setBusinessTypeFreeFormErrorMessage("");
      }
    }, [businessCategory, businessCategoryFreeFormText]);

    useEffect(() => {
      if (businessType.toLocaleLowerCase() === "other") {
        if (!businessTypeFreeFormText) {
          setBusinessTypeFreeFormErrorMessage(
            "Please Enter Business Type Value"
          );
        } else {
          setBusinessTypeFreeFormErrorMessage("");
        }
      } else {
        setBusinessTypeFreeFormErrorMessage("");
      }
    }, [businessType, businessTypeFreeFormText]);

    const renderPricingType = () => {
      return Pricing_Type.map((item: any, index: number) => {
        return (
          <div className="three wide field" key={index}>
            <Radio
              label={item.value}
              name="pricingType"
              value={item.value}
              checked={pricingType === item.value}
              onChange={handleChangePricingType}
            />
          </div>
        );
      });
    };
    const handlePricingCardType = (event: any, data: CheckboxProps) => {
      const value = data.value;
      const checked: any = data.checked;
      const cardvalue = [...pricingCardValue];
      if (checked) {
        cardvalue.push(value);
        setPricingCardValue(cardvalue);
      } else {
        cardvalue.splice(cardvalue.indexOf(value), 1);
        setPricingCardValue(cardvalue);
      }
      const newPricingCardType = [...pricingCardType];
      const index = newPricingCardType.findIndex(
        (Item: any) => Item.value === data.value
      );
      newPricingCardType[index].ischecked = checked;
      setPricingCardType(newPricingCardType);
    };
    const renderCardType = () => {
      return pricingCardType.map((item: any, index: number) => {
        return (
          <div className="three wide field" key={index}>
            <Checkbox
              label={item.value}
              name="pricingCardType"
              value={item.value}
              checked={item.ischecked}
              onChange={(event, data: CheckboxProps) => {
                handlePricingCardType(event, data);
              }}
            />
          </div>
        );
      });
    };
    const handleRetail = (data: any) => {
      setIsRetail(data.checked);
      let business_conduct = businessConduct;
      if (data.checked) {
        business_conduct.push("Retail");
        setBusinessConduct(business_conduct);
      } else {
        const index = business_conduct.indexOf("Retail");
        if (index > -1) {
          business_conduct.splice(index, 1);
          setBusinessConduct(business_conduct);
          setDiscountPercentage("")
        }
      }
    };

    const handleMailOrder = (data: any) => {
      setIsMailOrder(data.checked);
      let business_conduct = businessConduct;
      if (data.checked) {
        business_conduct.push("Mail Order/Tel");
        setBusinessConduct(business_conduct);
      } else {
        const index = business_conduct.indexOf("Mail Order/Tel");
        if (index > -1) {
          business_conduct.splice(index, 1);
          setBusinessConduct(business_conduct);
        }
      }
    };

    const handleEcommerce = (data: any) => {
      setIsEcommerce(data.checked);
      let business_conduct = businessConduct;
      if (data.checked) {
        business_conduct.push("E-Commerce");
        setBusinessConduct(business_conduct);
      } else {
        const index = business_conduct.indexOf("E-Commerce");
        if (index > -1) {
          business_conduct.splice(index, 1);
          setBusinessConduct(business_conduct);
        }
      }
    };


    useEffect(() => {
      if (!freeEquipmentPlacementStatus) {
        const list: any = [...equipmentSectionData]
        const filterEquipmentBillTo = equipment_billTo.filter(item => (item.value !== 'Trinity'));
        const updatedList: any = []
        list.forEach((element: any) => {
          if (element.billTo === 'Trinity') {
            element.billTo = ''
          }
          updatedList.push(element)
        });
        console.log(updatedList,"updatedList")
        setEquipmentSectionData([...updatedList])
        setEquipmentBillTo(filterEquipmentBillTo)
      } else {
        setEquipmentBillTo(equipment_billTo)
      }
    }, [freeEquipmentPlacementStatus])
   
    // handle input change
    const handleEquipChange = (
      e: any,
      index: any,
      data?: any,
      type?: string
    ) => {
      const list: any = [...equipmentSectionData];
      if (!!type) {
        list[index][type] = e;
      } else if (data && data.name && data.hasOwnProperty("value")) {
        // to handle the dropdown event
        const dd_name = data.name;
        const dd_value = data.value;
        list[index][dd_name] = dd_value;
        if (dd_name === "equipment_type") {
          list[index]["application"] = "";
        }
      } else if (!!data && !!data.type && data.type === "checkbox") {
        list[index][data.name] = data.checked || false;
      } else {
        const { name, value } = e.target;
        list[index][name] = value;
      }

      if (
        data &&
        data.name === "product" &&
        list[index]["equipment_type"] === "Physical Terminal"
      ) {
        const cost = getProductCost(data.value);
        list[index]["cost"] = cost;
        setMinEquipmentCost(parseFloat(cost));
      }
      setEquipmentSectionData(list);
    };

    const handleEquipCostBlur = (e: any, index: any) => {
      const list: any = [...equipmentSectionData];
      const floatCost = parseFloat(list[index]["cost"]);
      const updatedCost = floatCost.toFixed(2);
      list[index]["cost"] = updatedCost;

      setEquipmentSectionData(list);
    };

    // handle click event of the Remove button
    const handleRemoveEquipClick = (index: any) => {
      const list = [...equipmentSectionData];
      list.splice(index, 1);
      setEquipmentSectionData(list);
      // after removing any owner data set fiest owner as active
      handleEquipmentClick(0);
    };

    // handle click event of the Add button
    const handleAddEquipClick = (index: any) => {
      handleEquipmentClick(equipmentSectionData.length);
      setEquipmentSectionData([...equipmentSectionData, equip_default_obj]);
    };

    const handleEquipmentClick = (index: any) => {
      setEquipmentActive(index);
      setHideEquipment(true);
    };

    const renderPinDebit = () => {
      return option_yesno.map((item: any, index: number) => {
        return (
          <Radio
            key={index}
            label={item.value}
            className="margin-right-10"
            name="pinDebit"
            value={item.value}
            checked={pinDebit === item.value}
            onChange={handleChangePinDebit}
          />
        );
      });
    };
    const renderAcceptEbt = () => {
      return option_yesno.map((item: any, index: number) => {
        return (
          <Radio
            key={index}
            label={item.value}
            name="acceptEbt"
            className="margin-right-10"
            value={item.value}
            checked={acceptEbt === item.value}
            onChange={handleAcceptEbt}
          />
        );
      });
    };

    // prescreening section status
    useEffect(() => {
      const updatePreScreeningSectionStatus = () => {
        const businessCategoryFilled =
          businessCategory.toLocaleLowerCase() === "other"
            ? businessCategoryFreeFormText
            : businessCategory;
        const businessTypeFilled =
          businessType.toLocaleLowerCase() === "other"
            ? businessTypeFreeFormText
            : businessType;
        if (
          !!businessConduct &&
          !!businessCategoryFilled &&
          !!businessTypeFilled &&
          !!riskLevel &&
          !!selectedBankOption &&
          !!selectedSellTime &&
          (isRetail || isMailOrder || isEcommerce)
        ) {
          setPreScreeningSectionStatus("filled");
        } else {
          setPreScreeningSectionStatus("");
        }
      };
      updatePreScreeningSectionStatus();
    }, [
      sellTypeValue,
      businessCategory,
      businessType,
      riskLevel,
      selectedBankOption,
      selectedSellTime,
      businessCategoryFreeFormText,
      businessTypeFreeFormText,
      isRetail,
      isEcommerce,
      isMailOrder,
    ]);
    const updatePricingSectionStatus = () => {
      const tieredQualDiscountFilled =
        pricingType === "Tiered" &&
        (pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover"))
          ? !!tieredQualDiscount && !!tieredQualPerItem : true;

      const tieredMidDiscountFilled =
        pricingType === "Tiered" &&
        (pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover"))
          ? !!tieredMidDiscount && !!tieredMidPerItem  : true;
          
      const tieredNonDiscountFilled =
        pricingType === "Tiered" &&
        (pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover"))
          ? !!tieredNonDiscount && !!tieredNonPerItem
          : true;
      const tieredDebitDiscountFilled =
        pricingType === "Tiered" &&
        (pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover"))
          ? !!tieredDebitDiscount && !!tieredDebitPerItem
          : true;
      const errQualDiscountFilled =
        pricingType === "Err" &&
        (pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover"))
          ? !!errQualDiscount && !!errQualPerItem
          : true;
      const errNonDiscountFilled =
        pricingType === "Err" &&
        (pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover"))
          ? !!errNonDiscount && !!errNonPerItem
          : true;
      const discountRateFilled =
        pricingType === "Interchange" || pricingType === "Flat Rate"
          ? pricingCardValue.includes("Visa") ||
            pricingCardValue.includes("MC") ||
            pricingCardValue.includes("Discover")
            ? !!discountRate && !!perItem
            : true
          : true;
      const debitDiscountRateFilled =
        pricingType === "Interchange" ? 
          pricingCardValue.includes("Visa") ||
          pricingCardValue.includes("MC") ||
          pricingCardValue.includes("Discover")
          ? !!debitDiscountRate : true
          : true

      const amexQualFilled =
        pricingType === "Tiered" && pricingCardValue.includes("AMEX")
          ? !!amexQual && !!amexPerItem 
          : true;
      const amexMidQualFilled =
        pricingType === "Tiered" && pricingCardValue.includes("AMEX")
          ? !!amexMidQual && !!amexMidPerItem
          : true;
      const amexNonQualFilled =
        pricingType === "Tiered" && pricingCardValue.includes("AMEX")
          ? !!amexNonQual && !!amexNonPerItem
          : true;
      const intAmexDiscountRateFilled =
        pricingType === "Err" ||
        pricingType === "Interchange" ||
        pricingType === "Flat Rate"
          ? pricingCardValue.includes("AMEX")
            ? !!intAmexDiscountRate &&!!intAmexperItem 
            : true
          : true; 
      const pinDebitAuthFilled = pinDebit === "Yes" ? !!pinDebitAuth : true;
      const ebtAuthFilled = acceptEbt === "Yes" ? !!ebtAuth : true;
      const accountReserveFilled = businessConduct.includes('E-Commerce') && riskLevel === RISK_LEVELS.HIGH_RISK ? !!accountReserve : true
      const debitAccessFeeFilled = riskLevel === RISK_LEVELS.LOW_RISK && pinDebit === "Yes" ? !!debitAccessFee : true
      const amexOptubleSupportFeeFilled = pricingCardValue.includes('AMEX') ? !!amexOptubleSupportFee : true
      const amexOptubleNetworkFeeFilled = pricingCardValue.includes('AMEX') ? !!amexOptubleNetworkFee : true
      const ebtFilled = riskLevel === RISK_LEVELS.LOW_RISK && acceptEbt === "Yes" ? !!ebt : true
      const mcFilled = pricingCardValue.includes('Visa') || pricingCardValue.includes('MC') || pricingCardValue.includes('Discover') ? !!mc : true
      const amexAuthFilled = pricingCardValue.includes('AMEX') ? !!amexAuth : true

      if (
        !!pricingType &&
        (pricingCardValue.length > 0) &&
        (!!tieredQualDiscountFilled &&
        !!tieredMidDiscountFilled &&
        !!tieredNonDiscountFilled &&
        !!tieredDebitDiscountFilled) &&
        (!!intAmexDiscountRateFilled) &&
        (!!amexQualFilled &&
        !!amexMidQualFilled &&
        !!amexNonQualFilled) &&
        (!!errQualDiscountFilled &&
        !!errNonDiscountFilled &&
        !!discountRateFilled &&
        !!debitDiscountRateFilled) &&
        ((!!pinDebit && !!pinDebitAuthFilled && !!acceptEbt && !!ebtAuthFilled) || 
        riskLevel === RISK_LEVELS.HIGH_RISK)&& 
        (!!annualFee && !!monthlyFee && !!chargeBackFee && !!monthlyMinimum && !!earlyTerminationFee) && 
        (accountReserveFilled && debitAccessFeeFilled && amexOptubleSupportFeeFilled && amexOptubleNetworkFeeFilled && !!voiceAuth && !!excessiveElectronicAuth && !!irsReportingFee && !!retreivalFee && !!invalidTinFee && !!achRejectFee && !!collectionsFee && !!pciValidation && !!returnTransactionFee && !!pciNonCompliance && !!industryCompliance)&&
        (!!avs && !!batchFee && ebtFilled && mcFilled && amexAuthFilled && !pciError && !chargeBackError))
      {
        setPricingSectionStatus('filled');
      } else if(selectedSellTime === 'Cash Discount'  && !!pricingType && (pricingCardValue.length > 0) && !!ticketSize && (!!discountPercentage || !!serviceFee) && !!licenseTechFees && !!acceptEbt 
      && !!ebtAuthFilled && !!earlyTerminationFee && !!chargeBackFee && !!pciValidation && !!pciNonCompliance && !pciError && !chargeBackError){
        setPricingSectionStatus('filled');
    } else {
      setPricingSectionStatus('')
    }
  }
  const updateEarlyterminationFee = () =>{
    if (!!earlyTerminationFee) {      
      setManualTerminationFee(earlyTerminationFee)
    }
  }

    useEffect(() => {
      updatePricingSectionStatus();
    }, [
      pricingType,
      pricingCardType,
      pricingCardValue,
      tieredQualDiscount,
      tieredMidDiscount,
      tieredNonDiscount,
      tieredDebitDiscount,
      amexQual,
      amexMidQual,
      amexNonQual,
      errQualDiscount,
      errNonDiscount,
      intAmexDiscountRate,
      discountRate,
      pinDebit,
      pinDebitAuth,
      acceptEbt,
      ebt,
      ebtAuth,
      ticketSize, 
      selectedSellTime, 
      licenseTechFees,
      discountPercentage,
      handleDuplicate
    ]);

    // equipmentSectionStatus
    useEffect(() => {
      const updateEquipSectionStatus = () => {
        if (equipmentSectionData && equipmentSectionData.length) {
          let equipmentLength = [];
          for (const equipment of equipmentSectionData) {
            let checkBoxValidator =
              equipment.ip_connection ||
              equipment.purchasing_cards ||
              equipment.avs ||
              equipment.server_clerk ||
              equipment.wireless ||
              equipment.invoice_number ||
              equipment.cvv2 ||
              equipment.fraud_check;

            let billedTovalidator =
              equipment.billTo === "Merchant" &&
              equipment.equipment_type === "Physical Terminal"
                ? !!equipment.cost
                : true;

            let equipmentCostValidator = true;
            if (
              equipment.billTo === "Merchant" &&
              equipment.equipment_type === "Physical Terminal" &&
              !!equipment.cost
            ) {
              equipmentCostValidator =
                parseFloat(equipment.cost) >= minEquipmentCost;
              setEquipmentCostStatus(
                parseFloat(equipment.cost) >= minEquipmentCost
              );
            } else {
              setEquipmentCostStatus(true);
            }

            let typeValidator = false;
            if (equipment.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL) {
              typeValidator = !!equipment.product
            } else if (equipment.equipment_type ===EQUIPMENT_TYPE.VIRTUAL_TERMINAL) {
              typeValidator = (!!equipment.product && equipment.product === 'Other') ? !!equipment.virtual_terminal_other : !!equipment.product
            } else if (equipment.equipment_type === EQUIPMENT_TYPE.PIN_PAD) {
              typeValidator = !!equipment.product
            } else if (equipment.equipment_type === EQUIPMENT_TYPE.POS) {
              typeValidator = !!equipment.pos_name
            } else if (equipment.equipment_type === EQUIPMENT_TYPE.CHECK_READER) {
              typeValidator = !!equipment.check_reader_text
            }

            let equipment_quantity = !!equipment.quantity;
            let equipment_mailing_addr_type =
              !!equipment.equipment_mailing_addr_type;
            let connection_type = !!equipment.connection_type;
            let auto_close = !!equipment.auto_close;
            let application = !!equipment.application

            if (equipment.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL) {
              billedTovalidator = true
              equipment_quantity = true
              equipment_mailing_addr_type = true
              connection_type = true
              auto_close = true
              checkBoxValidator = true
              if(equipment.mobile_equipment==="BBPOS Chipper 2X BT__$105"){
                equipment_quantity = !!equipment.quantity
                equipment_mailing_addr_type = !!equipment.equipment_mailing_addr_type
                billedTovalidator = !!equipment.billTo
                
              }
            }else if(equipment.equipment_type === EQUIPMENT_TYPE.POS){
              billedTovalidator = true
              equipment_quantity = true
              equipment_mailing_addr_type = true
              connection_type = true
              auto_close = true
              checkBoxValidator = true
              application = true
            }else if(equipment.equipment_type === EQUIPMENT_TYPE.PIN_PAD){
              connection_type = true
              auto_close = true
              checkBoxValidator = true
              application = true
            }else{
              equipment_mailing_addr_type = equipment.deployment === "New"?!!equipment.equipment_mailing_addr_type:true
            }

            if (
              connection_type &&
              auto_close &&
              checkBoxValidator &&
              equipment_quantity &&
              // !!equipment.platform &&
              !!application &&
              equipment_mailing_addr_type &&
              !!equipment.deployment &&
              billedTovalidator &&
              equipmentCostValidator &&
              typeValidator
            ) {
              if (
                role === ROLES.PARTNER ||
                role === ROLES.RELATIONSHIP_MANAGER ||
                role === ROLES.OFFICE_MANAGER ||
                role === ROLES.OFFICE_ADMIN
              ) {
                equipment.rm_added = true;
              }
              equipmentLength.push("filled");
            } else {
              setEquipmentSectionStatus("");
            }
          }
          if(equipmentSectionData.length === equipmentLength.length){
            setEquipmentSectionStatus("filled");
          }
        } else {
          setEquipmentSectionStatus("");
        }
      };
      updateEquipSectionStatus();
    }, [equipmentSectionData]);

    useEffect(() => {
      if(equipmentTotalCost > manualTerminationFee){
       setEarlyTerminationFee(equipmentTotalCost)
       setDisableEarlyTermination(true)
      }else{
       setEarlyTerminationFee(manualTerminationFee)
       setDisableEarlyTermination(false)
      }
     }, [equipmentTotalCost,manualTerminationFee])

    const openViewLinkDetailsModal = (index: number, customData: any) => {
      setShowViewLinkDetailsModal(true);
      const data: any = index >= 0 ? sendLinkUsers[index] : customData;
      console.log("data:", data);
      setLinkDetailsID(data.app_link_code);
      setLinkDetailsName(data?.link_name);
      setLinkDetailsURL(data.link_url);
      setLinkDetailsTinyURL(data.tiny_url);
      setLinkDetailsNoOfApps(data.no_of_apps);
      setLinkDetailsNoOfClicks(data.no_of_clicks);
      setLinkDetailsPartnerName(data.partner_name);

      setLinkDetailsconditionalSellType(
        data?.merchant_prescreen?.business_subtype
      );
      setLinkDetailssellTypeValue(data?.merchant_prescreen?.business_type);
      setLinkDetailsconditionalSellGood(
        data?.merchant_prescreen?.physical_goods_type
      );
      setLinkDetailsbusinessType(data?.merchant_prescreen?.price);
      setLinkDetailsselectedBankOption(
        data?.merchant_prescreen?.processor_name
      );
      setLinkDetailsselectedSellTime(data?.merchant_prescreen?.sales_frequency);
    };

    const handleEditButtonClick = (e: any, app_link_id: string,duplicated: boolean, duplicate_link_data?: any) => {
      e?.preventDefault();
      e?.stopPropagation();
      setIsEditMode(true);
      window.scroll({ top: 0, left: 0 });
      if (isMobile)
        document.getElementsByClassName("container")[0].scrollTop = 0;

      let data: any = {}
      if(duplicated) data = duplicate_link_data[0]
      else {
        const index = sendLinkUsers.findIndex(
          (save_link: any) => save_link.app_link_id === app_link_id
        );
        data = sendLinkUsers[index];
      }
      // updatePricingSectionStatus();
      console.log("data::",data)
         setCurrentSaveLink(data);
      setSaveLinkId(data?.app_link_id);
      setSaveLinkCode(data?.app_link_code)
      setLinkName(data?.link_name || "");
      setAppLinkStatus(data?.app_link_status || "");
      setAppLinkUrl(data?.link_url || "");
      setFreeEquipmentPlacementStatus(data?.free_equipment_placement_enabled||false);
      setBusinessName(data?.app_data_sections?.dba_section?.dba_name || "");
      setBusinessEmail(data.merchant_email || "");
      setBusinessContact(data.merchant_name || "");
      const partner_name = data?.partner_name ? data?.partner_name : partnerName
      setPartnerName(partner_name || "");
      if(role === ROLES.PARTNER){
        getRmsByLoginUser()
      } else if(role === ROLES.OFFICE_ADMIN || role === ROLES.OFFICE_MANAGER){
        fetchPartnerRms(data.partner_id);
      } else if(role === ROLES.RELATIONSHIP_MANAGER){
        fetchPartnerRms(data.partner_id, false);
      }
      setPartnerId(data.partner_id || "");
      setPartnerRmName(partner_name || "");
      const partner_rm_id = REACT_APP_BACKEND_MYSQL ? data?.partner_rm_user_id : data?.partner_rm_id
      console.log("partnerName:",partner_name,"partnerId:",data.partner_id,"partnerRmId:p",partner_rm_id)
      setPartnerRmId(partner_rm_id || "");
      setSellGoodValue(data?.merchant_prescreen?.goods_sold || "");
      setConditionalSellGood(
        data?.merchant_prescreen?.physical_goods_type || ""
      );
      setSelectedSellTime(data?.merchant_prescreen?.sales_frequency || "");

      // PreScreen
      const businessConduct_value: any[] =
        data?.merchant_prescreen?.business_conducted || [];
      if (businessConduct_value.includes("Retail")) {
        setIsRetail(true);
      }
      if (businessConduct_value.includes("Mail Order/Tel")) {
        setIsMailOrder(true);
      }
      if (businessConduct_value.includes("E-Commerce")) {
        setIsEcommerce(true);
      }
      setBusinessConduct(businessConduct_value);
      setBusinessCategory(data?.merchant_prescreen?.business_category || "");
      handleBusinessCategory(
        {},
        { value: data?.merchant_prescreen?.business_category }
      );
      setBusinessCategoryFreeFormText(
        data?.merchant_prescreen?.business_category_free_form || ""
      );
      setBusinessTypeFreeFormText(
        data?.merchant_prescreen?.business_type_free_form || ""
      );
      setBusinessType(data?.merchant_prescreen?.business_type || "");
      setRiskLevel(data?.merchant_prescreen?.price || "");

     let riskLevels = data?.merchant_prescreen?.price
     
      if( role ===ROLES.PARTNER && allowed_risk_levels_partner.length !==0 && !allowed_risk_levels_partner.includes(riskLevels)){
        setRiskLevel('')
     }
      
      setSelectedBankOption(data?.merchant_prescreen?.processor_name || "");
      setPersonalMessage(data?.personal_message || "");

      // Pricing
      setPricingType(
        data?.app_data_sections?.pricing_details?.pricing_type || ""
      );
      const pricing_cardType: any[] =
        data?.app_data_sections?.pricing_details?.card_type || [];
      if (pricing_cardType) {
        pricingCardType.forEach((item: any) => {
          pricing_cardType.forEach((selectedItem: any) => {
            if (item.value === selectedItem) {
              item.ischecked = true;
            }
          });
          setPricingCardValue(pricing_cardType);
        });
      } else {
        setPricingCardValue([]);
      }
      setMonthlyFee(
        data?.app_data_sections?.pricing_details?.monthly_fee || ""
      );
      setAnnualFee(data?.app_data_sections?.pricing_details?.annual_fee || "");
      setTieredQualDiscount(
        data?.app_data_sections?.pricing_details?.tiered_qual_discount || ""
      );
      setTieredQualPerItem(
        data?.app_data_sections?.pricing_details?.tiered_qual_per_item || ""
      );

      if(data?.merchant_prescreen?.processor_name === BANK_CODES.AXIOM || data?.merchant_prescreen?.processor_name === BANK_CODES.SYNOVUS){
        setTieredMidPerItem(
          data?.app_data_sections?.pricing_details?.tiered_qual_per_item ? (parseFloat(data?.app_data_sections?.pricing_details?.tiered_qual_per_item) + 0.1).toFixed(2).toString() : ''
        );
        setTieredMidDiscount(
          data?.app_data_sections?.pricing_details?.tiered_qual_discount ? (parseFloat(data?.app_data_sections?.pricing_details?.tiered_qual_discount) + 1.05).toFixed(2).toString() : ''
        );
        setTieredNonPerItem(
          data?.app_data_sections?.pricing_details?.tiered_qual_per_item ? (parseFloat(data?.app_data_sections?.pricing_details?.tiered_qual_per_item) + 0.1).toFixed(2).toString() : ''
        );
        setTieredNonDiscount(
          data?.app_data_sections?.pricing_details?.tiered_qual_discount ? (parseFloat(data?.app_data_sections?.pricing_details?.tiered_qual_discount) + 1.55).toFixed(2).toString() : ''
        );
      } else {
        setTieredMidPerItem(
          data?.app_data_sections?.pricing_details?.tiered_mid_per_item || ""
        );
        setTieredMidDiscount(
          data?.app_data_sections?.pricing_details?.tiered_mid_discount || ""
        );
        setTieredNonPerItem(
          data?.app_data_sections?.pricing_details?.tiered_non_per_item || ""
        );
        setTieredNonDiscount(
          data?.app_data_sections?.pricing_details?.tiered_non_discount || ""
        );
      }

      setTieredDebitDiscount(
        data?.app_data_sections?.pricing_details?.tiered_debit_discount || ""
      );
      setTieredDebitPerItem(
        data?.app_data_sections?.pricing_details?.tiered_debit_per_item || ""
      );
      setErrQualDiscount(
        data?.app_data_sections?.pricing_details?.err_qual_discount || ""
      );
      setErrQualPerItem(
        data?.app_data_sections?.pricing_details?.err_qual_per_item || ""
      );
      setErrNonDiscount(
        data?.app_data_sections?.pricing_details?.err_non_discount || ""
      );
      setErrNonPerItem(
        data?.app_data_sections?.pricing_details?.err_non_per_item || ""
      );
      setIntAmexDiscountRate(
        data?.app_data_sections?.pricing_details?.intamex_discount || ""
      );
      setIntAmexperItem(
        data?.app_data_sections?.pricing_details?.intamex_per_item || ""
      );
      setAmexQual(data?.app_data_sections?.pricing_details?.amex_qual || "");
      setAmexPerItem(
        data?.app_data_sections?.pricing_details?.amex_per_item || ""
      );
      setAmexMidQual(
        data?.app_data_sections?.pricing_details?.amex_mid_qual || ""
      );
      setAmexMidPerItem(
        data?.app_data_sections?.pricing_details?.amex_mid_per_item || ""
      );
      setAmexNonQual(
        data?.app_data_sections?.pricing_details?.amex_non_qual || ""
      );
      setAmexNonPerItem(
        data?.app_data_sections?.pricing_details?.amex_non_per_item || ""
      );
      setDiscountRate(
        data?.app_data_sections?.pricing_details?.discount_rate || ""
      );
      setPerItem(data?.app_data_sections?.pricing_details?.per_item || "");
      setDebitDiscountRate(
        data?.app_data_sections?.pricing_details?.debit_discount_rate || ""
      );
      setDebitNetworkFees(
        data?.app_data_sections?.pricing_details?.debit_network_fees || "Yes"
      );
      setClymDataPrivacyCompliance(
        data?.app_data_sections?.pricing_details
          ?.clym_data_privacy_compliance || "Yes"
      );
      setClymDataPrivacyComplianceFee(
        data?.app_data_sections?.pricing_details
          ?.clym_data_privacy_compliance_fee || "20"
      );
      setPinDebit(data?.app_data_sections?.pricing_details?.pin_debit || "");
      setPinDebitAuth(
        data?.app_data_sections?.pricing_details?.pin_debit_auth || ""
      );
      setAcceptEbt(data?.app_data_sections?.pricing_details?.accept_ebt || "");
      setEbt(data?.app_data_sections?.pricing_details?.ebt || "");
      setEbtAuth(data?.app_data_sections?.pricing_details?.ebt_auth || "");
      setWirelessFee(
        data?.app_data_sections?.pricing_details?.wireless_fee || ""
      );
      setOfferAmex(data?.app_data_sections?.pricing_details?.offer_amex || "");
      setMc(data?.app_data_sections?.pricing_details?.mc || "");
      setVisa(data?.app_data_sections?.pricing_details?.visa || "");
      setDiscAuth(data?.app_data_sections?.pricing_details?.disc_auth || "");
      setAmexAuth(data?.app_data_sections?.pricing_details?.amex_auth || "");
      setAvs(data?.app_data_sections?.pricing_details?.avs || "");
      setBatchFee(data?.app_data_sections?.pricing_details?.batch_fee || "");
      checkChargeBackValue(data?.app_data_sections?.pricing_details?.charge_back_fee);
      setChargeBackFee(
        data?.app_data_sections?.pricing_details?.charge_back_fee || ""
      );
      setMonthlyMinimum(
        data?.app_data_sections?.pricing_details?.monthly_minimum || ""
      );
      setEarlyTerminationFee(
        data?.app_data_sections?.pricing_details?.early_termination_fee || ""
      );
      setManualTerminationFee(data?.app_data_sections?.pricing_details?.early_termination_fee || "")
      setAccountReserve(
        data?.app_data_sections?.pricing_details?.account_reserve || ""
      );
      setOther(data?.app_data_sections?.pricing_details?.other || "");
      setBilled(data?.app_data_sections?.pricing_details?.billed || "");
      setVoiceAuth(
        data?.app_data_sections?.pricing_details?.voice_auth || "1.96"
      );
      setExcessiveElectronicAuth(
        data?.app_data_sections?.pricing_details?.excessive_electronic_auth ||
          "0.10"
      );
      setIrsReportingFee(
        data?.app_data_sections?.pricing_details?.irs_reporting_fee || "4.95"
      );
      setRetreivalFee(
        data?.app_data_sections?.pricing_details?.retreival_fee || "20"
      );
      setInvalidTinFee(
        data?.app_data_sections?.pricing_details?.invalid_tin_fee || "19.95"
      );
      setAchRejectFee(
        data?.app_data_sections?.pricing_details?.ach_reject_fee
          ? data?.app_data_sections?.pricing_details?.ach_reject_fee
          : selectedBankOption === BANK_CODES.DART
          ? "25"
          : "30"
      );
      
      setCollectionsFee(
        data?.app_data_sections?.pricing_details?.collections_fee || "20"
      );
      checkPciValue(data?.app_data_sections?.pricing_details?.pci_validation);
      setPciValidation(
        data?.app_data_sections?.pricing_details?.pci_validation || "119.99"
      );
      const pciNonCompliance : any = (data?.app_data_sections?.pricing_details?.pci_non_compliance || (selectedBankOption === BANK_CODES.CFSB || selectedBankOption === BANK_CODES.CFSB_CD) ? otherFees_cfsb.pciNonCompliance : otherFees.pciNonCompliance)
      setPciNonCompliance(pciNonCompliance);
      setIndustryCompliance(
        data?.app_data_sections?.pricing_details?.industry_compliance || "9.95"
      );
      setReturnTransactionFee(
        data?.app_data_sections?.pricing_details?.return_transaction_fee ||
          "0.10"
      );
      setAmexOptubleSupportFee(
        data?.app_data_sections?.pricing_details?.amex_optuble_support_fee ||
          "0.50"
      );
      setAmexOptubleNetworkFee(
        data?.app_data_sections?.pricing_details?.amex_optuble_network_fee ||
          "0.15"
      );
      setDebitAccessFee(
        data?.app_data_sections?.pricing_details?.debit_access_fee || "3.95"
      );
      setTicketSize(data?.app_data_sections?.pricing_details?.ticketSize || '')
      setDiscountPercentage(data?.app_data_sections?.pricing_details?.discountPercentage || '')
      setServiceFee(data?.app_data_sections?.pricing_details?.serviceFee || '')
      setLicenseTechFees(data?.app_data_sections?.pricing_details?.licenseTechFees || '25')
      let equipment_section = data?.app_data_sections?.equipment_section || [];
      if (!!equipment_section) {
        if (equipment_section.length > 0) {
          setNumberOfEquipments(equipment_section.length);
          setEquipmentSectionData(equipment_section);
        }
      }
    };

    const setMerchantMessage = async (event: any) => {
      setUserMessage(event.target.value);
    };

    //Sorting
    const handleSortByFieldName = async (field: string, order: string) => {
      setSortFieldOrder(sortFieldOrder === "desc" ? "asc" : "desc");
      setSortField(field);
    };

    //Searching
    const handleSearchByFieldName = async (field: string, value: string) => {
      if (!!value) {
        setSearchField(field);
      } else {
        setSearchField("");
      }
      setSearchText(value);
    };

    const sortAndSearchResult = (data: any) => {
      const sendLinkUsersTemp: any = data;

      let sendLinkUsersSearchResult: any = sendLinkUsersTemp;

      if (!!searchText && !!searchField) {
        sendLinkUsersSearchResult = sendLinkUsersTemp.filter((item: any) => {
          let searched_Name = "";
          if (searchField === "createdTS") {
            searched_Name = formatDate(
              new Date(
                item?.created_ts?._seconds * 1000 +
                  item?.created_ts?._nanoseconds / 1000000
              ).toDateString()
            );
          } else if (searchField === "updatedTS") {
            searched_Name = formatDate(
              new Date(
                item?.updated_ts?._seconds * 1000 +
                  item?.updated_ts?._nanoseconds / 1000000
              ).toDateString()
            );
          } else if (typeof item[searchField] === "number") {
            searched_Name = item[searchField].toString();
          } else {
            if (item[searchField]) {
              searched_Name = item[searchField].toLowerCase();
            }
          }
          return searched_Name.indexOf(searchText.toLowerCase()) > -1;
        });
      }

      const sortFieldOrderTemp: any = sortFieldOrder === "desc" ? -1 : 1;

      sendLinkUsersSearchResult.sort(
        firstBy(
          function (save_link: any) {
            if (sortField === "createdTS") {
              return new Date(
                save_link?.created_ts?._seconds * 1000 +
                  save_link?.created_ts?._nanoseconds / 1000000
              );
            } else if (sortField === "updatedTS") {
              return new Date(
                save_link?.updated_ts?._seconds * 1000 +
                  save_link?.updated_ts?._nanoseconds / 1000000
              );
            } else if (typeof get(save_link, sortField, "") === "number") {
              return get(save_link, sortField, 0);
            }
            return get(save_link, sortField, "").toLowerCase();
          },
          { direction: sortFieldOrderTemp }
        )
      );

      return sendLinkUsersSearchResult;
    };

    const getAppControls = (data: any, index: any) => {
      const viewControl =
        data.app_link_status === "inactive" || selectedAppBulk.length > 1
          ? "not-active view-icon"
          : "view-icon";
      const copyControl =
        data.app_link_status === "inactive" || selectedAppBulk.length > 1
          ? "not-active duplicate-icon"
          : "duplicate-icon";
      const editControl =
        data.app_link_status === "inactive" || selectedAppBulk.length > 1
          ? "not-active edit-icon"
          : "edit-icon";
      const duplicateControl =
        data.app_link_status === "inactive" || selectedAppBulk.length > 1
          ? "not-active clone-icon"
          : "clone-icon";
      const deleteControl =
        data.app_link_status === "inactive" || selectedAppBulk.length > 1
          ? "not-active delete-icon"
          : "delete-icon";
      const uiActions = [
        {
          className: viewControl,
          label: "View",
          action: () => openViewLinkDetailsModal(index, data),
        },
        {
          className: copyControl,
          label: "Copy",
          action: () => handleCopy(data.tiny_url),
        },
        {
          display: role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED,
          className: selectedAppBulk.length > 1 ? "not-active" : "",
          label: data.app_link_status === "inactive" ? "Activate" : "Deactivate",
          action: () => {
            clearForm();
            handleActivate(data.app_link_id, data.app_link_status)
          },
        },
        {
          display: role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED,
          className: editControl,
          label: "Edit",
          action: (e: any) => handleEditButtonClick(e, data.app_link_id, false),
        },
        {
          display: role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED,
          className: duplicateControl,
          label: "Duplicate",
          action: (e: any) => {
            clearForm();
            handleDuplicate(e, data.app_link_id)
          },
        },
        {
          display: role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED,
          className: deleteControl,
          label: "Delete",
          action: () => handleDelete(data.app_link_id, true),
        },
      ];
      return uiActions;
    };

    const tableData = sortAndSearchResult(sendLinkUsers).map(
      (user: any, index: number) => {
        const createdTS = formatDate(
          new Date(
            user.created_ts._seconds * 1000 +
              user.created_ts._nanoseconds / 1000000
          ).toDateString()
        );
        const updatedTS = formatDate(
          new Date(
            user?.updated_ts?._seconds * 1000 +
              user?.updated_ts?._nanoseconds / 1000000
          ).toDateString()
        );
        user.createdTS = createdTS;
        user.updatedTS = updatedTS;
        user.uiActions = getAppControls(user, -1);
        return user;
      }
    );
    const search = useLocation().search;
    const searchObj = new URLSearchParams(search);
    const sortOrder = searchObj.get('sort');
    const ascFn = (a:string, b:string) => a.localeCompare(b);
    if (!!sortOrder) {
      tableData.sort((a:any,b:any) => sortOrder === 'asc' ? ascFn(a.app_link_id, b.app_link_id) : ascFn(b.app_link_id, a.app_link_id));
    }

    // RENDER PRE-SCREENING
    const renderPreScreening = () => {
      return (
        <div className="form-contents">
          <div className="form-headingleftpadding" style={{ color: config?.client_config_fe?.button_color }}>
            Pre-Screening Information
            <span
              className="navigationright-icon"
              onClick={() => handlePrevNextNavigation("prescreening", "next")}
            />
          </div>
          <hr className="top" style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
          <PreScreeningSection
            isRetail={isRetail}
            handleRetail={handleRetail}
            isMailOrder={isMailOrder}
            handleMailOrder={handleMailOrder}
            isEcommerce={isEcommerce}
            handleEcommerce={handleEcommerce}
            businessCategoryConditionalOptions={
              businessCategoryConditionalOptions
            }
            businessCategory={businessCategory}
            handleBusinessCategory={handleBusinessCategory}
            businessTypeConditionalOptions={businessTypeConditionalOptions}
            handleBusinessTypeChange={handleBusinessTypeChange}
            businessCategoryFreeFormText={businessCategoryFreeFormText}
            setBusinessCategoryFreeFormText={setBusinessCategoryFreeFormText}
            businessType={businessType}
            businessTypeFreeFormText={businessTypeFreeFormText}
            setBusinessTypeFreeFormText={setBusinessTypeFreeFormText}
            businessCategoryFreeFormErrorMessage={
              businessCategoryFreeFormErrorMessage
            }
            businessTypeFreeFormErrorMessage={businessTypeFreeFormErrorMessage}
            role={role}
            showRiskLevels={showRiskLevels}
            riskLevel={riskLevel}
            handleRiskLevel={handleRiskLevel}
            selectedSellTime={selectedSellTime}
            handleChangeSellTime={handleChangeSellTime}
            showProcessors={showProcessors}
            showLowRiskProcessors={showLowRiskProcessors}
            showHighRiskProcessors={showHighRiskProcessors}
            selectedBankOption={selectedBankOption}
            handleChangeBankOption={handleChangeBankOption}
            getLink={getLink}
            merchantToken={merchantToken}
            isMobile={isMobile}
            isTab={isTablet}
            showCashDiscount={showCashDiscount}
            disableSalesMethod={disableSalesMethod}
            cdBankOptionsForCD={cdBankOptionsForCD}
          />
          <div className="center-buttons ipad-center-buttons">
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('prescreening', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('prescreening', 'next')}

          />
          </div>
        </div>
      );
    };
    // RENDER PRICING
    const renderPricing = () => {
      return (
        <div className="form-contents">
          <div className="form-headingleftpadding" style={{ color: config?.client_config_fe?.button_color }}>
            <span
              className="navigationleft-icon"
              onClick={() => handlePrevNextNavigation("pricing", "prev")}
            />
            Pricing
            <span
              className="navigationright-icon"
              onClick={() => handlePrevNextNavigation("pricing", "next")}
            />
            {!isEditMode && (
              <Button
                style={{ display: "none" }}
                color="blue"
                inverted
                className="save-button-right"
              >
                SAVE
              </Button>
            )}
          </div>
          <hr className="top" style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
          <PricingSection
            isRetail={isRetail}
            businessConduct={businessConduct}
            riskLevel={riskLevel}
            selectedBankOption={selectedBankOption}
            pricingType={pricingType}
            pricingCardValue={pricingCardValue}
            tieredQualDiscount={tieredQualDiscount}
            handleChangeTextInput={handleChangeTextInput}
            updatePricingSectionStatus={updatePricingSectionStatus}
            updateEarlyterminationFee={updateEarlyterminationFee}
            tieredQualPerItem={tieredQualPerItem}
            tieredMidDiscount={tieredMidDiscount}
            tieredNonDiscount={tieredNonDiscount}
            tieredNonPerItem={tieredNonPerItem}
            tieredDebitDiscount={tieredDebitDiscount}
            tieredDebitPerItem={tieredDebitPerItem}
            amexQual={amexQual}
            amexPerItem={amexPerItem}
            amexMidQual={amexMidQual}
            amexMidPerItem={amexMidPerItem}
            amexNonQual={amexNonQual}
            amexNonPerItem={amexNonPerItem}
            errQualDiscount={errQualDiscount}
            errQualPerItem={errQualPerItem}
            errNonDiscount={errNonDiscount}
            errNonPerItem={errNonPerItem}
            discountRate={discountRate}
            perItem={perItem}
            debitDiscountRate={debitDiscountRate}
            debitNetworkFees={debitNetworkFees}
            setDebitNetworkFees={setDebitNetworkFees}
            intAmexDiscountRate={intAmexDiscountRate}
            intAmexperItem={intAmexperItem}
            pinDebit={pinDebit}
            pinDebitAuth={pinDebitAuth}
            acceptEbt={acceptEbt}
            ebt={ebt}
            ebtAuth={ebtAuth}
            mc={mc}
            amexAuth={amexAuth}
            avs={avs}
            batchFee={batchFee}
            annualFee={annualFee}
            monthlyFee={monthlyFee}
            chargeBackFee={chargeBackFee}
            monthlyMinimum={monthlyMinimum}
            earlyTerminationFee={earlyTerminationFee}
            accountReserve={accountReserve}
            wirelessFee={wirelessFee}
            other={other}
            billedOptions={billedOptions}
            billed={billed}
            handleBilledChange={handleBilledChange}
            role={role}
            merchantToken={merchantToken}
            voiceAuth={voiceAuth}
            excessiveElectronicAuth={excessiveElectronicAuth}
            irsReportingFee={irsReportingFee}
            retreivalFee={retreivalFee}
            invalidTinFee={invalidTinFee}
            achRejectFee={achRejectFee}
            collectionsFee={collectionsFee}
            pciValidation={pciValidation}
            returnTransactionFee={returnTransactionFee}
            pciNonCompliance={pciNonCompliance}
            debitAccessFee={debitAccessFee}
            industryCompliance={industryCompliance}
            amexOptubleSupportFee={amexOptubleSupportFee}
            amexOptubleNetworkFee={amexOptubleNetworkFee}
            Pricing_Type={Pricing_Type}
            handleChangePricingType={handleChangePricingType}
            pricingCardType={pricingCardType}
            handlePricingCardType={handlePricingCardType}
            tieredMidPerItem={tieredMidPerItem}
            option_yesno={option_yesno}
            handleChangePinDebit={handleChangePinDebit}
            handleAcceptEbt={handleAcceptEbt}
            clymDataPrivacyCompliance={clymDataPrivacyCompliance}
            setClymDataPrivacyCompliance={setClymDataPrivacyCompliance}
            clymDataPrivacyComplianceFee={clymDataPrivacyComplianceFee}
            disableEarlyTermination={disableEarlyTermination}
            ticketSizeOptions={ticketSizeOptions}
            ticketSize={ticketSize}
            handleTicketSizeChange={handleTicketSizeChange}
            selectedSellTime={selectedSellTime}
            discountPercentageOptions={discountPercentageOptions}
            discountPercentage={discountPercentage}
            handleDiscountPercentChange={handleDiscountPercentChange}
            serviceFeeOptions={serviceFeeOptions}
            serviceFee={serviceFee}
            handleServiceFeeChange={handleServiceFeeChange}
            licenseTechFees={licenseTechFees}
            config={config}
            pciError={pciError}
            pciErrorMessage={pciErrorMessage}
            chargeBackError={chargeBackError}
            chargeBackErrorMessage={chargeBackErrorMessage}
          />
          <div className="center-buttons ipad-center-buttons">
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('pricing', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('pricing', 'next')}

          />
          </div>
        </div>
      );
    };

    // RENDER EQUIPMENT BUTTONS
    const renderEquipmentButton = (merchantWithEquip: boolean) => {
      return equipmentSectionData.map((item, i) => {
        let disableFlag =
          role === ROLES.PARTNER ||
          role === ROLES.RELATIONSHIP_MANAGER ||
          role === ROLES.OFFICE_MANAGER ||
          role === ROLES.OFFICE_ADMIN ||
          (!item?.rm_added && !role)
            ? false
            : true;
        return (
          <div>
            <div className="three wide field">
              <Button
                color="blue"
                inverted={equipmentActive === i ? false : true}
                onClick={() => handleEquipmentClick(i)}
              >
                Equipment{i + 1}
              </Button>
              {equipmentSectionData.length - 1 === i &&
                equipmentSectionData.length < 4 && (
                  <Button
                    color="green"
                    inverted
                    onClick={() => handleAddEquipClick(i)}
                  >
                    +
                  </Button>
                )}
            </div>
            {equipmentSectionData.length > 1 && (
              <div className="three wide field" style={{ paddingTop: "10px" }}>
                <Button
                  disabled={disableFlag}
                  color="red"
                  inverted
                  onClick={() => handleRemoveEquipClick(i)}
                >
                  -
                </Button>
              </div>
            )}
          </div>
        );
      });
    };

    const getProductCost = (product: any) => {
      if (product) {
        const index = productPrices.findIndex((item: any) => {
          return item.startsWith(product);
        });
        const productValue: string = productPrices[index];
        const price = product.split("__$")[1];
        return price;
      }

      return "";
    };

    // RENDER EQUIPMENT INFORMATION
    const renderEquipmentInfo = () => {
      let merchantWithEquip =
        role !== ROLES.RELATIONSHIP_MANAGER &&
        role !== ROLES.PARTNER &&
        role !== ROLES.OFFICE_MANAGER &&
        role !== ROLES.OFFICE_ADMIN &&
        numberOfEquipments !== 0;
      return (
        <div className="form-contents">
          <div className="form-headingleftpadding" style={{ color: config?.client_config_fe?.button_color }}>
            <span
              className="navigationleft-icon"
              onClick={() => handlePrevNextNavigation("equipment", "prev")}
            />
            Equipment Information
            {((businessConduct.includes("E-Commerce") &&
              businessCategory === "E COMMERCE" &&
              businessType === "CBD" &&
              (selectedBankOption === "PB&T" ||
                selectedBankOption === "Esquire")) ||
              selectedSellTime === "Trial") && (
              <span
                className="navigationright-icon"
                onClick={() => handlePrevNextNavigation("equipment", "next")}
              />
            )}
            {!isEditMode && (
              <Button
                style={{ display: "none" }}
                color="blue"
                inverted
                className="save-button-right"
              >
                SAVE
              </Button>
            )}
          </div>
          <hr className="top" style={{ color: config?.client_config_fe?.button_color ,border:'1px solid'}}/>
          <EquipmentInformation
            equipmentSectionData={equipmentSectionData}
            role={role}
            equipmentActive={equipmentActive}
            getProductCost={getProductCost}
            setMinEquipmentCost={setMinEquipmentCost}
            setEquipmentSectionData={setEquipmentSectionData}
            equip_default_obj={equip_default_obj}
            setEquipmentActive={setEquipmentActive}
            setHideEquipment={setHideEquipment}
            equipment_terminal={equipment_terminal}
            merchantWithEquip={merchantWithEquip}
            equipment_deployment={equipment_deployment}
            condProductOptions={condProductOptions}
            productOptions={productOptions}
            equipment_billTo={equipmentBillTo}
            minEquipmentCost={minEquipmentCost}
            equipment_quantity={equipment_quantity}
            equipment_application={equipment_application}
            mailing_address={mailing_address}
            connection_type={connection_type}
            format={format}
            isMobile={isMobile}
            equipmentTerminal={equipmentTerminal}
            equipmentTypeConditionalOptions={equipmentTypeConditionalOptions}
            setEquipmentProduct={setEquipmentProduct}
            equipmentProduct={equipmentProduct}
            getProductOptions={getProductOptions}
            mobileEquipmentOptions={mobileEquipmentOptions}
            connection_type_physical={connection_type_physical}
            auto_close_default={auto_close_default}
            selectedBankOption={selectedBankOption}
            selectedSellTime={selectedSellTime}
            setEquipmentTotalCost={setEquipmentTotalCost}
            equipment_deployment_default={equipment_deployment_default}
            tokenID={tokenID}
            riskLevel={riskLevel}
            config={config}
            pinDebit={pinDebit}
          />
          <div className="center-buttons ipad-center-buttons">
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='PREV'
            className="save-button button-border"
            onClick={() => handlePrevNextNavigation('equipment', 'prev')}

          />
          <BasicButton
            textColor={config?.client_config_fe?.button_color}
            backgroundColor={'#FFFFFF'}
            size='18px'
            buttonText='NEXT'
            className={
              !(
                (businessConduct.includes("E-Commerce") &&
                  businessCategory === "E COMMERCE" &&
                  businessType === "CBD" &&
                  (selectedBankOption === "PB&T" ||
                    selectedBankOption === "Esquire")) ||
                selectedSellTime === "Trial"
              ) ? "save-button button-border not-active"
              : "save-button button-border"
            }
            onClick={() => handlePrevNextNavigation('misc', 'next')}

          />
            {/* <Button
              color="blue"
              inverted
              onClick={() => handlePrevNextNavigation("equipment", "next")}
              className="mr-0 nextClick"
              disabled={
                !(
                  (businessConduct.includes("E-Commerce") &&
                    businessCategory === "E COMMERCE" &&
                    businessType === "CBD" &&
                    (selectedBankOption === "PB&T" ||
                      selectedBankOption === "Esquire")) ||
                  selectedSellTime === "Trial"
                )
              }
            >
              NEXT
            </Button> */}
          </div>
          {!equipmentCostStatus && (
            <h4 className="warning-text">{`Minimum Equipment Cost should be $${minEquipmentCost.toFixed(
              2
            )}`}</h4>
          )}
        </div>
      );
    };

    const renderSections = () => {
      switch (activesection) {
        case "prescreening":
          return <div>{renderPreScreening()}</div>;
        case "pricing":
          return <div>{renderPricing()}</div>;
        case "equipment":
          return <div>{renderEquipmentInfo()}</div>;
        default:
          return null;
      }
    };
    const renderSendLinkTable = () => {
      return (
        <SendAppTable
          sortField={sortField}
          handleSortByFieldName={handleSortByFieldName}
          sortFieldOrder={sortFieldOrder}
          searchText={searchText}
          handleSearchByFieldName={handleSearchByFieldName}
          searchField={searchField}
          sortAndSearchResult={sortAndSearchResult}
          sendLinkUsers={sendLinkUsers}
          formatDate={formatDate}
          openViewLinkDetailsModal={openViewLinkDetailsModal}
          handleCopy={handleCopy}
          handleEditButtonClick={handleEditButtonClick}
          handleActivate={handleActivate}
          handleDuplicate={handleDuplicate}
          clearForm={clearForm}
          handleDelete={handleDelete}
          setCancelAlertOpen={setCancelAlertOpen}
          setDeleteAppLinkId={setDeleteAppLinkId}
          setDeleteModalOpen={setDeleteModalOpen}
          setIsEditMode={setIsEditMode}
          role={role}
          saveLinkId={saveLinkId}
          isEditMode={isEditMode}
          isParentChecked = {isParentChecked}
          changeCheckboxStatus = {changeCheckboxStatus}
          selectedAppBulk={selectedAppBulk}
          config={config}
        />
      );
    };

    const getTableKeys = () => {
      let headerKeys: any = [];
      let rowKeys: any = [];

      if (isMobile) {
        headerKeys = [
          { label: "Link ID",key: REACT_APP_BACKEND_MYSQL ? "app_link_code" : "app_link_id"},
          { label: "User Name", key: "partner_name" },
          { label: "Status", key: "app_link_status" },
        ];

        rowKeys = [
          { label: "Link Name", key: "link_name" },
          { label: "Link URL", key: "tiny_url" },
          { label: "Create Date", key: REACT_APP_BACKEND_MYSQL ? "created_ts" : "createdTS"},
          { label: "Last Modified", key: REACT_APP_BACKEND_MYSQL ? "updated_ts" : "updatedTS"},,
          { label: "Clicks", key: "no_of_clicks" },
          { label: "Apps", key: "no_of_apps" },
        ];
      } else if (isTablet) {
        if (window.innerHeight > window.innerWidth) {
          headerKeys = [
            { label: "Link ID", key: REACT_APP_BACKEND_MYSQL ? "app_link_code" : "app_link_id"},
            { label: "Sales User Name", key: "partner_name" },
            { label: "Link Name", key: "link_name" },
            { label: "Create Date", key: REACT_APP_BACKEND_MYSQL ? "created_ts" : "createdTS"},
            { label: "Status", key: "app_link_status" },
          ];

          rowKeys = [
            { label: "Link URL", key: "tiny_url" },
            { label: "Last Modified", key: REACT_APP_BACKEND_MYSQL ? "updated_ts" : "updatedTS"},
            { label: "Clicks", key: "no_of_clicks" },
            { label: "Apps", key: "no_of_apps" },
          ];
        } else {
          headerKeys = [
            { label: "Link ID", key: REACT_APP_BACKEND_MYSQL ? "app_link_code" : "app_link_id"},
            { label: "Sales User Name", key: "partner_name" },
            { label: "Link Name", key: "link_name" },
            { Label: "Bank Name", key: "processor_name"},
            { label: "Create Date", key: REACT_APP_BACKEND_MYSQL ? "created_ts" : "createdTS"},
            { label: "Last Modified", key: REACT_APP_BACKEND_MYSQL ? "updated_ts" : "updatedTS"},
            { label: "Status", key: "app_link_status" },
          ];

          rowKeys = [
            { label: "Link URL", key: "tiny_url" },
            { label: "Clicks", key: "no_of_clicks" },
            { label: "Apps", key: "no_of_apps" },
          ];
        }
      }

      return {
        headerKeys,
        rowKeys,
      };
    };

    return (
      <Layout page="createlink">
        {role !== ROLES.VIEW_ONLY && role !== ROLES.SALES_LIMITED ? (
          <div className="ui form startapp">
            {signModalOpen === true && isMobile && (
              <>
                <MobileSignModal
                  signModalOpen={signModalOpen}
                  setSignModalOpen={setSignModalOpen}
                  loading={loading}
                  business_name={business_name}
                  business_email={business_email}
                  setMerchantMessage={setMerchantMessage}
                  userMessage={userMessage}
                  setUserMessage={setUserMessage}
                  handleSendApp={handleSendApp}
                  business_contact={business_contact}
                />
              </>
            )}
            {!(signModalOpen && isMobile) ? (
              <div className="top-container" style={{ background: config?.client_config_fe?.button_color }}>
                <div className="top-content">                        
                   {!!role && CREATE_LINK_PAGE.includes(role) &&                                                       
                    <div className="inline fields">
                      <div className="four wide field">
                        <label className="top-content-label">
                          Choose Sales User Name
                          <span className="warning-text"> *</span>{" "}
                        </label>
                      </div>
                      <div className="twelve wide field">
                        <Dropdown
                          placeholder="select the sales user"
                          name=""
                          // className="form-input"
                          className={!partnerId ? "not-filled" : "form-input"}
                          fluid
                          search
                          selection
                          options={partners}
                          value={partnerId}
                          onChange={handleChangePartner}
                        />
                      </div>
                    </div>
                  }
                  {(role === ROLES.RELATIONSHIP_MANAGER ||
                    role === ROLES.OFFICE_MANAGER ||
                    role === ROLES.OFFICE_ADMIN || role === ROLES.PARTNER) &&(
                    <div className="inline fields">
                      <div className="four wide field">
                        <label className="top-content-label">
                          Assigned RM
                          {(partnerRm.length>0)?<span className="required-text"> *</span>:<></>}{" "}
                        </label>
                      </div>
                      <div className="twelve wide field">
                        <Dropdown
                          placeholder="Assigned RM"
                          name=""
                          className={(partnerRm.length>0? !partnerRmId : false) ? 'not-filled' :'form-input'}
                          fluid
                          search
                          selection
                          options={partnerRm}
                          value={partnerRmId}
                          onChange={handleChangePartnerRms}
                          disabled={partnerRm.length===0}
                        />
                      </div>
                    </div>
                  )}

                  <div className="inline fields">
                    <div className="four wide field">
                      <label className="top-content-label">
                        DBA Name
                        {/* <span className="margin-left-25">:</span> */}
                      </label>
                    </div>
                    <div className="twelve wide field">
                      <input
                        type="text"
                        className="form-input"
                        placeholder={isMobile ? "DBA Name" : ""}
                        name="business_name"
                        value={business_name}
                        onChange={handleChangeTextInput}
                      />
                    </div>
                  </div>
                  <div className="inline fields">
                    <div className="four wide field">
                      <label className="top-content-label">
                        Merchant Email
                        {/* <span className="margin-left-30">:</span> */}
                      </label>
                    </div>
                    <div className="twelve wide field">
                      <input
                        type="text"
                        className="form-input"
                        placeholder={isMobile ? "Merchant Email" : ""}
                        name="business_email"
                        value={business_email}
                        onChange={handleChangeTextInput}
                      />
                    </div>
                  </div>
                  {business_email !== "" &&
                  !email_pattern.test(business_email) ? (
                    <div className="inline fields">
                      <div className="sixteen wide field">
                        <span className="topmail-warning-text">
                          Please enter a Valid Email Address
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <div className="inline fields">
                    <div className="four wide field">
                      <label className="top-content-label">
                        Merchant Business Contact Name
                        {/* <span className="margin-left-15">:</span> */}
                      </label>
                    </div>
                    <div className="twelve wide field">
                      <input
                        type="text"
                        className="form-input"
                        name="business_contact"
                        value={business_contact}
                        placeholder={
                          isMobile ? "Merchant Business Contact Name" : ""
                        }
                        onChange={handleChangeTextInput}
                      />
                    </div>
                  </div>
                  {
                    isEditMode &&
                    <div className="inline fields">
                      <div className="four wide field">
                        <label className="top-content-label">
                          APP LINK ID
                          {/* <span className="margin-left-30">:</span> */}
                        </label>
                      </div>
                      <div className="twelve wide field">
                        <input
                          type="text"
                          className="form-input"
                          value ={REACT_APP_BACKEND_MYSQL ? saveLinkCode : saveLinkId}
                          disabled
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            ) : (
              <></>
            )}
            {!(signModalOpen && isMobile) ? (
              <div className="form-container">
                <div className="main-content">
                  <div className="left-content">
                    <ul className="padding-left-0">
                      {
                        <RenderBlocks
                          onOpenBlock={() => setActiveSection("prescreening")}
                          preScreeningSectionStatus={preScreeningSectionStatus}
                          activesection={activesection}
                          title={"Pre-Screening Information"}
                          renderSections={renderSections}
                          block={"prescreening"}
                          status={preScreeningSectionStatus}
                          disableSave={true}
                        />
                      }
                      {
                        <RenderBlocks
                          onOpenBlock={() => setActiveSection("pricing")}
                          pricingSectionStatus={pricingSectionStatus}
                          activesection={activesection}
                          title={"Pricing"}
                          renderSections={renderSections}
                          block={"pricing"}
                          status={pricingSectionStatus}
                          disableSave={true}
                          display={
                            role === ROLES.PARTNER ||
                            role === ROLES.RELATIONSHIP_MANAGER ||
                            role === ROLES.OFFICE_MANAGER ||
                            role === ROLES.OFFICE_ADMIN
                          }
                        />
                      }
                      {
                        <RenderBlocks
                          onOpenBlock={() => setActiveSection("equipment")}
                          equipmentSectionStatus={equipmentSectionStatus}
                          activesection={activesection}
                          title={"Equipment Information"}
                          renderSections={renderSections}
                          block={"equipment"}
                          status={equipmentSectionStatus}
                          disableSave={true}
                        />
                      }
                      {isMobile ? (
                        <>
                          <Button
                            color="blue"
                            inverted
                            onClick={handleCancel}
                            className="save-button"
                            disabled = {!isEditMode ? true : false}
                          >
                            CANCEL
                          </Button>{" "}
                          {!isEditMode && (
                           <Tooltip placement="left" title={!!saveLinkError ? saveLinkError : ''} open={!!saveLinkError ? true : false}>
                            <Button
                              color="blue"
                              onClick={() => {
                                if (
                                  business_name !== "" ||
                                  business_contact !== "" ||
                                  business_email !== ""
                                ) {
                                  setSaveLinkError(
                                    "To create a General Link, DBA Name, Merchant Email, and Contact Name on top of the page should be blank"
                                  );
                                } else {
                                  setShowSaveLinkModal(true);
                                }
                              }}
                              inverted
                              className="save-button block-button"
                              disabled={
                                ((role === ROLES.RELATIONSHIP_MANAGER ||
                                  role === ROLES.OFFICE_MANAGER ||
                                  role === ROLES.OFFICE_ADMIN) &&
                                  partnerName === "") ||
                                (partnerRm.length > 0 && partnerRmId === "") || !!saveLinkError ||
                                preScreeningSectionStatus === "" ||
                                pricingSectionStatus === "" || (partnerRm.length > 0? !partnerRmId : false)
                              }
                              
                            >
                              CREATE GENERAL LINK
                            </Button>
                            </Tooltip>
                          )}
                          {isEditMode && (
                            <Tooltip placement="left" title={!!saveLinkError ? saveLinkError : ''} open={!!saveLinkError ? true : false}>
                            <Button
                              color="blue"
                              onClick={(e) => {
                                if (
                                  business_name !== "" ||
                                  business_contact !== "" ||
                                  business_email !== ""
                                ) {
                                  setSaveLinkError(
                                    "To update General Link, DBA Name, Merchant Email, and Contact Name on top of the page should be blank"
                                  );
                                } else {
                                  setShowSaveLinkModal(true);
                                }
                              }}
                              inverted
                              className="save-button mob-update block-button"
                              // disable upadte link
                              disabled={
                                ((role === ROLES.RELATIONSHIP_MANAGER ||
                                  role === ROLES.OFFICE_MANAGER ||
                                  role === ROLES.OFFICE_ADMIN) &&
                                  partnerName === "") ||
                                (partnerRm.length > 0 && partnerRmId === "") || !!saveLinkError ||
                                preScreeningSectionStatus === "" ||
                                pricingSectionStatus === ""
                                }
                            >
                              UPDATE GENERAL LINK
                            </Button>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <li className="vertical-list"></li>
                      )}
                      <li className="vertical-list"></li>
                      {isMobile ? (
                        <>
                          {" "}
                          <Button
                            color="blue"
                            inverted
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setSignModalOpen(true);
                            }}
                            className="save-button mob-sent"
                            disabled={
                              ((role === ROLES.RELATIONSHIP_MANAGER ||
                                role === ROLES.OFFICE_MANAGER ||
                                role === ROLES.OFFICE_ADMIN) &&
                                partnerName === "") ||
                              business_name === "" ||
                              business_contact === "" ||
                              !merchantEmailValid ||
                              business_email === "" ||
                              !email_pattern.test(business_email) ||
                              preScreeningSectionStatus === "" ||
                              pricingSectionStatus === "" || (partnerRm.length > 0? !partnerRmId : false)
                            }
                          >
                            SEND TO MERCHANT
                          </Button>
                          {/* {saveLinkError && (
                            <div className="flex">
                              <span className="alert-icon" />
                              <span className="error-message alert-text-message">
                                {saveLinkError}
                              </span>
                            </div>
                          )} */}
                        </>
                      ) : (
                        <li className="vertical-list"></li>
                      )}
                      <li className="vertical-list"></li>
                    </ul>
                  </div>
                  <div className="right-content">
                    <div className="right-form-content">
                      {renderSections()}
                      <div className="center-buttons">
                        <BasicButton
                          textColor={config?.client_config_fe?.button_color}
                          backgroundColor={'#FFFFFF'}
                          size='18px'
                          buttonText='CANCEL'
                          className={!isEditMode ? "save-button button-border not-active" : "save-button button-border"}
                          onClick={handleCancel}

                        />
                        <BasicButton
                          textColor={config?.client_config_fe?.button_color}
                          backgroundColor={'#FFFFFF'}
                          size='18px'
                          buttonText='SEND TO MERCHANT'
                          className={((role === ROLES.RELATIONSHIP_MANAGER ||
                            role === ROLES.OFFICE_MANAGER ||
                            role === ROLES.OFFICE_ADMIN) &&
                            partnerName === "") ||
                            business_name === "" ||
                            business_contact === "" ||
                            !merchantEmailValid ||
                            business_email === "" ||
                            !email_pattern.test(business_email) ||
                            preScreeningSectionStatus === "" ||
                            pricingSectionStatus === "" || (partnerRm.length > 0 ? !partnerRmId : false)
                            ? "save-button button-border not-active" : "save-button button-border"}
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSignModalOpen(true);
                          }}

                        />
                        {!isEditMode && (
                          <Tooltip placement={isTablet ? "topRight" : 'right'} title={!!saveLinkError ? saveLinkError : ''} open={!!saveLinkError ? true : false}>
                          <BasicButton
                            textColor={config?.client_config_fe?.button_color}
                            backgroundColor={'#FFFFFF'}
                            size='18px'
                            buttonText='CREATE GENERAL LINK'
                            className={((role === ROLES.RELATIONSHIP_MANAGER ||
                              role === ROLES.OFFICE_MANAGER ||
                              role === ROLES.OFFICE_ADMIN) &&
                              partnerName === "") ||
                              (partnerRm.length > 0 && partnerRmId === "") || !!saveLinkError ||
                              preScreeningSectionStatus === "" ||
                              pricingSectionStatus === "" || (partnerRm.length > 0 ? !partnerRmId : false)
                              ? "save-button button-border not-active" : "save-button button-border"}
                            onClick={() => {
                              if (
                                business_name !== "" ||
                                business_contact !== "" ||
                                business_email !== ""
                              ) {
                                setSaveLinkError(
                                  "To create a General Link, DBA Name, Merchant Email, and Contact Name on top of the page should be blank"
                                );
                              } else {
                                setShowSaveLinkModal(true);
                              }
                            }}

                          />
                          </Tooltip>
                        )}
                        {isEditMode && (
                          <Tooltip  placement={isTablet ? "topRight" : 'right'} title={!!saveLinkError ? saveLinkError : ''} open={!!saveLinkError ? true : false}>
                          <BasicButton
                            textColor={config?.client_config_fe?.button_color}
                            backgroundColor={'#FFFFFF'}
                            size='18px'
                            buttonText='UPDATE GENERAL LINK'
                            className={((role === ROLES.RELATIONSHIP_MANAGER ||
                              role === ROLES.OFFICE_MANAGER ||
                              role === ROLES.OFFICE_ADMIN) &&
                              partnerName === "") ||
                              (partnerRm.length > 0 && partnerRmId === "") || !!saveLinkError ||
                              preScreeningSectionStatus === "" ||
                              pricingSectionStatus === "" || (partnerRm.length > 0 ? !partnerRmId : false)
                              ? "save-button button-border not-active" : "save-button button-border"}
                            onClick={(e: any) => {
                              if (
                                business_name !== "" ||
                                business_contact !== "" ||
                                business_email !== ""
                              ) {
                                setSaveLinkError(
                                  "To update General Link, DBA Name, Merchant Email, and Contact Name on top of the page should be blank"
                                );
                              } else {
                                setShowSaveLinkModal(true);
                              }
                            }}

                          />
                          </Tooltip>
                        )}
                      </div>
                      {/* {saveLinkError && (
                        <div className="flex">
                          <span className="alert-icon" />
                          <span className="error-message alert-text-message">
                            {saveLinkError}
                          </span>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ): <></>}
        {!(signModalOpen && isMobile) ? (
          <div className="applist-container">
            {((!!sendLinkUsers && sendLinkUsers.length > 0) ||
              sendLinkUsersLoading) && (
              <>
                { Object.keys(selectedAppBulk).length > 1 && (
                  <div className='bulkupdate-action'>
                    <Select
                      isMulti={false}
                      value={bulkaction}
                      options={appBulkAction}
                      onChange={ bulkUpdateAction}
                      placeholder="Bulk Action"
                      isSearchable={false}
                    />
                  </div>
                )}
                {isMobile || isTablet ? (
                  <>
                    <TableComponent
                      headerList={getTableKeys().headerKeys}
                      tableData={tableData}
                      uniqueId={"app_link_id"}
                      rowKeys={getTableKeys().rowKeys}
                      heading={
                        role === ROLES.VIEW_ONLY
                          ? "APP LINK LIST"
                          : "LIST OF LINKS"
                      }
                      noAppMessage={
                        role === ROLES.VIEW_ONLY || role === ROLES.SALES_LIMITED
                          ? "No App Links as of now, please start from Create Link"
                          : "NO APP LINKS FOUND"
                      }
                      displayFooter={true}
                      saveLinkId={saveLinkId}
                      isEditMode={isEditMode}
                      loading={loading || algoliaLoading}
                      viewOptions= {viewOptions}
                      changeCheckboxStatus={changeCheckboxStatus}
                      isParentChecked = {isParentChecked}
                      bulkId= {"link_id"}
                    />

                  </>
                ) : (
                  <div
                    className={
                      window.innerWidth > 1640
                        ? "body-content"
                        : "listapp-body-content"
                    }
                  >
                    <div className="heading">
                      <span className="heading-text-left">
                        {role === ROLES.VIEW_ONLY
                          ? "APP LINK LIST"
                          : "List Of Links"}
                      </span>
                    </div>
                    <ProgressLoader
                      loading={sendLinkUsersLoading}
                      size="large"
                      config={config}
                    />

                    {renderSendLinkTable()}
                  </div>
                )}
              </>
            )}
            {sendLinkUsers.length === 0 &&
              !loading &&
              !algoliaLoading &&
              (role === ROLES.VIEW_ONLY || role === ROLES.SALES_LIMITED) && (
                <div className="body-content">
                  <span className="heading-center">{noAppText}</span>
                </div>
              )}
          </div>
        ) : (
          <></>
        )}
        <Confirm
          open={emptyTopFields}
          content="DBA Name,Primary Contact Email and Business Contact Name should be Blank"
          confirmButton="OK"
          onCancel={handleEmptyFieldAlert}
          onConfirm={handleEmptyFieldAlert}
        />
        <Confirm
          open={cancelAlertOpen}
          content="Are you Sure? You may loose the Data"
          cancelButton="CANCEL"
          confirmButton= {<Button style={{ background: config?.client_config_fe?.button_color }}>OK</Button>}
          onCancel={handleCancelCancel}
          onConfirm={handleCancelConfirm}
        />
        <ProgressLoader
          loading={(loading && fetchingProcessors) || loading}
          size="large"
        />

        {showSaveLinkModal === true && (
          <ShowSaveLinkModal
            showSaveLinkModal={showSaveLinkModal}
            linkName={linkName}
            handleChangeTextInput={handleChangeTextInput}
            isEditMode={isEditMode}
            handleSaveLinkApp={handleSaveLinkApp}
            handleUpdateLinkApp={handleUpdateLinkApp}
            setShowSaveLinkModal={setShowSaveLinkModal}
            config={config}
          />
        )}

        {showViewLinkDetailsModal === true && (
          <ShowViewLinkDetailsModal
            showViewLinkDetailsModal={showViewLinkDetailsModal}
            linkDetailsID={linkDetailsID}
            linkDetailsName={linkDetailsName}
            linkDetailsTinyURL={linkDetailsTinyURL}
            linkDetailsURL={linkDetailsURL}
            linkDetailsNoOfClicks={linkDetailsNoOfClicks}
            linkDetailsNoOfApps={linkDetailsNoOfApps}
            linkDetailsPartnerName={linkDetailsPartnerName}
            linkDetailsconditionalSellType={linkDetailsconditionalSellType}
            linkDetailssellTypeValue={linkDetailssellTypeValue}
            linkDetailsconditionalSellGood={linkDetailsconditionalSellGood}
            linkDetailsbusinessType={linkDetailsbusinessType}
            linkDetailsselectedBankOption={linkDetailsselectedBankOption}
            linkDetailsselectedSellTime={linkDetailsselectedSellTime}
            setShowViewLinkDetailsModal={setShowViewLinkDetailsModal}
            config={config}
          />
        )}

        {!isMobile ? (
          <>
            {signModalOpen === true && (
              <SignModalOpenPage
                signModalOpen={signModalOpen}
                setSignModalOpen={setSignModalOpen}
                loading={loading}
                business_name={business_name}
                business_email={business_email}
                setMerchantMessage={setMerchantMessage}
                userMessage={userMessage}
                setUserMessage={setUserMessage}
                handleSendApp={handleSendApp}
                business_contact={business_contact}
                config={config}
              />
            )}
          </>
        ) : (
          <></>
        )}

        {sentMailModalOpen === true && (
          <SentMailModal
            setSentMailModalOpen={setSentMailModalOpen}
            sentMailModalOpen={sentMailModalOpen}
            setSaveLinkError={setSaveLinkError}
            config={config}
          />
        )}

        {deleteModalOpen && (
          <DeleteModalPage
            deleteModalOpen={deleteModalOpen}
            overlay={overlay}
            overlayMsg={overlayMsg}
            handleDelete={handleDelete}
            loading={loading}
            deleteAppLinkId={deleteAppLinkId}
            setDeleteAppLinkId={setDeleteAppLinkId}
            setDeleteModalOpen={setDeleteModalOpen}
          />
        )}
        {actionConfirmation && (
          <ActionConfirmation
            selectedUser={selectedAppBulk}
            loading={loading}
            deactivateModalOpen={actionConfirmation}
            handleCloseModal={handleCloseModal}
            modalAction = {modalAction}
            countforAction = {statusCount}
            handleActivateBulk = {handleActivateBulk}
            config={config}
          />
        )}
        {actionConfirmationDelete && (
          <ActionConfirmationDelete
            selectedUser={selectedAppBulk}
            loading={loading}
            deactivateModalOpen={actionConfirmationDelete}
            handleCloseModal={handleCloseModal}
            modalAction = {modalAction}
            countforAction = {inactiveStatusCount}
            handleDeleteBulk = {handleDeleteBulk}
          />
        )}

      
        {showProgress && (
        <div className="overlay" style={overlay.style}>
          <div className="overlay-image">
          <Progress percent={progress} status="active" showInfo={false} className="progress-bar" />
            <span className="overlay-text" style={{ color: config?.client_config_fe?.button_color }}>{overlayMsg}</span>
          </div>
        </div>
        )}
        <Footer page="createlink" />
        <ButtonScrollTop />
      </Layout>
    );
};

export default memo(SendApp);
